import { reactive } from "vue";
import { appConsts } from "./constants.js";
// import { useAuthStore } from '@/store/auth.js'
// // import axios from 'axios';
// const authStore = useAuthStore();

export const functions = reactive({
  async getHttp(endURL, token) {
    const url = appConsts.baseURL + endURL;
    await axios.get(url, token).then((response) => {
      if (response.data.status == "success") {
        // console.log(response.data.data);
        return response.data.data;
      } else {
        console.log(response.data.message);
      }
    });
  },

  async postForm(data, endURL, token, ifStatus, returnit = false) {
    const url = appConsts.baseURL + endURL;
    if (returnit) {
      return await axios.post(url, data, token);
    } else {
      await axios.post(url, data, token).then((response) => {
        if (response.data.status == "success") {
          //this.kSwal(ifStatus, response.data.message);
          return response.data.status;
        } else {
          //this.kErrorSwal(response.data.message);
        }
      });
    }
  },

  async postHttp(data, endURL, token, ifStatus, elseStatus) {
    const url = appConsts.baseURL + endURL;
    await axios.post(url, data, token).then((response) => {
      if (response.data.status == "success") {
        if (data.statusValue == 1) {
          // this.kSwal (ifStatus, response.data.message)
        } else {
          //this.kSwal (elseStatus, response.data.message)
        }
      } else {
        // this.kErrorSwal(response.data.message);
      }
    });
  },

  kSwal(title, message) {
    Swal.fire(title + "!", message, "success");
  },
  kErrorSwal(message) {
    Swal.fire("Error", message, "error");
  },
  destroyBtnLoader() {
    $(".preloader3").parent().removeAttr("disabled");
    $(".preloader3").remove();
  },
  destroyLoader() {
    $(".theme-loader").fadeOut("slow", function () {
      $(this).remove();
    });
  },
  askSwal(title = 'Are you sure?',msg = 'You won\'t be able to revert this!',btntext = 'Yes, delete it!') {
    return Swal.fire({
      title: title,
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: btntext
    });
  },
  initSoftLoader() {
    Swal.fire({
      title: "Loading....",
      text: "Please Wait, This may take a while...",
      iconHtml: `<div class="loader-block">
    <svg id="loader2" viewBox="0 0 100 100">
    <circle id="circle-loader2" cx="50" cy="50" r="45"></circle>
    </svg>
    </div>`,
      showConfirmButton: false,
    });
  },
  initLoader() {
    // $('#pcoded').parent().append(`<div class="theme-loader">
    $(".main-body").append(`<div class="theme-loader">
        <div class="ball-scale">
            <div class='contain'>
                <div class="ring">
                    <div class="frame"></div>
                </div>
                <div class="ring">
                    <div class="frame"></div>
                </div>
                <div class="ring">
                    <div class="frame"></div>
                </div>
                <div class="ring">
                    <div class="frame"></div>
                </div>
                <div class="ring">
                    <div class="frame"></div>
                </div>
                <div class="ring">
                    <div class="frame"></div>
                </div>
                <div class="ring">
                    <div class="frame"></div>
                </div>
                <div class="ring">
                    <div class="frame"></div>
                </div>
                <div class="ring">
                    <div class="frame"></div>
                </div>
                <div class="ring">
                    <div class="frame"></div>
                </div>
            </div>
        </div>
    </div>`);
  },
  callOnReady() {
    var elem = Array.prototype.slice.call(
      document.querySelectorAll(".js-switch")
    );
    elem.forEach(function (html) {
      new Switchery(html, { color: "#4680ff", jackColor: "#fff" });
    });
    if ($(".data_table").length > 0) {
      $(".data_table").each(function () {
        $(this).DataTable({
          ordering: true,
          searching: true,
        });
      });
    }
    // $(document).ready(function(){
    // console.log($("#verticle-wizard").length);
    if ($("#verticle-wizard").length > 0) {
      $("#verticle-wizard").steps({
        headerTag: "h3",
        bodyTag: "fieldset",
        transitionEffect: "slide",
        stepsOrientation: "vertical",
        autoFocus: true,
      });
    }
    $(document).on("click", ".funload", function () {
      $(this).attr("disabled");
      $(this).append(`<div class="preloader3  ">
<div class="circ1 loader-default"></div>
<div class="circ2 loader-default"></div>
<div class="circ3 loader-default"></div>
<div class="circ4 loader-default"></div>
</div>`);
    });
    swal.close();
    $(".theme-loader").fadeOut("slow", function () {
      $(this).remove();
    });
  },
});
