<template>
    <div class="main-body">
        <div class="page-wrapper">
            <!-- Page body start -->
            <div class="page-body">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-header">
                                <h5>
                                    <span>Homes</span>
                                    <button type="button"
                                        class="btn btn-primary addnewbtn btn-round waves-effect f-right "
                                        @click="addNew()">Add
                                        New</button>
                                </h5>
                                <div class="card-header-right">
                                    <div class="page-header-breadcrumb">
                                        <ul class="breadcrumb-title">
                                <li class="breadcrumb-item" style="float: left;">
                                    <a href="#">
                                        <i class="icofont icofont-home"></i>
                                    </a>
                                </li>
                                <li class="breadcrumb-item" style="float: left;"><router-link
                                        :to="{ name: 'VAHomes' }">Homes</router-link>
                                </li>
                            </ul>
                                    </div>
                                </div>

                            </div>
                            <div class="card-body">
                                <table-view v-if="displaytbl" :data="responsefromapi" @viewbuttonclicked="editbtnclicked" @activeunactive="activeunactive"
                                    @deletebuttonclicked="deletebuttonclicked" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script setup>

import TableView from '../../../shared/TableView.vue';
import { functions } from '../../../../common/appFunctions.js'; 
import { ref, reactive, onMounted } from 'vue';
import { useAuthStore } from '@/store/auth.js'
import factory from '@/common/factory.js' 
import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const authStore = useAuthStore(); 

var responsefromapi = ref();
var displaytbl = ref(false);
function getToken() {
    const token = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authStore.token,
        },
    };
    return token;
}
 
function deletebuttonclicked(id) {
  var ask = functions.askSwal();
  ask.then(async (result) => {
    if (result.isConfirmed) {
      const homes = new factory(getToken(), "homes/" + id);
      await homes.deleteHttp().then((response) => {
        if (response.data.status == "success") {
          location.reload();
        } else {
          console.log(response.data.message);
        }
      });
    }
  });
}
async function activeunactive(id,status){
    var upactiveunactive = {
        home_id:id,
        home_isactive:!status
    }
    var updatestatus = new factory(getToken(), 'activehomes', upactiveunactive);
    await updatestatus.putForm().then((response) => {
        swal.fire('Done!', 'Status Changed Successfully.','success');
    });
}
function editbtnclicked(id) {
    router.push({
        name: 'VAEditHome',
        query: {
            id,
        },
    })
}
function addNew(id) {
    router.push({
        name: 'VAAddHome'
    })
}
onMounted(async () => { 
    functions.initLoader();
    const Homes = new factory(getToken(), 'homes');
    const dt = await Homes.getHttp().then(response => {
        functions.destroyLoader();
        var tabledata = {
            "conditions": {
                'update': true,
                'trash': true,
                'active': true,
                'primarykey': 'home_id',
                'hiddendata': ['home_id'],
                'active_index': 'home_isactive',
            },
            "columns": [
                'Name',
                'Company',
                'Administrator',
                'Start Date',
                'End Date',
                'Active',
                '',
            ],
            "data_order": ['home_name','company','administrator','home_start_date','home_end_date'],
            "data": response.data.data
        };
        displaytbl.value = true
        responsefromapi.value = tabledata; 
    });
    functions.callOnReady();
});
</script> 
