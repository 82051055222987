<template>
    <div class="pcoded-inner-content">

        <div class="main-body">
            <div class="page-wrapper">

                <div class="page-body">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-header">
                                    <h5>
                                        <span>Add New User</span>

                                    </h5>
                                    <div class="card-header-right">
                                        <div class="page-header-breadcrumb">
                                            <ul class="breadcrumb-title">
                                                <li class="breadcrumb-item" style="float: left;">
                                                    <a href="#"> <i class="feather icon-home"></i> </a>
                                                </li>
                                                <li class="breadcrumb-item" style="float: left;"><router-link
                                                        :to="{ name: 'VAUsers' }">Users</router-link>
                                                </li>
                                                <li class="breadcrumb-item" style="float: left;">
                                                    <a href="#"> Add New User </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                                <div class="card-body">
                                    <form class="wizard-form wizard clearfix vertical" action="#">
                                        <div class="steps clearfix">
                                            <ul class="tablist" role="tablist">
                                                <li role="tab" class="first current" aria-disabled="true"><a
                                                        id="verticle-wizard-t-1" href="javascript:void(0);"
                                                        data-target="#verticle-wizard-p-1"
                                                        aria-controls="verticle-wizard-p-1"><span class="number"></span>
                                                        Personal Information </a></li>
                                                <li role="tab" class="disabled" aria-disabled="false" aria-selected="true">
                                                    <a id="verticle-wizard-t-0" href="javascript:void(0);"
                                                        data-target="#verticle-wizard-p-0"
                                                        aria-controls="verticle-wizard-p-0"><span
                                                            class="current-info audible">current step: </span><span
                                                            class="number"></span> Access Administration </a>
                                                </li>
                                                
                                            </ul>
                                        </div>
                                        <div class="content clearfix">
 
                                            <fieldset id="verticle-wizard-p-1" role="tabpanel"
                                                aria-labelledby="verticle-wizard-h-0" class="body current"
                                                aria-hidden="true">
                                                <div class="row">
                                                    <image-input v-model="form.u_photo" class="col-md-4"
                                                        :source="form.u_photo" kStyle="height:20vh;" />

                                                    <div class="col-md-8">
                                                        <div class="row">
                                                            <text-input v-model="form.u_firstname" class="col-md-4"
                                                                label="First Name" />
                                                            <text-input v-model="form.u_middlename" class="col-md-4"
                                                                label="Middle Name" />
                                                            <text-input v-model="form.u_lastname" class="col-md-4"
                                                                label="Last Name" />
                                                            <text-input v-model="form.email" class="col-md-6"
                                                                label="Email" />
                                                            <select-input v-model="form.user_company_id"
                                                                class="col-md-6 my-select selectpicker" label="Company">
                                                                <option value="">Select...</option>
                                                                <option v-for="comp in companiesdata" :value="comp.c_id">{{
                                                                    comp.c_name }}</option>
                                                            </select-input>
                                                            <text-input type="date" v-model="form.u_birthdate" class="col-md-6"
                                                                label="Date of Birth" />
                                                            <select-input v-model="form.u_isactive"
                                                                class="col-md-6 my-select selectpicker" label="Active">
                                                                <option value="0">No</option>
                                                                <option value="1">Yes</option>
                                                            </select-input>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-12">
                                                        <hr />
                                                    </div>
                                                    <select-input v-model="form.u_account_type_id"
                                                        class="col-md-4 my-select selectpicker" label="User Type"> 
                                                        <option value="">Select...</option>
                                                        <option v-for="comp in usertypedata" :value="comp.at_id">{{
                                                                    comp.at_name }}</option>
                                                    </select-input>
                                                    <select-input v-model="form.user_role"
                                                        class="col-md-4 my-select selectpicker" label="User Role">
                                                        <option value="0">Select</option>
                                                        <option v-for="comp in rolesdata" :value="comp.role_id">{{
                                                                    comp.role }}</option>
                                                    </select-input>
                                                    <select-input v-model="form.u_gender" class="col-md-4"
                                                        label="Gender">
                                                        <option value="">Select...</option>
                                                        <option v-for="gender, k in genderdata" :value="k">
                                                            {{ gender }}
                                                        </option>
                                                    </select-input>
                                                </div>
                                            </fieldset>
                                            <fieldset id="verticle-wizard-p-0" style="display:none" role="tabpanel"
                                                aria-labelledby="verticle-wizard-h-0" class="body " aria-hidden="true">
                                                <div class="row">
                                                    <select-input v-model="form.user_hidefuturetask"
                                                        class="col-md-4 my-select selectpicker" label="Hide Future tasks">
                                                        <option value="0">No</option>
                                                        <option value="1">Yes</option>
                                                    </select-input>
                                                    <select-input v-model="form.user_accessristrict"
                                                        class="col-md-4 my-select selectpicker"
                                                        label="Access restricted to Company Network">
                                                        <option value="0">No</option>
                                                        <option value="1">Yes</option>
                                                    </select-input>
                                                    <select-input v-model="form.user_ristrictlogin"
                                                        class="col-md-4 my-select selectpicker"
                                                        label="Restrict Login to Region">
                                                        <option value="0">No</option>
                                                        <option value="1">Yes</option>
                                                    </select-input>
                                                    <text-input v-model="form.user_disableloginat" class="col-md-4"
                                                        label="Disable Login At" />
                                                    <select-input v-model="form.user_instantexpiry"
                                                        class="col-md-4 my-select selectpicker"
                                                        label="Password Instant Expire">
                                                        <option value="0">No</option>
                                                        <option value="1">Yes</option>
                                                    </select-input>
                                                    <text-input type="date" v-model="form.user_passwordexpiry"
                                                        class="col-md-4" label="Password Expires At" />
                                                    <text-input v-model="form.password" class="col-md-4"
                                                        label="Password" />
                                                    <text-input v-model="form.password_confirmation" class="col-md-4"
                                                        label="Confirm Password" />
                                                </div>
                                            </fieldset>
                                             
                                            



                                        </div>
                                        <div class="actions clearfix">
                                            <ul role="menu" aria-label="Pagination">
                                                <li aria-disabled="true" class="wizard-prev-btn disabled">
                                                    <a href="javascript:void(0);" role="menuitem">Previous</a>
                                                </li>
                                                <li aria-hidden="false" aria-disabled="false" class="wizard-next-btn"><a
                                                        href="javascript:void(0);" role="menuitem">Next</a></li>
                                                <li aria-hidden="true" style="display: none;" class="wizard-finish"><a
                                                        href="javascript:void(0);" role="menuitem" @click="addUser();">Finish</a></li>
                                            </ul>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
    <div class="modal fade" id="add_address">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Add Address</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        @click="hidemodal('#add_address');">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <text-input class="col-md-8" v-model="address_name" label="Name" />
                        <select-input v-model="address_country" class="col-md-4" label="Country">
                            <option value="">Select </option>
                        </select-input>
                        <text-input class="col-md-4" v-model="address_city" label="City" />
                        <text-input class="col-md-4" v-model="address_state" label="State" />
                        <text-input class="col-md-4" v-model="address_postcode" label="Post Code" />
                        <text-input class="col-md-6" v-model="address_address_1" label="Address 1" />
                        <text-input class="col-md-6" v-model="address_address_2" label="Address 2" />
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default waves-effect " data-dismiss="modal"
                        @click="hidemodal('#add_address');">Close</button>
                    <button type="button" class="btn btn-primary btn-round waves-effect waves-light "
                        @click="addUserAddress();">Save
                        Information</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="add_phone">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Add Phone Number</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        @click="hidemodal('#add_phone');">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <select-input v-model="phonenumber_type" class="col-md-6" label="Number Type">
                            <option value="1">Home </option>
                            <option value="2">Work </option>
                        </select-input>
                        <select-input v-model="phonenumber_country" class="col-md-6" label="Country">
                            <option value="">Select </option>
                        </select-input>
                        <text-input class="col-md-12" v-model="phonenumber" label="Number" />
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default waves-effect " data-dismiss="modal"
                        @click="hidemodal('#add_phone');">Close</button>
                    <button type="button" class="btn btn-primary btn-round waves-effect waves-light "
                        @click="addUserPhone();">Save
                        Information</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="add_socialmedia">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Add Social Media Info</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        @click="hidemodal('#add_socialmedia');">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <select-input v-model="socialmedia_type" class="col-md-12" label="Social Media Type">
                            <option value="1">FB </option>
                            <option value="2">Insta </option>
                        </select-input>
                        <text-input class="col-md-12" v-model="socialmedia_name" label="Name" />
                        <text-input class="col-md-12" v-model="socialmedia_link" label="Link" />
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default waves-effect " data-dismiss="modal"
                        @click="hidemodal('#add_socialmedia');">Close</button>
                    <button type="button" class="btn btn-primary btn-round waves-effect waves-light "
                        @click="addUserSocial();">Save
                        Information</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import SelectInput from '../../../shared/SelectBox.vue';
import TextInput from '../../../shared/TextInput'
import TextareaInput from '../../../shared/TextareaInput'
import ImageInput from '../../../shared/ImageInput.vue'
import { ref, reactive, onMounted, onBeforeMount } from 'vue';
import { useAuthStore } from '@/store/auth.js'
import { functions } from '../../../../common/appFunctions.js';
import factory from '@/common/factory.js'
import { useRouter } from 'vue-router';
const router = useRouter();
const authStore = useAuthStore();

var address_name = ref('');
var address_country = ref('');
var address_city = ref('');
var address_state = ref('');
var address_postcode = ref('');
var address_address_1 = ref('');
var address_address_2 = ref('');
var phonenumber_type = ref('');
var phonenumber_country = ref('');
var phonenumber = ref('');
var socialmedia_type = ref('');
var socialmedia_link = ref('');
var socialmedia_name = ref('');

// ****** Dropdowns data ********************
var companiesdata = ref([]);
var genderdata = ref([]);
var usertypedata = ref([]);
var rolesdata = ref([]);
// ****** Dropdowns data ********************

const form = ref({
    u_vendor_id:authStore.user.u_vendor_id,
    u_photo: 'https://demo.dashboardpack.com/adminty-html/files/assets/images/gallery-grid/1.png',
    u_firstname: '',
    u_middlename:'',
    u_lastname: '',
    email: '',
    u_name:'',
    u_isverified: 1,
    // user_company_id: '',
    u_birthdate: '',
    u_isactive: '',
    u_account_type_id: '',
    user_role: '',
    u_gender: '',
    // user_hidefuturetask: '',
    // user_accessristrict: '',
    // user_ristrictlogin: '',
    // user_disableloginat: '',
    // user_passwordexpiry: '',
    password: '',
    password_confirmation: '',
    // user_addresses: [],
    // user_phonenumbers: [],
    // user_sociallinks: [],
});
function addUserAddress() {
    if (address_name.value.trim() == '') {
        alert("Kindly Enter Vehicle IN!");
        return false;
    }
    form.user_addresses.push({
        address_name: address_name.value,
        address_country: address_country.value,
        address_city: address_city.value,
        address_state: address_state.value,
        address_postcode: address_postcode.value,
        address_address_1: address_address_1.value,
        address_address_2: address_address_2.value,
    });
    address_name.value = '';
    address_country.value = '';
    address_city.value = '';
    address_state.value = '';
    address_postcode.value = '';
    address_address_1.value = '';
    address_address_2.value = '';
    hidemodal('#add_address');
}
function addUserPhone() {
    if (phonenumber.value.trim() == '') {
        alert("Kindly Enter Vehicle IN!");
        return false;
    }
    form.user_phonenumbers.push({
        type: phonenumber_type.value,
        country: phonenumber_country.value,
        phonenumber: phonenumber.value,

    });
    phonenumber_type.value = '';
    phonenumber_country.value = '';
    phonenumber.value = '';
    hidemodal('#add_phone');
}
function addUserSocial() {
    if (socialmedia_name.value.trim() == '') {
        alert("Kindly Enter Vehicle IN!");
        return false;
    }
    form.user_sociallinks.push({
        type: socialmedia_type.value,
        link: socialmedia_link.value,
        name: socialmedia_name.value,

    });
    socialmedia_type.value = '';
    socialmedia_link.value = '';
    socialmedia_name.value = '';
    hidemodal('#add_socialmedia');
}
function getToken() {
    const token = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authStore.token,
        },
    };
    return token;
}
onMounted(async () => {
    functions.initSoftLoader();
    const Users = new factory(getToken(), 'getallapis', { index: ['companies', 'gender','AccountTypes','roles'] });
    await Users.postForm().then((response) => {
        if (response.data.status == "success") {
            companiesdata.value = response.data.data.companies;
            genderdata.value = response.data.data.gender;
            usertypedata.value = response.data.data.accountTypes;
            rolesdata.value = response.data.data.roles;
        }
    });

    functions.callOnReady();
});
async function addUser(){
    functions.initSoftLoader();
    
    form.value.u_name = form.value.u_firstname+form.value.u_middlename+form.value.u_lastname;
    const Users = new factory(getToken(), 'users', form.value);
    await Users.postForm().then((response) => {
        if (response.data.status == "success") {
            router.push({ name: 'VAUsers' })
        }
    });
}

 
function showmodal(id) {
    $(id).modal('show');
}
function hidemodal(id) {
    $(id).modal('hide');
}
</script>
<style scoped>
#wizard2 fieldset {
    display: block;
    position: relative;
}

.wizard>.current {
    display: block;
}

.wizard>.content>.body {
    position: relative;

}
</style>