import { createStore } from 'vuex';
const store = createStore({
    state() {
        return {
            token: localStorage.getItem('token'),
            user: localStorage.getItem('user'),
            role: localStorage.getItem('role'),
        };
    },
    getters: {

    },
    mutations: {
        DELETE_TOKEN(state) {
            state.token = null;
            localStorage.removeItem('token');
        },
        SAVE_TOKEN(state, payload) {
            state.token = payload;
            localStorage.setItem('token', payload);
        },
        SAVE_ROLE(state, payload) {
            state.role = payload;
            localStorage.setItem('role', payload);
        },
        SAVE_USER(state, payload) {
            state.user = payload;
            localStorage.setItem('user', payload);
        },
    },
    actions: {
        addToken({ commit },value){
            commit('SAVE_TOKEN',value)
        },
        addUser({ commit },value){
            commit('SAVE_USER',value)
        },
        addRole({ commit },value){
            commit('SAVE_ROLE',value)
        },
        deleteToken({ commit },value){
            commit('DELETE_TOKEN',value)
        }
    },
});

export default store;
