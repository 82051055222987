
<template>
    <table class="table  data_table">
        <thead>
            <tr>
                <td :class="data.conditions.active ? 'text-center' : ''" v-for="column in data.columns ">{{ column }}
                </td>
            </tr>
        </thead>
        <tbody>
            <template v-if="data.data.length > 0">
                <template v-for="(rows, index1, key1) in data.data ">
                    <tr v-bind:data-id="data.data[index1][data.conditions.primarykey]">
                        <template v-for="(row, index2, key2) in data.data_order">
                            <td v-if="!data.conditions.hiddendata.includes(row)">{{ data.data[index1][row] }}</td>
                            <td class="text-center"
                                v-if="(index2 == (Object.keys(data.data_order).length - 1) && (data.conditions.active))">

                                <!-- {{ data.data[index1][data.conditions.active_index] = (data.data[index1][data.conditions.active_index] == 1 || data.data[index1][data.conditions.active_index] == true) ? true : false }} -->
                                <div class="toggle-button-cover">
                                    <div class="button b2" id="button-11">
                                        <input
                                            v-if="(data.data[index1][data.conditions.active_index] == 1 || data.data[index1][data.conditions.active_index] == true) ? true : false"
                                            type="checkbox" class="checkbox "
                                            v-model="data.data[index1][data.conditions.active_index]"
                                            @change="activeUnactive($event, data.data[index1][data.conditions.primarykey]);">
                                        <input
                                            v-if="(data.data[index1][data.conditions.active_index] == 1 || data.data[index1][data.conditions.active_index] == true) ? false : true"
                                            type="checkbox" checked class="checkbox"
                                            @change="activeUnactive($event, data.data[index1][data.conditions.primarykey]);">
                                        <div class="knobs">
                                            <span></span>
                                        </div>
                                        <div class="layer"></div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center"
                                v-if="(index2 == (Object.keys(data.data_order).length - 1) && (data.conditions.update || data.conditions.trash || data.conditions.extra_btns != null))">
                                <template
                                    v-if="data.conditions.extra_btns != null && data.conditions.extra_btns.length > 0">
                                    <loading-button v-for="extrabtn in data.conditions.extra_btns"
                                        :class="extrabtn.cssclass" class="m-1 btn badge" style="padding:10px" type="button"
                                        @click="extrabuttonclicked(extrabtn.returncondition, data.data[index1][data.conditions.primarykey])">
                                        <i v-if="extrabtn.icon != null" :class="extrabtn.icon" class=" fs-5"></i> {{
                                            extrabtn.text ?? '' }}
                                    </loading-button>
                                </template>
                                <loading-button v-if="data.conditions.update" class="m-1 editbtn badge tbleditbtn"
                                    style="padding:10px" type="button"
                                    @click="viewbuttonclickedtbl(data.data[index1][data.conditions.primarykey])"><i
                                        class="ti-pencil-alt fs-5"></i></loading-button>
                                <loading-button v-if="data.conditions.trash" class="m-1 trashbtn badge kAshley"
                                    style="padding:10px" type="button"
                                    @click="deletebuttonclickedtbl(data.data[index1][data.conditions.primarykey])"><i
                                        class="ti-trash fs-5"></i></loading-button>
                            </td>
                        </template>
                    </tr>
                </template>
            </template>
            <template v-else>
                <tr :colspan="data.data_order.length">
                    <td>No Record Found</td>
                </tr>
            </template>
        </tbody>
    </table>
</template> 
<style scoped>
.toggle-button-cover {
    /* display: table-cell;
    position: relative; */
    /* width: 200px; */
    height: 50px;
    box-sizing: border-box;
}

.table,
.table td {
    border: snow;
}


.button-cover {
    height: 100px;
    margin: 20px;
    background-color: #fff;
    box-shadow: 0 10px 20px -8px #c5d6d6;
    border-radius: 4px;
}

.button-cover:before {
    counter-increment: button-counter;
    content: counter(button-counter);
    position: absolute;
    right: 0;
    bottom: 0;
    color: #d7e3e3;
    font-size: 12px;
    line-height: 1;
    padding: 5px;
}

.button-cover,
.knobs,
.layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.layer {
    border-radius: 25px;
}

.button {
    position: relative;
    top: 50%;
    width: 74px;
    height: 36px;
    margin: -20px auto 0 auto;
    overflow: hidden;
}

.button.r,
.button.r .layer {
    border-radius: 100px;
}

.button.b2 {
    border-radius: 2px;
}

.checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
}

.knobs span {
    border-radius: 50px !important;
}

.knobs {
    z-index: 2;
}

.layer {
    width: 100%;
    background-color: #ebf7fc;
    transition: 0.3s ease all;
    z-index: 1;
}

#button-11 {
    overflow: visible;
}

#button-11 .knobs {
    perspective: 70px;
}

#button-11 .knobs:before,
#button-11 .knobs:after,
#button-11 .knobs span {
    position: absolute;
    top: 4px;
    border-radius: 2px;
}

#button-11 .knobs:before,
#button-11 .knobs:after {
    width: 20px;
    height: 10px;
    color: #4e4e4e;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 9px 4px;
}

#button-11 .knobs:before {
    content: 'YES';
    left: 10px;
}

#button-11 .knobs:after {
    content: 'NO';
    right: 10px;
}

#button-11 .knobs span {
    right: 4px;
    width: 33px;
    height: 28px;
    background-color: #03a9f4;
    transform: rotateY(0);
    transform-origin: 0% 50%;
    transition: 0.6s ease all;
    z-index: 1;
}

#button-11 .checkbox:checked+.knobs span {
    transform: rotateY(-180deg);
    background-color: #f44336;
}

#button-11 .checkbox:checked~.layer {
    background-color: #fcebeb;
}
</style>
<script setup>
import { defineEmits, ref } from 'vue';
import LoadingButton from './LoadingButton.vue';
let props = defineProps({
    data: Array,
});
var abc = ref(true);
var definedEmits = defineEmits(['viewbuttonclicked', 'deletebuttonclicked', 'activeunactive', 'extrabuttonclicked'])

function viewbuttonclickedtbl(pkey) {
    definedEmits('viewbuttonclicked', pkey);
}

function deletebuttonclickedtbl(pkey) {
    definedEmits('deletebuttonclicked', pkey);
}
function extrabuttonclicked(returncondition, pkey) {
    definedEmits('extrabuttonclicked', returncondition, pkey);
}
function activeUnactive(obj, pkey) {
    var ischecked = $(obj.target).prop('checked');
    definedEmits('activeunactive', pkey, ischecked);
}
</script>

