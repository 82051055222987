<template>
    <div class="pcoded-inner-content">

        <div class="main-body">
            <div class="page-wrapper">

                <div class="page-body">
                    <div class="row">
                        <div class="col-sm-12">

                            <div class="card">
                                <div class="card-header">
                                    <h5>
                                        <span>Add New Home</span>
                                    </h5>
                                    <div class="card-header-right">
                                        <div class="page-header-breadcrumb">
                                            <ul class="breadcrumb-title">
                                                <li class="breadcrumb-item" style="float: left;">
                                                    <a href="#"> <i class="feather icon-home"></i> </a>
                                                </li>
                                                <li class="breadcrumb-item" style="float: left;"><router-link
                                                        :to="{ name: 'VAHomes' }">Homes</router-link>
                                                </li>
                                                <li class="breadcrumb-item" style="float: left;">
                                                    <a href="#"> Add New Home </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                                <div class="card-body">
                                    <form class="wizard-form wizard clearfix vertical" action="#">
                                        <div class="steps clearfix">
                                            <ul class="tablist" role="tablist">
                                                <li role="tab" class="first current" aria-disabled="true"><a
                                                        id="verticle-wizard-t-1" href="javascript:void(0);"
                                                        data-target="#verticle-wizard-p-0"
                                                        aria-controls="verticle-wizard-p-1"><span class="number"></span>
                                                        Basic Information </a></li>
                                                <li role="tab" class="disabled" aria-disabled="false" aria-selected="true">
                                                    <a id="verticle-wizard-t-0" href="javascript:void(0);"
                                                        data-target="#verticle-wizard-p-1"
                                                        aria-controls="verticle-wizard-p-0"> <span class="number"></span>
                                                        Contact Details </a>
                                                </li>
                                                <li role="tab" class="disabled" aria-disabled="true"><a
                                                        id="verticle-wizard-t-2" href="javascript:void(0);"
                                                        data-target="#verticle-wizard-p-2"
                                                        aria-controls="verticle-wizard-p-2"><span class="number"></span>
                                                        Ofsted Details </a></li>
                                                <li role="tab" class="disabled" aria-disabled="true"><a
                                                        id="verticle-wizard-t-3" href="javascript:void(0);"
                                                        data-target="#verticle-wizard-p-3"
                                                        aria-controls="verticle-wizard-p-3"><span class="number"></span>
                                                        Other Details </a></li>
                                            </ul>
                                        </div>
                                        <div class="content clearfix">

                                            <fieldset id="verticle-wizard-p-0" role="tabpanel"
                                                aria-labelledby="verticle-wizard-h-0" class="body current"
                                                aria-hidden="true">
                                                <div class="row">
                                                    <image-input v-model="form.home_picture" class="col-md-4"
                                                        :source="form.home_picture" kStyle="height:20vh;" />

                                                    <div class="col-md-8">
                                                        <div class="row">
                                                            <text-input v-model="form.home_name" class="col-md-6"
                                                                label="Name" />
                                                            <select-input v-model="form.home_company_id"
                                                                class="col-md-6 my-select selectpicker" label="Company">
                                                                <option value="">Select...</option>
                                                                <option v-for="comp in companiesdata" :value="comp.c_id">{{
                                                                    comp.c_name }}</option>
                                                            </select-input>
                                                            <text-input v-model="form.home_start_date" class="col-md-6"
                                                                label="Start Date" type="date" />
                                                            <text-input v-model="form.home_end_date" class="col-md-6"
                                                                label="End Date" type="date" />
                                                            <select-input v-model="form.home_administrator" class="col-md-6"
                                                                label="Administrator">
                                                                <option value="">Select...</option>
                                                                <option v-for="user in usersdata" :value="user.u_id">
                                                                    {{ user.u_name }}
                                                                </option>
                                                            </select-input>
                                                            <select-input v-model="form.home_region" class="col-md-6"
                                                                label="Region (Optional)">
                                                                <option value="">Select...</option>
                                                                <option v-for="reg in regionsdata" :value="reg.r_id">
                                                                    {{ reg.r_name }}
                                                                </option>
                                                            </select-input>

                                                        </div>
                                                    </div>

                                                    <div class="col-md-12">
                                                        <hr />
                                                    </div>
                                                    <select-input v-model="form.home_incharge" class="col-md-3"
                                                        label="Person in Charge">
                                                        <option value="">Select...</option>
                                                        <option v-for="user in usersdata" :value="user.u_id">
                                                            {{ user.u_name }}
                                                        </option>
                                                    </select-input>
                                                    <select-input v-model="form.home_responsible" class="col-md-3"
                                                        label="Responsible Individual">
                                                        <option value="">Select...</option>
                                                        <option v-for="user in usersdata" :value="user.u_id">
                                                            {{ user.u_name }}
                                                        </option>
                                                    </select-input>
                                                    <text-input v-model="form.home_min_age" class="col-md-3"
                                                        label="Minimum Age Group" />
                                                    <text-input v-model="form.home_max_age" class="col-md-3"
                                                        label="Maximum Age Group" />
                                                    <text-input v-model="form.home_no_beds" class="col-md-3"
                                                        label="No. of Beds" />
                                                    <text-input v-model="form.home_pat_date" class="col-md-3"
                                                        label="PAT Date" type="date" />
                                                    <text-input v-model="form.home_electrical" class="col-md-3"
                                                        label="Electrical Certificate" />
                                                    <text-input v-model="form.home_gas" class="col-md-3"
                                                        label="Gas Certificate" />
                                                    <text-input v-model="form.home_firedrill_day" class="col-md-3"
                                                        label="Fire Drill - Day" />
                                                    <text-input v-model="form.home_firedrill_night" class="col-md-3"
                                                        label="Fire Drill - Night" />
                                                </div>
                                            </fieldset>
                                            <fieldset id="verticle-wizard-p-1" role="tabpanel"
                                                aria-labelledby="verticle-wizard-h-0" class="body " aria-hidden="true"
                                                style="display:none;">
                                                <div class="row">
                                                    <textarea-input v-model="form.home_address" class="col-md-12" rows="5"
                                                        label="Address"></textarea-input>
                                                    <text-input v-model="form.home_postcode" class="col-md-3"
                                                        label="Postcode" />
                                                    <text-input v-model="form.home_telephone" class="col-md-3"
                                                        label="Telephone Number" />
                                                    <text-input v-model="form.home_faxnumber" class="col-md-3"
                                                        label="Fax Number" />
                                                    <text-input v-model="form.home_email" class="col-md-3"
                                                        label="Email Address" />
                                                </div>
                                            </fieldset>
                                            <fieldset id="verticle-wizard-p-2" role="tabpanel"
                                                aria-labelledby="verticle-wizard-h-0" class="body " aria-hidden="true"
                                                style="display:none;">
                                                <div class="row">
                                                    <text-input v-model="form.home_ofsted_urn" class="col-md-6"
                                                        label="Ofsted URN" />
                                                    <text-input v-model="form.home_ofsted_rating" class="col-md-6"
                                                        label="Full Ofsted Rating" />
                                                    <text-input v-model="form.home_ofsted_rating_date" class="col-md-6"
                                                        label="Rating Date" type="date" />
                                                    <text-input v-model="form.home_ofsted_requirements" class="col-md-6"
                                                        label="Number of Requirements" />
                                                    <text-input v-model="form.home_ofsted_list_regulation_requirements"
                                                        class="col-md-6" label="List Regulation Numbers for Requirements" />
                                                    <text-input v-model="form.home_ofsted_recommendations" class="col-md-6"
                                                        label="Number of Recommendations" />
                                                    <text-input v-model="form.home_ofsted_no_recommendations"
                                                        class="col-md-6"
                                                        label="List Regulation Numbers for Recommendations" />
                                                    <text-input v-model="form.home_ofsted_interim_rating" class="col-md-6"
                                                        label="Interim Ofsted Rating" />
                                                    <text-input v-model="form.home_ofsted_interim_rating_date" type="date"
                                                        class="col-md-6" label="Interim Rating Date" />
                                                    <text-input v-model="form.home_ofsted_interim_requirment"
                                                        class="col-md-6" label="Number of Requirements (Interim)" />
                                                    <text-input v-model="form.home_ofsted_interim_no_requirment"
                                                        class="col-md-6"
                                                        label="List Regulation Numbers for Requirements (Interim)" />
                                                    <text-input v-model="form.home_ofsted_interim_recommendation"
                                                        class="col-md-6" label="Number of Recommendations" />
                                                    <text-input v-model="form.home_ofsted_interim_regulation"
                                                        class="col-md-6" label="List Regulation Numbers for Requirements" />
                                                </div>
                                            </fieldset>
                                            <fieldset id="verticle-wizard-p-3" role="tabpanel"
                                                aria-labelledby="verticle-wizard-h-0" class="body " aria-hidden="true"
                                                style="display:none;">
                                                <div class="row">
                                                    <textarea-input v-model="form.home_longdescription" class="col-md-12"
                                                        rows="5" label="Description"></textarea-input>
                                                    <select-input v-model="form.home_secure_accomodation" class="col-md-6"
                                                        label="Home is secure accomodation">
                                                        <option value="0">No</option>
                                                        <option value="1">Yes</option>
                                                    </select-input>
                                                    <select-input v-model="form.home_shortstay" class="col-md-6"
                                                        label="Home accomodates short-term stays">
                                                        <option value="0">No</option>
                                                        <option value="1">Yes</option>
                                                    </select-input>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <div class="actions clearfix">
                                            <ul role="menu" aria-label="Pagination">
                                                <li aria-disabled="true" class="wizard-prev-btn disabled">
                                                    <a href="javascript:void(0);" role="menuitem">Previous</a>
                                                </li>
                                                <li aria-hidden="false" aria-disabled="false" class="wizard-next-btn"><a
                                                        href="javascript:void(0);" role="menuitem">Next</a></li>
                                                <li aria-hidden="true" style="display: none;" class="wizard-finish"><a
                                                        href="javascript:void(0);" role="menuitem"
                                                        @click="addHome();">Finish</a></li>
                                            </ul>
                                        </div>
                                    </form>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>
<script setup>
import SelectInput from '../../../shared/SelectInput.vue';
import TextInput from '../../../shared/TextInput'
import TextareaInput from '../../../shared/TextareaInput'
import ImageInput from '../../../shared/ImageInput.vue'
import { reactive, onMounted, provide, ref } from 'vue';
import { useAuthStore } from '@/store/auth.js'
import { functions } from '../../../../common/appFunctions.js';
import factory from '@/common/factory.js'
import { useRouter } from 'vue-router';
const router = useRouter();
const authStore = useAuthStore();

// ****** Dropdowns data ********************
var companiesdata = ref([]);
var usersdata = ref([]);
var regionsdata = ref([]);
// ****** Dropdowns data ********************

const form = reactive({
    home_picture: 'https://demo.dashboardpack.com/adminty-html/files/assets/images/gallery-grid/1.png',
    home_name: '',
    home_company_id: '',
    home_start_date: '',
    home_end_date: '',
    home_administrator: '',
    home_region: '',
    home_incharge: '',
    home_responsible: '',
    home_min_age: '',
    home_max_age: '',
    home_no_beds: '',
    home_pat_date: '',
    home_electrical: '',
    home_gas: '',
    home_firedrill_day: '',
    home_firedrill_night: '',
    home_address: '',
    home_postcode: '',
    home_telephone: '',
    home_faxnumber: '',
    home_email: '',
    home_ofsted_urn: '',
    home_ofsted_rating: '',
    home_ofsted_rating_date: '',
    home_ofsted_requirements: '',
    home_ofsted_list_regulation_requirements: '',
    home_ofsted_recommendations: '',
    home_ofsted_no_recommendations: '',
    home_ofsted_interim_rating: '',
    home_ofsted_interim_rating_date: '',
    home_ofsted_interim_requirment: '',
    home_ofsted_interim_no_requirment: '',
    home_ofsted_interim_recommendation: '',
    home_ofsted_interim_regulation: '',
    home_longdescription: '',
    home_secure_accomodation: '',
    home_shortstay: '',
});
function getToken() {
    const token = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authStore.token,
        },
    };
    return token;
}
provide('getToken', getToken());

async function addHome() {
    const Home = new factory(getToken(), 'homes', form);
    await Home.postForm().then((response) => {
        console.log(response);
        if (response.data.status == "success") {
            router.push({ path: 'homes' })
        } else {
            console.log(response.data.message);
        }
    });
};

onMounted(async () => {
    var Homes = new factory(getToken(), 'getallapis', { index: ['companies', 'users', 'regions'] });
    await Homes.postForm().then((response) => {
        if (response.data.status == "success") {
            companiesdata.value = response.data.data.companies;
            usersdata.value = response.data.data.users;
            regionsdata.value = response.data.data.regions;
        }
    });

    functions.callOnReady();
});



 
</script>
<style scoped>
#wizard2 fieldset {
    display: block;
    position: relative;
}

.wizard>.current {
    display: block;
}

.wizard>.content>.body {
    position: relative;

}
</style>