<template>
    <div class="pcoded-inner-content">
        <div class="main-body">
            <div class="page-wrapper">
                <div class="page-body">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-header">
                                    <h5>
                                        <span>Add New Company Manual</span>
                                    </h5>
                                    <div class="card-header-right">
                                        <div class="page-header-breadcrumb">
                                            <ul class="breadcrumb-title">
                                                <li class="breadcrumb-item" style="float: left;">
                                                    <a href="#"> <i class="feather icon-home"></i> </a>
                                                </li>
                                                <li class="breadcrumb-item" style="float: left;"><router-link
                                                        :to="{ name: 'VACompanyManuals' }">Company Manuals</router-link>
                                                </li>
                                                <li class="breadcrumb-item" style="float: left;">
                                                    <a href="#"> Add New Company Manuals</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <text-input v-model="manual_title" class="col-md-3" label="Manual Title" />
                                        <select-input v-model="manual_company" class="col-md-3" label="Manual Company">
                                            <option value="">Select Company</option>
                                            <option v-for="comp in companiesdata" :value="comp.c_id">{{
                                                comp.c_name }}</option>
                                        </select-input>
                                        <select-input v-model="manual_priority" class="col-md-3" label="Manual Priority">
                                            <option value="0">Select...</option>
                                            <option v-for="mod in perioritydata" :value="mod.id">{{
                                                mod.priority }}</option>
                                        </select-input>
                                        <select-input v-model="manual_type" class="col-md-3" label="Manual Related To">
                                            <option value="0">Policy</option>
                                            <option value="1">Core Responsibilities</option>
                                            <option value="2">Announcements</option>
                                            <option value="3">Job Description</option>
                                        </select-input>

                                        <textarea-input v-model="manual_detail" rows="10" class="col-md-12"
                                            label="Manual Detail"></textarea-input>
                                    </div>





                                    <div class="row ">
                                        <div class="col-md-12">
                                            <label class="mb-1">Add text for single or multiple checkboxes for users to
                                                accept
                                                before sign and submit the manuals. <span class="text-danger">(Default
                                                    State for checkbox will be stored)</span></label>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text bg-light">
                                                        <div class="checkbox-fade fade-in-primary m-0">
                                                            <label>
                                                                <input type="checkbox" v-model="checkboxval" value="true">
                                                                <span class="cr">
                                                                    <i
                                                                        class="cr-icon icofont icofont-ui-check txt-primary"></i>
                                                                </span>
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </span>
                                                </div>
                                                <div class="p-0" style="width: 88%;">
                                                    <div class="">
                                                        <input noformgroup="true" class="form-control" type="text"
                                                            v-model="checkboxtitle">
                                                    </div>
                                                </div>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text btn btn-warning"
                                                        @click="addCheckboxes()"><i class="ti-plus"></i></span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>LIST OF CHECKBOXES</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template v-if="checkboxes_list.length > 0">
                                                    <tr v-for="(check, index) in checkboxes_list">
                                                        <td>
                                                            <div class="checkbox-fade fade-in-primary m-0">
                                                                <label>
                                                                    <input type="checkbox" :checked="check.defaultvalue"
                                                                        value="true">
                                                                    <span class="cr">
                                                                        <i
                                                                            class="cr-icon icofont icofont-ui-check txt-primary"></i>
                                                                    </span>
                                                                    <span> {{ check.label }}</span>
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td class="text-right">
                                                            <loading-button class="m-1 trashbtn badge kAshley"
                                                                style="padding:10px" type="button"
                                                                @click="deleteRowtbl(index)"><i
                                                                    class="ti-trash fs-5"></i></loading-button>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="card-foot p-3">
                                    <div class="col-md-12 text-center">
                                        <button type="button" class="btn btn-primary btn-round waves-effect waves-light funload"
                                            @click="addCompanyManual()">Save Information</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import LoadingButton from '../../../shared/LoadingButton.vue';
import SelectInput from '../../../shared/SelectBox.vue';
import TextInput from '../../../shared/TextInput'
import TextareaInput from '../../../shared/TextareaInput.vue'
import { ref, onMounted } from 'vue';
import { useAuthStore } from '@/store/auth.js'
import { functions } from '../../../../common/appFunctions.js';
import { useRouter } from 'vue-router';
import factory from '@/common/factory.js'

const router = useRouter();
const authStore = useAuthStore();

var manual_title = ref('');
var manual_company = ref('');
var manual_type = ref(0);
var manual_priority = ref(0);
var manual_detail = ref('');
var checkboxtitle = ref('');
var checkboxval = ref(true);
var checkboxes_list = ref([]);


var companiesdata = ref([]);
var perioritydata = ref([]);

async function addCompanyManual() {

    var createobj = {
        manual_company_id: manual_company.value,
        manual_title: manual_title.value,
        manual_type: manual_type.value,
        manual_detail: manual_detail.value,
        checkboxes_list: checkboxes_list.value,
    }
    const Companymanual = new factory(getToken(), 'companymanuals', createobj);
    await Companymanual.postForm().then((response) => {
        if (response.data.status == "success") {
            router.push({ name: 'VACompanyManuals' })
        } else {
            console.log(response.data.message);
        }
    });
}
function getToken() {
    const token = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authStore.token,
        },
    };
    return token;
}

function addCheckboxes() {
    if (checkboxtitle.value.trim() == '') {
        alert("Kindly Enter Checkbox Title!");
        return false;
    }
    checkboxes_list.value.push({
        label: checkboxtitle.value,
        defaultvalue: checkboxval.value,
    });
    checkboxtitle.value = '';
}
function deleteRowtbl(index) {
    checkboxes_list.value = checkboxes_list.value.filter((x, k) => (k !== index));
}


onMounted(async () => {
    functions.initSoftLoader();
    // ****************** missing data
    // Manual Priority, Manual related to
    // ****************** missing data
    const Users = new factory(getToken(), 'getallapis', { index: ['companies','periority'] });
    await Users.postForm().then((response) => {
        if (response.data.status == "success") {
            companiesdata.value = response.data.data.companies;
            perioritydata.value = response.data.data.periority;
        }
    });

    functions.callOnReady();
});


</script>