<template>
 
  <div class="row" :class="'uid'+uid">
    <div class="col-md-3" :class="($attrs.nooptions)?'':'col-md-3'" >
      <div class="toolbar" id="tools" v-show="!$attrs.nooptions">
        <div class="section files" v-show="!$attrs.nooptions">
          <div id="reset" class="button"><i class="fa fa-trash-o" aria-hidden="true"></i></div>
          <div id="tool-save" class="button"><i class="fa fa-save" aria-hidden="true"></i></div>
          <div id="tool-load" class="button"><i class="fa fa-folder-open-o" aria-hidden="true"></i></div>
          <div id="tool-screenshot" class="button tool-screenshot"><i class="fa fa-camera-retro" aria-hidden="true"></i></div>
        </div>

        <div class="section drawing" >
          <div id="tool-pen" class="button active tool-pen"><i class="fa fa-pencil" aria-hidden="true"></i></div>
          <div v-show="!$attrs.nooptions" id="tool-highlighter" class="button tool-highlighter"><i class="fa fa-paint-brush" aria-hidden="true"></i></div>
          <div v-show="!$attrs.nooptions" id="tool-rainbow" class="button rbw"><i class="fa fa-magic" aria-hidden="true"></i></div>
          <div v-show="!$attrs.nooptions" id="tool-mandala" class="button mandala"><i class="fa fa-snowflake-o" aria-hidden="true"></i></div>
        </div>
        <div class="section drawing"  >
          <div v-show="!$attrs.nooptions" id="tool-line" class="button line">╱</div>
          <div v-show="!$attrs.nooptions" id="tool-rectangle" class="button rect"><i class="fa fa-square-o" aria-hidden="true"></i></div>
          <div v-show="!$attrs.nooptions" id="tool-circle" class="button circ"><i class="fa fa-circle-thin" aria-hidden="true"></i></div>
          <div v-show="!$attrs.nooptions" id="tool-type" class="button font"><i class="fa fa-font" aria-hidden="true"></i></div>



          <div class="section rubber">
            <div id="tool-eraser" class="button tool-eraser"><i class="fa fa-eraser" aria-hidden="true"></i></div>
            <div v-show="!$attrs.nooptions" id="tool-cutout" class="button"><i class="fa fa-scissors" aria-hidden="true"></i></div>
          </div>
          <div class="section special" v-show="!$attrs.nooptions">
            <div id="tool-rotate-viewport" class="button rotate-viewport"><i class="fa fa-circle-o-notch" aria-hidden="true"></i></div>
            <div id="tool-move-viewport" class="button move-viewport"><i class="fa fa-hand-paper-o" aria-hidden="true"></i></div>
          </div>
          <div class="section special" v-show="!$attrs.nooptions">
            <div id="tool-zoom-out" class="button"><i class="fa fa-search-minus" aria-hidden="true"></i></div>
            <div id="tool-zoom-1" class="button"><i class="fa fa-home" aria-hidden="true"></i></div>
            <div id="tool-zoom-in" class="button"><i class="fa fa-search-plus" aria-hidden="true"></i></div>
          </div>
          <div class="section special" v-show="!$attrs.nooptions">
            <div id="tool-fast-undo" class="button f-backward"><i class="fa fa-fast-backward" aria-hidden="true"></i></div>
            <div id="tool-undo" class="button undo"><i class="fa fa-backward" aria-hidden="true"></i></div>
            <div id="tool-redo" class="button redo"><i class="fa fa-forward" aria-hidden="true"></i></div>
            <div id="tool-fast-redo" class="button f-forward"><i class="fa fa-fast-forward" aria-hidden="true"></i></div>
          </div>
          <div class="section special" v-show="!$attrs.nooptions">
            <hr>
            <span id="toolName">Tool name</span>
          </div>
          <div id="size" class="section size" v-show="!$attrs.nooptions">
            <input class="slider" id="size-slider" type ="range" min ="1" max="20" step ="1" value ="1"/>
          </div>

          <div id="colorpaletteSection"  class="section colorpicker" v-if="!$attrs.nooptions">
            <div id="colorpalette" class="colorpalette"></div>
          </div>
          <div id="colorpaletteFillSection" class="section colorpicker" v-show="!$attrs.nooptions">
            <div id="colorpaletteFill" class="colorpalette"></div>
          </div>
        </div>
      </div>
    </div>
    <div :class="($attrs.nooptions)?'col-md-12':'col-md-9'">
      <text-input class="hide hidden_base64" :value="modelValue" />
        <!-- <input type="hidden" class="hidden_base64" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" /> -->
        <div id="sketchpad"  class="sketchpad" :style="{minHeight:($attrs.kstyle.height != undefined)?$attrs.kstyle.height:'250px'}"></div>
    </div>
  </div> 
</template>

<script setup>
import { v4 as uuid } from 'uuid';
import { ref,onMounted,defineEmits } from "vue"; 
import { TextInput } from "./TextInput.vue";

var uid = ref();

let props = defineProps({
  displayGrid: Boolean,
  modelValue: {
    type: Boolean,
    default: false
  }
}); 
const emit = defineEmits(['update:modelValue'])
function initSketchpad() {
    "use strict";
    var sketchpadEl = $('.uid'+uid.value).find('.sketchpad').get(0);
    //  console.log(typeof sketchpadEl);
    //  console.log('.uid'+uid.value);
    //  var sketchpadEl = document.getElementById("sketchpad");
    //  var sketchpadEl = $(document).find("#sketchpad").get(0); 
    //  console.log(sketchpadEl);
    var sketchpad = new Sketchpad({
        containerEl: sketchpadEl,
        features: {
            displayCrosshair: true,
            displayGrid: props.displayGrid
        },
        createPageConfig: {
            no: 1,
            backgroundColor: "rgba(250,250,250,1)"
        }
    });

    // window.sketchpad = sketchpad;


    // var colorpalette = new Colorpalette({
    //     containerEl: document.getElementById("colorpalette")
    // }).on("change", function (e) {
    //     sketchpad.getCurrentTool().setColor(e.color.red, e.color.green, e.color.blue, e.color.alpha);
    // });
    // window.colorpalette = colorpalette;

    // var colorpaletteFill = new Colorpalette({
    //     containerEl: document.getElementById("colorpaletteFill")
    // }).on("change", function (e) {
    //     sketchpad.getCurrentTool().setFillColor(e.color.red, e.color.green, e.color.blue, e.color.alpha);
    // });
    // window.colorpaletteFill = colorpaletteFill;


    /**
     * Changes current tool
     * @param  {string} toolId  - tool id
     */
    function selectTool(toolId) {
        // console.log("selectTool", toolId);
        sketchpad.setTool(toolId);

        document.querySelectorAll(".toolbar .button").forEach(function (el) {
            el.classList.remove("active");
        });
        document.getElementById("tool-" + toolId).classList.add("active");

        document.getElementById("size").style.display = "none";
        // document.getElementById("colorpaletteSection").style.display = "none";
        // document.getElementById("colorpaletteFillSection").style.display = "none";

        var tool = sketchpad.getCurrentTool();

        document.getElementById("toolName").innerHTML = tool.toolLabel || "Undefined";
        /**
         * set toolbar for tool
         */

        if (typeof tool.getColor === "function") {
            // colorpalette.setColor(tool.getColor(), "noPropagate");
        }

        if (typeof tool.getFillColor === "function") {
            // colorpaletteFill.setColor(tool.getFillColor(), "noPropagate");
        }

        if (typeof tool.getSize === "function") {
            var size = tool.getSize();
            document.getElementById("size-slider").value = size;
        }

        switch (toolId) {
        case "pen":
            // document.getElementById("colorpaletteSection").style.display = "block";
            document.getElementById("size").style.display = "block";
            break;
        case "highlighter":
            // document.getElementById("colorpaletteSection").style.display = "block";
            document.getElementById("size").style.display = "block";
            break;
        case "eraser":
            break;
        case "rectangle":
            // document.getElementById("colorpaletteSection").style.display = "block";
            // document.getElementById("colorpaletteFillSection").style.display = "block";
            document.getElementById("size").style.display = "block";
            break;
        case "circle":
            // document.getElementById("colorpaletteSection").style.display = "block";
            // document.getElementById("colorpaletteFillSection").style.display = "block";
            document.getElementById("size").style.display = "block";
            break;
        case "line":
            // document.getElementById("colorpaletteSection").style.display = "block";
            document.getElementById("size").style.display = "block";
            break;
        }
    }

    selectTool("pen");

    document.getElementById("size-slider").addEventListener("change", function (e) {
        if (typeof sketchpad.getCurrentTool().setSize === "function") {
            sketchpad.getCurrentTool().setSize(e.target.value);
        }
    });

    //save
    document.getElementById('tool-save').addEventListener("click", function () {
        var data = sketchpad.saveSketchpad(true); 
        saveFile(JSON.stringify(data), sketchpad.room.room_token + ".json", "text/json");
    });


    /**
     * Load sketch from json
     */
    function jsonToDraw(sketchpad, inputList) {
        var i,
            input;

        sketchpad.reset();
        sketchpad.receiveMessageFromServer({data: JSON.stringify({cmd: "history-begin"})});
        sketchpad.sendMessageToServer({cmd: "history-begin"});

        for (i = 0; i < inputList.length; i += 1) {
            input = inputList[i];
            input.bid = 0;
            input.uid = sketchpad.UID;
            if (input.config && input.config.sid) {
                console.log("PAGE: Input.cmd", input.cmd, input.config, input.config.sid);
            } else {
                console.log("Input: Input.cmd", input.cmd, input.sid);
            }

            sketchpad.sendMessageToServer(inputList[i]);
            sketchpad.receiveMessageFromServer({data: JSON.stringify(inputList[i])});
        }
        sketchpad.receiveMessageFromServer({data: JSON.stringify({cmd: "history-end"})});
        sketchpad.sendMessageToServer({cmd: "history-end"});
        //select current page?
        return inputList;
    }


    //load
    document.getElementById('tool-load').addEventListener("click", function () {
        loadFile(".json,application/json", function (data) {
            try {
                data = JSON.parse(data);
            } catch (e) {
                console.error("Error parsing file", e);
                return;
            }
            if (Array.isArray(data)) {
                return jsonToDraw(sketchpad, data);
            } else {
                console.error("Wrong file content");
                return;
            }
        });
    });


    //screenshot
    $('.uid'+uid.value).find('.tool-screenshot').get(0).addEventListener("click", function () {
        // blob = $('.uid'+uid.value).find('#canvas').get(0).toDataURL();    
        emit('update:modelValue', $('.uid'+uid.value).find('#canvas').get(0).toDataURL())
        // saveFile(blob, sketchpad.room.room_token + ".png", "image/png");
        
        // sketchpad.screenshot(function (blob) {
        //     saveFile(blob, sketchpad.room.room_token + ".png", "image/png");
        // }, "image/png", 1);

    });

    //pen
    $('.uid'+uid.value).find('.tool-pen').get(0).addEventListener("click", function () {
        selectTool("pen");
    });

    // marker
    $('.uid'+uid.value).find('.tool-highlighter').get(0).addEventListener("click", function () {
        selectTool("highlighter");
    });

    // mandala
    document.getElementById('tool-mandala').addEventListener("click", function () {
        selectTool("mandala");
    });

    // mandala
    document.getElementById('tool-type').addEventListener("click", function () {
        selectTool("type");
    });

    //eraser
    $('.uid'+uid.value).find('.tool-eraser').get(0).addEventListener("click", function () {
        selectTool("eraser");
    });


    //cutout
    document.getElementById('tool-cutout').addEventListener("click", function () {
        selectTool("cutout");
    });

    document.getElementById('tool-rectangle').addEventListener("click", function () {
        selectTool("rectangle");
    });

    document.getElementById('tool-line').addEventListener("click", function () {
        selectTool("line");
    });

    document.getElementById('tool-circle').addEventListener("click", function () {
        selectTool("circle");
    });

    document.getElementById('tool-rainbow').addEventListener("click", function () {
        selectTool("rainbow");
    });

    document.getElementById('tool-move-viewport').addEventListener("click", function () {
        selectTool("move-viewport");
    });

    document.getElementById('tool-rotate-viewport').addEventListener("click", function () {
        selectTool("rotate-viewport");
    });

    document.getElementById('tool-zoom-in').addEventListener("click", function () {
        sketchpad.setScale(sketchpad.scale * 2);
    });
    document.getElementById('tool-zoom-1').addEventListener("click", function () {
        sketchpad.setScale(1);
        sketchpad.setViewportPosition(0, 0);
        sketchpad.setRotation(0);
    });

    document.getElementById('tool-zoom-out').addEventListener("click", function () {
        sketchpad.setScale(sketchpad.scale / 2);
    });

    document.getElementById('reset').addEventListener("click", function () {
        sketchpad.reset();
    });

    document.getElementById('tool-undo').addEventListener("click", function () {
        sketchpad.undo();
    });
    document.getElementById('tool-redo').addEventListener("click", function () {
        sketchpad.redo();
    });

}
onMounted(() => {
  uid.value = uuid();
  setTimeout(() => {
    
      initSketchpad();
  }, 500);
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.sketchpad {
    margin: auto;
    width: 100%;
    background-repeat: repeat;
}

.toolbar {
  width: 100%;
  min-width: 228px;
  background-color: #fafafa;
  text-align: center;
  padding: 14px;
  display: inline-block;
}
.toolbar .section {
  clear:both;
  margin-bottom: 8px;
}
.toolbar .slider {
  width: 100%;
}

.toolbar .button {
  float: left;
  width: 32px;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  text-align: center;
}

.toolbar .button.active {
  background-color: rgba(0, 0, 0, .5);
  color: rgba(255, 255, 255, 1);
}

</style>
