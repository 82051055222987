<template>
    <div class="pcoded-inner-content">

        <div class="main-body">
            <div class="page-wrapper">
                <div class="page-header">
                    <div class="row align-items-end">
                        <div class="col-lg-8">
                            <div class="page-header-title">
                                <div class="d-inline">
                                    <h4>AccountTypes</h4>
                                    <span>Edit</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="page-header-breadcrumb">
                                <ul class="breadcrumb-title">
                                    <li class="breadcrumb-item" style="float: left;">
                                        <a href="../index.html"> <i class="feather icon-home"></i> </a>
                                    </li>
                                   
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="page-body">
                    <div class="row">
                        <div class="col-sm-12">

                            <div class="card">
                                
                                <form >
                                    <div class="card-body">
                        <div class="row">

                            <select-input v-model="form.company_id" class="col-md-6" label="Company">
                                <option value="0">Select Type</option>
                                <option value="1">Company</option>
                                <option value="2">Hybrid</option>
                                <option value="3">Limited</option>
                            </select-input>
                            <text-input v-model="form.name" class="col-md-12" label="Company name" />
                        </div>
                    </div>
                        <div class="card-footer">
                            <button type="button" class="btn btn-default waves-effect ">Cancel</button>
                            <button type="button" class="btn btn-primary btn-round waves-effect waves-light "
                                @click="updateCompany()">Save</button>
                        </div>
                    </form>
                            
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>

</template>
<script setup>
import { functions } from '../../../../common/appFunctions.js';
import SelectInput from '../../../shared/SelectInput.vue';
import TextInput from '../../../shared/TextInput'
import { onMounted, ref } from 'vue';
import { useAuthStore } from '@/store/auth.js'
import factory from '@/common/factory.js'
import { useRouter, useRoute  } from 'vue-router';
const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();

var form = ref({
    id: 0,
    company_id: 0,
    name: '',
    isactive: 0,
});
var companies = ref({});
function getToken() {
    const token = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authStore.token,
        },
    };
    return token;
}
async function updateCompany() {
    const Company = new factory(getToken(), 'companies', form.value);
    await Company.putForm().then((response) => {
        console.log(response);
            if (response.data.status == "success") {
                router.push({ path: 'accounttypes' })
            } else {
                console.log(response.data.message);
            }});   
};
onMounted(async () =>{
    const Company = new factory(getToken(), 'accounttypes/'+route.query.id);
    await Company.getHttp().then(response => {
        if (response.data.status == "success") {
           form.value = response.data.data[0];
            } else {
                return response.data.message;
            }           
    });
    const Companies = new factory(getToken(), 'companies/vendor/'+authStore.user.vendor_id);
    await Company.getHttp().then(response => {
        if (response.data.status == "success") {
            companies.value = response.data.data;
            } else {
                return response.data.message;
            }           
    });
    functions.callOnReady();  
})
</script>