<template>
  <div class="main-body">
    <div class="page-wrapper">
      <!-- Page body start -->
      <div class="page-body">
        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-block">
                <div class="row">
                  <select-input
                    v-model="attendance_employee_id"
                    class="col-md-3"
                    label="Select Employee"
                  >
                    <option
                      v-for="emp in employeedata"
                      :value="emp.employee_id"
                    >
                      {{ emp.employee_name }}
                    </option>
                  </select-input>

                  <select-input v-model="month" class="col-md-2" label="Month">
                    <option value="0">January</option>
                    <option value="01">February</option>
                    <option value="02">March</option>
                    <option value="03">April</option>
                    <option value="04">May</option>
                    <option value="05">June</option>
                    <option value="06">July</option>
                    <option value="07">Augest</option>
                    <option value="08">September</option>
                    <option value="09">Octuber</option>
                    <option value="10">November</option>
                    <option value="11">December</option>
                  </select-input>
                  <text-input v-model="year" class="col-md-3" label="Year" />
                  <div class="col-md-2 mt-1">
                    <div class="form-group">
                      <label style="width: 100%">&nbsp;</label>
                      <button
                        type="button"
                        class="btn btn-block btn-round btn-block btn-primary waves-effect waves-light"
                        @click="reloadAttendance()"
                      >
                        Apply Changes
                      </button>
                    </div>
                  </div>
                  <div class="col-md-2 mt-1">
                    <div class="form-group">
                      <label style="width: 100%">&nbsp;</label>
                      <button
                        type="button"
                        class="btn btn-block btn-round btn-block btn-danger waves-effect waves-light"
                        @click="showHideShifts()"
                        v-if="showhideshifts"
                      >
                        <i class="fa fa-calendar"></i>Hide Shifts
                      </button>
                      <button
                        type="button"
                        class="btn btn-block btn-round btn-secondary waves-effect waves-light"
                        @click="showHideShifts()"
                        v-if="!showhideshifts"
                      >
                        <i class="fa fa-calendar"></i>Show Shifts
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="card">
              <div class="card-body p-0">
                <div class="col-md-12 table-responsive p-0">
                  <table class="table attendancetbl">
                    <thead>
                      <tr>
                        <th style="width: 15%">Date</th>
                        <th style="width: 85%" class="p-0">
                          <table
                            style="width: 100%"
                            class="table table-bordered mb-0"
                          >
                            <thead>
                              <th style="width: 100px">In</th>
                              <th style="width: 100px">Out</th>
                              <th style="width: 70px">Break</th>
                              <th>Details</th>
                              <th style="width: 100px">Hours Paid</th>
                            </thead>
                          </table>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="calenderdisplay">
                        <template v-for="(datearr, key) in datesarray">
                          <tr
                            :data-date="formatDate($moment, datearr[0])"
                            :class="checkWeekDay($moment, datearr[0])"
                            class="tblrow"
                          >
                            <td class="pb-0">
                              {{ formatDate($moment, datearr[0], "DD dddd") }}
                              <i
                                class="icofont icofont-plus-square addattendancerowbtn fr text-dark"
                                @click="addAttendanceRow(key)"
                              ></i>
                            </td>
                            <td class="p-0" colspan="5">
                              <table
                                style="width: 100%"
                                class="table table-bordered mb-0"
                              >
                                <tbody>
                                  <tr v-for="(date, key2) in datearr[1]">
                                    <td style="width: 100px" class="p-0">
                                      <text-input
                                        v-model="date.in"
                                        :noformgroup="true"
                                        class="col-md-12"
                                        @change="
                                          createAttendance(
                                            $moment,
                                            datearr[0],
                                            key,
                                            key2
                                          )
                                        "
                                      />
                                    </td>
                                    <td style="width: 100px" class="p-0">
                                      <text-input
                                        v-model="date.out"
                                        :noformgroup="true"
                                        class="col-md-12"
                                        @change="
                                          createAttendance(
                                            $moment,
                                            datearr[0],
                                            key,
                                            key2
                                          )
                                        "
                                      />
                                    </td>
                                    <td style="width: 70px" class="p-0">
                                      <text-input
                                        v-model="date.break"
                                        :noformgroup="true"
                                        class="col-md-12"
                                        @change="
                                          createAttendance(
                                            $moment,
                                            datearr[0],
                                            key,
                                            key2
                                          )
                                        "
                                      />
                                    </td>
                                    <td class="pl-1 pr-1">
                                      <div
                                        class="row"
                                        v-if="
                                          date.in != '' ||
                                          date.out != '' ||
                                          date.break != '' ||
                                          date.hourspaid != ''
                                        "
                                      >
                                        <div
                                          class="col-md-12"
                                          style="vertical-align: middle"
                                        >
                                          <label
                                            style="
                                              line-height: 2;
                                              color: black !important;
                                              font-size: 14px;
                                            "
                                            @click="
                                              editAttendanceInfo(key, key2)
                                            "
                                            class="editattendanceinfo label text-c-black bg-c-grey"
                                          >
                                            <i
                                              class="icofont icofont-queen-crown text-c-black"
                                            ></i
                                            >:
                                            {{
                                              date.details.role.name ??
                                              "No Role"
                                            }}
                                            <i
                                              class="text-c-black fa fa-home"
                                            ></i
                                            >:
                                            {{
                                              date.details.home.name ??
                                              "No Home"
                                            }}
                                            <i
                                              class="fa fa-edit text-c-black"
                                              style="
                                                float: right;
                                                font-size: 20px;
                                              "
                                            ></i>
                                          </label>
                                        </div>
                                      </div>
                                      <!-- <text-input
                                                                                v-model="date.details"
                                                                                :noformgroup="true" class="col-md-12"
																				@change="createAttendance($moment, datearr[0], key, key2);" />  -->
                                    </td>
                                    <td style="width: 100px" class="p-0">
                                      <text-input
                                        v-model="date.hourspaid"
                                        :noformgroup="true"
                                        class="col-md-12"
                                        @change="
                                          createAttendance(
                                            $moment,
                                            datearr[0],
                                            key,
                                            key2
                                          )
                                        "
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </template>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="editattencanceinfo">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Details</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
            @click="hidemodal('#editattencanceinfo')"
          >
            &times;
          </button>
        </div>
        <div class="modal-body">
          <select-input
            v-model="up.attendance_role_id"
            class="col-md-12"
            label="Role"
          >
            <option value="">Select...</option>
            <option v-for="comp in roleslistemployee" :value="comp.role_id">
              {{ comp.role }}
            </option>
          </select-input>
          <select-input
            v-model="up.attendance_location_id"
            class="col-md-12"
            label="Home"
          >
            <option value="">Select...</option>
            <option v-for="comp in homesdata" :value="comp.home_id">
              {{ comp.home_name }}
            </option>
          </select-input>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            @click="hidemodal('#editattencanceinfo')"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="UpdateAttendanceInfo()"
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import SelectInput from "../../../shared/SelectBox.vue";
import HeaderPreview from "../../../website/headerDesigns/index.vue";
import TextInput from "../../../shared/TextInput.vue";
import ColorInput from "../../../shared/ColorInput.vue";
import ImageInput from "../../../shared/ImageInput.vue";
import { functions } from "../../../../common/appFunctions.js";
import { ref, reactive, onMounted } from "vue";
import { useAuthStore } from "@/store/auth.js";
import factory from "@/common/factory.js";
import { useRouter } from "vue-router";

const authStore = useAuthStore();
const isPreview = ref(false);
const calenderdisplay = ref(false);
var attendance_employee_id = ref(1);
var showhideshifts = ref(false);
var rolesdata = ref([]);
var homesdata = ref([]);
var up = ref({});
var editedattendance = ref(0);
var editedkey = ref(0);
var editedchildkey = ref(0);
var roleslistemployee = ref([]);
var homeslistemployee = ref([]);

const form = reactive({
  header_logo:
    "https://demo.dashboardpack.com/adminty-html/files/extra-pages/landingpage/assets/logos/logo.png",
  menu_group_id: "",
  menu_shortdescription: "",
  menu_page: "",
  menu_customlink: "",
  menu_order: "",
});
// function format_date(value) {
//     if (value) {
//         return this.$moment(String(value)).format('DD/MM/YYYY')
//     }
// }
function formatDate(moment, date, format = "YYYY-MM-DD") {
  return moment(date).format(format);
}
async function createAttendance(moment, date, mainarray, childarray) {
  // attendance_employee_id.value
  // console.log(mainarray);
  // console.log(childarray);
  // console.log(datesarray.value);
  // console.log(datesarray.value[mainarray]);
  // console.log(datesarray.value[mainarray][1]);
  // console.log(datesarray.value[mainarray][1][childarray]);
  // console.log(date);
  // datesarray.value[mainarray][1][childarray];
  // datesarray.value[mainarray][1][childarray].attendance_id;
  var createobj = {
    attendance_employee_id: employeeid.value,
    attendance_date: moment(date).format("YYYY-MM-DD"),
    attendance_in: datesarray.value[mainarray][1][childarray].in,
    attendance_out: datesarray.value[mainarray][1][childarray].out,
    attendance_break: datesarray.value[mainarray][1][childarray].break,
    attendance_details: "",
    attendance_hourspaid: datesarray.value[mainarray][1][childarray].hourspaid,
  };
  if (datesarray.value[mainarray][1][childarray].attendance_id == 0) {
    // create
    const Attendance = new factory(getToken(), "attendance", createobj);
    await Attendance.postForm().then((response) => {
      if (response.data.status == "success") {
        swal.close();
        datesarray.value[mainarray][1][childarray].attendance_id =
          response.data.data.attendance_id;
      }
    });
  } else {
    // update
    createobj.attendance_id =
      datesarray.value[mainarray][1][childarray].attendance_id;
    createobj.attendance_details = "";
    const Attendance = new factory(getToken(), "attendance", createobj);
    await Attendance.putForm().then((response) => {
      if (response.data.status == "success") {
        swal.close();
      }
    });
  }
}
function editAttendanceInfo(key1, childarray) {
  editedkey.value = key1;
  editedchildkey.value = childarray;
  editedattendance.value = datesarray.value[key1][1][childarray].attendance_id;

  up.value.attendance_location_id =
    datesarray.value[key1][1][childarray].details.home.id;
  up.value.attendance_role_id =
    datesarray.value[key1][1][childarray].details.role.id;

  roleslistemployee.value = [];

  var empinfo = employeedata.value.find(
    (item) => item.employee_id == attendance_employee_id.value
  ); 
  empinfo.roles.forEach(function (v, k) {
    var fil = rolesdata.value.find((item) => item.role_id == v.role_id);
    roleslistemployee.value.push(fil);
  });
  empinfo.locations.forEach(function (v, k) {
    var fil = homesdata.value.find((item) => item.location_id == v.location_id);
    homeslistemployee.value.push(fil);
  });
  // 

  $("#editattencanceinfo").modal("show");
}
async function UpdateAttendanceInfo() {
  var createobj = {
    attendance_id: editedattendance.value,
    attendance_location_id: up.value.attendance_location_id,
    attendance_role_id: up.value.attendance_role_id,
  };
  const Attendance = new factory(getToken(), "attendance", createobj);
  await Attendance.putForm().then((response) => {
    if (response.data.status == "success") {
      swal.close();
      getAttendanceInfo();
      $("#editattencanceinfo").modal("hide");
    }
  });
}
function checkWeekDay(moment, date) {
  var day = moment(date).format("dddd");

  if (day == "Sunday") {
    return "attendance-sunday";
  } else if (day == "Saturday") {
    return "attendance-weekend";
  }
  return "attendance-weekday";
}
function addAttendanceRow(key) {
  datesarray.value[key][1].push({
    in: "",
    out: "",
    break: "",
    details: { home: {}, role: {} },
    hourspaid: "",
    attendance_id: 0,
  });
}

var today = new Date();
var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
var lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth() + 1, 0);
var nextThirtyDays = Array(lastDay.getDate())
  .fill()
  .map((_, i) => new Date(firstDay).setDate(firstDay.getDate() + i));
var datesarray = ref([]);
var employeeid = ref(1);
var employeedata = ref([]);
var month = ref(today.getMonth().toString());
month.value = month.value < 10 ? "0" + month.value : month.value;
var year = ref(firstDay.getFullYear());
nextThirtyDays.forEach((v, k) => {
  datesarray.value.push([
    v,
    [
      {
        in: "",
        out: "",
        break: "",
        details: { home: {}, role: {} },
        hourspaid: "",
        attendance_id: 0,
      },
    ],
  ]);
});
// console.log(datesarray);
function reloadAttendance() {
  var dateselected = new Date(year.value, month.value, "01");
  var firstDay = new Date(
    dateselected.getFullYear(),
    dateselected.getMonth(),
    1
  );
  var lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth() + 1, 0);
  var nextThirtyDays = Array(lastDay.getDate())
    .fill()
    .map((_, i) => new Date(firstDay).setDate(firstDay.getDate() + i));
  datesarray.value = [];
  // month.value = dateselected.getMonth()+1;
  // year.value = firstDay.getFullYear();
  nextThirtyDays.forEach((v, k) => {
    datesarray.value.push([
      v,
      [
        {
          in: "",
          out: "",
          break: "",
          details: { home: {}, role: {} },
          hourspaid: "",
          attendance_id: 0,
        },
      ],
    ]);
  });
  getAttendanceInfo();
  getShifts();
}
function previewHeader() {
  if (isPreview.value) {
    isPreview.value = false;
  } else {
    isPreview.value = true;
  }
}
function getToken() {
  const token = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authStore.token,
    },
  };
  return token;
}
async function getAttendanceInfo() {
  var BreakException = {};

  var mo = parseInt(month.value) + 1;
  mo = mo < 10 ? "0" + mo : mo;
  var attendanceinfo = {
    employee_id: employeeid.value,
    // "date":year.value+"-"+(mo)+"-01"
  };
  var Attendance = new factory(getToken(), "getattendance", attendanceinfo);
  await Attendance.postForm().then((response) => {
    swal.close();
    if (response.data.status == "success") {
      var totalcount = response.data.data.length; // to avoid extra loop
      if (response.data.data.length > 0) {
        try {
          datesarray.value.forEach(function (val, key) {
            var founddata = response.data.data.filter(
              (item) =>
                item.attendance_date ==
                moment(datesarray.value[key][0]).format("YYYY-MM-DD")
            );
            if (founddata.length > 0) {
              datesarray.value[key][1] = [];
              founddata.forEach(function (v, k) {
                var tbp = {
                  in: v.attendance_in,
                  out: v.attendance_out,
                  break: v.attendance_break,
                  details: {
                    home: {
                      id: v.attendance_location_id,
                      name: v.location,
                    },
                    role: {
                      id: v.attendance_role_id,
                      name: v.role,
                    },
                  },
                  hourspaid: v.attendance_hourspaid,
                  attendance_id: v.attendance_id,
                };
                datesarray.value[key][1].push(tbp);
              });
            }
            if (totalcount == 0) {
              throw BreakException;
            }
          });
        } catch (e) {
          if (e !== BreakException) throw e;
        }
      }
    }
  });
}
async function getShifts() {
  $(".shifts").remove();
  if (showhideshifts.value) {
    var mo = parseInt(month.value) + 1;
    mo = mo < 10 ? "0" + mo : mo;
    var filtershift = {
      employee_id: employeeid.value,
      date: year.value + "-" + mo + "-01",
    };
    var Attendance = new factory(getToken(), "rotabyempdate", filtershift);
    await Attendance.postForm().then((response) => {
      if (response.data.status == "success") {
        // console.log('Employee Shifts');
        // console.log(response);
        swal.close();
        console.log(response.data.data);
        response.data.data.forEach((v, k) => {
          var startdate = v.rota_start_date;
          var enddate = v.rota_end_date;

          var sdate = moment(startdate, "YYYY-MM-DD").valueOf();
          var edate = moment(enddate, "YYYY-MM-DD").valueOf();
          var monthstart = moment(
            year.value + "-" + mo + "-01",
            "YYYY-MM-DD"
          ).valueOf();
          var monthend = moment(year.value + "-" + mo + "-01")
            .endOf("month")
            .format("YYYY-MM-DD")
            .valueOf();
          if (sdate < monthstart) {
            startdate = monthstart;
          } else {
            startdate = sdate;
          }
          startdate = moment(startdate);
          if (edate > monthend) {
            enddate = monthend;
          } else {
            enddate = edate;
          }
          enddate = moment(enddate);

          var days = enddate.diff(startdate, "days") + 1;

          var startd = new Date(startdate);
          var datelist = Array(days)
            .fill()
            .map((_, i) =>
              moment(new Date(startd).setDate(startd.getDate() + i)).format(
                "YYYY-MM-DD"
              )
            );
          datelist = datelist.map((line) => `[data-date="${line}"]`);
          // console.log(datelist);
          var html =
            `<tr class="shifts" style="opacity: 0.7;border: 3px solid #bfc6cc;">
                                        <td style="width: 100px;background-color: #e9ecef;">
                                            <div class="col-md-12"><div class="">` +
            v.rota_start_time +
            `</div></div>
                                        </td>
                                        <td style="width: 100px;background-color: #e9ecef;">
                                            <div class="col-md-12"><div class="">` +
            v.rota_end_time +
            `</div></div>
                                        </td>
                                        <td class="p-0" style="width: 70px;background-color: #e9ecef;">
                                            <div class="col-md-12"><div class=""></div></div>
                                        </td>
                                        <td style="background-color: #e9ecef;">
                                            <div class="col-md-12"><div class="">Role: ` +
            (v.role ? v.role : "") +
            ` | Residance:` +
            (v.location ? v.location : "") +
            ` </div></div>
                                        </td>
                                        <td class="p-0" style="width: 100px;background-color: #e9ecef;">
                                            
                                        </td>
                                    </tr>`;
          $(datelist.toString()).find("table").append(html);
          console.log(datelist);
        });
      }
    });
  }
}
function showHideShifts() {
  if (showhideshifts.value) {
    showhideshifts.value = false;
    $(".shifts").remove();
  } else {
    showhideshifts.value = true;
    $(".shifts").remove();
    getShifts();
  }
}
onMounted(async () => {
  var alldata = new factory(getToken(), "getallapis", {
    index: ["employees", "roles", "homes"],
  });
  await alldata.postForm().then((response) => {
    if (response.data.status == "success") {
    //   employeedata.value = response.data.data.employees;
      rolesdata.value = response.data.data.roles;
      homesdata.value = response.data.data.homes;
    //   employeeid.value = employeedata.value[0].employee_id;
    }
  });
  
  
  var employeefilter = {};
  var alldata = new factory(getToken(), "getemployees", employeefilter);
  await alldata.postForm().then((response) => {
    if (response.data.status == "success") {
      swal.close();
      employeedata.value = response.data.data;
      employeeid.value = employeedata.value[0].employee_id; 
    }
  });

  functions.callOnReady();

  getAttendanceInfo();

  calenderdisplay.value = true;
});
function showmodal(id) {
  $(id).modal("show");
}
function hidemodal(id) {
  $(id).modal("hide");
}
</script>
