import { ref, reactive } from "vue";
import { defineStore } from "pinia";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    token: ref(null),
    user: reactive({}),
    reports:reactive([]),
    selectedreport:ref(0),
    data:reactive({})
  }),

  persist: {
    storage: sessionStorage, // data in sessionStorage is cleared when the page session ends.
  },
});

// watch(
//   pinia.state,
//   (state) => {
//     // persist the whole state to the local storage whenever it changes
//     localStorage.setItem("piniaState", JSON.stringify(state));
//   },
//   { deep: true }
// );
