<template>
    <div class="pcoded-inner-content">

        <div class="main-body">
            <div class="page-wrapper">

                <div class="page-body">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-header">
                                    <h5>
                                        <span>Add New Vehicle</span>
                                    </h5>
                                    <div class="card-header-right">
                                        <div class="page-header-breadcrumb">
                                            <ul class="breadcrumb-title">
                                                <li class="breadcrumb-item" style="float: left;">
                                                    <a href="#"> <i class="feather icon-home"></i> </a>
                                                </li>
                                                <li class="breadcrumb-item" style="float: left;"><router-link
                                                        :to="{ name: 'VAVehicles' }">Vehicles</router-link>
                                                </li>
                                                <li class="breadcrumb-item" style="float: left;">
                                                    <a href="#"> Add New Vehicle </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                                <div class="card-body">
                                    <form class="wizard-form wizard clearfix vertical" action="#">
                                        <div class="steps clearfix">
                                            <ul class="tablist" role="tablist">
                                                <li role="tab" class="first current" aria-disabled="true"><a
                                                        id="verticle-wizard-t-1" href="javascript:void(0);"
                                                        data-target="#verticle-wizard-p-1"
                                                        aria-controls="verticle-wizard-p-1"><span class="number"></span>
                                                        Basic Information </a></li>
                                                <li role="tab" class="disabled" aria-disabled="false" aria-selected="true"> 
                                                    <a id="verticle-wizard-t-0" href="javascript:void(0);"
                                                        data-target="#verticle-wizard-p-0"
                                                        aria-controls="verticle-wizard-p-0"><span
                                                            class="current-info audible">current step: </span><span
                                                            class="number"></span> Detailed Information </a></li>
                                                <li role="tab" class="disabled" aria-disabled="true"><a
                                                        id="verticle-wizard-t-2" href="javascript:void(0);"
                                                        data-target="#verticle-wizard-p-2"
                                                        aria-controls="verticle-wizard-p-2"><span class="number"></span>
                                                        Purchase Details </a></li>
                                            </ul>
                                        </div>
                                        <div class="content clearfix">

                                            <fieldset id="verticle-wizard-p-1" role="tabpanel"
                                                aria-labelledby="verticle-wizard-h-0" class="body current"
                                                aria-hidden="true">
                                                <div class="row">
                                                    <image-input v-model="form.vehicle_picture" class="col-md-4"
                                                        :source="form.vehicle_picture" kStyle="height:20vh;" />

                                                    <div class="col-md-8">
                                                        <div class="row">
                                                            <text-input v-model="form.vehicle_name" class="col-md-6" 
                                                                label="Name" />
                                                            <select-input v-model="form.vehicle_company_id"
                                                                class="col-md-6 my-select selectpicker" label="Company">
                                                                <option value="">Select...</option>
                                                                <option v-for="comp in companiesdata" :value="comp.c_id">{{
                                                                    comp.c_name }}</option>
                                                            </select-input>
                                                            <text-input v-model="form.vehicle_start_date" class="col-md-6"
                                                                type="date" label="Start Date" />
                                                            <text-input v-model="form.vehicle_end_date" class="col-md-6"
                                                                type="date" label="End Date" />
                                                            <select-input v-model="form.vehicle_administrator"
                                                                class="col-md-6" label="Administrator">
                                                                <option value="">Select...</option>
                                                                <option v-for="user in usersdata" :value="user.u_id">
                                                                    {{ user.u_name }}
                                                                </option>
                                                            </select-input>
                                                            <select-input v-model="form.vehicle_region" class="col-md-6"
                                                                label="Region (Optional)">
                                                                <option value="">Select...</option>
                                                                <option v-for="reg in regionsdata" :value="reg.r_id">
                                                                    {{ reg.r_name }}
                                                                </option>
                                                            </select-input>

                                                        </div>
                                                    </div>

                                                    <div class="col-md-12">
                                                        <hr />
                                                    </div>
                                                    <select-input v-model="form.vehicle_correspondinguser" class="col-md-4"
                                                        label="Corresponding User">
                                                        <option value="">None</option>
                                                        <option v-for="user in usersdata" :value="user.u_id">
                                                            {{ user.u_name }}
                                                        </option>
                                                    </select-input>
                                                </div>
                                            </fieldset>
                                            <fieldset id="verticle-wizard-p-0" style="display:none" role="tabpanel"
                                                aria-labelledby="verticle-wizard-h-0" class="body " aria-hidden="true">
                                                <div class="row">
                                                    <text-input v-model="form.vehicle_make" class="col-md-4" label="Make" />
                                                    <text-input v-model="form.vehicle_model" class="col-md-4"
                                                        label="Model" />
                                                    <text-input v-model="form.vehicle_color" class="col-md-4"
                                                        label="Colour" />
                                                    <text-input v-model="form.vehicle_innumber" class="col-md-4"
                                                        label="Vehicle Identification Number" />
                                                    <text-input v-model="form.vehicle_registrationno" class="col-md-4"
                                                        label="Registration Number" />
                                                    <text-input v-model="form.vehicle_registrationdate" class="col-md-4"
                                                        label="Registration Date" />
                                                    <text-input v-model="form.vehicle_motdate" class="col-md-4"
                                                        label="MOT Date" type="date" />
                                                    <text-input v-model="form.vehicle_insurancedate" class="col-md-4"
                                                        label="Insurance Date" type="date" />
                                                    <text-input v-model="form.vehicle_taxdate" type="date" class="col-md-4"
                                                        label="Tax Date" />
                                                    <text-input v-model="form.vehicle_fueltype" class="col-md-4"
                                                        label="Fuel Type" />
                                                    <select-input v-model="form.vehicle_ownership" class="col-md-4"
                                                        label="Ownership">
                                                        <option value="0">Select</option>
                                                    </select-input>
                                                    <text-input v-model="form.vehicle_purchaseprice" class="col-md-4"
                                                        label="Purchase Price" />
                                                </div>
                                            </fieldset>
                                            <fieldset id="verticle-wizard-p-2" style="display:none" role="tabpanel"
                                                aria-labelledby="verticle-wizard-h-0" class="body " aria-hidden="true">
                                                <div class="row">
                                                    <text-input type="date" v-model="form.vehicle_purchasedate"
                                                        class="col-md-4" label="Purchase Date (If applicable)" />
                                                    <text-input type="date" v-model="form.vehicle_leasestartdate"
                                                        class="col-md-4" label="Lease Start Date (If applicable)" />
                                                    <text-input type="date" v-model="form.vehicle_leaseenddate"
                                                        class="col-md-4" label="Lease End Date (If applicable)" />
                                                    <text-input v-model="form.vehicle_balloon" class="col-md-12"
                                                        label="Balloon" />
                                                    <textarea-input v-model="form.vehicle_usefulcontacts" class="col-md-12"
                                                        rows="3" label="Useful Contact Details"></textarea-input>
                                                    <textarea-input v-model="form.vehicle_miscdetails" class="col-md-12"
                                                        rows="3" label="Miscellaneous Details"></textarea-input>
                                                </div>
                                            </fieldset>

                                        </div>
                                        <div class="actions clearfix">
                                            <ul role="menu" aria-label="Pagination">
                                                <li aria-disabled="true" class="wizard-prev-btn disabled">
                                                    <a href="javascript:void(0);" role="menuitem">Previous</a>
                                                </li>
                                                <li aria-hidden="false" aria-disabled="false" class="wizard-next-btn"><a
                                                        href="javascript:void(0);" role="menuitem">Next</a></li>
                                                <li aria-hidden="true" style="display: none;" class="wizard-finish"><a
                                                        href="javascript:void(0);" role="menuitem"
                                                        @click="addVehicles();">Finish</a></li>
                                            </ul>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>
<script setup>
import SelectInput from '../../../shared/SelectBox.vue';
import TextInput from '../../../shared/TextInput'
import TextareaInput from '../../../shared/TextareaInput'
import ImageInput from '../../../shared/ImageInput.vue'
import { ref, reactive, onMounted, onBeforeMount, provide } from 'vue';
import { useAuthStore } from '@/store/auth.js'
import { functions } from '../../../../common/appFunctions.js';
import factory from '@/common/factory.js'
import { useRouter } from 'vue-router';
const router = useRouter();
const authStore = useAuthStore();
var resident_in = ref('');
var admission_date = ref('');
var leaving_date = ref('');

// ****** Dropdowns data ********************
var companiesdata = ref([]);
var usersdata = ref([]);
var regionsdata = ref([]);
var genderdata = ref([]);
// ****** Dropdowns data ********************

const form = reactive({
    vehicle_picture: 'https://demo.dashboardpack.com/adminty-html/files/assets/images/gallery-grid/1.png',
    vehicle_name: '',
    vehicle_company_id: '',
    vehicle_start_date: '',
    vehicle_end_date: '',
    vehicle_administrator: '',
    vehicle_region: '',
    vehicle_correspondinguser: '',
    vehicle_make: '',
    vehicle_model: '',
    vehicle_color: '',
    vehicle_innumber: '',
    vehicle_registrationno: '',
    vehicle_registrationdate: '',
    vehicle_motdate: '',
    vehicle_insurancedate: '',
    vehicle_taxdate: '',
    vehicle_fueltype: '',
    vehicle_ownership: '',
    vehicle_purchaseprice: '',
    vehicle_purchasedate: '',
    vehicle_leasestartdate: '',
    vehicle_leaseenddate: '',
    vehicle_balloon: '',
    vehicle_usefulcontacts: '',
    vehicle_miscdetails: '',
    vehicle_association: [],
});
function getToken() {
    const token = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authStore.token,
        },
    };
    return token;
}
provide('getToken', getToken());
async function addVehicles() {
    const Vehicle = new factory(getToken(), 'vehicles', form);
    await Vehicle.postForm().then((response) => {
        if (response.data.status == "success") {
            router.push({ path: 'vehicles' })
        } else {
            console.log(response.data.message);
        }
    });
};
onMounted(async () => {
    var Employee = new factory(getToken(), 'getallapis', { index: ['companies', 'users', 'regions', 'gender'] });
    await Employee.postForm().then((response) => {
        if (response.data.status == "success") {
            companiesdata.value = response.data.data.companies;
            usersdata.value = response.data.data.users;
            regionsdata.value = response.data.data.regions;
            genderdata.value = response.data.data.gender;
        }
    });
    functions.callOnReady();
});

 
</script>
<style scoped>
#wizard2 fieldset {
    display: block;
    position: relative;
}

.wizard>.current {
    display: block;
}

.wizard>.content>.body {
    position: relative;

}
</style>