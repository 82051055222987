<template v-if="form.form_components != null">
<div class="row mb-3 noprint" v-if="$attrs.preview != 1">
  <div class="col-12 text-right">
    <label>&nbsp;</label>
    <button
      type="button"
      @click="printReport()"
      class="btn btn-primary btn-round waves-effect waves-light"
    >
      <i class="fa fa-print"></i>
    </button>
  </div>
</div>
<div class="container reportprintview">
  <div class="card">
    <div class="row invoice-contact">
      <div class="col-3">
        <div class="invoice-box row">
          <div class="col-12">
            <table class="table invoice-table table-borderless">
              <tbody>
                <tr>
                  <td>
                    <img style="max-width: 100%;"
                      src="https://caredoodles.t6ve.com/public/uploads/images/1676478976.png"
                      class="m-b-10"
                      alt=""
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-4 ">
        <h4 class="text-right">
          <b>{{ form.form_title }}</b>
        </h4>
      </div>
      <div class="col-5 text-right">
        <div class="invoice-box row">
          <div class="col-12">
            <table class="table invoice-table table-borderless">
              <tbody>
                <tr>
                  <td>
                    123 6th St. West Chicago, IL 60185
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:demo@gmail.com" target="_top"
                      >demo@gmail.com</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>+91 919-91-91-919</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-12">
        <hr />
      </div>
      <div class="col-12">
        <div
          v-for="(component, index, object) in form.form_components"
          :key="component"
          :class="component.form_component_cssclass"
          class="p-3"
          style="min-height: 10vh"
          v-show="component.form_component_display"
        >
          <div class="row">
            
            <template v-if="component.children != null">
              <div
                v-for="(
                  childval, childindex, childobject
                ) in component.children"
                class="p-2 pt-0"
                :class="rectifyclass(childval.form_child_cssclass)"
                
                :style="{
                  display:
                    childval.form_child_display == 1 ? 'block' : 'none',
                }"
              >
         
                <div class="form-group" style="position: relative">
                  <template
                    v-if="
                      childval.form_child_type != 'checkbox' &&
                      childval.form_child_type != 'heading'
                    "
                  >
                    <label
                      >{{ childval.form_child_label }}
                      <i
                        style="float: right; display: none"
                        class="fa fa-repeat btn btn-dark resign"
                        v-if="childval.form_child_type == 'signaturepad'"
                        @click="saveSign($event.target)"
                      ></i>
                    </label>
                  </template>
                  <template v-if="childval.form_child_type == 'checkbox'">
                    <label style="width: 100%">&nbsp;</label>
                  </template>
                  <template
                    v-if="
                      childval.form_child_type == 'datetime-local' ||
                      childval.form_child_type == 'time' ||
                      childval.form_child_type == 'text' ||
                      childval.form_child_type == 'number' ||
                      childval.form_child_type == 'date'
                    "
                  >
                    <input disabled
                      :type="childval.form_child_type"
                      class="form-control"
                      :required="childval.form_child_mandatory"
                      v-model="childval.form_child_value"
                      :placeholder="childval.form_child_placeholder"
                      :value="childval.form_child_value"
                    />
                  </template>
                  <template
                    v-else-if="childval.form_child_type == 'textarea'"
                  >
                    <textarea
                      class="form-control" disabled
                      v-model="childval.form_child_value"
                      :required="childval.form_child_mandatory"
                      :placeholder="childval.form_child_placeholder"
                      :rows="childval.rows"
                      >{{ childval.form_child_value }}</textarea
                    >
                  </template>
                  <template
                    v-else-if="childval.form_child_type == 'fileuploader'"
                  >
                    <input
                      class="form-control"
                      :required="childval.form_child_mandatory"
                      type="file"
                    />
                  </template>
                  <template
                    v-else-if="childval.form_child_type == 'signaturepad'"
                  > 
                    <img
                      :src="childval.form_child_value" style="border-bottom: 1px dashed #060606;border-left: 1px dashed #000000;border-right: 1px dashed #000000;"
                      class="signdisp"
                    />
                     
                  </template>
                  <template
                    v-else-if="childval.form_child_type == 'imageeditor'"
                  >
                    <div
                      class="form-control"
                      :style="{
                        height: childval.form_child_height
                          ? childval.form_child_height + 'px'
                          : '',
                        width: childval.form_child_width
                          ? childval.form_child_width + 'px'
                          : '',
                      }"
                      style="border: 1px solid grey"
                    ></div>
                  </template>
                  <template
                    v-else-if="childval.form_child_type == 'inlineimage'"
                  >
                    <img
                      class="img-responsive"
                      :style="{
                        height: childval.form_child_height
                          ? childval.form_child_height + 'px'
                          : '',
                        width: childval.form_child_width
                          ? childval.form_child_width + 'px'
                          : '',
                      }"
                      :src="childval.form_child_link"
                    />
                  </template>
                  <template v-else-if="childval.form_child_type == 'heading'">
                    <span :class="childval.form_child_headtype">{{
                      childval.form_child_value
                    }}</span>
                  </template>
                  <template
                    v-else-if="childval.form_child_type == 'paragraph'"
                  >
                    <p>{{ childval.form_child_value }}</p>
                  </template>
                  <template v-else-if="childval.form_child_type == 'divider'">
                    <hr
                      :size="childval.form_child_height + 'px'"
                      :style="{ opacity: '8' }"
                    />
                  </template>
                  <template
                    v-else-if="childval.form_child_type == 'checkbox'"
                  >
                    <div class="checkbox-fade fade-in-primary">
                      <label>
                        <input
                          type="checkbox"
                          :checked="childval.form_child_checked"
                          :required="childval.form_child_mandatory"
                          :value="childval.form_child_value"
                          v-model="childval.form_child_checked"
                          @change="
                            displayOrNot(
                              childval.form_child_uniqueid,
                              childval.form_child_checked
                                ? childval.form_child_value
                                : ''
                            )
                          "
                        />
                        <span class="cr">
                          <i
                            class="cr-icon icofont icofont-ui-check txt-primary"
                          ></i>
                        </span>
                        <span>{{ childval.form_child_label }}</span>
                      </label>
                    </div>
                  </template>
                  <template
                    v-else-if="childval.form_child_type == 'dropdown'"
                  >
                    <select
                      class="form-control" disabled
                      :required="childval.form_child_mandatory"
                      v-model="childval.form_child_value"
                      @change="
                        displayOrNot(
                          childval.form_child_uniqueid,
                          childval.form_child_value
                        )
                      "
                    >
                      <template
                        v-for="(
                          options, option_index, option_object
                        ) in childval.form_child_options"
                      >
                        <option
                          :value="options.optionvalue"
                          :selected="options.selected"
                        >
                          {{ options.optiontext }}
                        </option>
                      </template>
                    </select>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="col-12">
        <hr />
      </div>
      <div class="col-12  p-3">
        <div class="row p-2">
          <div class="col-4">
            <div class="form-group">
              <label>Assignee</label>
              <input
                type="text"
                class="form-control"
                :value="form.report_assign_to"
              />
            </div>
          </div>
          <div class="col-4">
            <label>Reporter</label>
            <input
              type="text"
              class="form-control"
              :value="form.report_reported_to"
            />
          </div>
           
        </div>
      </div>
    </div>
  </div>
</div>
   
      
     
</template>
<script setup>
import { defineEmits, onMounted, ref, reactive } from "vue";
import factory from "@/common/factory.js";
import Sketchpad from "./sketchpad.vue";
import SelectInput from "./SelectBox.vue";
import { useAuthStore } from "@/store/auth.js";
const authStore = useAuthStore();
let props = defineProps({
  form: Array,
});
var usersdata = ref([]);
function getToken() {
  const token = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authStore.token,
    },
  };
  return token;
}
onMounted(async () => {
  var FormExtraData = new factory(getToken(), "getallapis", {
    index: ["users"],
  });
  await FormExtraData.postForm().then((response) => {
    swal.close();
    if (response.data.status == "success") {
      usersdata.value = response.data.data.users;
    }
  });

  $(".pcoded-navbar").show();
  $(".pcoded-content").removeAttr("style");
});

const form = ref(props.form);
function displayOrNot(uniquename, currenvalue) {
  debugger;
  if (uniquename == undefined || uniquename == null || uniquename == "") {
  } else {
    for (var i = 0; i < form.value.form_components.length; i++) {
      // sections
      if (
        form.value.form_components[i].form_component_conditionalevents.length >
        0
      ) {
        form.value.form_components[i].form_component_conditionalevents.filter(
          filterEvents
        );
        function filterEvents(v, k) {
          if (v.uniquename == uniquename) {
            if (currenvalue == v.compunentvalue && v.condition == "==") {
              if (v.do == "show") {
                form.value.form_components[i].form_component_display = true;
              } else if (v.do == "hide") {
                form.value.form_components[i].form_component_display = false;
              }
              // console.log('Equal');
            } else if (currenvalue != v.compunentvalue && v.condition == "!=") {
              // console.log('Not Equal');
              if (v.do == "show") {
                form.value.form_components[i].form_component_display = true;
              } else if (v.do == "hide") {
                form.value.form_components[i].form_component_display = false;
              }
            }
          }
        }
      }

      // compunents
      if (form.value.form_components[i].children.length > 0) {
        form.value.form_components[i].children.filter((x, childkey) =>
          x.form_child_conditionalevents.filter((cond) =>
            filterCompunentEvents(cond, childkey)
          )
        );
        function filterCompunentEvents(v, k) {
          if (v.uniquename == uniquename) {
            if (currenvalue == v.compunentvalue && v.condition == "==") {
              if (v.do == "show") {
                form.value.form_components[i].children[
                  k
                ].form_child_display = true;
              } else if (v.do == "hide") {
                form.value.form_components[i].children[
                  k
                ].form_child_display = false;
              }
            } else if (currenvalue != v.compunentvalue && v.condition == "!=") {
              if (v.do == "show") {
                form.value.form_components[i].children[
                  k
                ].form_child_display = true;
              } else if (v.do == "hide") {
                form.value.form_components[i].children[
                  k
                ].form_child_display = false;
              }
            }
          }
        }
      }
    }
  }
}
function rectifyclass(val){
  return (val).replace("md-","") 
}
function printReport() {
  window.print();
}
</script>
