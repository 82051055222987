<template>
    <div class="pcoded-inner-content">

        <div class="main-body">
            <div class="page-wrapper">

                <div class="page-body">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-header">
                                    <h5>
                                        <span>Update Support Ticket</span>
                                    </h5>
                                    <div class="card-header-right">
                                        <div class="page-header-breadcrumb">
                                            <ul class="breadcrumb-title">
                                                <li class="breadcrumb-item" style="float: left;">
                                                    <a href="#"> <i class="feather icon-home"></i> </a>
                                                </li>
                                                <li class="breadcrumb-item" style="float: left;"><router-link
                                                        :to="{ name: 'VASupportTickets' }">Support Tickets</router-link>
                                                </li>
                                                <li class="breadcrumb-item" style="float: left;">
                                                    <a href="#"> Single Support Ticket </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <text-input v-model="subject" class="col-md-12" label="Subject" />
                                        <select-input v-model="companyid" class="col-md-4" label="Company">
                                            <option value="">Select</option>
                                            <option v-for="comp in companiesdata" :value="comp.c_id">{{
                                                comp.c_name }}</option>
                                        </select-input>
                                       
                                        <select-input v-model="ticket_priority" class="col-md-4"
                                            label="Ticket Priority">
                                            <option value="">Select...</option>
                                            <option v-for="mod in perioritydata" :value="mod.id">{{
                                                mod.priority }}</option>
                                        </select-input>
                                        <select-input v-model="ticket_type" class="col-md-4" label="Ticket Related To">
                                            <option value="0">Reports</option>
                                            <option value="1">Residents</option>
                                            <option value="2">Employees</option>
                                            <option value="3">Homes</option>
                                            <option value="4">Vehicles</option>
                                        </select-input>
                                    </div>

                                    <div class="row" v-show="ticket_type == 0">
                                        <select-input v-model="related_to_reports" class="col-md-4"
                                            label="Related To Reports">
                                            <option value="0">Related To New Rports</option>
                                            <option value="1">Related To An Existing Rports</option>
                                        </select-input>
                                        <select-input  v-show="related_to_reports == 0" v-model="report_type" class="col-md-4" label="Report Form ID">
                                            <option value="">Select...</option>
                                            <option v-for="form in formsdata" :value="form.form_id">{{
                                                form.form_title }}</option>
                                        </select-input>
                                        <select-input v-show="related_to_reports == 0" v-model="resident_id"
                                            class="col-md-4" label="Select Resident">
                                            <option value="">Select...</option>
                                            <option v-for="resident in residencydata" :value="resident.ry_id">{{
                                                resident.ry_name }}</option>
                                        </select-input>
                                        <select-input v-show="related_to_reports == 1" v-model="report_id"
                                            class="col-md-6" label="Select Report">
                                            <option v-for="mod in modulesdata" :value="mod.m_id">{{
                                                mod.m_name }}</option>
                                        </select-input>
                                         
                                    </div>

                                    <div class="row" v-show="ticket_type == 1">
                                        <select-input v-model="related_to_residents" class="col-md-4"
                                            label="Select Resident">
                                            <option value="">Select...</option>
                                            <option v-for="resident in residencydata" :value="resident.ry_id">{{
                                                resident.ry_name }}</option>
                                        </select-input>
                                        
                                    </div>

                                    <div class="row" v-show="ticket_type == 2">
                                        <select-input v-model="related_to_employees" class="col-md-4"
                                            label="Related To Employees">
                                            <option value="">Select...</option>
                                            <option v-for="emp in employeesdata" :value="emp.employee_id">{{
                                                emp.employee_name }}</option>
                                        </select-input>
                                        
                                    </div>

                                    <div class="row" v-show="ticket_type == 3">
                                        <select-input v-model="related_to_homes" class="col-md-4"
                                            label="Related To Homes">
                                            <option value="">Select...</option>
                                            <option v-for="home in homesdata" :value="home.home_id">{{
                                                home.home_name }}</option>
                                        </select-input>
                                         
                                    </div>

                                    <div class="row" v-show="ticket_type == 4">
                                        <select-input v-model="related_to_vehicles" class="col-md-4"
                                            label="Related To Vehicle">
                                            <option value="">Select...</option>
                                            <option v-for="veh in vehiclesdata" :value="veh.vehicle_id">{{
                                                veh.vehicle_name }}</option>
                                        </select-input>
                                        
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <div class="checkbox-fade fade-in-primary m-0">
                                                    <label>
                                                        <input type="checkbox" :checked="reported_to"
                                                            v-model="reported_to" value="1">
                                                        <span class="cr">
                                                            <i class="cr-icon icofont icofont-ui-check txt-primary"></i>
                                                        </span>
                                                        <span> Reported To</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <select-input v-show="reported_to" v-model="user_id" class="col-md-4"
                                            label="Select Reported to User">
                                            <option value="">Select...</option>
                                            <option v-for="user in usersdata" :value="user.u_id">{{
                                                user.u_name }}</option>
                                        </select-input>
                                    </div>

                                    <div>
                                        <textarea-input v-model="ticket_description" rows="10" class="col-md-12"
                                            label="Ticket Description"></textarea-input>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <button type="button"
                                                class="btn btn-primary btn-round waves-effect waves-light funload"
                                                @click="updateTicket()">Update Changes</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import SelectInput from '../../../shared/SelectBox.vue';
import TextInput from '../../../shared/TextInput'
import TextareaInput from '../../../shared/TextareaInput.vue'
import { ref, reactive, onMounted } from 'vue';
import { useAuthStore } from '@/store/auth.js'
import { functions } from '../../../../common/appFunctions.js';
import { useRouter, useRoute } from 'vue-router';
import factory from '@/common/factory.js'
const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();

var subject = ref('');
var ticket_priority = ref('');
var ticket_type = ref(0);
var related_to_reports = ref('');
var report_type = ref('');
var resident_id = ref('');
var report_id = ref('');
var related_to_residents = ref('');
var related_to_employees = ref('');
var related_to_homes = ref('');
var related_to_vehicles = ref('');
var reported_to = ref('');
var manager_id = ref('');
var user_id = ref('');
var ticket_description = ref('');
var companyid = ref('');
var ticket_id = ref(0);


var companiesdata = ref([]);
var usersdata = ref([]);
var modulesdata = ref([]);
var formsdata = ref([]);
var residencydata = ref([]);
var vehiclesdata = ref([]);
var employeesdata = ref([]);
var homesdata = ref([]);
var perioritydata = ref([]);


async function updateTicket() {
    var formid = 0;
    var ticket_related_existing = 0;
    if (ticket_type == 0) {
        formid = report_id.value;
        if (related_to_reports.value == 0) {
            ticket_related_existing = resident_id.value;
        } else {
            ticket_related_existing = report_id.value;
        }
    } else if (ticket_type == 1) {
        formid = related_to_residents.value;
    } else if (ticket_type == 2) {
        formid = related_to_employees.value;
    } else if (ticket_type == 3) {
        formid = related_to_homes.value;
    } else if (ticket_type == 4) {
        formid = related_to_vehicles.value;
    }
    var createobj = {
        ticket_id:ticket_id.value,
        ticket_company_id: companyid.value,
        ticket_subject: subject.value,
        ticket_priority: ticket_priority.value,
        ticket_related_to_type: ticket_type.value,
        ticket_new_or_existing_reports: related_to_reports.value,
        ticket_report_form_id: report_type.value,
        ticket_report_id: report_id.value,
        ticket_related_to_type_id: formid,
        ticket_reported_to: reported_to.value,
        ticket_user_id: (reported_to.value == 0) ? 0 : user_id.value,
        ticket_detail: ticket_description.value,
        ticket_resident_id: resident_id.value,
    }
    const Supportticket = new factory(getToken(), 'tickets', createobj);
    await Supportticket.putForm().then((response) => {
        functions.destroyBtnLoader();
        if (response.data.status == "success") {
            functions.kSwal('Done','Changes Saved Successfully.');
        } else {
            console.log(response.data.message);
        }
    });
}
function getToken() {
    const token = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authStore.token,
        },
    };
    return token;
}


onMounted(async () => {
    functions.initSoftLoader(); 
    const Users = new factory(getToken(), 'getallapis', { index: ['companies', 'modules', 'forms', 'residency', 'vehicles', 'employees', 'homes', 'users','periority'] });
    await Users.postForm().then((response) => {
        if (response.data.status == "success") {
            companiesdata.value = response.data.data.companies;
            modulesdata.value = response.data.data.modules;
            formsdata.value = response.data.data.forms;
            residencydata.value = response.data.data.residency;
            vehiclesdata.value = response.data.data.vehicles;
            employeesdata.value = response.data.data.employees;
            homesdata.value = response.data.data.homes;
            usersdata.value = response.data.data.users;
            perioritydata.value = response.data.data.periority;
        }
    });

    const Supportticket = new factory(getToken(), 'tickets/' + route.query.id);
    await Supportticket.getHttp().then(response => {
        if (response.data.status == 'success') {
            var formid = 0;
            var ticket_type = 0;
             
            ticket_id.value = response.data.data.ticket_id;
            report_type.value = response.data.data.ticket_report_form_id;
            companyid.value = response.data.data.ticket_company_id;
            subject.value = response.data.data.ticket_subject;
            ticket_priority.value = response.data.data.ticket_priority;
            related_to_reports.value = response.data.data.ticket_new_or_existing_reports;
            formid = response.data.data.ticket_related_to_type_id;
            ticket_type =  response.data.data.ticket_related_to_type;
            resident_id.value = response.data.data.ticket_related_to_type;
            
            if (ticket_type == 0) {
                report_id.value = response.data.data.ticket_report_id;
                if (related_to_reports.value == 0) {
                    resident_id.value = response.data.data.ticket_related_existing;
                } else {
                    report_id.value = response.data.data.ticket_related_existing;
                }
            } else if (ticket_type == 1) {
                related_to_residents.value = formid;
            } else if (ticket_type == 2) {
                related_to_employees.value = formid;
            } else if (ticket_type == 3) {
                related_to_homes.value = formid;
            } else if (ticket_type == 4) {
                related_to_vehicles.value = formid;
            }
            reported_to.value = response.data.data.ticket_reported_to;
            if (reported_to.value == 0) {
                 
            } else {
                user_id.value = response.data.data.ticket_manager_id;
            }
            ticket_description.value = response.data.data.ticket_detail;
        }
    });
    functions.callOnReady();
});


</script>