<template>
    <section class="login-block">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <form class="md-float-material form-material">
                        <div class="text-center">
                            <img alt="logo.png">
                        </div>
                        <div class="auth-box card">
                            <div class="card-block">
                                <div class="row m-b-20">
                                    <div class="col-md-12">
                                        <h3 class="text-center txt-primary">Sign up</h3>
                                    </div>
                                </div>
                                <div class="form-group form-primary">
                                    <input v-model="form.name" type="text" name="name" class="form-control" required=""
                                        placeholder="Enter Full Name">
                                    <span class="form-bar"></span>
                                </div>
                                <div class="form-group form-primary">
                                    <input v-model="form.email" type="text" name="email" class="form-control"
                                        required="" placeholder="Your Email Address">
                                    <span class="form-bar"></span>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group form-primary">
                                            <input v-model="form.password" type="password" name="password"
                                                class="form-control" required="" placeholder="Password">
                                            <span class="form-bar"></span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group form-primary">
                                            <input v-model="form.password_confirmation" type="password"
                                                name="confirm-password" class="form-control" required=""
                                                placeholder="Confirm Password">
                                            <span class="form-bar"></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row m-t-25 text-left">
                                    <div class="col-md-12">
                                        <div class="checkbox-fade fade-in-primary">
                                            <label>
                                                <input type="checkbox" value="">
                                                <span class="cr"><i
                                                        class="cr-icon icofont icofont-ui-check txt-primary"></i></span>
                                                <span class="text-inverse">I read and accept <a href="#">Terms &amp;
                                                        Conditions.</a></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="checkbox-fade fade-in-primary">
                                            <label>
                                                <input type="checkbox" value="">
                                                <span class="cr"><i
                                                        class="cr-icon icofont icofont-ui-check txt-primary"></i></span>
                                                <span class="text-inverse">Send me the <a href="#!">Newsletter</a>
                                                    weekly.</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row m-t-30">
                                    <div class="col-md-12">
                                        <button type="button"
                                            class="btn btn-primary btn-round btn-md btn-block waves-effect text-center m-b-20"
                                            @click="register()">Sign
                                            up now</button>
                                    </div>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-md-10">
                                        <p class="text-inverse text-left m-b-0">Thank you.</p>
                                        <p class="text-inverse text-left"><a href="../index.html"><b
                                                    class="f-w-600">Back
                                                    to website</b></a></p>
                                    </div>
                                    <div class="col-md-2">
                                        <img alt="small-logo.png">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>

            </div>

        </div>

    </section>
</template>
<script>
import { functions } from '../../common/appFunctions.js';
import { reactive } from 'vue';

export default {
    setup() {
        const form = reactive({
            vendor_id: 1,
            name: '',
            email: '',
            password: '',
            password_confirmation: '',
            account_type_id: 1,
        });

        async function register() {
            await functions.postForm(this.form, 'register', 'Registered Successfully');
        };

        return { form: form, register };
    }
}



    // async checkDuplicateEmail() {
    //     const url = appConsts.baseURL + 'check_email';
    //     await axios.post(url, { email: this.form.email }, this.getToken()).then(response => {
    //         if (response.data.status == 'success') {
    //             this.duplicateEmailAvailable = response.data.message;
    //         } else {
    //             this.duplicateEmailUnavailable = response.data.message;
    //         }
    //     }).catch(error => {
    //         // this.duplicateEmailError = error.response.data.message;
    //         console.log(error.response.data.message)
    //     });
    // },
// };
</script>

