<template>
    <div class="row">
        <div class="col-lg-2 col-xl-2 p-0 compunentsdiv" style="margin-top: -10px;height:90vh;overflow-y:scroll;">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-header-text">Deisgned Compunents</h5>
                </div>
                <div class="card-block p-b-0">
                    <div class="row">
                        <div class="col-md-12 p-1">
                            <div class="sortable-moves sortable-compunent pt-2 text-center z-depth-left-0 waves-effect badge kPreBooking w-100"
                                style="height: 100px;" @click="addSection();">
                                <h6><i class="ti-layout-sidebar-none fs-2"></i>
                                </h6>
                                <p style="font-size: 16px; margin-top: 7px;">Section</p>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <hr />
                        </div>

                        <div class="col-md-6 p-1" :style="{ opacity: opacity }">
                            <div class="sortable-moves sortable-compunent pt-2 text-center z-depth-left-0 waves-effect badge kMildred"
                                style="height: 80px; width: 100px;" @click="addHeading();">
                                <h6><i class="ti-smallcap fs-2"></i></h6>
                                <p style="font-size: 12px; margin-top: 3px;">Heading</p>
                            </div>
                        </div>
                        <div class="col-md-6 p-1" :style="{ opacity: opacity }">
                            <div class="sortable-moves sortable-compunent pt-2 text-center z-depth-left-0 waves-effect badge kPeggy"
                                style="height: 80px; width: 100px;" @click="addParagraph();">
                                <h6><i class="ti-paragraph fs-2"></i></h6>
                                <p style="font-size: 12px; margin-top: 3px;">Paragraph</p>
                            </div>
                        </div>
                        <div class="col-md-6 p-1" :style="{ opacity: opacity }">
                            <div class="sortable-moves sortable-compunent pt-2 text-center z-depth-left-0 waves-effect badge kJhelum"
                                style="height: 80px; width: 100px;" @click="addDivider();">
                                <h6><i class="ti-layout-line-solid fs-2"></i></h6>
                                <p style="font-size: 12px; margin-top: 3px;">Divider</p>
                            </div>
                        </div>
                        <div class="col-md-6 p-1" :style="{ opacity: opacity }">
                            <div class="sortable-moves sortable-compunent pt-2 text-center z-depth-left-0 waves-effect badge kAshley"
                                style="height: 80px; width: 100px;" @click="addInlineImage();">
                                <h6><i class="ti-image fs-2"></i></h6>
                                <p style="font-size: 12px; margin-top: 3px;">Inline Image</p>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <hr />
                        </div>
                        <div class="col-md-6 p-1" :style="{ opacity: opacity }">
                            <div class="sortable-moves sortable-compunent pt-2 text-center z-depth-left-0 waves-effect badge kJennifer"
                                style="height: 80px; width: 100px;" @click="addImageBox();">
                                <h6><i class="ti-image fs-2"></i></h6>
                                <p style="font-size: 12px; margin-top: 3px;">Image Box</p>
                            </div>
                        </div>
                        <div class="col-md-6 p-1" :style="{ opacity: opacity }">
                            <div class="sortable-moves sortable-compunent pt-2 text-center z-depth-left-0 waves-effect badge kMamie"
                                style="height: 80px; width: 100px;" @click="addIconBox();">
                                <h6><i class="ti-info-alt fs-2"></i></h6>
                                <p style="font-size: 12px; margin-top: 3px;">Icon Box</p>
                            </div>
                        </div>
                        <div class="col-md-6 p-1" :style="{ opacity: opacity }">
                            <div class="sortable-moves sortable-compunent pt-2 text-center z-depth-left-0 waves-effect badge kAnnette"
                                style="height: 80px; width: 100px;" @click="addBanner();">
                                <h6><i class="ti-layout-cta-left fs-2"></i></h6>
                                <p style="font-size: 12px; margin-top: 3px;">Banner</p>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <hr />
                        </div>
                        <div class="col-md-6 p-1" :style="{ opacity: opacity }">
                            <div class="sortable-moves sortable-compunent pt-2 text-center z-depth-left-0 waves-effect badge kCompleted"
                                style="height: 80px; width: 100px;" @click="addImagesSlider();">
                                <h6><i class="ti-layout-slider-alt fs-2"></i></h6>
                                <p style="font-size: 12px; margin-top: 3px;">Images Slider</p>
                            </div>
                        </div>
                        <div class="col-md-6 p-1" :style="{ opacity: opacity }">
                            <div class="sortable-moves sortable-compunent pt-2 text-center z-depth-left-0 waves-effect badge kAlyssa"
                                style="height: 80px; width: 100px;" @click="addTestimonials();">
                                <h6><i class="ti-comments fs-2"></i></h6>
                                <p style="font-size: 12px; margin-top: 3px;">Testimonials</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-10 p-0">
            <div class="main-body" style="height:90vh;overflow-y:scroll;overflow-x: hidden;">
                <div class="page-wrapper" style="padding-right:0px;">
                    <!-- Page-header end -->
                    <div class="modal fade" id="addnew_plan">
                        <div class="modal-dialog modal-xl" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title">Update Page Basic Information</h4>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                        @click="hidemodal('#addnew_plan');">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="row">
                                        <text-input class="col-md-6" v-model="form.page_name" label="Name"
                                            @change="renderAlias();" @keyup="renderAlias();" @keydown="renderAlias();" />
                                        <text-input class="col-md-6" v-model="form.page_alias" label="Alias (unique)"
                                            readonly />
                                        <text-input class="col-md-12" v-model="form.page_shortdescription"
                                            label="Short Description" />
                                    </div>

                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-default waves-effect " data-dismiss="modal"
                                            @click="hidemodal('#addnew_plan');">Close</button>
                                        <button type="button"
                                            class="btn btn-primary btn-round funload waves-effect waves-light "
                                            @click="updatePage()">Save Form</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Page body start -->
                    <div class="page-body">
                        <div class="row">

                            <div class="col-lg-12 formbuilderdiv">
                                <div class="card">
                                    <div class="card-header pb-2">

                                        <button @click="showmodal('#addnew_plan');"
                                            class="btn btn-round btn-success fr ml-5" type="button">
                                            Save Changes</button>

                                        <a :href="'../website/' + form.page_alias" target="_blank" v-show="!isPreview"
                                            class="btn btn-primary btn-round waves-effect waves-light fr mr-5 "
                                            style="margin-right:10px;">Preview
                                            Page</a>


                                        <router-link :to="{ name: 'VAPages' }" class="btn btn-dark fr btn-round    mr-10  "
                                            style="margin-right:10px;">
                                            <i class="ti-arrow-left"></i>
                                            Back to All Pages
                                        </router-link>
                                    </div>
                                    <div class="card-block p-b-0">
                                        <!-- <FormbuilderPreview :form="form" v-if="isPreview"></FormbuilderPreview> -->
                                        <div class="row maincontainer">
                                            <template v-if="form.page_components != null">
                                                <div v-for="(component, index, object) in form.page_components"
                                                    :key="component"
                                                    :class="component.component_cssclass + ((component.component_display) ? '' : ' cond-hide ')"
                                                    class=" sortable-moves p-3 section   sectiondiv  "
                                                    style="min-height: 10vh;" :data-key="index">
                                                    <i class="fa fa-copy copysectionbtn" @click="copySection(index);"></i>
                                                    <i class="ti-pencil-alt addbtn"
                                                        @click="addChildren(index, component.component_uniqueid);"></i>
                                                    <i class="ti-settings sectionsettingbtn"
                                                        @click="configureSection(index, 'section');"></i>
                                                    <i class="ti-move movebtn" @click="addSort();"></i>
                                                    <i class="ti-close removesecbtn"
                                                        @click="removeSection(index, component.component_uniqueid);"></i>
                                                    <div class="row childContainer">
                                                        <template v-if="component.children != null">
                                                            <template
                                                                v-for="(childval, childindex, childobject) in component.children">
                                                                <template v-if="childval.child_type == 'inlineimage'">
                                                                    <div :class="'p-3 compunent ' + childval.child_cssclass + ((childval.child_display) ? '' : ' cond-hide ')"
                                                                        :data-key="childindex" :data-sectionkey="index">
                                                                        <i class="fa fa-copy copyCompunent" @click="copyCompunent(index,childindex,childval);"></i>
                                                                        <i class="ti-move movebtn"
                                                                            @click="addSortChild();"></i>
                                                                        <i class="ti-close removebtn"
                                                                            @click="removeCompunent(index, childindex, childval.child_uniqueid);"></i>
                                                                        <i class="ti-settings settingbtn"
                                                                            @click="configureCompunent(childindex);"></i>

                                                                        <img class="img-responsive" :style="{
                                                                            height: ((childval.meta.height) ? childval.meta.height + 'px' : ''),
                                                                            width: ((childval.meta.width) ? childval.meta.width + 'px' : ''),
                                                                        }" :src="childval.meta.link">
                                                                    </div>
                                                                </template>

                                                                <template v-else-if="childval.child_type == 'heading'">
                                                                    <div :class="'p-3 compunent ' + childval.child_cssclass + ((childval.child_display) ? '' : ' cond-hide ')"
                                                                        :data-key="childindex" :data-sectionkey="index">
                                                                        <i class="fa fa-copy copyCompunent" @click="copyCompunent(index,childindex,childval);"></i>
                                                                        <i class="ti-move movebtn"
                                                                            @click="addSortChild();"></i>
                                                                        <i class="ti-close removebtn"
                                                                            @click="removeCompunent(index, childindex, childval.child_uniqueid);"></i>
                                                                        <i class="ti-settings settingbtn"
                                                                            @click="configureCompunent(childindex);"></i>
                                                                        <span :class="childval.meta.headtype">{{
                                                                            childval.meta.value
                                                                        }}</span>
                                                                    </div>
                                                                </template>
                                                                <template v-else-if="childval.child_type == 'paragraph'">
                                                                    <div :class="'p-3 compunent ' + childval.child_cssclass + ((childval.child_display) ? '' : ' cond-hide ')"
                                                                        :data-key="childindex" :data-sectionkey="index">
                                                                        <i class="fa fa-copy copyCompunent" @click="copyCompunent(index,childindex,childval);"></i>
                                                                        <i class="ti-move movebtn"
                                                                            @click="addSortChild();"></i>
                                                                        <i class="ti-close removebtn"
                                                                            @click="removeCompunent(index, childindex, childval.child_uniqueid);"></i>
                                                                        <i class="ti-settings settingbtn"
                                                                            @click="configureCompunent(childindex);"></i>
                                                                        <p>{{ childval.meta.value }}</p>
                                                                    </div>
                                                                </template>

                                                                <template v-else-if="childval.child_type == 'divider'">
                                                                    <div :class="'p-3 compunent ' + childval.child_cssclass + ((childval.child_display) ? '' : ' cond-hide ')"
                                                                        :data-key="childindex" :data-sectionkey="index">
                                                                        <i class="fa fa-copy copyCompunent" @click="copyCompunent(index,childindex,childval);"></i>
                                                                        <i class="ti-move movebtn"
                                                                            @click="addSortChild();"></i>
                                                                        <i class="ti-close removebtn"
                                                                            @click="removeCompunent(index, childindex, childval.child_uniqueid);"></i>
                                                                        <i class="ti-settings settingbtn"
                                                                            @click="configureCompunent(childindex);"></i>
                                                                        <hr :size="childval.meta.height + 'px'"
                                                                            :style="{ opacity: '8' }">
                                                                    </div>
                                                                </template>
                                                                <template v-else-if="childval.child_type == 'imagebox'">
                                                                    <template v-if="childval.meta.theme == '3'">
                                                                        <image-box3 class=" p-3 compunent " :data="childval"
                                                                            :isbuilder="true" @addSortChild="addSortChild"
                                                                            @removeCompunent="removeCompunent"
                                                                            @configureCompunent="configureCompunent"
                                                                            :index="index"
                                                                            :child_uniqueid="childval.child_uniqueid"
                                                                            :childindex="childindex"></image-box3>
                                                                    </template>
                                                                    <template v-else-if="childval.meta.theme == '2'">
                                                                        <image-box2 class=" p-3 compunent " :data="childval"
                                                                            :isbuilder="true" @addSortChild="addSortChild"
                                                                            @removeCompunent="removeCompunent"
                                                                            @configureCompunent="configureCompunent"
                                                                            :child_uniqueid="childval.child_uniqueid"
                                                                            :index="index"
                                                                            :childindex="childindex"></image-box2>
                                                                    </template>
                                                                    <template v-else>
                                                                        <image-box1 class=" p-3 compunent " :data="childval"
                                                                            :isbuilder="true" @addSortChild="addSortChild"
                                                                            @removeCompunent="removeCompunent"
                                                                            @configureCompunent="configureCompunent"
                                                                            :child_uniqueid="childval.child_uniqueid"
                                                                            :index="index"
                                                                            :childindex="childindex"></image-box1>
                                                                    </template>
                                                                </template>
                                                                <template v-else-if="childval.child_type == 'iconbox'">
                                                                    <!-- <template> -->
                                                                    <icon-box1 class=" p-3 compunent " :data="childval"
                                                                        :isbuilder="true" @addSortChild="addSortChild"
                                                                        @removeCompunent="removeCompunent"
                                                                        :child_uniqueid="childval.child_uniqueid"
                                                                        @configureCompunent="configureCompunent"
                                                                        :index="index" :childindex="childindex"></icon-box1>
                                                                    <!-- </template> -->
                                                                </template>
                                                                <template v-else-if="childval.child_type == 'banner'">
                                                                    <!-- <template> -->
                                                                    <banner1 class=" p-3 compunent " :data="childval"
                                                                        :isbuilder="true" @addSortChild="addSortChild"
                                                                        @removeCompunent="removeCompunent"
                                                                        :child_uniqueid="childval.child_uniqueid"
                                                                        @configureCompunent="configureCompunent"
                                                                        :index="index" :childindex="childindex">
                                                                    </banner1>
                                                                    <!-- </template> -->
                                                                </template>
                                                                <template v-else-if="childval.child_type == 'testimonials'">
                                                                    <!-- <template> -->
                                                                    <testimonials1 class=" p-3 compunent " :data="childval"
                                                                        :isbuilder="true" @addSortChild="addSortChild"
                                                                        @removeCompunent="removeCompunent"
                                                                        :child_uniqueid="childval.child_uniqueid"
                                                                        @configureCompunent="configureCompunent"
                                                                        :index="index" :childindex="childindex">
                                                                    </testimonials1>
                                                                    <!-- </template> -->
                                                                </template>
                                                                <template v-else-if="childval.child_type == 'imagesslider'">
                                                                    <!-- <template> -->
                                                                    <images-slider1 class=" p-3 compunent " :data="childval"
                                                                        :isbuilder="true" @addSortChild="addSortChild"
                                                                        @removeCompunent="removeCompunent"
                                                                        :child_uniqueid="childval.child_uniqueid"
                                                                        @configureCompunent="configureCompunent"
                                                                        :index="index"
                                                                        :childindex="childindex"></images-slider1>
                                                                    <!-- </template> -->
                                                                </template>

                                                            </template>
                                                        </template>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="styleSelector" class=" col-lg-3">
                                <div class="selector-toggle"><a href="javascript:void(0)"></a></div>
                                <ul>
                                    <li>
                                        <p class="selector-title main-title st-main-title"><b>Component </b>Settings</p>
                                        <span class="text-muted">Live customizer with tons of options
                                        </span>
                                    </li>
                                </ul>
                                <div class="slimScrollDiv" v-if="displayConfiguration">
                                    <div class="style-cont m-t-10">
                                        <ul class="nav nav-tabs  tabs" role="tablist">
                                            <li class="nav-item"><a class="nav-link active" data-toggle="tab"
                                                    data-target="#sel-layout" href="javascript:void(0)" role="tab">{{
                                                        editcompunent.data.type.toUpperCase()
                                                    }}</a></li>
                                        </ul>
                                        <div class="tab-content tabs">
                                            <div class="tab-pane active" id="sel-layout" role="tabpanel">
                                                <div class="row">
                                                    <text-input class="col-md-12" readonly
                                                        v-model="editcompunent.data.component_uniqueid" label="Unique ID"
                                                        v-if="editcompunent.data.type == 'section'" />
                                                    <text-input class="col-md-12" readonly
                                                        v-model="editcompunent.data.child_uniqueid" label="Unique ID"
                                                        v-if="editcompunent.data.type != 'section'" />

                                                    <!-- Heading -->
                                                    <text-input v-if="editcompunent.data.type == 'heading'"
                                                        class="col-md-12" v-model="editcompunent.data.meta.value"
                                                        label="Value" />
                                                    <select-input v-if="editcompunent.data.type == 'heading'"
                                                        v-model="editcompunent.data.meta.headtype" class="col-md-6"
                                                        label="Fontsize">
                                                        <option value="h1">H1</option>
                                                        <option :selected="(editcompunent.data.meta.headtype == 'h2')"
                                                            value="h2">H2
                                                        </option>
                                                        <option :selected="(editcompunent.data.meta.headtype == 'h3')"
                                                            value="h3">H3
                                                        </option>
                                                        <option :selected="(editcompunent.data.meta.headtype == 'h4')"
                                                            value="h4">H4
                                                        </option>
                                                        <option :selected="(editcompunent.data.meta.headtype == 'h5')"
                                                            value="h5">H5
                                                        </option>
                                                    </select-input>

                                                    <!-- Inline Image -->
                                                    <text-input v-if="editcompunent.data.type == 'inlineimage'"
                                                        :type="number" class="col-md-6"
                                                        v-model="editcompunent.data.meta.width" label="Width (px)" />
                                                    <text-input
                                                        v-if="editcompunent.data.type == 'divider' || editcompunent.data.type == 'inlineimage'"
                                                        :type="number" class="col-md-6"
                                                        v-model="editcompunent.data.meta.height" label="Height (px)" />
                                                    <image-input v-if="editcompunent.data.type == 'inlineimage'"
                                                        v-model="editcompunent.data.meta.link" class="col-md-6"
                                                        :source="editcompunent.data.meta.link" kStyle="height:20vh;"
                                                        label="Link" />


                                                    <!-- Pargraph -->
                                                    <textarea-input v-if="editcompunent.data.type == 'paragraph'"
                                                        class="col-md-12" rows="5" label="Value"
                                                        v-model="editcompunent.data.meta.value" />

                                                    <!-- Icon Box -->

                                                    <text-input v-if="editcompunent.data.type == 'iconbox'"
                                                        class="col-md-12" label="Icon"
                                                        v-model="editcompunent.data.meta.icon" />
                                                    <text-input v-if="editcompunent.data.type == 'iconbox'"
                                                        class="col-md-12" label="Heading"
                                                        v-model="editcompunent.data.meta.heading" />
                                                    <text-input v-if="editcompunent.data.type == 'iconbox'"
                                                        class="col-md-12" label="Short Description"
                                                        v-model="editcompunent.data.meta.shortdescription" />

                                                    <!-- Banner -->
                                                    <text-input v-if="editcompunent.data.type == 'banner'" class="col-md-12"
                                                        label="Image URL" v-model="editcompunent.data.meta.src" />
                                                    <text-input v-if="editcompunent.data.type == 'banner'" class="col-md-6"
                                                        label="Image Height" v-model="editcompunent.data.meta.imgheight" />
                                                    <text-input v-if="editcompunent.data.type == 'banner'" class="col-md-6"
                                                        label="Image Width" v-model="editcompunent.data.meta.imgwidth" />
                                                    <text-input v-if="editcompunent.data.type == 'banner'" class="col-md-6"
                                                        label="Button Text" v-model="editcompunent.data.meta.buttontext" />
                                                    <text-input v-if="editcompunent.data.type == 'banner'" class="col-md-6"
                                                        label="Button Link" v-model="editcompunent.data.meta.buttonlink" />
                                                    <text-input v-if="editcompunent.data.type == 'banner'" class="col-md-12"
                                                        label="Heading" v-model="editcompunent.data.meta.heading" />
                                                    <text-input v-if="editcompunent.data.type == 'banner'" class="col-md-12"
                                                        label="Short Description"
                                                        v-model="editcompunent.data.shortdescription" />




                                                    <!-- Image Box -->
                                                    <div class="col-md-12" v-if="editcompunent.data.type == 'imagebox'">
                                                        <div class="form-group">
                                                            <label class="form-label">Theme</label>
                                                            <select class="form-control"
                                                                v-model="editcompunent.data.meta.theme">
                                                                <option value="1">Top Image Bottom Text</option>
                                                                <option value="2">Left Image Right Text</option>
                                                                <option value="3">Right Image Left Text</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <image-input v-if="editcompunent.data.type == 'imagebox'"
                                                        v-model="editcompunent.data.meta.src" class="col-md-12"
                                                        :source="editcompunent.data.meta.src" kStyle="height:20vh;"
                                                        label="Image" />

                                                    <text-input v-if="editcompunent.data.type == 'imagebox'"
                                                        class="col-md-6" label="Image Height"
                                                        v-model="editcompunent.data.meta.imgheight" />
                                                    <text-input v-if="editcompunent.data.type == 'imagebox'"
                                                        class="col-md-6" label="Image Width"
                                                        v-model="editcompunent.data.meta.imgwidth" />
                                                    <text-input v-if="editcompunent.data.type == 'imagebox'"
                                                        class="col-md-6" label="Button Text"
                                                        v-model="editcompunent.data.meta.buttontext" />
                                                    <text-input v-if="editcompunent.data.type == 'imagebox'"
                                                        class="col-md-6" label="Button Link"
                                                        v-model="editcompunent.data.meta.buttonlink" />
                                                    <text-input v-if="editcompunent.data.type == 'imagebox'"
                                                        class="col-md-12" label="Heading"
                                                        v-model="editcompunent.data.meta.heading" />
                                                    <textarea-input rows="5" v-if="editcompunent.data.type == 'imagebox'"
                                                        class="col-md-12" label="Paragraph"
                                                        v-model="editcompunent.data.meta.paragraph" />

                                                    <!-- Section -->
                                                    <div class="col-md-6" v-if="editcompunent.data.type == 'section'">
                                                        <div class="form-group">
                                                            <label class="form-label">Width</label>
                                                            <select class="form-control"
                                                                v-model="editcompunent.data.component_cssclass">
                                                                <option value="col-md-12">100%</option>
                                                                <option value="col-md-6">50%</option>
                                                                <option value="col-md-4">30%</option>
                                                                <option value="col-md-3">25%</option>
                                                                <option value="col-md-2">15%</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <!-- global -->
                                                    <div class="col-md-6" v-if="editcompunent.data.type != 'section'">
                                                        <div class="form-group">
                                                            <label class="form-label">Width</label>
                                                            <select class="form-control"
                                                                v-model="editcompunent.data.child_cssclass">
                                                                <option value="col-md-12">100%</option>
                                                                <option value="col-md-6">50%</option>
                                                                <option value="col-md-4">30%</option>
                                                                <option value="col-md-3">25%</option>
                                                                <option value="col-md-2">15%</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <!-- Images Slider -->
                                                    <div class="col-md-12" v-if="editcompunent.data.type == 'imagesslider'">
                                                        <button type="button" class="btn btn-warning"
                                                            @click="addImagestoSlider();"><i class="ti-plus"></i></button>
                                                    </div>
                                                    <div class="col-md-12 p-0"
                                                        v-if="editcompunent.data.type == 'imagesslider'">
                                                        <table class="table p-0">
                                                            <tr v-for="image in editcompunent.data.meta.data">
                                                                <td>
                                                                    <image-input v-model="image.image" class="col-md-12"
                                                                        :source="image.image" kStyle="height:20vh;"
                                                                        label="Image" />
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div class="col-md-12" v-if="editcompunent.data.type == 'testimonials'">
                                                        <button type="button" class="btn btn-warning"
                                                            @click="addTestimonialtoSlider();"><i
                                                                class="ti-plus"></i></button>
                                                    </div>
                                                    <div class="col-md-12 p-0"
                                                        v-if="editcompunent.data.type == 'testimonials'">
                                                        <table class="table p-0">
                                                            <tr v-for="testimonial in editcompunent.data.meta.data"
                                                                style="border-bottom:2px solid black">
                                                                <td>
                                                                    <image-input v-model="testimonial.image"
                                                                        class="col-md-12" :source="testimonial.image"
                                                                        kStyle="height:20vh;" label="Image" />
                                                                    <text-input class="col-md-12" label="Heading 1"
                                                                        v-model="testimonial.heading1" />
                                                                    <text-input class="col-md-12" label="Heading 1"
                                                                        v-model="testimonial.heading2" />
                                                                    <text-input class="col-md-12" label="No of Stars"
                                                                        v-model="testimonial.noofstars" />
                                                                    <textarea-input class="col-md-12" label="Description"
                                                                        v-model="testimonial.paragraph" />
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <hr>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- Page body end -->
                </div>

            </div>
        </div>
    </div>
</template>
<!-- <style scoped src="../../../../../public/files/extra-pages/landingpage/assets/css/style.css"></style> -->
<script setup>
import "../../../../../public/files/extra-pages/landingpage/assets/css/style.css";
import { functions } from '../../../../common/appFunctions.js';
import { appConsts } from '../../../../common/constants.js';
import SelectInput from '../../../shared/SelectBox.vue';
import ImageInput from '../../../shared/ImageInput.vue'
import FormbuilderPreview from '../../../shared/FormbuilderPreview.vue';
import ImageBox1 from '../../../website/websiteCompunents/imagebox/imagebox-1.vue';
import ImageBox2 from '../../../website/websiteCompunents/imagebox/imagebox-2.vue';
import ImageBox3 from '../../../website/websiteCompunents/imagebox/imagebox-3.vue';
import IconBox1 from '../../../website/websiteCompunents/iconbox/iconbox-1.vue';
import Banner1 from '../../../website/websiteCompunents/banner/banner-1.vue';
import Testimonials1 from '../../../website/websiteCompunents/testimonials/testimonials-1.vue';
import ImagesSlider1 from '../../../website/websiteCompunents/imagesslider/imagesslider-1.vue';
import TextInput from '../../../shared/TextInput'
import TextareaInput from '../../../shared/TextareaInput'
import { ref, reactive, onMounted, provide } from 'vue';
import factory from '@/common/factory.js'
import { useRoute } from 'vue-router';
import { useAuthStore } from '@/store/auth.js'

const authStore = useAuthStore();
const route = useRoute();
const isPreview = ref(false);


function previewForm() {
    if (isPreview.value) {
        isPreview.value = false;
    } else {
        isPreview.value = true;
    }
}
function renderAlias() {
    form.page_alias = form.page_name.replace(/ /g, '-');
}
function addImagestoSlider() {
    editcompunent.data.data.push({ image: '' });
}
function addTestimonialtoSlider() {
    editcompunent.data.data.push({
        image: '',
        heading1: '',
        heading2: '',
        noofstars: '',
        paragraph: '',
    });
}

const form = reactive({
    page_id: 0,
    page_name: '',
    page_alias: '',
    page_shortdescription: '',
    page_vendor_id: 0,
    page_components: [],
});
var editcompunent = reactive({});
var displayConfiguration = ref(false);
var opacity = ref(0.3);

function reorderObject() {
    var newarray = [];
    $('.sectiondiv').each(function (k2, v2) {
        var key = $(this).data('key');
        var newarraychilren = [];
        $(this).find('.compunent').each(function (k, v) {
            var key2 = $(this).data('key');
            var sectionkey = $(this).data('sectionkey');
            if (form.page_components[sectionkey].children[key2] != undefined) {
                form.page_components[sectionkey].children[key2].child_orderby = k;
                newarraychilren.push(form.page_components[sectionkey].children[key2]);
            }
        });
        form.page_components[key].component_orderby = k2;
        var fullarr = form.page_components[key];
        fullarr = {
            ...fullarr,
            children: newarraychilren
        }
        newarray.push(fullarr);
    });
    form.page_components = newarray;
}
async function updatePage(successmsg = true) {
    reorderObject();
    // console.log(form);
    const Page = new factory(getToken(), 'pages', form);
    await Page.putForm().then((response) => {
        if (response.data.status == "success") {
            functions.destroyBtnLoader();
            if(successmsg){
                functions.kSwal('Done', 'Changes Saved Successfully.');
            }else{
                swal.close();
            }
            hidemodal('#addnew_plan');
        }
    });
}
onMounted(async () => {
    functions.initLoader();
    const Page = new factory(getToken(), 'pages/' + route.query.id);
    await Page.getHttp().then(response => {
        if (response.status == 200) {
            var data = (response.status == 400) ? [] : response.data.data;
            // form.value = data;
            form.page_id = data.page_id;
            form.page_vendor_id = data.page_vendor_id;
            form.page_name = data.page_name;
            form.page_alias = data.page_alias;
            form.page_shortdescription = data.page_shortdescription;
            form.page_components = data.page_components;

        }
    });

    functions.callOnReady();
    $(document).ready(function () {

        $(".testimonials").owlCarousel({
            slideSpeed: 200,
            items: 1,
            singleItem: true,
            autoPlay: true,
            pagination: false
        });

        /* ------ Clients Section Owl Carousel ----- */

        $(".clients").owlCarousel({
            slideSpeed: 200,
            items: 5,
            singleItem: false,
            autoPlay: true,
            pagination: false
        });
    })
});

function getToken() {
    const token = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authStore.token,
        },
    };
    return token;
}
provide('getToken', getToken());
async function addSection() {
    var sectioncreate = {
        page_id: form.page_id,
        component_type: 'section',
        component_cssclass: 'col-md-12',
        component_conditionalevents: [],
        component_display: true,
        component_orderby: form.page_components.length,
    }
    const Page = new factory(getToken(), 'sections', sectioncreate);
    await Page.postForm().then((response) => {
        swal.close();
        if (response.data.status == "success") {

            var feildobj = {
                component_uniqueid: response.data.data.component_uniqueid,
                component_type: 'section',
                component_cssclass: 'col-md-12',
                component_display: true,
                component_conditionalevents: [],
                children: [],
                component_orderby: form.page_components.length,
            };
            form.page_components.push(feildobj);
        } else {
            console.log(response.data.message);
        }
    });
}

async function addImageBox() {

    var childcreate = {
        page_component_uuid: editcompunent.uuid,
        child_type: 'imagebox',
        child_cssclass: 'col-md-4',
        child_display: true,
        child_orderby: form.page_components[editcompunent.pindex].children.length,
        meta: {
            theme: '1',
            src: "https://demo.dashboardpack.com/adminty-html/files/extra-pages/landingpage/assets/logos/icon1.png",
            heading: "Mega feature rich",
            paragraph: "Adminty is one of unique dashboard template which come with tons of ready to use feature. We continuous working on it to provide latest updates in digital market.",
            buttontext: "Learn More",
            buttonlink: "#",
            imgheight: '',
            imgwidth: '',
        }
    }
    const ChildAdd = new factory(getToken(), 'section/child', childcreate);
    await ChildAdd.postForm().then((response) => {
        swal.close();
        if (response.data.status == "success") {
            var feildobj = {
                child_uniqueid: response.data.data.child_uniqueid,
                child_type: 'imagebox',
                child_cssclass: 'col-md-4',
                child_display: true,
                child_orderby: form.page_components[editcompunent.pindex].children.length,
                meta: {
                    theme: '1',
                    src: "https://demo.dashboardpack.com/adminty-html/files/extra-pages/landingpage/assets/logos/icon1.png",
                    heading: "Mega feature rich",
                    paragraph: "Adminty is one of unique dashboard template which come with tons of ready to use feature. We continuous working on it to provide latest updates in digital market.",
                    buttontext: "Learn More",
                    buttonlink: "#",
                    imgheight: '',
                    imgwidth: '',
                }

            };
            if (editcompunent.pindex == null) {
                alert('Kindly Select Section');
            } else {
                form.page_components[editcompunent.pindex].children.push(feildobj);
            }
        }
    });

}
async function addImagesSlider() {
    var childcreate = {
        page_component_uuid: editcompunent.uuid,
        child_type: 'imagesslider',
        child_cssclass: 'col-md-12',
        child_display: true,
        child_orderby: form.page_components[editcompunent.pindex].children.length,
        meta: {
            theme: '1',
            initclass: 'clients' + (form.page_components[editcompunent.pindex].children.length + 1),
            data: [
                {
                    image: 'https://demo.dashboardpack.com/adminty-html/files/extra-pages/landingpage/assets/logos/logo7.png',
                },
                {
                    image: 'https://demo.dashboardpack.com/adminty-html/files/extra-pages/landingpage/assets/logos/logo7.png',
                },
                {
                    image: 'https://demo.dashboardpack.com/adminty-html/files/extra-pages/landingpage/assets/logos/logo7.png',
                },
            ],
        }
    }
    const ChildAdd = new factory(getToken(), 'section/child', childcreate);
    await ChildAdd.postForm().then((response) => {
        swal.close();
        if (response.data.status == "success") {

            var feildobj = {
                child_uniqueid: response.data.data.child_uniqueid,
                child_type: 'imagesslider',
                child_cssclass: 'col-md-12',
                child_display: true,
                child_orderby: form.page_components[editcompunent.pindex].children.length,
                meta: {
                    theme: '1',
                    initclass: 'clients' + (form.page_components[editcompunent.pindex].children.length + 1),
                    data: [
                        {
                            image: 'https://demo.dashboardpack.com/adminty-html/files/extra-pages/landingpage/assets/logos/logo7.png',
                        },
                        {
                            image: 'https://demo.dashboardpack.com/adminty-html/files/extra-pages/landingpage/assets/logos/logo7.png',
                        },
                        {
                            image: 'https://demo.dashboardpack.com/adminty-html/files/extra-pages/landingpage/assets/logos/logo7.png',
                        },
                    ],
                }
            };
            if (editcompunent.pindex == null) {
                alert('Kindly Select Section');
            } else {
                form.page_components[editcompunent.pindex].children.push(feildobj);
            }
            setTimeout(() => {
                $(".clients" + form.page_components[editcompunent.pindex].children.length).owlCarousel({
                    slideSpeed: 200,
                    items: 5,
                    singleItem: false,
                    autoPlay: true,
                    pagination: false
                });
            }, 1000);
        }
    });

}



async function addIconBox() {
    var childcreate = {
        page_component_uuid: editcompunent.uuid,
        child_type: 'iconbox',
        child_cssclass: 'col-md-4',
        child_display: true,
        child_orderby: form.page_components[editcompunent.pindex].children.length,
        meta: {
            theme: '1',
            icon: 'ion-android-download',
            heading: 'TEXT',
            shortdescription: 'Description'
        }
    }
    const ChildAdd = new factory(getToken(), 'section/child', childcreate);
    await ChildAdd.postForm().then((response) => {
        swal.close();
        if (response.data.status == "success") {
            var feildobj = {
                child_uniqueid: response.data.data.child_uniqueid,
                child_type: 'iconbox',
                child_cssclass: 'col-md-4',
                child_display: true,
                child_orderby: form.page_components[editcompunent.pindex].children.length,
                meta: {
                    theme: '1',
                    icon: 'ion-android-download',
                    heading: 'TEXT',
                    shortdescription: 'Description'
                }
            };
            if (editcompunent.pindex == null) {
                alert('Kindly Select Section');
            } else {
                form.page_components[editcompunent.pindex].children.push(feildobj);
            }
        }
    });

}
async function addBanner() {
    var childcreate = {
        page_component_uuid: editcompunent.uuid,
        child_type: 'banner',
        child_cssclass: 'col-md-12',
        child_display: true,
        child_orderby: form.page_components[editcompunent.pindex].children.length,
        meta: {
            theme: '1',
            src: "https://demo.dashboardpack.com/adminty-html/files/extra-pages/landingpage/assets/logos/icon1.png",
            heading: "Mega feature rich",
            shortdescription: "Adminty is one of unique dashboard template which come with tons of ready to use feature.",
            buttontext: "Learn More",
            buttonlink: "#",
        }
    }
    const ChildAdd = new factory(getToken(), 'section/child', childcreate);
    await ChildAdd.postForm().then((response) => {
        swal.close();
        if (response.data.status == "success") {

            var feildobj = {
                child_uniqueid: response.data.data.child_uniqueid,
                child_type: 'banner',
                child_cssclass: 'col-md-12',
                child_display: true,
                child_orderby: form.page_components[editcompunent.pindex].children.length,
                meta: {
                    theme: '1',
                    src: "https://demo.dashboardpack.com/adminty-html/files/extra-pages/landingpage/assets/logos/icon1.png",
                    heading: "Mega feature rich",
                    shortdescription: "Adminty is one of unique dashboard template which come with tons of ready to use feature.",
                    buttontext: "Learn More",
                    buttonlink: "#",
                }
            };
            if (editcompunent.pindex == null) {
                alert('Kindly Select Section');
            } else {
                form.page_components[editcompunent.pindex].children.push(feildobj);
            }
        }
    });
}
async function addTestimonials() {
    var childcreate = {
        page_component_uuid: editcompunent.uuid,
        child_type: 'testimonials',
        child_cssclass: 'col-md-12',
        child_display: true,
        child_orderby: form.page_components[editcompunent.pindex].children.length,
        meta: {
            initclass: 'testimonials' + (form.page_components[editcompunent.pindex].children.length + 1),
            theme: '1',
            data: [
                {
                    image: 'https://demo.dashboardpack.com/adminty-html/files/extra-pages/landingpage/assets/images/testimonial2.jpg',
                    heading1: 'Code Quality',
                    heading2: '- Boy ( Engineer )',
                    paragraph: 'Totally flexible admin template. Easy to use and easy to manage all the elements in entire theme.  Great support team behind this product. Low turnaround time with exact support which i needed.',
                    noofstars: '3'
                },
                {
                    image: 'https://demo.dashboardpack.com/adminty-html/files/extra-pages/landingpage/assets/images/testimonial2.jpg',
                    heading1: 'Code Quality',
                    heading2: '- Boy ( Engineer )',
                    paragraph: 'Totally flexible admin template. Easy to use and easy to manage all the elements in entire theme.  Great support team behind this product. Low turnaround time with exact support which i needed.',
                    noofstars: '3'
                },
                {
                    image: 'https://demo.dashboardpack.com/adminty-html/files/extra-pages/landingpage/assets/images/testimonial2.jpg',
                    heading1: 'Code Quality',
                    heading2: '- Boy ( Engineer )',
                    paragraph: 'Totally flexible admin template. Easy to use and easy to manage all the elements in entire theme.  Great support team behind this product. Low turnaround time with exact support which i needed.',
                    noofstars: '3'
                }
            ]
        },
    }
    const ChildAdd = new factory(getToken(), 'section/child', childcreate);
    await ChildAdd.postForm().then((response) => {
        swal.close();
        if (response.data.status == "success") {
            var feildobj = {
                child_uniqueid: response.data.data.child_uniqueid,
                child_type: 'testimonials',
                child_cssclass: 'col-md-12',
                child_display: true,
                child_orderby: form.page_components[editcompunent.pindex].children.length,
                meta: {
                    initclass: 'testimonials' + (form.page_components[editcompunent.pindex].children.length + 1),
                    theme: '1',
                    data: [
                        {
                            image: 'https://demo.dashboardpack.com/adminty-html/files/extra-pages/landingpage/assets/images/testimonial2.jpg',
                            heading1: 'Code Quality',
                            heading2: '- Boy ( Engineer )',
                            paragraph: 'Totally flexible admin template. Easy to use and easy to manage all the elements in entire theme.  Great support team behind this product. Low turnaround time with exact support which i needed.',
                            noofstars: '3'
                        },
                        {
                            image: 'https://demo.dashboardpack.com/adminty-html/files/extra-pages/landingpage/assets/images/testimonial2.jpg',
                            heading1: 'Code Quality',
                            heading2: '- Boy ( Engineer )',
                            paragraph: 'Totally flexible admin template. Easy to use and easy to manage all the elements in entire theme.  Great support team behind this product. Low turnaround time with exact support which i needed.',
                            noofstars: '3'
                        },
                        {
                            image: 'https://demo.dashboardpack.com/adminty-html/files/extra-pages/landingpage/assets/images/testimonial2.jpg',
                            heading1: 'Code Quality',
                            heading2: '- Boy ( Engineer )',
                            paragraph: 'Totally flexible admin template. Easy to use and easy to manage all the elements in entire theme.  Great support team behind this product. Low turnaround time with exact support which i needed.',
                            noofstars: '3'
                        }
                    ]
                },
            };
            if (editcompunent.pindex == null) {
                alert('Kindly Select Section');
            } else {
                form.page_components[editcompunent.pindex].children.push(feildobj);

                setTimeout(function () {
                    $(".testimonials" + form.page_components[editcompunent.pindex].children.length).owlCarousel({
                        slideSpeed: 200,
                        items: 1,
                        singleItem: true,
                        autoPlay: true,
                        pagination: false
                    });
                }, 1000);
            }
        }
    });
}
async function addInlineImage() {
    var childcreate = {
        page_component_uuid: editcompunent.uuid,
        child_type: 'inlineimage',
        child_cssclass: 'col-md-4',
        child_display: true,
        child_orderby: form.page_components[editcompunent.pindex].children.length,
        meta: {
            label: 'Inline Image',
            height: '',
            width: '',
            link: 'https://static.vecteezy.com/system/resources/previews/005/337/799/original/icon-image-not-found-free-vector.jpg',
        },
    }
    const ChildAdd = new factory(getToken(), 'section/child', childcreate);
    await ChildAdd.postForm().then((response) => {
        swal.close();
        if (response.data.status == "success") {

            var feildobj = {
                child_uniqueid: response.data.data.child_uniqueid,
                child_type: 'inlineimage',
                child_cssclass: 'col-md-4',
                child_display: true,
                child_orderby: form.page_components[editcompunent.pindex].children.length,
                meta: {
                    label: 'Inline Image',
                    height: '',
                    width: '',
                    link: 'https://static.vecteezy.com/system/resources/previews/005/337/799/original/icon-image-not-found-free-vector.jpg',
                },
            };


            if (editcompunent.pindex == null) {
                alert('Kindly Select Section');
            } else {
                form.page_components[editcompunent.pindex].children.push(feildobj);
            }
        }
    });

}
async function addHeading() {

    var childcreate = {
        page_component_uuid: editcompunent.uuid,
        child_type: 'heading',
        child_cssclass: 'col-md-12',
        child_display: true,
        child_orderby: form.page_components[editcompunent.pindex].children.length,
        meta: {
            value: 'Heading',
            headtype: 'h1',
        },
    }
    const ChildAdd = new factory(getToken(), 'section/child', childcreate);
    await ChildAdd.postForm().then((response) => {
        swal.close();
        if (response.data.status == "success") {

            if (editcompunent.uuid == null) {
                alert('Kindly Select Section');
            }

            var feildobj = {
                child_uniqueid: response.data.data.child_uniqueid,
                child_type: 'heading',
                child_cssclass: 'col-md-12',
                child_display: true,
                child_orderby: form.page_components[editcompunent.pindex].children.length,
                meta: {
                    value: 'Heading',
                    headtype: 'h1',
                },
            };

            if (editcompunent.pindex == null) {
                alert('Kindly Select Section');
            } else {
                form.page_components[editcompunent.pindex].children.push(feildobj);
            }
        }
    });
}

async function addDivider() {
    var childcreate = {
        page_component_uuid: editcompunent.uuid,
        child_type: 'divider',
        child_cssclass: 'col-md-12',
        child_display: true,
        child_orderby: form.page_components[editcompunent.pindex].children.length,
        meta: {
            height: '3',
            headtype: 'h1',
        },
    }
    const ChildAdd = new factory(getToken(), 'section/child', childcreate);
    await ChildAdd.postForm().then((response) => {
        swal.close();
        if (response.data.status == "success") {

            var feildobj = {
                child_uniqueid: response.data.data.child_uniqueid,
                child_type: 'divider',
                child_cssclass: 'col-md-12',
                child_display: true,
                child_orderby: form.page_components[editcompunent.pindex].children.length,
                meta: {
                    height: '3',
                    headtype: 'h1',
                },
            };

            if (editcompunent.uuid == null) {
                alert('Kindly Select Section');
            }
            if (editcompunent.pindex == null) {
                alert('Kindly Select Section');
            } else {
                form.page_components[editcompunent.pindex].children.push(feildobj);
            }
        }
    });


}
async function addParagraph() {
    var childcreate = {
        page_component_uuid: editcompunent.uuid,
        child_type: 'paragraph',
        child_cssclass: 'col-md-12',
        child_display: true,
        child_orderby: form.page_components[editcompunent.pindex].children.length,
        meta: {
            value: 'Enter Paragraph Here'
        },
    }
    const ChildAdd = new factory(getToken(), 'section/child', childcreate);
    await ChildAdd.postForm().then((response) => {
        swal.close();
        if (response.data.status == "success") {

            var feildobj = {
                child_uniqueid: response.data.data.child_uniqueid,
                child_type: 'paragraph',
                child_cssclass: 'col-md-12',
                child_display: true,
                child_orderby: form.page_components[editcompunent.pindex].children.length,
                meta: {
                    value: 'Enter Paragraph Here'
                },
            };

            if (editcompunent.pindex == null) {
                alert('Kindly Select Section');
            } else {
                form.page_components[editcompunent.pindex].children.push(feildobj);
            }
        }
    });
}

function configureCompunent(index) {
    displayConfiguration.value = true;
    editcompunent.data = form.page_components[editcompunent.pindex].children[index];
    editcompunent.index = index;
    editcompunent.data.type = editcompunent.data.child_type;

    $('.formbuilderdiv').removeClass('col-lg-12');
    $('.formbuilderdiv').addClass('col-lg-9');

}
function configureSection(index, condition = '') {

    editcompunent.data = form.page_components[index];

    if (condition == 'section') {
        editcompunent.data.type = 'section';
    }

    displayConfiguration.value = true;

    $('.formbuilderdiv').removeClass('col-lg-12');
    $('.formbuilderdiv').addClass('col-lg-9');
}

function addChildren(index, uuid) {
    editcompunent.pindex = index;
    editcompunent.uuid = uuid;
    opacity.value = 1;
}
$(document).on('click', '[data-toggle="tab"]', function () {
    var openingtabid = $(this).data('target');
    $('[data-toggle="tab"]').removeClass('active');
    $(this).addClass('active');
    $(this).closest('.nav').parent().find('.tab-pane').removeClass('active');
    $(openingtabid).addClass('active');
})


function addSort() {
    $('.maincontainer').sortable({
        disabled: false,
        connectWith: ".maincontainer"
    });
}

function resetSort() {
    $(".maincontainer").sortable({
        disabled: true
    });
}
async function copyCompunent(index,childindex,valuee){
    var childcreate = valuee;
     childcreate.page_component_uuid =  editcompunent.uuid;
     childcreate.child_orderby = (parseInt(form.page_components[index].children[childindex].child_orderby)+1);
    //  {
        // page_component_uuid: editcompunent.uuid,
        // child_type: 'heading',
        // child_cssclass: 'col-md-12',
        // child_display: true,
        // child_orderby: (parseInt(form.page_components[index].children[childindex].child_orderby)+1),
        // meta: {
            // value: 'Heading',
            // headtype: 'h1',
        // },
    // }
    // console.log(valuee);
    const ChildAdd = new factory(getToken(), 'section/child', childcreate);
    await ChildAdd.postForm().then((response) => {
        swal.close();
        if (response.data.status == "success") {

            if (editcompunent.uuid == null) {
                alert('Kindly Select Section');
            }

            // var feildobj = valuee;
            // feildobj.child_uniqueid = response.data.data.child_uniqueid;
            // feildobj.child_orderby = (parseInt(form.page_components[index].children[childindex].child_orderby)+1);
            var feildobj = {
                child_uniqueid: response.data.data.child_uniqueid,
                child_type: response.data.data.child_type,
                child_cssclass: response.data.data.child_cssclass,
                child_display: 1,
                child_orderby: response.data.data.child_orderby,
                meta: {},
            }; 
            for (var [key, value] of Object.entries(form.page_components[index].children[childindex].meta)) {
                feildobj.meta[key] = value;
            }
            var newobject = form.page_components[index].children;
            form.page_components[index].children = [];

            for(var i = 0; i< newobject.length; i++) {
                newobject[i].child_orderby = i;
                form.page_components[index].children.push(newobject[i]); 
                if(i == (parseInt(newobject[childindex].child_orderby))){
                    form.page_components[index].children.push(feildobj);
                }
            }  
            setTimeout(function(){
                updatePage(false); 
            },400);

        }
    });
} 
async function copySection(index) {
    var exsitingdata = form.page_components[index];
    var sectioncreate = {
        page_id: form.page_id,
        ...exsitingdata,
        component_orderby: (parseInt(form.page_components[index].component_orderby) + 1),
    }
    sectioncreate.component_uniqueid = '';
    
    functions.initSoftLoader();
    const Page = new factory(getToken(), 'sections', sectioncreate);
    await Page.postForm().then(async (response) => {
        swal.close();
        if (response.data.status == "success") { 
            var feildobj = {
                component_uniqueid: response.data.data.component_uniqueid,
                ...exsitingdata,
                component_orderby: (parseInt(form.page_components[index].component_orderby) + 1),
                children:[]
            };
            var newobject = form.page_components;
            var compunents = [];
            form.page_components = [];
            // console.log(form.page_components);
            for(var i = 0; i< newobject.length; i++) {
                form.page_components.push(newobject[i]);
                
                if(i == (parseInt(newobject[index].component_orderby))){
                    feildobj.component_uniqueid = response.data.data.component_uniqueid;
                    feildobj.component_orderby = (parseInt(newobject[index].component_orderby) + 1);
                    
                    for(var j = 0; j < response.data.data.children.length; j++) {
                        // newobject[i].children.length
                        var childobj = {
                            child_uniqueid: response.data.data.children[j].child_uniqueid,
                            child_type: response.data.data.children[j].child_type,
                            child_cssclass: response.data.data.children[j].child_cssclass,
                            child_display: response.data.data.children[j].child_display,
                            child_orderby: response.data.data.children[j].child_orderby,
                            child_theme: response.data.data.children[j].child_theme,
                            meta: {}
                        };
                        for (var [key, value] of Object.entries(newobject[i].children[j].meta)) {
                            childobj.meta[key] = value;
                        }
                        feildobj.children.push(childobj);
                    }
                    form.page_components.push(feildobj);
                }
            }

            setTimeout(function(){
                updatePage(false); 
            },500);
            // form.page_components = compunents;
            // console.log(newobject);
            // changeArr(newobject);
            // a.then(()=>{
                // updatePage(false);
            // });
            // form.page_components = [];
            // setTimeout(function(){
            //     form.page_components = newobject;
            // },800);
            // console.log(form.page_components);
        } else {
            console.log(response.data.message);
        }
    }); 
}
function changeArr(newobject){
    form.page_components = newobject;

}
async function removeSection(index, uuid) {
    const Page = new factory(getToken(), 'sections/' + uuid);
    await Page.deleteHttp().then((response) => {
        swal.close();
        if (response.data.status == "success") {
            form.page_components = form.page_components.filter((x, k) => k !== index);
        }
    });
}
async function removeCompunent(sectionindex, compunentindex, uuid) {
    const Page = new factory(getToken(), 'section/child/' + uuid);
    await Page.deleteHttp().then((response) => {
        swal.close();
        if (response.data.status == "success") {
            var remainingchilren = form.page_components[sectionindex].children.filter((x, k) => k !== compunentindex);
            form.page_components[sectionindex].children = remainingchilren;
        }
    });
}

function addSortChild() {
    $('.childContainer').sortable({
        disabled: false,
        connectWith: ".childContainer"
    });
}

function resetSortChild() {
    $(".childContainer").sortable({
        disabled: true
    });
}
$(document).on('click', '.selector-toggle', function () {
    $(this).removeClass('active');
    $('.formbuilderdiv').addClass('col-lg-12');
    $('.formbuilderdiv').removeClass('col-lg-9');
    $('#styleSelector').removeClass('open');
});
$(document).on('click', '.settingbtn,.sectionsettingbtn', function () {

    $('.compunent').removeClass('active');
    $(this).closest('.compunent').addClass('active');
    $('#styleSelector').addClass('open');
    $('.selector-toggle').addClass('active');
})
$(document).on('click', '.addbtn', function () {
    $('.section').removeClass('active');
    $('#styleSelector').removeClass('open');
    $('.selector-toggle').removeClass('active');
    $('.formbuilderdiv').addClass('col-lg-12');
    $('.formbuilderdiv').removeClass('col-lg-9');
    $('.section').removeClass('addactive');
    $(this).closest('.section').addClass('addactive');
})
$('.pcoded-navbar').hide();
$('.pcoded-content').attr('style', 'margin-left: 0px !important;');


function showmodal(id) {
    $(id).modal('show');
}
function hidemodal(id) {
    $(id).modal('hide');
}

</script> 
<style type="text/css" scoped>
.selector-toggle.active {
    display: block !important;
}

.selector-toggle {
    display: none;
}

.sortable-compunent:hover {
    background-color: #404e671a;
}

.sortable-compunent {
    cursor: pointer;
    padding-left: unset;
    padding-right: unset;
    border-top: 1px dashed #404e67;
    border-bottom: 1px dashed #404e67;
    border-radius: 6px;
}

.slimScrollDiv {

    margin-left: -15px;
    margin-right: -15px;

}

#styleSelector .open {
    position: relative;
    top: -38px;
}

.pcoded-navbar {
    display: none !important;
}

.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="expanded"][vertical-effect="shrink"] .pcoded-content {
    margin-left: 0px;
}
</style>
