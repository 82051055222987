<template>
    <div
      class="main-body"
      style="height: 90vh; overflow-y: scroll; overflow-x: hidden"
    >
      <!-- Page body start -->
      <div class="page-body">
        <div class="row">
          <div class="col-lg-12 formbuilderdiv">
            <div class="card">
              <div class="card-block p-b-0">
                <FormbuilderPreview
                  @reportSaveBtnTriggered="updateReport"
                  :form="form"
                  v-if="isPreview"
                ></FormbuilderPreview>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Page body end -->
    </div>
  </template>
  
  <script setup>
  import { functions } from '../../../../common/appFunctions.js';
  import FormbuilderPreview from '../../../shared/FormbuilderPreview.vue';
  import { ref, reactive, onMounted, provide,onBeforeMount } from 'vue';
  import { useAuthStore } from '@/store/auth.js'
  import factory from '@/common/factory.js'
  import { useRoute,useRouter } from 'vue-router';
  
  const authStore = useAuthStore();
  const route = useRoute();
  const router = useRouter();
  const isPreview = ref(false);
  
  function previewForm() {
      if (isPreview.value) {
          isPreview.value = false;
      } else {
          isPreview.value = true;
      }
  }
  
  var form = ref({
      form_id: '',
      form_title: '',
      form_description: '',
      form_instructions: '',
      form_company_id: '',
      form_company_id: '',
      form_task_triggered: '',
      form_isactive: 1,
      form_emailnotifications: [],
      form_components: [],
  });
  
  function getToken() {
      const token = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + authStore.token,
          },
      };
      return token;
  }
  provide('getToken', getToken());
  
  onMounted(async () => {
      const Form = new factory(getToken(), 'forms/' + route.query.id);
      await Form.getHttp().then(response => {
          if (response.status == 200) {
              isPreview.value = true; 
  
              var data = (response.status == 400) ? [] : response.data.data;
              form.value = data;
              form.value.form_emailnotifications = JSON.parse(form.value.form_emailnotifications);
               
          }
      });
      functions.callOnReady();
   
  
      $('.pcoded-navbar').show();
      $('.pcoded-content').removeAttr('style');
  });
  
  async function updateReport(){
      var FormApi = new factory(getToken(), "forms", form.value);
      await FormApi.putForm().then((response) => {
          functions.destroyBtnLoader();
          swal.close();
          if (response.data.status == "success") {
              swal.fire('Done!','Report Updated Successfully','success');
          }
      });
  }
  </script>
  <style type="text/css" scoped>
  .selector-toggle.active {
    display: block !important;
  }
  
  .selector-toggle {
    display: none;
  }
  
  .sortable-compunent:hover {
    background-color: #404e671a;
  }
  
  .sortable-compunent {
    cursor: pointer;
    padding-left: unset;
    padding-right: unset;
    border-top: 1px dashed #404e67;
    border-bottom: 1px dashed #404e67;
    border-radius: 6px;
  }
  
  .slimScrollDiv {
    margin-left: -15px;
    margin-right: -15px;
  }
  
  #styleSelector .open {
    position: relative;
    top: -38px;
  }
  </style>
  