<template>
  <div class="page-header">
    <div class="row">
      <div class="col-xl-2 col-md-2">
        <div class="card bg-c-grey text-c-black">
          <div class="card-block">
            <div class="row align-items-center">
              <div class="col">
                <p class="m-b-5">Homes</p>
                <h4 class="m-b-0">852</h4>
              </div>
              <div class="col col-auto text-right">
                <i class="icofont icofont-ui-home f-50 text-c-black"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-md-2">
        <div class="card bg-c-grey text-c-black">
          <div class="card-block">
            <div class="row align-items-center">
              <div class="col">
                <p class="m-b-5">Employee</p>
                <h4 class="m-b-0">852</h4>
              </div>
              <div class="col col-auto text-right">
                <i class="icofont icofont-business-man f-50 text-c-black"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-md-2">
        <div class="card bg-c-grey text-c-black">
          <div class="card-block">
            <div class="row align-items-center">
              <div class="col">
                <p class="m-b-5">Residents</p>
                <h4 class="m-b-0">852</h4>
              </div>
              <div class="col col-auto text-right">
                <i class="icofont icofont-users-social f-50 text-c-black"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-md-2">
        <div class="card bg-c-grey text-c-black">
          <div class="card-block">
            <div class="row align-items-center">
              <div class="col">
                <p class="m-b-5">Vehicals</p>
                <h4 class="m-b-0">852</h4>
              </div>
              <div class="col col-auto text-right">
                <i class="icofont icofont-car-alt-1 f-50 text-c-black"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-md-2">
        <div class="card bg-c-grey text-c-black">
          <div class="card-block">
            <div class="row align-items-center">
              <div class="col">
                <p class="m-b-5">Users</p>
                <h4 class="m-b-0">852</h4>
              </div>
              <div class="col col-auto text-right">
                <i class="icofont icofont-users-alt-3 f-50 text-c-black"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-md-2">
        <div class="card bg-c-grey text-c-black">
          <div class="card-block">
            <div class="row align-items-center">
              <div class="col">
                <p class="m-b-5">Manuals</p>
                <h4 class="m-b-0">852</h4>
              </div>
              <div class="col col-auto text-right">
                <i class="icofont icofont-ui-home f-50 text-c-black"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 col-lg-4">
        <div class="card">
          <div class="card-header">
            <h5>Reports</h5>
            <span>Number of reports.</span>
          </div>
          <div class="card-block">
            <canvas id="pieChart" width="400" height="400"></canvas>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="card">
          <div class="card-header">
            <h5>Tasks</h5>
            <span>Number of Tasks.</span>
          </div>
          <div class="card-block">
            <canvas id="polarChart" width="400" height="400"></canvas>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="card">
          <div class="card-header">
            <h5>Tickets</h5>
            <span>Number of Tickets.</span>
          </div>
          <div class="card-block">
            <canvas id="myChart" width="400" height="400"></canvas>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-lg-4">
        <div class="card">
          <div class="card-header">
            <h5>Reports</h5>
            <span>Monthly number of reports</span>
          </div>
          <div class="card-block">
            <div
              id="placeholder"
              class="demo-placeholder"
              style="height: 300px"
            ></div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-lg-4">
        <div class="card">
          <div class="card-header">
            <h5>Tasks</h5>
            <span>Monthly number of Tasks</span>
          </div>
          <div class="card-block">
            <div
              id="placeholder2"
              class="demo-placeholder"
              style="height: 300px"
            ></div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-lg-4">
        <div class="card">
          <div class="card-header">
            <h5>Tickets</h5>
            <span>Monthly number of Tickets</span>
          </div>
          <div class="card-block">
            <div
              id="placeholder3"
              class="demo-placeholder"
              style="height: 300px"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue";
import factory from "@/common/factory.js";
import { useRouter, useRoute } from "vue-router";
import { useAuthStore } from "@/store/auth.js";
import { functions } from "../../../../common/appFunctions.js";
const authStore = useAuthStore();
function getToken() {
  const token = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authStore.token,
    },
  };
  return token;
}

onMounted(async () => {
  functions.initLoader();
  

  $(document).ready(function () {
    var ctx = document.getElementById("myChart");
    var data = {
      labels: ["A", "B", "C", "D "],
      datasets: [
        {
          data: [40, 10, 40, 10],
          backgroundColor: ["#1ABC9C", "#FCC9BA", "#B8EDF0", "#B4C1D7"],
          borderWidth: ["0px", "0px", "0px", "0px"],
          borderColor: ["#1ABC9C", "#FCC9BA", "#B8EDF0", "#B4C1D7"],
        },
      ],
    };
    var myDoughnutChart = new Chart(ctx, { type: "doughnut", data: data });

    var polarElem = document.getElementById("polarChart");
    var data3 = {
      datasets: [
        {
          data: [11, 16, 7, 3, 14],
          backgroundColor: [
            "#7E81CB",
            "#1ABC9C",
            "#B8EDF0",
            "#B4C1D7",
            "#01C0C8",
          ],
          hoverBackgroundColor: [
            "#a1a4ec",
            "#2adab7",
            "#a7e7ea",
            "#a5b0c3",
            "#10e6ef",
          ],
          label: "My dataset",
        },
      ],
      labels: ["Blue", "Green", "Light Blue", "grey", "Sea Green"],
    };
    new Chart(polarElem, {
      data: data3,
      type: "polarArea",
      options: { elements: { arc: { borderColor: "" } } },
    });
    var pieElem = document.getElementById("pieChart");
    var data4 = {
      labels: ["Blue", "Orange", "Sea Green"],
      datasets: [
        {
          data: [30, 30, 40],
          backgroundColor: ["#25A6F7", "#FB9A7D", "#01C0C8"],
          hoverBackgroundColor: ["#6cc4fb", "#ffb59f", "#0dedf7"],
        },
      ],
    };
    var myPieChart = new Chart(pieElem, { type: "pie", data: data4 });

    $(window).on("resize", function () {
      categoryChart(); 
    });
    categoryChart(); 
    function categoryChart() {
      var data = [
        ["January", 20],
        ["February", 8],
        ["March", 4],
        ["April", 13],
        ["May", 5],
        ["June", 9],
      ];
      $.plot("#placeholder", [data], {
        series: { bars: { show: true, barWidth: 0.6, align: "center" } },
        xaxis: { mode: "categories", tickLength: 0, tickColor: "#f5f5f5" },
        colors: ["#01C0C8", "#83D6DE"],
        labelBoxBorderColor: "red",
      });
      $.plot("#placeholder2", [data], {
        series: { bars: { show: true, barWidth: 0.6, align: "center" } },
        xaxis: { mode: "categories", tickLength: 0, tickColor: "#f5f5f5" },
        colors: ["#01C0C8", "#83D6DE"],
        labelBoxBorderColor: "red",
      });
      $.plot("#placeholder3", [data], {
        series: { bars: { show: true, barWidth: 0.6, align: "center" } },
        xaxis: { mode: "categories", tickLength: 0, tickColor: "#f5f5f5" },
        colors: ["#01C0C8", "#83D6DE"],
        labelBoxBorderColor: "red",
      });
    }
     
  });
  const Report = new factory(getToken(), "forms/modules/1");
  await Report.getHttp().then((response) => {
    swal.close();
    if (response.data.status == "success") {
      authStore.reports = response.data.data;
    }
  });
});
</script>
