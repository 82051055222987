<template>
    <div class="main-body">
        <div class="page-wrapper">
            <!-- Page body start -->
            <div class="page-body">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-header">
                                <h5>
                                    <span>Employee</span>
                                    <button type="button"
                                        class="btn btn-primary addnewbtn btn-round waves-effect f-right "
                                        @click="addNew()">Add
                                        New</button>
                                </h5>
                                <div class="card-header-right">
                                    <div class="page-header-breadcrumb">
                                        <ul class="breadcrumb-title">
                                            <li class="breadcrumb-item" style="float: left;">
                                                <a href="index.html">
                                                    <i class="icofont icofont-home"></i>
                                                </a>
                                            </li>
                                            <li class="breadcrumb-item" style="float: left;"><router-link
                                                    :to="{ name: 'VAEmployee' }">Employee</router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <div class="card-body">
                                <table-view v-if="displaytbl" :data="responsefromapi"
                                    @viewbuttonclicked="editbtnclicked" @deletebuttonclicked="deletebuttonclicked" @activeunactive="activeunactive" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script setup>

import TableView from '../../../shared/TableView.vue';
import { functions } from '../../../../common/appFunctions.js';
import { ref, reactive, onMounted } from 'vue';
import { useAuthStore } from '@/store/auth.js'
import factory from '@/common/factory.js'
import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const authStore = useAuthStore();

var responsefromapi = ref();
var displaytbl = ref(false);
function getToken() {
    const token = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authStore.token,
        },
    };
    return token;
}
function deletebuttonclicked(id) {
  var ask = functions.askSwal();
  ask.then(async (result) => {
    if (result.isConfirmed) {
      const employees = new factory(getToken(), "employees/" + id);
      await employees.deleteHttp().then((response) => {
        if (response.data.status == "success") {
          location.reload();
        } else {
          console.log(response.data.message);
        }
      });
    }
  });
}
async function activeunactive(id,status){
    var upactiveunactive = {
        employee_id:id,
        employee_isactive:!status
    }
    var updatestatus = new factory(getToken(), 'employees', upactiveunactive);
    await updatestatus.putForm().then((response) => {
        swal.fire('Done!', 'Status Changed Successfully.','success');
    });
} 
 
function editbtnclicked(id) {
    router.push({
        name: 'VAEditEmployee',
        query: {
            id,
        },
    })
}
function addNew() {
    router.push({
        name: 'VAAddEmployee'
    })
}
onMounted(async () => {
    functions.initLoader();
    const Residents = new factory(getToken(), 'employees');
    const dt = await Residents.getHttp().then(response => {
        functions.destroyLoader();
        var tabledata = {
            "conditions": {
                'update': true,
                'trash': true,
                'active': true,
                'primarykey': 'employee_id',
                'hiddendata': ['employee_id'],
                'active_index': 'employee_isactive',
            },
            "columns": [
                'Name',
                'Company',
                'Location',
                'Administrator',
                'Start Date',
                'End Date',
                'Active',
                '',
            ],
            "data_order": ['employee_name', 'administrator', 'administrator', 'administrator', 'employee_start_date', 'employee_end_date'],
            "data": response.data.data
        };
        displaytbl.value = true
        responsefromapi.value = tabledata;
    });
    functions.callOnReady();
});
</script> 
