<template>
  <div class="main-body">
    <div class="page-wrapper">
      <!-- Page body start -->
      <div class="page-body">
        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-block">
                <div class="row">
                  
                  <div class="col-lg-3">
                    <select-input
                      class="col-md-12"
                      label=""
                      v-model="homeid"
                      @change="getEmployees()"
                    >
                      <option v-for="eth in homesdata" :value="eth.home_id">
                        {{ eth.home_name }}
                      </option>
                    </select-input>
                  </div>
                  <div class="col-lg-1"></div>
                  <div class="col-lg-4"> 
                    <div class="input-group mt-1">
                      <div
                        class="input-group-prepend"
                        @click="prevWeek($moment)"
                      >
                        <span class="input-group-text btn btn-primary btn-round" style="border-top-left-radius: 0.5rem !important;border-bottom-left-radius: 0.5rem !important;"
                          ><i class="ti-control-backward"></i
                        ></span>
                      </div>

                      <text-input
                        type="date"
                        :noformgroup="true"
                        class="p-0"
                        v-model="displaydate"
                        @change="dateChanged()"
                      />
                      <div
                        class="input-group-prepend"
                        @click="nextWeek($moment)"
                      >
                        <span class="input-group-text btn btn-primary btn-round" style="border-top-right-radius: 0.5rem !important;border-bottom-right-radius: 0.5rem !important;"
                          ><i class="ti-control-forward"></i
                        ></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 text-right"> 
                    <button
                      class="btn btn-secondary btn-primary btn-round"
                      type="button"
                      @click="morefilter()"
                    >
                      More Filters
                    </button>
                  </div>
                </div>
                <div
                  class="row pt-3 pb-3"
                  style="
                    display: none;
                    background: rgb(240, 241, 244);
                    border-radius: 3px;
                  "
                  id="morefilter"
                >
                  <select-input
                    v-model="filterroleid"
                    class="col-md-3"
                    label="Role"
                    @change="getEmployees()"
                  >
                    <option value="">Any</option>
                    <option v-for="reg in rolesdata" :value="reg.role_id">
                      {{ reg.role }}
                    </option>
                  </select-input>

                  <select-input
                    v-model="filterregionid"
                    class="col-md-3"
                    label="Employee Region"
                    @change="getEmployees()"
                  >
                    <option value="">Any</option>
                    <option v-for="reg in regionsdata" :value="reg.r_id">
                      {{ reg.r_name }}
                    </option>
                  </select-input>

                  <select-input
                    v-model="filterethnicity"
                    class="col-md-3"
                    label="Ethnicity"
                    @change="getEmployees()"
                  >
                    <option value="">Any</option>
                    <option
                      v-for="con in ethinicitydata"
                      :value="con.ethinicity_id"
                    >
                      {{ con.ethinicity_name }}
                    </option>
                  </select-input>

                  <select-input
                    v-model="filtergender"
                    class="col-md-3"
                    label="Gender"
                    @change="getEmployees()"
                  >
                    <option value="">Any</option>
                    <option v-for="(gender, k) in genderdata" :value="k">
                      {{ gender }}
                    </option>
                  </select-input>

                  <select-input
                    v-model="filteremploymenttype"
                    class="col-md-3"
                    label="Employment Type"
                    @change="getEmployees()"
                  >
                    <option value="">Any</option>
                    <option v-for="con in employmenttypedata" :value="con.id">
                      {{ con.priority }}
                    </option>
                  </select-input>

                  <select-input
                    v-model="filtercontracttype"
                    class="col-md-3"
                    label="Contract Type"
                    @change="getEmployees()"
                  >
                    <option value="">Any</option>
                    <option v-for="con in contracttypedata" :value="con.id">
                      {{ con.priority }}
                    </option>
                  </select-input>

                  <text-input
                    v-model="filterjobtitle"
                    @change="getEmployees()"
                    class="col-md-3"
                    label="Job Title"
                  />

                  <text-input
                    v-model="filteremployeename"
                    label="Employee Name"
                    class="col-md-3"
                    @change="getEmployees()"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
           
            <div class="card">
              <div class="card-body p-0">
                <div class="col-md-12 p-0">
                  <template v-if="calenderdisplay">
                    <table class="table rotatbl">
                      <thead>
                        <tr>
                          <th style="width: 15%">Employee</th>
                          <th style="width: 85%" class="p-0">
                            <table
                              style="width: 100%"
                              class="table table-bordered mb-0"
                            >
                              <thead>
                                <tr>
                                  <th
                                    v-for="date in nextSevenDays"
                                    style="max-width: 100px"
                                  >
                                    {{ formatDate($moment, date, "DD dddd") }}
                                  </th>
                                </tr>
                              </thead>
                            </table>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="(emp, key) in employee">
                          <tr class="">
                            <td class="pb-0" style="vertical-align: middle">
                              <router-link
                                class="text-c-dark"
                                :to="{ name: 'VARegions' }"
                              >
                                <img
                                  :src="emp.employee_picture"
                                  alt="user image"
                                  class="img-radius img-40"
                                />
                                {{ emp.employee_name }}
                              </router-link>
                            </td>
                            <td class="p-0" colspan="5">
                              <table
                                style="width: 100%"
                                class="table table-bordered mb-0"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      class="tblcolumn"
                                      :data-empdate="
                                        'emp' +
                                        emp.employee_id +
                                        formatDate($moment, date, 'YYYY-MM-DD')
                                      "
                                      :data-date="
                                        formatDate($moment, date, 'YYYY-MM-DD')
                                      "
                                      :data-empid="emp.employee_id"
                                      v-for="date in nextSevenDays"
                                      :class="checkWeekDay($moment, date)"
                                      style="width: 100px"
                                    >
                                      <i
                                        class="icofont icofont-plus addrotabtn fr text-dark"
                                        @click="addRota($moment, key, date)"
                                      ></i>
                                      &nbsp; &nbsp;
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="add_rota">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Add Rota</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="hidemodal('#add_rota')"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <text-input
              class="col-md-8"
              readonly
              v-model="form.employeename"
              label="Employee"
            /> 
            <select-input
              v-model="form.rota_role_id"
              class="col-md-4"
              label="Role"
            >
              <option value="">Select...</option>
              <option v-for="reg in roleslistemployee" :value="reg.role_id">
                {{ reg.role }}
              </option>
            </select-input>
            <text-input
              class="col-md-3"
              type="date"
              v-model="form.rota_start_date"
              label="Start Date"
            />
            <text-input
              class="col-md-3 br-2"
              type="date"
              v-model="form.rota_end_date"
              label="End Date"
            />
            <text-input
              class="col-md-3"
              type="time"
              v-model="form.rota_start_time"
              label="Shift Start Time"
            />
            <text-input
              class="col-md-3"
              type="time"
              v-model="form.rota_end_time"
              label="Shift End Time"
            />
            <div class="col-md-12 form-group text-center">
              <hr class="customborder" />
              <h3>
                <b
                  >Days of Week
                  <a class="mytooltip tooltip-effect-9" href="#"
                    ><i class="ion-information-circled"></i
                    ><span class="tooltip-content3"
                      >Include/Exclude Days of Week between Start & End
                      date</span
                    ></a
                  >
                  </b
                >
              </h3>
            </div>
            <div class="col-md-12 form-group text-center">
              <div class="checkbox-fade fade-in-primary">
                <label>
                  <input
                    type="checkbox"
                    value="monday"
                    v-model="form.monday"
                    :checked="form.monday"
                  />
                  <span class="cr">
                    <i class="cr-icon icofont icofont-ui-check txt-primary"></i>
                  </span>
                  <span>Monday</span>
                </label>
              </div>
              <div class="checkbox-fade fade-in-primary">
                <label>
                  <input
                    type="checkbox"
                    value="tuesday"
                    v-model="form.tuesday"
                    :checked="form.tuesday"
                  />
                  <span class="cr">
                    <i class="cr-icon icofont icofont-ui-check txt-primary"></i>
                  </span>
                  <span>Tuesday</span>
                </label>
              </div>
              <div class="checkbox-fade fade-in-primary">
                <label>
                  <input
                    type="checkbox"
                    value="wednesday"
                    v-model="form.wednesday"
                    :checked="form.wednesday"
                  />
                  <span class="cr">
                    <i class="cr-icon icofont icofont-ui-check txt-primary"></i>
                  </span>
                  <span>Wednesday</span>
                </label>
              </div>
              <div class="checkbox-fade fade-in-primary">
                <label>
                  <input
                    type="checkbox"
                    value="thursday"
                    v-model="form.thursday"
                    :checked="form.thursday"
                  />
                  <span class="cr">
                    <i class="cr-icon icofont icofont-ui-check txt-primary"></i>
                  </span>
                  <span>Thursday</span>
                </label>
              </div>
              <div class="checkbox-fade fade-in-primary">
                <label>
                  <input
                    type="checkbox"
                    value="friday"
                    v-model="form.friday"
                    :checked="form.friday"
                  />
                  <span class="cr">
                    <i class="cr-icon icofont icofont-ui-check txt-primary"></i>
                  </span>
                  <span>Friday</span>
                </label>
              </div>
              <div class="checkbox-fade fade-in-primary">
                <label>
                  <input
                    type="checkbox"
                    value="saturday"
                    v-model="form.saturday"
                    :checked="form.saturday"
                  />
                  <span class="cr">
                    <i class="cr-icon icofont icofont-ui-check txt-primary"></i>
                  </span>
                  <span>Saturday</span>
                </label>
              </div>
              <div class="checkbox-fade fade-in-primary">
                <label>
                  <input
                    type="checkbox"
                    value="sunday"
                    v-model="form.sunday"
                    :checked="form.sunday"
                  />
                  <span class="cr">
                    <i class="cr-icon icofont icofont-ui-check txt-primary"></i>
                  </span>
                  <span>Sunday</span>
                </label>
              </div>
            </div>
            <div class="col-md-12 form-group">
              <hr class="customborder" />
            </div>
            <textarea-input
              class="col-md-12"
              rows="5"
              v-model="form.rota_notes"
              label="Notes"
              textinfo="Employees can only see Notes for their own shifts"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default waves-effect"
            data-dismiss="modal"
            @click="hidemodal('#add_rota')"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary btn-round waves-effect waves-light funload"
            @click="addRotaInfo($moment)"
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="update_rota">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Update Rota</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="hidemodal('#update_rota')"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <text-input
              class="col-md-8"
              readonly
              v-model="upform.employeename"
              label="Employee"
            />
            <select-input
              v-model="upform.rota_role_id"
              class="col-md-4"
              label="Role"
            >
              <option value="">Select...</option>
              <option v-for="reg in roleslistemployee" :value="reg.role_id">
                {{ reg.role }}
              </option>
            </select-input>
            <text-input
              class="col-md-3"
              type="date"
              v-model="upform.rota_start_date"
              label="Start Date"
            />
            <text-input
              class="col-md-3 br-2"
              type="date"
              v-model="upform.rota_end_date"
              label="End Date"
            />
            <text-input
              class="col-md-3"
              type="time"
              v-model="upform.rota_start_time"
              label="Shift Start Time"
            />
            <text-input
              class="col-md-3"
              type="time"
              v-model="upform.rota_end_time"
              label="Shift End Time"
            />
            <div class="col-md-12 form-group text-center">
              <hr class="customborder" />
              <h3>
                <b
                  >Days of Week
                  <a class="mytooltip tooltip-effect-9" href="#"
                    ><i class="ion-information-circled"></i
                    ><span class="tooltip-content3"
                      >Include/Exclude Days of Week between Start & End
                      date</span
                    ></a
                  ></b
                >
              </h3>
            </div>
            <div class="col-md-12 form-group text-center">
              <div class="checkbox-fade fade-in-primary">
                <label>
                  <input
                    type="checkbox"
                    value="monday"
                    v-model="upform.monday"
                    :checked="upform.monday"
                  />
                  <span class="cr">
                    <i class="cr-icon icofont icofont-ui-check txt-primary"></i>
                  </span>
                  <span>Monday</span>
                </label>
              </div>
              <div class="checkbox-fade fade-in-primary">
                <label>
                  <input
                    type="checkbox"
                    value="tuesday"
                    v-model="upform.tuesday"
                    :checked="upform.tuesday"
                  />
                  <span class="cr">
                    <i class="cr-icon icofont icofont-ui-check txt-primary"></i>
                  </span>
                  <span>Tuesday</span>
                </label>
              </div>
              <div class="checkbox-fade fade-in-primary">
                <label>
                  <input
                    type="checkbox"
                    value="wednesday"
                    v-model="upform.wednesday"
                    :checked="upform.wednesday"
                  />
                  <span class="cr">
                    <i class="cr-icon icofont icofont-ui-check txt-primary"></i>
                  </span>
                  <span>Wednesday</span>
                </label>
              </div>
              <div class="checkbox-fade fade-in-primary">
                <label>
                  <input
                    type="checkbox"
                    value="thursday"
                    v-model="upform.thursday"
                    :checked="upform.thursday"
                  />
                  <span class="cr">
                    <i class="cr-icon icofont icofont-ui-check txt-primary"></i>
                  </span>
                  <span>Thursday</span>
                </label>
              </div>
              <div class="checkbox-fade fade-in-primary">
                <label>
                  <input
                    type="checkbox"
                    value="friday"
                    v-model="upform.friday"
                    :checked="upform.friday"
                  />
                  <span class="cr">
                    <i class="cr-icon icofont icofont-ui-check txt-primary"></i>
                  </span>
                  <span>Friday</span>
                </label>
              </div>
              <div class="checkbox-fade fade-in-primary">
                <label>
                  <input
                    type="checkbox"
                    value="saturday"
                    v-model="upform.saturday"
                    :checked="upform.saturday"
                  />
                  <span class="cr">
                    <i class="cr-icon icofont icofont-ui-check txt-primary"></i>
                  </span>
                  <span>Saturday</span>
                </label>
              </div>
              <div class="checkbox-fade fade-in-primary">
                <label>
                  <input
                    type="checkbox"
                    value="sunday"
                    v-model="upform.sunday"
                    :checked="upform.sunday"
                  />
                  <span class="cr">
                    <i class="cr-icon icofont icofont-ui-check txt-primary"></i>
                  </span>
                  <span>Sunday</span>
                </label>
              </div>
            </div>
            <div class="col-md-12 form-group">
              <hr class="customborder" />
            </div>
            <textarea-input
              class="col-md-12"
              rows="5"
              v-model="upform.rota_notes"
              label="Notes"
              textinfo="Employees can only see Notes for their own shifts"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-danger btn-round waves-effect"
            data-dismiss="modal"
            @click="deleteRota()"
          >
            Delete
          </button>

          <button
            type="button"
            class="btn btn-default btn-round waves-effect"
            data-dismiss="modal"
            @click="hidemodal('#update_rota')"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary btn-round waves-effect waves-light funload"
            @click="updateRotaInfo($moment)"
          >
            Update Changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import TextInput from "../../../shared/TextInput.vue";
import SelectInput from "../../../shared/SelectBox.vue";
import TextareaInput from "../../../shared/TextareaInput.vue";
import { functions } from "../../../../common/appFunctions.js";
import { ref, reactive, onMounted } from "vue";
import { useAuthStore } from "@/store/auth.js";
import factory from "@/common/factory.js";
import { useRouter } from "vue-router";
import momentobj from "moment";

const authStore = useAuthStore();
const isPreview = ref(false);
const calenderdisplay = ref(false);

var homesdata = ref([]);
var regionsdata = ref([]);
var ethinicitydata = ref([]);
var genderdata = ref([]);
var employmenttypedata = ref([]);
var contracttypedata = ref([]);
var rolesdata = ref([]);
var roleslistemployee = ref([]);

const homeid = ref();

var upkey = ref(0);
const form = ref({
  rota_employee_id: 0,
  employeename: "",
  rota_start_date: "",
  rota_end_date: "",
  rota_start_time: "",
  rota_end_time: "",
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: false,
  sunday: false,
  rota_notes: "",
});
const upform = ref({
  rota_id: 0,
  rota_employee_id: 0,
  employeename: "",
  rota_start_date: "",
  rota_end_date: "",
  rota_start_time: "",
  rota_end_time: "",
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: false,
  sunday: false,
  rota_notes: "",
});
var rotsschedulelist = ref([]);
function getDaysArray(start, end) {
  for (
    var arr = [], dt = new Date(start);
    dt <= new Date(end);
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt));
  }
  return arr;
}

function prevWeek() {
  var dateselected = new Date(displaydate.value);

  var firstDay = dateselected.setDate(dateselected.getDate() - 7);
  displaydate.value = moment(firstDay).format("YYYY-MM-DD");
  // nextSevenDays.value = Array(7).fill().map((_, i) => new Date(firstDay).setDate(firstDay.getDate() + i));
  reloadRota();
  appendEvents(moment);
}
function addRota(moment, index, date) {
  showmodal("#add_rota");
  
  roleslistemployee.value = [];
  employee.value[index].roles.forEach(function(v,k){
    var fil = rolesdata.value.find((item)=>(item.role_id == v.role_id)) 
    roleslistemployee.value.push(fil);
  });
  form.value.rota_employee_id = employee.value[index].employee_id;
  form.value.employeename = employee.value[index].employee_name;
  form.value.rota_start_date = moment(date).format("YYYY-MM-DD");
  form.value.rota_end_date = moment(date).add(7, "days").format("YYYY-MM-DD");
  form.value.rota_start_time = "08:00";
  form.value.rota_end_time = "17:00";
}
function appendEvents(moment = false) {
  $(".event-badge").remove();
  rotsschedulelist.value.forEach((x, k) => {
    var datesarr = getDaysArray(
      new Date(x.rota_start_date),
      new Date(x.rota_end_date)
    );
    var eventobject = [];
    datesarr.forEach((date, k1) => {
      // console.log(x);
      if (moment) {
        if (
          (x[moment(date).format("dddd").toLowerCase()] == 1 ||
          x[moment(date).format("dddd").toLowerCase()] == true ) && (filterroleid.value == '' || filterroleid.value == x.rota_role_id)
        ) {
          eventobject.push(
            "[data-empdate='emp" +
              x.rota_employee_id +
              moment(date).format("YYYY-MM-DD") +
              "']"
          );
        }
      } else {
        if (
          (x[momentobj(date).format("dddd").toLowerCase()] == 1 ||
          x[momentobj(date).format("dddd").toLowerCase()] == true) && (filterroleid.value == '' ||filterroleid.value == x.rota_role_id)
        ) {
          eventobject.push(
            "[data-empdate='emp" +
              x.rota_employee_id +
              momentobj(date).format("YYYY-MM-DD") +
              "']"
          );
        }
      }
    });
    $(eventobject.toString()).append(
      '<label class="label  mb-1 event-badge " data-key="' +
        k +
        '"   > <b>' +
        momentobj(x.rota_start_time, "HHmmss").format("HH:mm") +
        " - " +
        momentobj(x.rota_end_time, "HHmmss").format("HH:mm") +
        `</b> <a class="mytooltip" href="javascript:void(0)"> <i class="fa fa-info-circle text-dark" style="float:right"></i><span class="tooltip-content5"><span class="tooltip-text3"><span class="tooltip-inner2">` +
        x.rota_notes +
        `</span></span></span></a>
        <br>
        <br>
        `+x.role+`
        </label>`
    );
  });
}

$(document).on("click", ".event-badge", function () {
  var key = $(this).data("key");
  var empdata = employee.value.find(
    (item) => (item.employee_id = rotsschedulelist.value[key].rota_employee_id)
  );
  upkey.value = key;

  roleslistemployee.value = [];
  empdata.roles.forEach(function(v,k){
    var fil = rolesdata.value.find((item)=>(item.role_id == v.role_id)) 
    roleslistemployee.value.push(fil);
  });

  upform.value.employeename = empdata.employee_name;
  upform.value.rota_employee_id = rotsschedulelist.value[key].rota_employee_id;
  upform.value.rota_id = rotsschedulelist.value[key].rota_id;
  upform.value.rota_start_date = rotsschedulelist.value[key].rota_start_date;
  upform.value.rota_end_date = rotsschedulelist.value[key].rota_end_date;
  upform.value.rota_start_time = rotsschedulelist.value[key].rota_start_time;
  upform.value.rota_end_time = rotsschedulelist.value[key].rota_end_time;
  (upform.value.rota_role_id = rotsschedulelist.value[key].rota_role_id),
    (upform.value.monday =
      rotsschedulelist.value[key].monday == 1 ||
      rotsschedulelist.value[key].monday == true
        ? true
        : false);
  upform.value.tuesday =
    rotsschedulelist.value[key].tuesday == 1 ||
    rotsschedulelist.value[key].tuesday == true
      ? true
      : false;
  upform.value.wednesday =
    rotsschedulelist.value[key].wednesday == 1 ||
    rotsschedulelist.value[key].wednesday == true
      ? true
      : false;
  upform.value.thursday =
    rotsschedulelist.value[key].thursday == 1 ||
    rotsschedulelist.value[key].thursday == true
      ? true
      : false;
  upform.value.friday =
    rotsschedulelist.value[key].friday == 1 ||
    rotsschedulelist.value[key].friday == true
      ? true
      : false;
  upform.value.saturday =
    rotsschedulelist.value[key].saturday == 1 ||
    rotsschedulelist.value[key].saturday == true
      ? true
      : false;
  upform.value.sunday =
    rotsschedulelist.value[key].sunday == 1 ||
    rotsschedulelist.value[key].sunday == true
      ? true
      : false;
  upform.value.rota_notes = rotsschedulelist.value[key].rota_notes;
  console.log(upform.value);
  console.log("Changed");
  showmodal("#update_rota");
});
function getToken() {
  const token = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authStore.token,
    },
  };
  return token;
}
async function addRotaInfo(moment) {
  // form.rota_employee_id;
  var createobj = {
    rota_employee_id: form.value.rota_employee_id,
    rota_location_id: homeid.value,
    rota_role_id: form.value.rota_role_id,
    rota_start_date: moment(form.value.rota_start_date).format("YYYY-MM-DD"),
    rota_end_date: moment(form.value.rota_end_date).format("YYYY-MM-DD"),
    rota_start_time: form.value.rota_start_time,
    rota_end_time: form.value.rota_end_time,
    monday: form.value.monday,
    tuesday: form.value.tuesday,
    wednesday: form.value.wednesday,
    thursday: form.value.thursday,
    friday: form.value.friday,
    saturday: form.value.saturday,
    sunday: form.value.sunday,
    rota_notes: form.value.rota_notes,
  };

  const Rota = new factory(getToken(), "rota", createobj);
  await Rota.postForm().then((response) => {
    swal.close();
    functions.destroyBtnLoader();
    if (response.data.status == "success") {
      createobj.rota_id = response.data.data.rota_id;
      var roleinfo = rolesdata.value.find((item)=> item.role_id == createobj.rota_role_id);
      createobj.role = roleinfo.role;
      // createobj.rota_employee_id = response.data.data.rota_id;
      rotsschedulelist.value.push(createobj);
      appendEvents(moment);
      hidemodal("#add_rota");
    } else {
      console.log(response.data.message);
    }
  });
}
async function deleteRota() {
  
  const Rota = new factory(getToken(), "rota/"+upform.value.rota_id);
  await Rota.deleteHttp().then((response) => {
    swal.close();
    functions.destroyBtnLoader();
    if (response.data.status == "success") {
        
        rotsschedulelist.value = rotsschedulelist.value.filter((x, k) => x.rota_id !== upform.value.rota_id);
        appendEvents();
        hidemodal('#update_rota');
        
      }
  });
}
async function updateRotaInfo(moment) {
  // form.rota_employee_id;
  // var createobj = {
  //     rota_employee_id: form.value.rota_employee_id,
  //     rota_start_date: moment(form.value.rota_start_date).format('YYYY-MM-DD'),
  //     rota_end_date: moment(form.value.rota_end_date).format('YYYY-MM-DD'),
  //     rota_start_time: form.value.rota_start_time,
  //     rota_end_time: form.value.rota_end_time,
  //     monday: form.value.monday,
  //     tuesday: form.value.tuesday,
  //     wednesday: form.value.wednesday,
  //     thursday: form.value.thursday,
  //     friday: form.value.friday,
  //     saturday: form.value.saturday,
  //     sunday: form.value.sunday,
  //     rota_notes: form.value.rota_notes,
  // }

  const Rota = new factory(getToken(), "rota", upform.value);
  await Rota.putForm().then((response) => {
    swal.close();
    functions.destroyBtnLoader();
    if (response.data.status == "success") {
      // createobj.rota_id = response.data.data.rota_id;
      // rotsschedulelist.value.push(createobj);
      rotsschedulelist.value[upkey.value] = upform.value;
      appendEvents(moment);
      hidemodal("#update_rota");
    } else {
      console.log(response.data.message);
    }
  });
}
function dateChanged() {
  reloadRota(); 
  setTimeout(function(){
    appendEvents(moment);
  },500);
}
function nextWeek(moment) {
  var dateselected = new Date(displaydate.value);

  var firstDay = dateselected.setDate(dateselected.getDate() + 7);
  displaydate.value = moment(firstDay).format("YYYY-MM-DD");
  // nextSevenDays.value = Array(7).fill().map((_, i) => new Date(firstDay).setDate(firstDay.getDate() + i));
  reloadRota(); 
  appendEvents(moment);
}

function formatDate(moment, date, format = "YYYY-MM-DD") {
  return moment(date).format(format);
}
function checkWeekDay(moment, date) {
  var day = moment(date).format("dddd");

  if (day == "Sunday") {
    return "attendance-sunday";
  } else if (day == "Saturday") {
    return "attendance-weekend";
  }
  return "attendance-weekday";
}

var nextSevenDays = ref([]);
var employee = ref([]);
var filterroleid = ref("");
var filterregionid = ref("");
var filterethnicity = ref("");
var filtergender = ref("");
var filteremploymenttype = ref("");
var filtercontracttype = ref("");
var filterjobtitle = ref("");
var filteremployeename = ref("");

var today = new Date();
var firstDay = new Date();
// nextSevenDays.value = Array(7)
//   .fill()
//   .map((_, i) => new Date(firstDay).setDate(firstDay.getDate() + i));
var datesarray = ref([]);
var rota_employee_id = ref(0);
var month = ref(today.getMonth().toString());
var year = ref(firstDay.getFullYear());
var displaydate = ref();
displaydate.value = momentobj(today).format("YYYY-MM-DD");

function reloadRota() {
  var dateselected = new Date(displaydate.value);
  var firstDay = dateselected;
  nextSevenDays.value = Array(7)
    .fill()
    .map((_, i) => new Date(firstDay).setDate(firstDay.getDate() + i));
}
function previewHeader() {
  if (isPreview.value) {
    isPreview.value = false;
  } else {
    isPreview.value = true;
  }
}
async function getEmployees() {
  var employeefilter = {
    location_id: homeid.value,
    employee_ethnicity: filterethnicity.value,
    role_id: filterroleid.value,
    employee_region: filterregionid.value,
    employee_gender: filtergender.value,
    employmenttype_id: filteremploymenttype.value,
    employee_contracttype: filtercontracttype.value,
    employee_jobtitle: filterjobtitle.value,
    employee_name: filteremployeename.value,
  };

  var alldata = new factory(getToken(), "getemployees", employeefilter);
  try {
    await alldata.postForm().then((response) => {
      if (response.data.status == "success") {
        swal.close();
        employee.value = response.data.data;
        dateChanged();
      }
    });
  } catch (error) {
    employee.value =[];
    swal.close();
  }
  dateChanged();
}
async function filterRotaInfo(){
   
  var rotaFilter = {
    "location_id": homeid.value,
    "role_id": filterroleid.value
  }
  // var Rota = new factory(getToken(), "rotabyfilter",rotaFilter);
  var Rota = new factory(getToken(), "rota");
  try {
    // await Rota.postForm().then((response) => {
    await Rota.getHttp().then((response) => {
      if (response.data.status == "success") {
        // rotabyfilter
        rotsschedulelist.value = response.data.data;
      }
    });
  } catch (error) {
    swal.close();
  }
}
onMounted(async () => {
  var alldata = new factory(getToken(), "getallapis", {
    index: [
      "homes",
      "regions",
      "ethinicity",
      "gender",
      "employmenttype",
      "contracttype",
      "roles",
      "employees",
    ],
  });
  await alldata.postForm().then((response) => {
    if (response.data.status == "success") {
      // employee.value  = response.data.data.employees;
      homesdata.value = response.data.data.homes;
      regionsdata.value = response.data.data.regions;
      ethinicitydata.value = response.data.data.ethinicity;
      genderdata.value = response.data.data.gender;
      employmenttypedata.value = response.data.data.employmenttype;
      contracttypedata.value = response.data.data.contracttype;
      rolesdata.value = response.data.data.roles;

      homeid.value = homesdata.value[0].home_id;
      getEmployees();
    }
  });

  filterRotaInfo();
  
  functions.callOnReady();
  calenderdisplay.value = true;
  // setTimeout(function () {
  //   appendEvents();
  // }, 1000);
});
function showmodal(id) {
  $(id).modal("show");
}
function hidemodal(id) {
  $(id).modal("hide");
}
function morefilter() {
  $("#morefilter").slideToggle();
}
</script>
