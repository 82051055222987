
<template>
    <table class="table table-striped table-bordered data_table">
        <thead>
            <tr>
                <td v-for="column in data.columns ">{{ column }}</td>
            </tr>
        </thead>
        <tbody>
            <template v-for="(rows, index1, key1) in data.data ">
                <tr v-bind:data-id="data.data[index1][data.conditions.primarykey]">
                    <template v-for="(row, index2, key2) in rows">
                        <td v-if="(index2 == (Object.keys(rows).length - 1)) && !(data.conditions.active)">{{ row }}
                        </td>
                        <td
                            v-else-if="(key2 != (Object.keys(rows).length - 1)) && !data.conditions.hiddendata.includes(index2)">
                            {{ row }} </td>
                        <td v-if="(index2 == data.conditions.active_index && (data.conditions.active))">
                            <input type="checkbox" class="js-switch" :checked="parseInt(rows[index2])" />
                        </td>
                        <td class="text-center"
                            v-if="(key2 == (Object.keys(rows).length - 1) && (data.conditions.update || data.conditions.trash))">
                            <loading-button v-if="data.conditions.update"
                                class="btn btn-success btn-outline-success m-1 editbtn" type="button"
                                @click="viewbuttonclickedtbl(data.data[index1][data.conditions.primarykey])"><i
                                    class="icofont icofont-eye-alt"></i></loading-button>
                            <loading-button v-if="data.conditions.trash"
                                class="btn btn-danger btn-outline-danger m-1 trashbtn" type="button"
                                @click="deletebuttonclickedtbl(data.data[index1][data.conditions.primarykey])"><i
                                    class="icofont icofont-warning-alt"></i></loading-button>
                        </td>
                    </template>
                </tr>
            </template>
        </tbody>
    </table>
</template> 
<script setup>
import { defineEmits } from 'vue';
import LoadingButton from '../shared/LoadingButton.vue';
let props = defineProps({
    data: Array,
});

var definedEmits = defineEmits(['viewbuttonclicked', 'deletebuttonclicked'])

function viewbuttonclickedtbl(pkey) {
    definedEmits('viewbuttonclicked', pkey);
}

function deletebuttonclickedtbl(pkey) {
    definedEmits('deletebuttonclicked', pkey);
}
</script>

