<template>
    <div class="pcoded-inner-content">

        <div class="main-body">
            <div class="page-wrapper">
                <div class="page-body">
                    <div class="row">
                        <div class="col-sm-12">

                            <div class="card">
                                <div class="card-header">
                                    <h5>
                                        <span>Add New Company</span>

                                    </h5>
                                    <div class="card-header-right">
                                        <div class="page-header-breadcrumb">
                                            <ul class="breadcrumb-title">
                                                <li class="breadcrumb-item" style="float: left;">
                                                    <a href="../index.html"> <i class="feather icon-home"></i> </a>
                                                </li>
                                                <li class="breadcrumb-item" style="float: left;"><router-link
                                                        :to="{ name: 'VACompanies' }">Companies</router-link>
                                                </li>
                                                <li class="breadcrumb-item" style="float: left;">
                                                    <a href="#"> Add New Company </a>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>

                                </div>

                                <form>
                                    <div class="card-body">
                                        <div class="row">
                                            <text-input v-model="form.c_name" class="col-md-9" label="Company name" />
                                            <select-input v-model="form.c_company_type" class="col-md-3"
                                                label="Company Type">
                                                <option :value="0">Select Type</option>
                                                <option v-for="u in companytypes" :key="u.ct_id" :value="u.ct_id">{{
                                                    u.ct_name
                                                }}</option>
                                            </select-input>
                                            <select-input v-model="form.c_company_owner" class="col-md-3"
                                                label="Company Owner">
                                                <option :value="0">Select Owner
                                                </option>
                                                <option v-for="u in users" :key="u.u_id" :value="u.u_id">{{ u.u_name }}
                                                </option>
                                            </select-input>
                                            <text-input v-model="form.e_email" class="col-md-3" label="Email" />
                                            <text-input v-model="form.e_phone_primary" class="col-md-3"
                                                label="Primary Phone" />
                                            <text-input v-model="form.e_phone_secondary" class="col-md-3"
                                                label="Secondary Phone" />
                                            <text-input v-model="form.e_address_primary" class="col-md-6"
                                                label="Address Primary" />
                                            <text-input v-model="form.e_address_secondary" class="col-md-6"
                                                label="Address Secondary" />
                                            <text-input v-model="form.e_city" class="col-md-3" label="City" />
                                            <text-input v-model="form.e_post_code" class="col-md-3" label="Post Code" />
                                            <text-input v-model="form.e_country" class="col-md-3" label="Country" />
                                            <text-input v-model="form.e_time_zone" class="col-md-3" label="Time Zone" />
                                            <text-input v-model="form.e_longitude" class="col-md-3" label="Longitude" />
                                            <text-input v-model="form.e_latitude" class="col-md-3" label="Latitude" />
                                        </div>
                                    </div>
                                    <div class="card-footer text-center">
                                        <button type="button"
                                            class="btn btn-primary btn-round waves-effect waves-light funload"
                                            @click="addCompany()">Save Information</button>
                                    </div>
                                </form>

                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>

</template>
<script setup>
import { functions } from '../../../../common/appFunctions.js';
import SelectInput from '../../../shared/SelectInput.vue';
import TextInput from '../../../shared/TextInput'
import { reactive, ref, onMounted } from 'vue';
import { transferData } from '@/store/transferdata.js'
import { useAuthStore } from '@/store/auth.js'
import factory from '@/common/factory.js'
import { useRouter, useRoute } from 'vue-router';
const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();

const form = reactive({
    c_vendor_id: authStore.user.u_vendor_id,
    c_name: '',
    c_company_type: '',
    c_company_owner: authStore.user.u_vendor_id,
    c_isactive: 1,
    e_email: '',
    e_phone_primary: '',
    e_phone_secondary: '',
    e_post_code: '',
    e_city: '',
    e_country: '',
    e_address_primary: '',
    e_address_secondary: '',
    e_time_zone: '',
    e_longitude: '',
    e_latitude: ''
});
var users = ref([]);
var companytypes = ref([]);
function getToken() {
    const token = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authStore.token,
        },
    };
    return token;
}

async function addCompany() {
    const Company = new factory(getToken(), 'companies', form);
    await Company.postForm().then((response) => {
        functions.destroyBtnLoader();
        if (response.data.status == "success") {
            router.push({ name: 'VACompanies' })
        } else {
            console.log(response.data.message);
        }
    });
};
onMounted(async () => {
    var token = getToken();
    const user = new factory(token, 'users/company/' + authStore.user.u_company_id);
    const dt = await user.getHttp().then(response => {
        users.value = response.data.data;
        console.log(users);
    });
    const companytypo = new factory(token, 'companytypes/vendor/' + authStore.user.u_vendor_id);
    const dtt = await companytypo.getHttp().then(response => {
        companytypes.value = response.data.data;
        console.log(companytypes);
    });
    functions.callOnReady();
});

</script>