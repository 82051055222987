<template>
  <div class="row" style="position:relative;" >
      
    <!-- <div
      class="col-lg-2 col-xl-2 p-0 compunentsdiv"
      style="margin-top: -10px;position:relative;"
    > -->
    <div style="position:absolute;  z-index: 99999;background: black;max-width: 16.666667%;bottom: 0px;" class="p-3">
        <button style="box-shadow:unset;-webkit-box-shadow:unset;"
          @click="showmodal('#addnew_plan')"
          class="btn btn-success    "
          type="button"
        >
          Save
        </button>
        <router-link style="box-shadow:unset;-webkit-box-shadow:unset;"
          :to="{ path: 'preveiwforms', query: { id: form.form_id } }"
          class="btn btn-primary    "
           
          >Preview</router-link
        > 
        <router-link style="box-shadow:unset;-webkit-box-shadow:unset;background:#3c4043"
          :to="{ name: 'VAForms' }"
          class="btn btn-dark    " 
          ><i class="ti-arrow-left"></i></router-link
        >
      </div>

    <!-- </div> -->
    <div
      class="col-lg-2 col-xl-2 p-0 compunentsdiv"
      style="margin-top: -10px; height: 86vh; overflow-y: scroll;position:relative;"
    >
    
      <div class="card bg-dark">
        <div class="card-header">
          <!-- <h5 class="card-header-text">Form Compunents</h5> -->
        </div>
        <div class="card-block p-b-0">
          <div class="row">
            <!-- <div class="col-md-12">
                            <div class="sortable-moves sortable-compunent pt-2 text-center  waves-effect
                                            badge  w-100" style="background: #3c4043;height: 84px;" @click="addSection();">
                                <h6><i class="ti-layout-sidebar-none fs-1"></i>
                                </h6>
                                <p style="font-size: 0.8rem; margin-top: 7px;">Section</p>
                            </div>
                        </div> -->

            <!-- <div class="col-md-12">
              <hr />
            </div> -->
            <div class="col-md-6" :style="{ opacity: opacity }">
              <div
                class="sortable-moves sortable-compunent pt-2 text-center waves-effect badge"
                style="background: #3c4043; height: 84px; width: 100%"
                @click="addHeading()"
              >
                <h6><i class="ti-smallcap fs-1"></i></h6>
                <p style="font-size: 0.8rem; margin-top: 7px">Heading</p>
              </div>
            </div>
            <div class="col-md-6" :style="{ opacity: opacity }">
              <div
                class="sortable-moves sortable-compunent pt-2 text-center waves-effect badge"
                style="background: #3c4043; height: 84px; width: 100%"
                @click="addParagraph()"
              >
                <h6><i class="ti-paragraph fs-1"></i></h6>
                <p style="font-size: 0.8rem; margin-top: 7px">Paragraph</p>
              </div>
            </div>
            <div class="col-md-6" :style="{ opacity: opacity }">
              <div
                class="sortable-moves sortable-compunent pt-2 text-center waves-effect badge"
                style="background: #3c4043; height: 84px; width: 100%"
                @click="addDivider()"
              >
                <h6><i class="ti-layout-line-solid fs-1"></i></h6>
                <p style="font-size: 0.8rem; margin-top: 7px">Divider</p>
              </div>
            </div>
            <div class="col-md-6" :style="{ opacity: opacity }">
              <div
                class="sortable-moves sortable-compunent pt-2 text-center waves-effect badge"
                style="background: #3c4043; height: 84px; width: 100%"
                @click="addSpace()"
              >
                <h6><i class="ti-widgetized fs-1"></i></h6>
                <p style="font-size: 0.8rem; margin-top: 7px">Space</p>
              </div>
            </div>

            <div class="col-md-12">
              <hr />
            </div>

            <div class="col-md-6" :style="{ opacity: opacity }">
              <div
                class="sortable-moves sortable-compunent pt-2 text-center waves-effect badge"
                style="background: #3c4043; height: 84px; width: 100%"
                @click="addField('text')"
              >
                <h6><i class="ti-pencil-alt fs-1"></i></h6>
                <p style="font-size: 0.8rem; margin-top: 7px">Text</p>
              </div>
            </div>
            <div class="col-md-6" :style="{ opacity: opacity }">
              <div
                class="sortable-moves sortable-compunent pt-2 text-center waves-effect badge"
                style="background: #3c4043; height: 84px; width: 100%"
                @click="addTextArea()"
              >
                <h6><i class="ti-book fs-1"></i></h6>
                <p style="font-size: 0.8rem; margin-top: 7px">Textarea</p>
              </div>
            </div>
            <div class="col-md-6" :style="{ opacity: opacity }">
              <div
                class="sortable-moves sortable-compunent pt-2 text-center waves-effect badge"
                style="background: #3c4043; height: 84px; width: 100%"
                @click="addField('number')"
              >
                <h6><i class="ti-quote-right fs-1"></i></h6>
                <p style="font-size: 0.8rem; margin-top: 7px">Number</p>
              </div>
            </div>
            <div class="col-md-6" :style="{ opacity: opacity }">
              <div
                class="sortable-moves sortable-compunent pt-2 text-center waves-effect badge"
                style="background: #3c4043; height: 84px; width: 100%"
                @click="addField('date')"
              >
                <h6><i class="ti-calendar fs-1"></i></h6>
                <p style="font-size: 0.8rem; margin-top: 7px">Date</p>
              </div>
            </div>
            <div class="col-md-6" :style="{ opacity: opacity }">
              <div
                class="sortable-moves sortable-compunent pt-2 text-center waves-effect badge"
                style="background: #3c4043; height: 84px; width: 100%"
                @click="addField('time')"
              >
                <h6><i class="ti-alarm-clock fs-1"></i></h6>
                <p style="font-size: 0.8rem; margin-top: 7px">Time</p>
              </div>
            </div>
            <div class="col-md-6" :style="{ opacity: opacity }">
              <div
                class="sortable-moves sortable-compunent pt-2 text-center waves-effect badge"
                style="background: #3c4043; height: 84px; width: 100%"
                @click="addField('datetime-local')"
              >
                <h6><i class="ti-calendar fs-1"></i></h6>
                <p style="font-size: 0.8rem; margin-top: 7px">DateTime</p>
              </div>
            </div>

            <div class="col-md-12">
              <hr />
            </div>
            <div class="col-md-6" :style="{ opacity: opacity }">
              <div
                class="sortable-moves sortable-compunent pt-2 text-center waves-effect badge"
                style="background: #3c4043; height: 84px; width: 100%"
                @click="addDropDown()"
              >
                <h6><i class="ti-layers-alt fs-1"></i></h6>
                <p style="font-size: 0.8rem; margin-top: 7px">Dropdown</p>
              </div>
            </div>
            <div class="col-md-6" :style="{ opacity: opacity }">
              <div
                class="sortable-moves sortable-compunent pt-2 text-center waves-effect badge"
                style="background: #3c4043; height: 84px; width: 100%"
                @click="addCheckbox()"
              >
                <h6><i class="ti-check-box fs-1"></i></h6>
                <p style="font-size: 0.8rem; margin-top: 7px">Checkbox</p>
              </div>
            </div>
            <div class="col-md-12">
              <hr />
            </div>
            <div class="col-md-6" :style="{ opacity: opacity }">
              <div
                class="sortable-moves sortable-compunent pt-2 text-center waves-effect badge"
                style="background: #3c4043; height: 84px; width: 100%"
                @click="addFileUploader()"
              >
                <h6><i class="ti-cloud-up fs-1"></i></h6>
                <p style="font-size: 0.8rem; margin-top: 7px">Uploader</p>
              </div>
            </div>
            <div class="col-md-6" :style="{ opacity: opacity }">
              <div
                class="sortable-moves sortable-compunent pt-2 text-center waves-effect badge"
                style="background: #3c4043; height: 84px; width: 100%"
                @click="addSignature()"
              >
                <h6><i class="ti-write fs-1"></i></h6>
                <p style="font-size: 0.8rem; margin-top: 7px">Signature</p>
              </div>
            </div>
            <div class="col-md-6" :style="{ opacity: opacity }">
              <div
                class="sortable-moves sortable-compunent pt-2 text-center waves-effect badge"
                style="background: #3c4043; height: 84px; width: 100%"
                @click="addInlineImage()"
              >
                <h6><i class="ti-image fs-1"></i></h6>
                <p style="font-size: 0.8rem; margin-top: 7px">Image</p>
              </div>
            </div>
            <div class="col-md-6" :style="{ opacity: opacity }">
              <div
                class="sortable-moves sortable-compunent pt-2 text-center waves-effect badge"
                style="background: #3c4043; height: 84px; width: 100%"
                @click="addImageEditor()"
              >
                <h6><i class="ti-widget fs-1"></i></h6>
                <p>Editor</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-10 col-xl-10 p-0">
      <div
        class="main-body"
        style="height: 90vh; overflow-y: scroll; overflow-x: hidden"
      >
        <!-- <div class="page-wrapper" style="padding-right:0px;"> -->

        <!-- Page-header end -->
        <div class="modal fade" id="addnew_plan">
          <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">Update Form Information</h4>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="hidemodal('#addnew_plan')"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <text-input
                    v-model="form.form_title"
                    class="col-md-12"
                    label="Form Title"
                  />
                  <div class="col-md-6">
                    <div class="row">
                      <textarea-input
                        class="col-md-12"
                        rows="5"
                        label="Description"
                        v-model="form.form_description"
                      />
                      <textarea-input
                        class="col-md-12"
                        rows="5"
                        label="Instruction"
                        v-model="form.form_instructions"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <select-input
                        v-model="form.form_task_triggered"
                        class="col-md-12"
                        label="Select Task To Be Trigger"
                      >
                        <option value="1">Task One</option>
                        <option value="2">Task Two</option>
                      </select-input>
                      <div class="col-md-12">
                        <table class="table">
                          <thead>
                            <tr>
                              <th class=" " @click="addEmailInput()">
                                <b>Email Notifications</b>
                                <button class="btn btn-sm btn-warning fr">
                                  <i class="ti-plus"></i>
                                </button>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <template
                              v-if="
                                typeof form.form_emailnotifications == 'object'
                              "
                            >
                              <tr
                                v-for="emailnotification in form.form_emailnotifications"
                              >
                                <td>
                                  <text-input
                                    class="col-md-12"
                                    v-model="emailnotification.email"
                                    label="Email"
                                  />
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-default waves-effect btn-round"
                    data-dismiss="modal"
                    @click="hidemodal('#addnew_plan')"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary funload btn-round waves-effect waves-light"
                    @click="updateForm()"
                  >
                    Update Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Page body start -->
        <div class="page-body">
          <div class="row">
            <div class="col-lg-12 formbuilderdiv">
              <div class="card">
                 
                <div class="card-block p-b-0">
                  <FormbuilderPreview
                    :form="form"
                    v-if="isPreview"
                  ></FormbuilderPreview>
                  <div class="row maincontainer " v-else>
                    <template v-if="form.form_components != null">
                      <div
                        v-for="(
                          component, index, object
                        ) in form.form_components"
                        :key="component"
                        :class="
                          component.form_component_cssclass +
                          (component.form_component_display == 1
                            ? ''
                            : ' cond-hide ')
                        "
                        class="sortable-moves p-3 section sectiondiv"
                        style="min-height: 10vh"
                        :data-key="index"
                      >
                        <i
                          class="ti-pencil-alt addbtn"
                          @click="
                            addChildren(
                              index,
                              component.form_component_id,
                              component.form_component_uniqueid
                            )
                          "
                        ></i>
                        <i
                          class="ti-settings sectionsettingbtn"
                          @click="configureSection(index)"
                        ></i>
                        <i class="ti-move movebtn" @click="addSort()"></i>
                        <i
                          class="ti-close removesecbtn"
                          @click="
                            removeSection(
                              index,
                              component.form_component_uniqueid
                            )
                          "
                        ></i>
                        <div class="row childContainer">
                          <template v-if="component.children != null">
                            <div
                              v-for="(
                                childval, childindex, childobject
                              ) in component.children"
                              class="p-3 compunent"
                              :class="
                                childval.form_child_cssclass +
                                (childval.form_child_display == 1
                                  ? ''
                                  : ' cond-hide ')
                              "
                              :data-sectionkey="index"
                              :data-key="childindex"
                            >
                              <i
                                class="ti-move movebtn"
                                @click="addSortChild()"
                              ></i>
                              <i
                                class="ti-close removebtn"
                                @click="
                                  removeCompunent(
                                    index,
                                    childindex,
                                    childval.form_child_uniqueid,
                                    component.form_component_id
                                  )
                                "
                              ></i>
                              <i
                                class="ti-settings settingbtn"
                                @click="configureCompunent(childindex)"
                              ></i>
                              <div class="form-group">
                                <template
                                  v-if="
                                    childval.form_child_type != 'checkbox' &&
                                    childval.form_child_type != 'heading'
                                  "
                                >
                                  <label>{{ childval.form_child_label }}</label>
                                </template>
                                <template
                                  v-if="childval.form_child_type == 'checkbox'"
                                >
                                  <label style="width: 100%">&nbsp;</label>
                                </template>
                                <template
                                  v-if="
                                    childval.form_child_type ==
                                      'datetime-local' ||
                                    childval.form_child_type == 'time' ||
                                    childval.form_child_type == 'text' ||
                                    childval.form_child_type == 'number' ||
                                    childval.form_child_type == 'date'
                                  "
                                >
                                  <input
                                    :type="childval.form_child_type"
                                    class="form-control"
                                    :required="childval.form_child_mandatory"
                                    v-model="childval.form_child_value"
                                    :placeholder="
                                      childval.form_child_placeholder
                                    "
                                    :value="childval.form_child_value"
                                  />
                                </template>
                                <template
                                  v-else-if="
                                    childval.form_child_type == 'textarea'
                                  "
                                >
                                  <textarea
                                    class="form-control"
                                    v-model="childval.form_child_value"
                                    :required="childval.form_child_mandatory"
                                    :placeholder="
                                      childval.form_child_placeholder
                                    "
                                    :rows="childval.form_child_rows"
                                    >{{ childval.form_child_value }}</textarea
                                  >
                                </template>
                                <template
                                  v-else-if="
                                    childval.form_child_type == 'fileuploader'
                                  "
                                >
                                  <input
                                    class="form-control"
                                    :required="childval.form_child_mandatory"
                                    type="file"
                                  />
                                </template>
                                <template
                                  v-else-if="
                                    childval.form_child_type == 'signaturepad'
                                  "
                                >
                                  <div
                                    class="form-control"
                                    :style="{
                                      height: childval.form_child_height
                                        ? childval.form_child_height + 'px'
                                        : '',
                                      width: childval.form_child_width
                                        ? childval.form_child_width + 'px'
                                        : '',
                                    }"
                                    style="border: 1px solid grey"
                                  ></div>
                                </template>
                                <template
                                  v-else-if="
                                    childval.form_child_type == 'imageeditor'
                                  "
                                >
                                  <div
                                    class="form-control"
                                    :style="{
                                      height: childval.form_child_height
                                        ? childval.form_child_height + 'px'
                                        : '',
                                      width: childval.form_child_width
                                        ? childval.form_child_width + 'px'
                                        : '',
                                    }"
                                    style="border: 1px solid grey"
                                  ></div>
                                </template>
                                <template
                                  v-else-if="
                                    childval.form_child_type == 'inlineimage'
                                  "
                                >
                                  <img
                                    class="img-responsive"
                                    :style="{
                                      height: childval.form_child_height
                                        ? childval.form_child_height + 'px'
                                        : '',
                                      width: childval.form_child_width
                                        ? childval.form_child_width + 'px'
                                        : '',
                                    }"
                                    :src="childval.form_child_link"
                                  />
                                </template>

                                <template
                                  v-else-if="
                                    childval.form_child_type == 'heading'
                                  "
                                >
                                  <span :class="childval.form_child_headtype">{{
                                    childval.form_child_value
                                  }}</span>
                                </template>
                                <template
                                  v-else-if="
                                    childval.form_child_type == 'paragraph'
                                  "
                                >
                                  <p>{{ childval.form_child_value }}</p>
                                </template>

                                <template
                                  v-else-if="
                                    childval.form_child_type == 'divider'
                                  "
                                >
                                  <hr
                                    :size="childval.form_child_height + 'px'"
                                    :style="{ opacity: '8' }"
                                  />
                                </template>
                                <template
                                  v-else-if="
                                    childval.form_child_type == 'emptyspace'
                                  "
                                >
                                  <span
                                    style="display: block"
                                    :style="{
                                      height: childval.form_child_height + 'px',
                                    }"
                                  ></span>
                                </template>
                                <template
                                  v-else-if="
                                    childval.form_child_type == 'checkbox'
                                  "
                                >
                                  <div class="checkbox-fade fade-in-primary">
                                    <label>
                                      <input
                                        type="checkbox"
                                        :checked="childval.form_child_checked"
                                        :required="
                                          childval.form_child_mandatory
                                        "
                                        :value="childval.form_child_value"
                                        v-model="childval.form_child_checked"
                                        @change="
                                          displayOrNot(
                                            childval.form_child_uniqueid,
                                            childval.form_child_checked
                                              ? childval.form_child_value
                                              : ''
                                          )
                                        "
                                      />
                                      <span class="cr">
                                        <i
                                          class="cr-icon icofont icofont-ui-check txt-primary"
                                        ></i>
                                      </span>
                                      <span>{{
                                        childval.form_child_label
                                      }}</span>
                                    </label>
                                  </div>
                                </template>
                                <template
                                  v-else-if="
                                    childval.form_child_type == 'dropdown'
                                  "
                                >
                                  <select
                                    class="form-control"
                                    :required="childval.form_child_mandatory"
                                    v-model="childval.form_child_value"
                                    @change="
                                      displayOrNot(
                                        childval.form_child_uniqueid,
                                        childval.form_child_value
                                      )
                                    "
                                  >
                                    <template
                                      v-for="(
                                        options, option_index, option_object
                                      ) in childval.form_child_options"
                                    >
                                      <option
                                        :value="options.optionvalue"
                                        :selected="options.selected"
                                      >
                                        {{ options.optiontext }}
                                      </option>
                                    </template>
                                  </select>
                                </template>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </template>
                    <div class="col-md-3"></div>
                    <div class="col-md-6">
                      <div
                        class="sortable-moves sortable-compunent pt-2 text-center waves-effect badge w-100"
                        style="
                          background: white;
                          height: 200px;
                          color: black;
                          border: 1px dashed;
                        "
                        @click="addSection()"
                      >
                        <div style="top: 23%; position: relative">
                          <h6><i class="ti-plus fs-1"></i></h6>
                          <p style="font-size: 0.8rem; margin-top: 7px">
                            Add Section
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="styleSelector" class="col-lg-3">
              <div class="selector-toggle">
                <a href="javascript:void(0)"></a>
              </div>
              <ul>
                <li>
                  <p class="selector-title main-title st-main-title">
                    <b>Component </b>Settings
                  </p>
                  <span class="text-muted"
                    >Live customizer with tons of options
                  </span>
                </li>
              </ul>
              <div class="slimScrollDiv" v-if="displayConfiguration">
                <div class="style-cont m-t-10">
                  <ul class="nav nav-tabs tabs" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        data-toggle="tab"
                        data-target="#sel-layout"
                        href="javascript:void(0)"
                        role="tab"
                        >{{ editcompunent.data.type.toUpperCase() }}</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        data-toggle="tab"
                        data-target="#sel-layout2"
                        href="javascript:void(0)"
                        role="tab"
                        >Conditional Event</a
                      >
                    </li>
                  </ul>
                  <div class="tab-content tabs">
                    <div
                      class="tab-pane active"
                      id="sel-layout"
                      role="tabpanel"
                    >
                      <div class="row">
                        <text-input
                          class="col-md-12"
                          v-model="editcompunent.data.form_component_uniqueid"
                          v-if="editcompunent.data.type == 'section'"
                          label="Unique ID"
                        />
                        <text-input
                          class="col-md-12"
                          v-model="editcompunent.data.form_child_uniqueid"
                          v-if="editcompunent.data.type != 'section'"
                          label="Unique ID"
                        />
                        <text-input
                          v-if="
                            editcompunent.data.type != 'heading' &&
                            editcompunent.data.type != 'paragraph' &&
                            editcompunent.data.type != 'section' &&
                            editcompunent.data.type != 'emptyspace' &&
                            editcompunent.data.type != 'divider'
                          "
                          class="col-md-12"
                          v-model="editcompunent.data.form_child_label"
                          label="Label"
                        />

                        <text-input
                          v-if="
                            editcompunent.data.type != 'date' &&
                            editcompunent.data.type != 'datetime-local' &&
                            editcompunent.data.type != 'inlineimage' &&
                            editcompunent.data.type != 'emptyspace' &&
                            editcompunent.data.type != 'signaturepad' &&
                            editcompunent.data.type != 'imageeditor' &&
                            editcompunent.data.type != 'time' &&
                            editcompunent.data.type != 'section' &&
                            editcompunent.data.type != 'date' &&
                            editcompunent.data.type != 'paragraph' &&
                            editcompunent.data.type != 'fileuploader' &&
                            editcompunent.data.type != 'divider'
                          "
                          class="col-md-12"
                          v-model="editcompunent.data.form_child_value"
                          label="Value"
                        />

                        <text-input
                          v-if="editcompunent.data.type == 'date'"
                          class="col-md-12"
                          v-model="editcompunent.data.form_child_value"
                          :type="editcompunent.data.form_child_type"
                          label="Value"
                        />
                        <text-input
                          v-if="editcompunent.data.type == 'datetime-local'"
                          class="col-md-12"
                          v-model="editcompunent.data.form_child_value"
                          :type="editcompunent.data.form_child_type"
                          label="Value"
                        />
                        <text-input
                          v-if="editcompunent.data.type == 'time'"
                          class="col-md-12"
                          v-model="editcompunent.data.form_child_value"
                          :type="editcompunent.data.type"
                          label="Value"
                        />
                        <text-input
                          v-if="
                            editcompunent.data.type != 'heading' &&
                            editcompunent.data.type != 'inlineimage' &&
                            editcompunent.data.type != 'signaturepad' &&
                            editcompunent.data.type != 'imageeditor' &&
                            editcompunent.data.type != 'emptyspace' &&
                            editcompunent.data.type != 'dropdown' &&
                            editcompunent.data.type != 'section' &&
                            editcompunent.data.type != 'fileuploader' &&
                            editcompunent.data.type != 'checkbox' &&
                            editcompunent.data.type != 'paragraph' &&
                            editcompunent.data.type != 'fileuploader' &&
                            editcompunent.data.type != 'divider'
                          "
                          class="col-md-12"
                          v-model="editcompunent.data.form_child_placeholder"
                          label="Placeholder"
                        />
                        <text-input
                          v-if="
                            editcompunent.data.type != 'heading' &&
                            editcompunent.data.type != 'inlineimage' &&
                            editcompunent.data.type != 'signaturepad' &&
                            editcompunent.data.type != 'imageeditor' &&
                            editcompunent.data.type != 'emptyspace' &&
                            editcompunent.data.type != 'dropdown' &&
                            editcompunent.data.type != 'fileuploader' &&
                            editcompunent.data.type != 'checkbox' &&
                            editcompunent.data.type != 'paragraph' &&
                            editcompunent.data.type != 'fileuploader' &&
                            editcompunent.data.type != 'divider'
                          "
                          class="col-md-12"
                          v-model="editcompunent.data.tooltip"
                          label="Tooltip"
                        />

                        <image-input
                          v-if="editcompunent.data.type == 'inlineimage'"
                          v-model="editcompunent.data.form_child_link"
                          class="col-md-12"
                          :source="editcompunent.data.form_child_link"
                          kStyle="height:20vh;"
                          label="Link"
                        />
                        <textarea-input
                          v-if="editcompunent.data.type == 'paragraph'"
                          class="col-md-12"
                          rows="5"
                          label="Value"
                          v-model="editcompunent.data.form_child_value"
                        />
                        <select-input
                          v-if="editcompunent.data.type == 'checkbox'"
                          v-model="editcompunent.data.form_child_checked"
                          class="col-md-12"
                          label="Checked"
                        >
                          <option value="false">No</option>
                          <option
                            :selected="editcompunent.data.form_child_checked"
                            value="true"
                          >
                            Yes
                          </option>
                        </select-input>

                        <div
                          class="col-md-6"
                          v-if="editcompunent.data.type == 'section'"
                        >
                          <div class="form-group">
                            <label class="form-label">Width</label>
                            <select
                              class="form-control"
                              v-model="
                                editcompunent.data.form_component_cssclass
                              "
                            >
                              <option value="col-md-12">100%</option>
                              <option value="col-md-6">50%</option>
                              <option value="col-md-4">30%</option>
                              <option value="col-md-3">25%</option>
                              <option value="col-md-2">15%</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6" v-else>
                          <div class="form-group">
                            <label class="form-label">Width</label>
                            <select
                              class="form-control"
                              v-model="editcompunent.data.form_child_cssclass"
                            >
                              <option value="col-md-12">100%</option>
                              <option value="col-md-6">50%</option>
                              <option value="col-md-4">30%</option>
                              <option value="col-md-3">25%</option>
                              <option value="col-md-2">15%</option>
                            </select>
                          </div>
                        </div>
                        <div
                          class="col-md-6"
                          v-if="editcompunent.data.type == 'section'"
                        >
                          <div class="form-group">
                            <label class="form-label">Default Display</label>
                            <select
                              class="form-control"
                              v-model="
                                editcompunent.data.form_component_display
                              "
                            >
                              <option value="1">Show</option>
                              <option value="0">Hide</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6" v-else>
                          <div class="form-group">
                            <label class="form-label">Default Display</label>
                            <select
                              class="form-control"
                              v-model="editcompunent.data.form_child_display"
                            >
                              <option value="1">Show</option>
                              <option value="0">Hide</option>
                            </select>
                          </div>
                        </div>
                        <select-input
                          v-if="
                            editcompunent.data.type != 'heading' &&
                            editcompunent.data.type != 'inlineimage' &&
                            editcompunent.data.type != 'signaturepad' &&
                            editcompunent.data.type != 'imageeditor' &&
                            editcompunent.data.type != 'paragraph' &&
                            editcompunent.data.type != 'section' &&
                            editcompunent.data.type != 'divider' &&
                            editcompunent.data.type != 'emptyspace'
                          "
                          v-model="editcompunent.data.form_child_mandatory"
                          class="col-md-6"
                          label="Required"
                        >
                          <option value="false">No</option>
                          <option
                            :selected="editcompunent.data.form_child_mandatory"
                            value="true"
                          >
                            Yes
                          </option>
                        </select-input>
                        <select-input
                          v-if="editcompunent.data.type == 'heading'"
                          v-model="editcompunent.data.form_child_headtype"
                          class="col-md-6"
                          label="Fontsize"
                        >
                          <option value="h1">H1</option>
                          <option
                            :selected="
                              editcompunent.data.form_child_headtype == 'h2'
                            "
                            value="h2"
                          >
                            H2
                          </option>
                          <option
                            :selected="
                              editcompunent.data.form_child_headtype == 'h3'
                            "
                            value="h3"
                          >
                            H3
                          </option>
                          <option
                            :selected="
                              editcompunent.data.form_child_headtype == 'h4'
                            "
                            value="h4"
                          >
                            H4
                          </option>
                          <option
                            :selected="
                              editcompunent.data.form_child_headtype == 'h5'
                            "
                            value="h5"
                          >
                            H5
                          </option>
                        </select-input>
                        <select-input
                          v-if="editcompunent.data.type == 'fileuploader'"
                          v-model="editcompunent.data.form_child_filetype"
                          class="col-md-6"
                          label="Allowed Type"
                        >
                          <option value="jpg/jpeg/png">jpg/jpeg/png</option>
                          <option
                            :selected="
                              editcompunent.data.form_child_filetype == 'pdf'
                            "
                            value="pdf"
                          >
                            pdf
                          </option>
                        </select-input>
                        <text-input
                          v-if="editcompunent.data.type == 'fileuploader'"
                          class="col-md-6"
                          v-model="editcompunent.data.form_child_maxsize"
                          label="Max Size"
                        />
                        <text-input
                          v-if="editcompunent.data.type == 'textarea'"
                          class="col-md-12"
                          v-model="editcompunent.data.form_child_rows"
                          label="Rows"
                        />

                        <text-input
                          v-if="
                            editcompunent.data.type == 'divider' ||
                            editcompunent.data.type == 'signaturepad' ||
                            editcompunent.data.type == 'imageeditor' ||
                            editcompunent.data.type == 'emptyspace' ||
                            editcompunent.data.type == 'inlineimage'
                          "
                          :type="number"
                          class="col-md-6"
                          v-model="editcompunent.data.form_child_height"
                          label="Height (px)"
                        />
                        <text-input
                          v-if="
                            editcompunent.data.type == 'inlineimage' ||
                            editcompunent.data.type == 'signaturepad' ||
                            editcompunent.data.type == 'imageeditor'
                          "
                          :type="number"
                          class="col-md-6"
                          v-model="editcompunent.data.form_child_width"
                          label="Width (px)"
                        />

                        <div class="col-md-12">
                          <hr />
                        </div>
                        <table
                          class="table"
                          v-if="editcompunent.data.type == 'dropdown'"
                        >
                          <thead>
                            <tr>
                              <th
                                colspan="3"
                                class="text-right"
                                @click="addDropdownOption()"
                              >
                                <button class="btn btn-sm btn-warning">
                                  <i class="ti-plus"></i>
                                </button>
                              </th>
                            </tr>
                          </thead>
                          <tbody
                            v-if="
                              editcompunent.data.type == 'dropdown' &&
                              editcompunent.data.form_child_options.length > 0
                            "
                          >
                            <tr
                              v-for="option in editcompunent.data
                                .form_child_options"
                            >
                              <td>
                                <text-input
                                  class="col-md-12"
                                  v-model="option.optionvalue"
                                  label="Value"
                                />
                              </td>
                              <td>
                                <text-input
                                  class="col-md-12"
                                  v-model="option.optiontext"
                                  label="Option"
                                />
                              </td>
                              <td>
                                <select-input
                                  v-model="option.selected"
                                  class="col-md-12"
                                  label="Selected"
                                >
                                  <option value="false">No</option>
                                  <option
                                    :selected="option.selected"
                                    value="true"
                                  >
                                    Yes
                                  </option>
                                </select-input>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="tab-pane" id="sel-layout2" role="tabpanel">
                      <div class="row">
                        <table class="table">
                          <thead>
                            <tr>
                              <th
                                class="text-right"
                                @click="addEventOption(editcompunent.data.type)"
                              >
                                <button class="btn btn-sm btn-warning">
                                  <i class="ti-plus"></i>
                                </button>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <template
                              v-if="
                                editcompunent.data
                                  .form_child_conditionalevents != null &&
                                typeof editcompunent.data
                                  .form_child_conditionalevents == 'object'
                              "
                            >
                              <tr
                                v-for="(event, condkey) in editcompunent.data
                                  .form_child_conditionalevents"
                              >
                                <td>
                                  <div class="row">
                                    <div class="col-md-12 text-right">
                                      <button
                                        class="btn btn-danger btn-sm btn-outline-danger"
                                        @click="removeConditionalEvent(condkey)"
                                        type="button"
                                      >
                                        <i class="ti-trash"></i>
                                      </button>
                                    </div>
                                    <text-input
                                      class="col-md-5"
                                      v-model="event.uniquename"
                                      label="Unique Name"
                                    />
                                    <select-input
                                      v-model="event.condition"
                                      class="col-md-2 p-0"
                                      label="Condition"
                                    >
                                      <option value="==">==</option>
                                      <option
                                        :selected="event.condition == '!='"
                                        value="!="
                                      >
                                        !=
                                      </option>
                                      <option
                                        :selected="event.condition == '<'"
                                        value="<"
                                      >
                                        &lt;
                                      </option>
                                      <option
                                        :selected="event.condition == '<='"
                                        value="<="
                                      >
                                        &lt;=
                                      </option>
                                      <option
                                        :selected="event.condition == '>'"
                                        value=">"
                                      >
                                        >
                                      </option>
                                      <option
                                        :selected="event.condition == '>='"
                                        value=">="
                                      >
                                        >=
                                      </option>
                                    </select-input>
                                    <text-input
                                      class="col-md-5"
                                      v-model="event.compunentvalue"
                                      label="Value"
                                    />
                                    <select-input
                                      v-model="event.do"
                                      class="col-md-12"
                                      label="DO"
                                    >
                                      <option value="show">Show</option>
                                      <option value="hide">Hide</option>
                                    </select-input>
                                  </div>
                                </td>
                              </tr>
                            </template>
                            <template
                              v-if="
                                editcompunent.data
                                  .form_component_conditionalevents != null
                              "
                            >
                              <tr
                                v-for="(event, condkey) in editcompunent.data
                                  .form_component_conditionalevents"
                              >
                                <td>
                                  <div class="row">
                                    <div class="col-md-12 text-right">
                                      <button
                                        class="btn btn-danger btn-sm btn-outline-danger"
                                        @click="
                                          removeCompConditionalEvent(condkey)
                                        "
                                        type="button"
                                      >
                                        <i class="ti-trash"></i>
                                      </button>
                                    </div>
                                    <text-input
                                      class="col-md-5"
                                      v-model="event.uniquename"
                                      label="Unique Name"
                                    />
                                    <select-input
                                      v-model="event.condition"
                                      class="col-md-2 p-0"
                                      label="Condition"
                                    >
                                      <option value="==">==</option>
                                      <option
                                        :selected="event.condition == '!='"
                                        value="!="
                                      >
                                        !=
                                      </option>
                                      <option
                                        :selected="event.condition == '<'"
                                        value="<"
                                      >
                                        &lt;
                                      </option>
                                      <option
                                        :selected="event.condition == '<='"
                                        value="<="
                                      >
                                        &lt;=
                                      </option>
                                      <option
                                        :selected="event.condition == '>'"
                                        value=">"
                                      >
                                        >
                                      </option>
                                      <option
                                        :selected="event.condition == '>='"
                                        value=">="
                                      >
                                        >=
                                      </option>
                                    </select-input>
                                    <text-input
                                      class="col-md-5"
                                      v-model="event.compunentvalue"
                                      label="Value"
                                    />
                                    <select-input
                                      v-model="event.do"
                                      class="col-md-12"
                                      label="DO"
                                    >
                                      <option value="show">Show</option>
                                      <option value="hide">Hide</option>
                                    </select-input>
                                  </div>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Page body end -->
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script setup>
import ImageInput from "../../../shared/ImageInput.vue";
import { functions } from "../../../../common/appFunctions.js";
import { appConsts } from "../../../../common/constants.js";
import SelectInput from "../../../shared/SelectInput.vue";
import FormbuilderPreview from "../../../shared/FormbuilderPreview.vue";
import TextInput from "../../../shared/TextInput";
import TextareaInput from "../../../shared/TextareaInput";
import { ref, reactive, onMounted, provide } from "vue";
import { useAuthStore } from "@/store/auth.js";
import factory from "@/common/factory.js";
import { useRoute } from "vue-router";

const authStore = useAuthStore();
const route = useRoute();
const isPreview = ref(false);

function previewForm() {
  if (isPreview.value) {
    isPreview.value = false;
  } else {
    isPreview.value = true;
  }
}

var form = ref({
  form_id: "",
  form_title: "",
  form_description: "",
  form_instructions: "",
  form_company_id: "",
  form_company_id: "",
  form_task_triggered: "",
  form_isactive: 1,
  form_emailnotifications: [],
  form_components: [],
});
var editcompunent = reactive({});
var displayConfiguration = ref(false);
var opacity = ref(0.3);

function reorderObject() {
  var newarray = [];
  $(".sectiondiv").each(function (k2, v2) {
    var key = $(this).data("key");
    var newarraychilren = [];
    $(this)
      .find(".compunent")
      .each(function (k, v) {
        var key2 = $(this).data("key");
        var sectionkey = $(this).data("sectionkey");
        if (
          form.value.form_components[sectionkey].children[key2] != undefined
        ) {
          form.value.form_components[sectionkey].children[
            key2
          ].form_child_orderby = k;
          newarraychilren.push(
            form.value.form_components[sectionkey].children[key2]
          );
        }
      });
    form.value.form_components[key].form_component_orderby = k2;
    var fullarr = form.value.form_components[key];
    fullarr = {
      ...fullarr,
      children: newarraychilren,
    };
    newarray.push(fullarr);
  });
  form.value.form_components = newarray;
}

function getToken() {
  const token = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authStore.token,
    },
  };
  return token;
}
provide("getToken", getToken());
async function updateForm() {
  // console.log(form.value);
  reorderObject();
  // console.log(form.value);
  // form.value = form.value;
  functions.initSoftLoader();
  const FormApi = new factory(getToken(), "forms", form.value);
  await FormApi.putForm().then((response) => {
    if (response.data.status == "success") {
      functions.destroyBtnLoader();
      functions.kSwal("Done", "Changes Saved Successfully.");
      hidemodal("#addnew_plan");
    }
  });
}
async function addSection() {
  var sectioncreate = {
    form_id: form.value.form_id,
    form_component_type: "section",
    form_component_cssclass: "col-md-12",
    form_component_display: true,
    form_component_orderby: form.value.form_components.length,
    form_component_conditionalevents: [],
    children: [],
  };
  const FormApi = new factory(getToken(), "form/sections", sectioncreate);
  await FormApi.postForm().then((response) => {
    swal.close();
    if (response.data.status == "success") {
      var feildobj = {
        form_id: form.value.form_id,
        form_component_uniqueid: response.data.data.form_component_uniqueid,
        form_component_id: response.data.data.form_component_id,
        form_component_type: "section",
        form_component_cssclass: "col-md-12",
        form_component_display: true,
        form_component_orderby: form.value.form_components.length,
        form_component_conditionalevents: [],
        children: [],
      };
      form.value.form_components.push(feildobj);
    } else {
      console.log(response.data.message);
    }
  });
}
async function addField(type) {
  var sectioncreate = {
    form_id: form.value.form_id,
    form_component_id: editcompunent.form_component_id,
    form_component_uuid: editcompunent.form_component_uuid,
    form_child_type: type,
    form_child_label:
      type?.charAt(0)?.toUpperCase() +
      type?.slice(1).toLocaleLowerCase() +
      " Field",
    form_child_value: "",
    form_child_mandatory: false,
    form_child_placeholder: "",
    form_child_cssclass: "col-md-4",
    form_child_display: true,
    form_child_orderby:
      form.value.form_components[editcompunent.pindex].children.length,
    form_child_conditionalevents: [],
    form_child_checked: false,
    form_child_rows: 0,
  };
  const FormApi = new factory(getToken(), "form/section/child", sectioncreate);
  await FormApi.postForm().then((response) => {
    swal.close();
    if (response.data.status == "success") {
      var feildobj = {
        form_id: form.value.form_id,
        form_component_id: response.data.message.form_component_id,
        form_child_uniqueid: response.data.message.form_child_uniqueid,
        form_child_type: type,
        form_child_label:
          type?.charAt(0)?.toUpperCase() +
          type?.slice(1).toLocaleLowerCase() +
          " Field",
        form_child_value: "",
        form_child_mandatory: false,
        form_child_placeholder: "",
        form_child_cssclass: "col-md-4",
        form_child_display: true,
        form_child_checked: false,
        form_child_rows: 0,
        form_child_orderby:
          form.value.form_components[editcompunent.pindex].children.length,
        form_child_conditionalevents: [],
      };
      if (editcompunent.pindex == null) {
        alert("Kindly Select Section");
      } else {
        form.value.form_components[editcompunent.pindex].children.push(
          feildobj
        );
      }
    }
  });
}
function displayOrNot(uniquename, currenvalue) {
  debugger;
  if (uniquename == undefined || uniquename == null || uniquename == "") {
  } else {
    for (var i = 0; i < form.value.form_components.length; i++) {
      // sections
      if (
        form.value.form_components[i].form_component_conditionalevents.length >
        0
      ) {
        form.value.form_components[i].form_component_conditionalevents.filter(
          filterEvents
        );
        function filterEvents(v, k) {
          if (v.uniquename == uniquename) {
            if (currenvalue == v.compunentvalue && v.condition == "==") {
              if (v.do == "show") {
                form.value.form_components[i].form_component_display = true;
              } else if (v.do == "hide") {
                form.value.form_components[i].form_component_display = false;
              }
              // console.log('Equal');
            } else if (currenvalue != v.compunentvalue && v.condition == "!=") {
              // console.log('Not Equal');
              if (v.do == "show") {
                form.value.form_components[i].form_component_display = true;
              } else if (v.do == "hide") {
                form.value.form_components[i].form_component_display = false;
              }
            }
          }
        }
      }

      // compunents
      if (form.value.form_components[i].children.length > 0) {
        form.value.form_components[i].children.filter((x, childkey) =>
          x.form_child_conditionalevents.filter((cond) =>
            filterCompunentEvents(cond, childkey)
          )
        );
        function filterCompunentEvents(v, k) {
          if (v.uniquename == uniquename) {
            if (currenvalue == v.compunentvalue && v.condition == "==") {
              if (v.do == "show") {
                form.value.form_components[i].children[
                  k
                ].form_child_display = true;
              } else if (v.do == "hide") {
                form.value.form_components[i].children[
                  k
                ].form_child_display = false;
              }
            } else if (currenvalue != v.compunentvalue && v.condition == "!=") {
              if (v.do == "show") {
                form.value.form_components[i].children[
                  k
                ].form_child_display = true;
              } else if (v.do == "hide") {
                form.value.form_components[i].children[
                  k
                ].form_child_display = false;
              }
            }
          }
        }
      }
    }
  }
}

async function addTextArea() {
  var sectioncreate = {
    form_id: form.value.form_id,
    form_component_id: editcompunent.form_component_id,
    form_component_uuid: editcompunent.form_component_uuid,
    form_child_type: "textarea",
    form_child_label: "Text Area",
    form_child_value: "",
    form_child_mandatory: false,
    form_child_placeholder: "",
    form_child_cssclass: "col-md-4",
    form_child_display: true,
    form_child_conditionalevents: [],
    form_child_checked: false,
    form_child_orderby:
      form.value.form_components[editcompunent.pindex].children.length,
    form_child_rows: 3,
  };
  const FormApi = new factory(getToken(), "form/section/child", sectioncreate);
  await FormApi.postForm().then((response) => {
    swal.close();
    if (response.data.status == "success") {
      var feildobj = {
        form_id: form.value.form_id,
        form_component_id: response.data.message.form_component_id,
        form_child_uniqueid: response.data.message.form_child_uniqueid,
        form_child_type: "textarea",
        form_child_label: "Text Area",
        form_child_value: "",
        form_child_mandatory: false,
        form_child_placeholder: "",
        form_child_cssclass: "col-md-4",
        form_child_display: true,
        form_child_conditionalevents: [],
        form_child_checked: false,
        form_child_orderby:
          form.value.form_components[editcompunent.pindex].children.length,
        form_child_rows: 3,
      };
      if (editcompunent.pindex == null) {
        alert("Kindly Select Section");
      } else {
        form.value.form_components[editcompunent.pindex].children.push(
          feildobj
        );
      }
    }
  });
}
async function addInlineImage() {
  var sectioncreate = {
    form_id: form.value.form_id,
    form_component_id: editcompunent.form_component_id,
    form_component_uuid: editcompunent.form_component_uuid,
    form_child_type: "inlineimage",
    form_child_label: "Inline Image",
    form_child_value: "",
    form_child_link: "",
    form_child_height: "60",
    form_child_width: "60",
    form_child_mandatory: false,
    form_child_placeholder: "",
    form_child_cssclass: "col-md-4",
    form_child_display: true,
    form_child_conditionalevents: [],
    form_child_orderby:
      form.value.form_components[editcompunent.pindex].children.length,
    form_child_checked: false,
    form_child_rows: 0,
  };
  const FormApi = new factory(getToken(), "form/section/child", sectioncreate);
  await FormApi.postForm().then((response) => {
    swal.close();
    if (response.data.status == "success") {
      var feildobj = {
        form_id: form.value.form_id,
        form_component_id: response.data.message.form_component_id,
        form_child_uniqueid: response.data.message.form_child_uniqueid,
        form_child_type: "inlineimage",
        form_child_label: "Inline Image",
        form_child_value: "",
        form_child_link: "",
        form_child_height: "60",
        form_child_width: "60",
        form_child_orderby:
          form.value.form_components[editcompunent.pindex].children.length,
        form_child_mandatory: false,
        form_child_placeholder: "",
        form_child_cssclass: "col-md-4",
        form_child_display: true,
        form_child_conditionalevents: [],
        form_child_checked: false,
        form_child_rows: 0,
      };
      if (editcompunent.pindex == null) {
        alert("Kindly Select Section");
      } else {
        form.value.form_components[editcompunent.pindex].children.push(
          feildobj
        );
      }
    }
  });
}
async function addSignature() {
  var sectioncreate = {
    form_id: form.value.form_id,
    form_component_id: editcompunent.form_component_id,
    form_component_uuid: editcompunent.form_component_uuid,
    form_child_type: "signaturepad",
    form_child_label: "Sign",
    form_child_value: "",
    form_child_link: "",
    form_child_height: "100",
    form_child_width: "",
    form_child_mandatory: false,
    form_child_placeholder: "",
    form_child_cssclass: "col-md-4",
    form_child_display: true,
    form_child_conditionalevents: [],
    form_child_orderby:
      form.value.form_components[editcompunent.pindex].children.length,
    form_child_checked: false,
    form_child_rows: 0,
  };
  const FormApi = new factory(getToken(), "form/section/child", sectioncreate);
  await FormApi.postForm().then((response) => {
    swal.close();
    if (response.data.status == "success") {
      var feildobj = {
        form_id: form.value.form_id,
        form_component_id: response.data.message.form_component_id,
        form_child_uniqueid: response.data.message.form_child_uniqueid,
        form_child_type: "signaturepad",
        form_child_label: "Sign",
        form_child_value: "",
        form_child_height: "100",
        form_child_width: "",
        form_child_mandatory: false,
        form_child_placeholder: "",
        form_child_cssclass: "col-md-4",
        form_child_display: true,
        form_child_conditionalevents: [],
        form_child_orderby:
          form.value.form_components[editcompunent.pindex].children.length,
        form_child_checked: false,
        form_child_rows: 0,
      };
      if (editcompunent.pindex == null) {
        alert("Kindly Select Section");
      } else {
        form.value.form_components[editcompunent.pindex].children.push(
          feildobj
        );
      }
    }
  });
}
async function addImageEditor() {
  var sectioncreate = {
    form_id: form.value.form_id,
    form_component_id: editcompunent.form_component_id,
    form_component_uuid: editcompunent.form_component_uuid,
    form_child_type: "imageeditor",
    form_child_label: "Image Editor",
    form_child_value: "",
    form_child_link: "",
    form_child_height: "100",
    form_child_width: "",
    form_child_mandatory: false,
    form_child_placeholder: "",
    form_child_cssclass: "col-md-4",
    form_child_orderby:
      form.value.form_components[editcompunent.pindex].children.length,
    form_child_display: true,
    form_child_conditionalevents: [],
    form_child_checked: false,
    form_child_rows: 0,
  };
  const FormApi = new factory(getToken(), "form/section/child", sectioncreate);
  await FormApi.postForm().then((response) => {
    swal.close();
    if (response.data.status == "success") {
      var feildobj = {
        form_id: form.value.form_id,
        form_component_id: response.data.message.form_component_id,
        form_child_uniqueid: response.data.message.form_child_uniqueid,
        form_child_type: "imageeditor",
        form_child_label: "Image Editor",
        form_child_value: "",
        form_child_link: "",
        form_child_height: "100",
        form_child_width: "",
        form_child_mandatory: false,
        form_child_placeholder: "",
        form_child_cssclass: "col-md-4",
        form_child_orderby:
          form.value.form_components[editcompunent.pindex].children.length,
        form_child_display: true,
        form_child_conditionalevents: [],
        form_child_checked: false,
        form_child_rows: 0,
      };
      if (editcompunent.pindex == null) {
        alert("Kindly Select Section");
      } else {
        form.value.form_components[editcompunent.pindex].children.push(
          feildobj
        );
      }
    }
  });
}
async function addFileUploader() {
  var sectioncreate = {
    form_id: form.value.form_id,
    form_component_id: editcompunent.form_component_id,
    form_component_uuid: editcompunent.form_component_uuid,
    form_child_type: "fileuploader",
    form_child_label: "File Uploader",
    form_child_filetype: "jpg/jpeg/png",
    form_child_orderby:
      form.value.form_components[editcompunent.pindex].children.length,
    form_child_maxsize: "5000",
    form_child_value: "",
    form_child_link: "",
    form_child_height: "100",
    form_child_width: "",
    form_child_mandatory: false,
    form_child_placeholder: "",
    form_child_cssclass: "col-md-4",
    form_child_display: true,
    form_child_conditionalevents: [],
    form_child_checked: false,
    form_child_rows: 0,
  };
  const FormApi = new factory(getToken(), "form/section/child", sectioncreate);
  await FormApi.postForm().then((response) => {
    swal.close();
    if (response.data.status == "success") {
      var feildobj = {
        form_id: form.value.form_id,
        form_component_id: response.data.message.form_component_id,
        form_child_uniqueid: response.data.message.form_child_uniqueid,
        form_child_type: "fileuploader",
        form_child_label: "File Uploader",
        form_child_filetype: "jpg/jpeg/png",
        form_child_maxsize: "5000",
        form_child_value: "",
        form_child_link: "",
        form_child_height: "100",
        form_child_width: "",
        form_child_mandatory: false,
        form_child_placeholder: "",
        form_child_orderby:
          form.value.form_components[editcompunent.pindex].children.length,
        form_child_cssclass: "col-md-4",
        form_child_display: true,
        form_child_conditionalevents: [],
        form_child_checked: false,
        form_child_rows: 0,
      };
      if (editcompunent.pindex == null) {
        alert("Kindly Select Section");
      } else {
        form.value.form_components[editcompunent.pindex].children.push(
          feildobj
        );
      }
    }
  });
}
async function addHeading() {
  var sectioncreate = {
    form_id: form.value.form_id,
    form_component_id: editcompunent.form_component_id,
    form_component_uuid: editcompunent.form_component_uuid,
    form_child_type: "heading",
    form_child_label: "",
    form_child_headtype: "h1",
    form_child_orderby:
      form.value.form_components[editcompunent.pindex].children.length,
    form_child_value: "Heading",
    form_child_mandatory: false,
    form_child_placeholder: "",
    form_child_cssclass: "col-md-12",
    form_child_display: true,
    form_child_conditionalevents: [],
    form_child_checked: false,
    form_child_rows: 0,
  };
  const FormApi = new factory(getToken(), "form/section/child", sectioncreate);
  await FormApi.postForm().then((response) => {
    swal.close();
    if (response.data.status == "success") {
      var feildobj = {
        form_id: form.value.form_id,
        form_component_id: response.data.message.form_component_id,
        form_child_uniqueid: response.data.message.form_child_uniqueid,
        form_child_type: "heading",
        form_child_label: "",
        form_child_headtype: "h1",
        form_child_orderby:
          form.value.form_components[editcompunent.pindex].children.length,
        form_child_value: "Heading",
        form_child_mandatory: false,
        form_child_placeholder: "",
        form_child_cssclass: "col-md-12",
        form_child_display: true,
        form_child_conditionalevents: [],
        form_child_checked: false,
        form_child_rows: 0,
      };
      if (editcompunent.pindex == null) {
        alert("Kindly Select Section");
      } else {
        form.value.form_components[editcompunent.pindex].children.push(
          feildobj
        );
      }
    }
  });
}

async function addDivider() {
  var sectioncreate = {
    form_id: form.value.form_id,
    form_component_id: editcompunent.form_component_id,
    form_component_uuid: editcompunent.form_component_uuid,
    form_child_type: "divider",
    form_child_label: "",
    form_child_value: "",
    form_child_mandatory: false,
    form_child_placeholder: "",
    form_child_cssclass: "col-md-12",
    form_child_orderby:
      form.value.form_components[editcompunent.pindex].children.length,
    form_child_display: true,
    form_child_conditionalevents: [],
    form_child_checked: false,
    form_child_height: "3",
    form_child_rows: 0,
  };
  const FormApi = new factory(getToken(), "form/section/child", sectioncreate);
  await FormApi.postForm().then((response) => {
    swal.close();
    if (response.data.status == "success") {
      var feildobj = {
        form_id: form.value.form_id,
        form_component_id: response.data.message.form_component_id,
        form_child_uniqueid: response.data.message.form_child_uniqueid,
        form_child_type: "divider",
        form_child_label: "",
        form_child_value: "",
        form_child_mandatory: false,
        form_child_placeholder: "",
        form_child_cssclass: "col-md-12",
        form_child_orderby:
          form.value.form_components[editcompunent.pindex].children.length,
        form_child_display: true,
        form_child_conditionalevents: [],
        form_child_checked: false,
        form_child_height: "3",
        form_child_rows: 0,
      };

      if (editcompunent.pindex == null) {
        alert("Kindly Select Section");
      } else {
        form.value.form_components[editcompunent.pindex].children.push(
          feildobj
        );
      }
    }
  });
}
async function addSpace() {
  var sectioncreate = {
    form_id: form.value.form_id,
    form_component_id: editcompunent.form_component_id,
    form_component_uuid: editcompunent.form_component_uuid,
    form_child_type: "emptyspace",
    form_child_label: "",
    form_child_value: "",
    form_child_mandatory: false,
    form_child_placeholder: "",
    form_child_cssclass: "col-md-12",
    form_child_orderby:
      form.value.form_components[editcompunent.pindex].children.length,
    form_child_display: true,
    form_child_conditionalevents: [],
    form_child_checked: false,
    form_child_height: "0",
    form_child_rows: 0,
  };
  const FormApi = new factory(getToken(), "form/section/child", sectioncreate);
  await FormApi.postForm().then((response) => {
    swal.close();
    if (response.data.status == "success") {
      var feildobj = {
        form_id: form.value.form_id,
        form_component_id: response.data.message.form_component_id,
        form_child_uniqueid: response.data.message.form_child_uniqueid,
        form_child_type: "emptyspace",
        form_child_label: "",
        form_child_value: "",
        form_child_mandatory: false,
        form_child_placeholder: "",
        form_child_cssclass: "col-md-12",
        form_child_orderby:
          form.value.form_components[editcompunent.pindex].children.length,
        form_child_display: true,
        form_child_conditionalevents: [],
        form_child_checked: false,
        form_child_height: "0",
        form_child_rows: 0,
      };

      if (editcompunent.pindex == null) {
        alert("Kindly Select Section");
      } else {
        form.value.form_components[editcompunent.pindex].children.push(
          feildobj
        );
      }
    }
  });
}
async function addParagraph() {
  var sectioncreate = {
    form_id: form.value.form_id,
    form_component_id: editcompunent.form_component_id,
    form_component_uuid: editcompunent.form_component_uuid,
    form_child_type: "paragraph",
    form_child_label: "",
    form_child_value: "Enter Paragraph Here",
    form_child_orderby:
      form.value.form_components[editcompunent.pindex].children.length,
    form_child_mandatory: false,
    form_child_placeholder: "",
    form_child_cssclass: "col-md-12",
    form_child_display: true,
    form_child_conditionalevents: [],
    form_child_checked: false,
    form_child_height: "",
    form_child_rows: 0,
  };
  const FormApi = new factory(getToken(), "form/section/child", sectioncreate);
  await FormApi.postForm().then((response) => {
    swal.close();
    if (response.data.status == "success") {
      var feildobj = {
        form_id: form.value.form_id,
        form_component_id: response.data.message.form_component_id,
        form_child_uniqueid: response.data.message.form_child_uniqueid,
        form_child_type: "paragraph",
        form_child_label: "",
        form_child_value: "Enter Paragraph Here",
        form_child_mandatory: false,
        form_child_placeholder: "",
        form_child_orderby:
          form.value.form_components[editcompunent.pindex].children.length,
        form_child_cssclass: "col-md-12",
        form_child_display: true,
        form_child_conditionalevents: [],
        form_child_checked: false,
        form_child_height: "",
        form_child_rows: 0,
      };
      if (editcompunent.pindex == null) {
        alert("Kindly Select Section");
      } else {
        form.value.form_components[editcompunent.pindex].children.push(
          feildobj
        );
      }
    }
  });
}
async function addDropDown() {
  var sectioncreate = {
    form_id: form.value.form_id,
    form_component_id: editcompunent.form_component_id,
    form_component_uuid: editcompunent.form_component_uuid,
    form_child_type: "dropdown",
    form_child_label: "Dropdown",
    form_child_value: "",
    form_child_mandatory: false,
    form_child_placeholder: "",
    form_child_cssclass: "col-md-4",
    form_child_orderby:
      form.value.form_components[editcompunent.pindex].children.length,
    form_child_display: true,
    form_child_checked: false,
    form_child_height: "",
    form_child_rows: 0,
    form_child_options: [],
    form_child_conditionalevents: [],
  };
  const FormApi = new factory(getToken(), "form/section/child", sectioncreate);
  await FormApi.postForm().then((response) => {
    swal.close();
    if (response.data.status == "success") {
      var feildobj = {
        form_id: form.value.form_id,
        form_component_id: response.data.message.form_component_id,
        form_child_uniqueid: response.data.message.form_child_uniqueid,
        form_child_type: "dropdown",
        form_child_label: "Dropdown",
        form_child_value: "",
        form_child_mandatory: false,
        form_child_orderby:
          form.value.form_components[editcompunent.pindex].children.length,
        form_child_placeholder: "",
        form_child_cssclass: "col-md-4",
        form_child_display: true,
        form_child_checked: false,
        form_child_height: "",
        form_child_rows: 0,
        form_child_conditionalevents: [],
        form_child_options: [
          {
            selected: "true",
            optionvalue: "option",
            optiontext: "Option 1",
          },
        ],
      };
      if (editcompunent.pindex == null) {
        alert("Kindly Select Section");
      } else {
        form.value.form_components[editcompunent.pindex].children.push(
          feildobj
        );
      }
    }
  });
}
async function addCheckbox() {
  var sectioncreate = {
    form_id: form.value.form_id,
    form_component_id: editcompunent.form_component_id,
    form_component_uuid: editcompunent.form_component_uuid,
    form_child_type: "checkbox",
    form_child_label: "Checkbox",
    form_child_value: "",
    form_child_mandatory: false,
    form_child_placeholder: "",
    form_child_cssclass: "col-md-4",
    form_child_orderby:
      form.value.form_components[editcompunent.pindex].children.length,
    form_child_display: true,
    form_child_checked: false,
    form_child_height: "",
    form_child_rows: 0,
    form_child_conditionalevents: [],
  };
  const FormApi = new factory(getToken(), "form/section/child", sectioncreate);
  await FormApi.postForm().then((response) => {
    swal.close();
    if (response.data.status == "success") {
      var feildobj = {
        form_id: form.value.form_id,
        form_component_id: response.data.message.form_component_id,
        form_child_uniqueid: response.data.message.form_child_uniqueid,
        form_child_type: "checkbox",
        form_child_label: "Checkbox",
        form_child_value: "",
        form_child_mandatory: false,
        form_child_placeholder: "",
        form_child_cssclass: "col-md-4",
        form_child_display: true,
        form_child_checked: false,
        form_child_orderby:
          form.value.form_components[editcompunent.pindex].children.length,
        form_child_height: "",
        form_child_rows: 0,
        form_child_conditionalevents: [],
      };

      if (editcompunent.pindex == null) {
        alert("Kindly Select Section");
      } else {
        form.value.form_components[editcompunent.pindex].children.push(
          feildobj
        );
      }
    }
  });
}
function addEmailInput() {
  form.value.form_emailnotifications =
    typeof form.value.form_emailnotifications != "object"
      ? []
      : form.value.form_emailnotifications;
  form.value.form_emailnotifications.push({
    email: "",
  });
}
function addDropdownOption() {
  var option = {
    selected: "false",
    optionvalue: "option",
    optiontext: "Option",
  };
  editcompunent.data.form_child_options.push(option);
}
function addEventOption(type) {
  if (type == "section") {
    editcompunent.data.form_component_conditionalevents =
      typeof editcompunent.data.form_component_conditionalevents != "object" ||
      editcompunent.data.form_component_conditionalevents == null
        ? []
        : editcompunent.data.form_component_conditionalevents;
    var option = {
      uniquename: "",
      condition: "==",
      compunentvalue: "",
      do: "show",
    };
    editcompunent.data.form_component_conditionalevents.push(option);
  } else {
    editcompunent.data.form_child_conditionalevents =
      typeof editcompunent.data.form_child_conditionalevents != "object" ||
      editcompunent.data.form_child_conditionalevents == null
        ? []
        : editcompunent.data.form_child_conditionalevents;
    var option = {
      uniquename: "",
      condition: "==",
      compunentvalue: "",
      do: "show",
    };
    editcompunent.data.form_child_conditionalevents.push(option);
  }
}

function configureCompunent(index) {
  displayConfiguration.value = true;
  editcompunent.data =
    form.value.form_components[editcompunent.pindex].children[index];
  editcompunent.data.type =
    form.value.form_components[editcompunent.pindex].children[
      index
    ].form_child_type;
  editcompunent.index = index;
  $(".formbuilderdiv").removeClass("col-lg-12");
  $(".formbuilderdiv").addClass("col-lg-9");
}
function configureSection(index) {
  displayConfiguration.value = true;
  editcompunent.data = form.value.form_components[index];
  editcompunent.data.type =
    form.value.form_components[index].form_component_type;

  $(".formbuilderdiv").removeClass("col-lg-12");
  $(".formbuilderdiv").addClass("col-lg-9");
}
$(document).on("click", ".selector-toggle", function () {
  $(".formbuilderdiv").removeClass("col-lg-9");
  $(".formbuilderdiv").addClass("col-lg-12");
  $(this).removeClass("active");
  $("#styleSelector").removeClass("open");
});
function addChildren(index, form_component_id, uuid) {
  editcompunent.pindex = index;
  editcompunent.form_component_id = form_component_id;
  editcompunent.form_component_uuid = uuid;
  opacity.value = 1;
}

$(document).on("click", '[data-toggle="tab"]', function () {
  var openingtabid = $(this).data("target");
  $('[data-toggle="tab"]').removeClass("active");
  $(this).addClass("active");
  $(this).closest(".nav").parent().find(".tab-pane").removeClass("active");
  $(openingtabid).addClass("active");
});
onMounted(async () => {
  functions.initLoader();
  const Form = new factory(getToken(), "forms/" + route.query.id);
  await Form.getHttp().then((response) => {
    if (response.status == 200) {
      functions.destroyLoader();
      var data = response.status == 400 ? [] : response.data.data;
      form.value = data;
      form.value.form_emailnotifications = JSON.parse(
        form.value.form_emailnotifications
      );
    }
  });

  functions.callOnReady();
});

function addSort() {
  $(".maincontainer").sortable({
    disabled: false,
    connectWith: ".maincontainer",
  });
}

function resetSort() {
  $(".maincontainer").sortable({
    disabled: true,
  });
}
async function removeSection(index, uuid) {
  // const Page = new factory(getToken(), 'form/sections/' + uuid);
  // await Page.deleteHttp().then((response) => {
  //     if (response.data.status == "success") {
  //         form.value.form_components = form.value.form_components.filter((x, k) => k !== index);
  //     }
  // });

  var deleteSection = {
    form_id: form.value.form_id,
    uuid: uuid,
  };
  const FormApi = new factory(getToken(), "form/deletesections", deleteSection);
  await FormApi.postForm().then((response) => {
    if (response.data.status == "success") {
      swal.close();
      form.value.form_components = form.value.form_components.filter(
        (x, k) => k !== index
      );
    }
  });
}
function removeConditionalEvent(index) {
  editcompunent.data.form_child_conditionalevents =
    editcompunent.data.form_child_conditionalevents.filter(
      (x, k) => k !== index
    );
}
function removeCompConditionalEvent(index) {
  editcompunent.data.form_component_conditionalevents =
    editcompunent.data.form_component_conditionalevents.filter(
      (x, k) => k !== index
    );
}
async function removeCompunent(
  sectionindex,
  compunentindex,
  uuid,
  compunentid
) {
  // const Page = new factory(getToken(), 'form/section/child/' + uuid);
  // await Page.deleteHttp().then((response) => {
  //     if (response.data.status == "success") {
  //         var remainingchilren = form.value.form_components[sectionindex].children.filter((x, k) => k !== compunentindex);
  //         form.value.form_components[sectionindex].children = remainingchilren;
  //     }
  // });
  // form.value.form_id
  var deleteChild = {
    uuid: uuid,
    form_component_id: compunentid,
  };
  const FormApi = new factory(
    getToken(),
    "form/section/deletechild",
    deleteChild
  );
  await FormApi.postForm().then((response) => {
    if (response.data.status == "success") {
      swal.close();
      var remainingchilren = form.value.form_components[
        sectionindex
      ].children.filter((x, k) => k !== compunentindex);
      form.value.form_components[sectionindex].children = remainingchilren;
    }
  });
}

function addSortChild() {
  $(".childContainer").sortable({
    disabled: false,
    connectWith: ".childContainer",
  });
}

function resetSortChild() {
  $(".childContainer").sortable({
    disabled: true,
  });
}

$(document).on("click", ".settingbtn,.sectionsettingbtn", function () {
  $(".compunent").removeClass("active");
  $(this).closest(".compunent").addClass("active");
  $("#styleSelector").addClass("open");
  $(".selector-toggle").addClass("active");
});
$(document).on("click", ".addbtn", function () {
  $(".section").removeClass("active");
  $("#styleSelector").removeClass("open");
  $("#styleSelector").removeClass("open");
  $(".selector-toggle").removeClass("active");
  $(".formbuilderdiv").addClass("col-lg-12");
  $(".formbuilderdiv").removeClass("col-lg-9");

  $(".section").removeClass("addactive");
  $(this).closest(".section").addClass("addactive");
});
$(".pcoded-navbar").hide();
$(".pcoded-content").attr("style", "margin-left: 0px !important;");
function showmodal(id) {
  $(id).modal("show");
}
function hidemodal(id) {
  $(id).modal("hide");
}
</script>
<style type="text/css" scoped>
.selector-toggle.active {
  display: block !important;
}

.selector-toggle {
  display: none;
}

.sortable-compunent:hover {
  background-color: #404e671a;
}

.sortable-compunent {
  cursor: pointer;
  padding-left: unset;
  padding-right: unset;
  border-top: 1px dashed #404e67;
  border-bottom: 1px dashed #404e67;
  border-radius: 6px;
}

.slimScrollDiv {
  margin-left: -15px;
  margin-right: -15px;
}

#styleSelector .open {
  position: relative;
  top: -38px;
}
</style>
