<template>
    <div class="pcoded-inner-content">

        <div class="main-body">
            <div class="page-wrapper">

                <div class="page-body">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-header">
                                    <h5>
                                        <span>Add New Resident</span>
                                    </h5>
                                    <div class="card-header-right">
                                        <div class="page-header-breadcrumb">
                                            <ul class="breadcrumb-title">
                                                <li class="breadcrumb-item" style="float: left;">
                                                    <a href="index.html">
                                                        <i class="icofont icofont-home"></i>
                                                    </a>
                                                </li>
                                                <li class="breadcrumb-item" style="float: left;"><router-link
                                                        :to="{ name: 'VAResidents' }">Residents</router-link>
                                                </li>
                                                <li class="breadcrumb-item" style="float: left;">
                                                    <a href="#"> Add New Resident </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                                <div class="card-body">
                                    <form class="wizard-form wizard clearfix vertical" action="#">
                                        <div class="steps clearfix">
                                            <ul class="tablist" role="tablist">
                                                <li role="tab" class="first current" aria-disabled="true"><a
                                                        id="verticle-wizard-t-1" href="javascript:void(0);"
                                                        data-target="#verticle-wizard-p-1"
                                                        aria-controls="verticle-wizard-p-1"><span class="number"></span>
                                                        Basic Information </a></li>

                                                <li role="tab" class="disabled" aria-disabled="true"><a
                                                        id="verticle-wizard-t-3" href="javascript:void(0);"
                                                        data-target="#verticle-wizard-p-3"
                                                        aria-controls="verticle-wizard-p-3"><span class="number"></span>
                                                        Contact Details </a></li>
                                                <li role="tab" class="disabled last" aria-disabled="true"><a
                                                        id="verticle-wizard-t-4" href="javascript:void(0);"
                                                        data-target="#verticle-wizard-p-4"
                                                        aria-controls="verticle-wizard-p-4"><span class="number"></span>
                                                        Health
                                                        Details </a></li>
                                            </ul>
                                        </div>
                                        <div class="content clearfix">

                                            <fieldset id="verticle-wizard-p-1" role="tabpanel"
                                                aria-labelledby="verticle-wizard-h-0" class="body current"
                                                aria-hidden="true">
                                                <div class="row">
                                                    <image-input v-model="form.ry_picture" class="col-md-4"
                                                        :source="form.ry_picture" kStyle="height:20vh;" />

                                                    <div class="col-md-8">
                                                        <div class="row">
                                                            <text-input v-model="form.ry_name" class="col-md-6"
                                                                label="Name" />
                                                            <select-input v-model="form.ry_company_id"
                                                                class="col-md-6 my-select selectpicker" label="Company">
                                                                <option value="">Select...</option>
                                                                <option v-for="comp in companiesdata" :value="comp.c_id">{{
                                                                    comp.c_name }}</option>
                                                            </select-input>
                                                            <text-input v-model="form.ry_start_date" class="col-md-6"
                                                                label="Start Date" type="date" />
                                                            <text-input v-model="form.ry_end_date" class="col-md-6"
                                                                label="End Date" type="date" />
                                                            <select-input v-model="form.ry_administrator" class="col-md-6"
                                                                label="Administrator">
                                                                <option value="">Select...</option>
                                                                <option v-for="user in usersdata" :value="user.u_id">
                                                                    {{ user.u_name }}
                                                                </option>
                                                            </select-input>
                                                            <select-input v-model="form.ry_region_id" class="col-md-6"
                                                                label="Region (Optional)">
                                                                <option value="">Select...</option>
                                                                <option v-for="reg in regionsdata" :value="reg.r_id">
                                                                    {{ reg.r_name }}
                                                                </option>
                                                            </select-input>

                                                        </div>
                                                    </div>

                                                    <div class="col-md-12">
                                                        <hr />
                                                    </div>
                                                    <select-input v-model="form.ry_correspondinguser_id" class="col-md-4"
                                                        label="Corresponding User">
                                                        <option value="">None</option>
                                                        <option v-for="user in usersdata" :value="user.u_id">
                                                            {{ user.u_name }}
                                                        </option>
                                                    </select-input>
                                                    <select-input v-model="form.ry_residenttype_id" class="col-md-4"
                                                    label="Resident Type">
                                                    <option value="">None</option>
                                                    <option v-for="user in residenttypedata" :value="user.residenttype_id">
                                                        {{ user.residenttype_type }}
                                                    </option> 
                                                    </select-input>
                                                    <select-input v-model="form.ry_keyworker_id" class="col-md-4"
                                                        label="Key Worker">
                                                        <option value="">Select...</option>
                                                        <option v-for="user in usersdata" :value="user.u_id">
                                                            {{ user.u_name }}
                                                        </option>
                                                    </select-input>
                                                    <select-input v-model="form.ry_practicemanager_id" class="col-md-4"
                                                        label="Practice Manager">
                                                        <option value="">Select...</option>
                                                        <option v-for="user in usersdata" :value="user.u_id">
                                                            {{ user.u_name }}
                                                        </option>
                                                    </select-input>
                                                    <select-input v-model="form.ry_therapist_id" class="col-md-4"
                                                        label="Therapist">
                                                        <option value="">Select...</option>
                                                        <option v-for="user in usersdata" :value="user.u_id">
                                                            {{ user.u_name }}
                                                        </option>
                                                    </select-input>
                                                    <select-input v-model="form.ry_responsible_id" class="col-md-4"
                                                        label="Person with Responsibility">
                                                        <option value="">Select...</option>
                                                        <option v-for="user in usersdata" :value="user.u_id">
                                                            {{ user.u_name }}
                                                        </option>
                                                    </select-input>
                                                    <select-input v-model="form.ry_allowalluser" class="col-md-12"
                                                        label="Allow all users with access to this resident's home to be given access to this resident?">
                                                        <option value="0">No</option>
                                                        <option value="1">Yes</option>
                                                    </select-input>
                                                    <select-input v-model="form.ry_isemergency" class="col-md-4"
                                                        label="Is this an emergency placement?">
                                                        <option value="">N/A</option>
                                                        <option value="0">No</option>
                                                        <option value="1">Yes</option>
                                                    </select-input>
                                                    <select-input v-model="form.ry_gender_id" class="col-md-4"
                                                    label="Gender">
                                                    <option value="">Select...</option>
                                                    <option v-for="gender,k in genderdata" :value="k">
                                                        {{ gender }}
                                                    </option> 
                                                </select-input>
                                                <select-input v-model="form.ry_ethnicity" class="col-md-4"
                                                label="Ethnicity">
                                                <option value="">Select...</option>
                                                <option v-for="eth in ethinicitydata" :value="eth.ethinicity_id">
                                                    {{ eth.ethinicity_name }}
                                                </option>  
                                                    </select-input>
                                                    <text-input v-model="form.ry_room_no" class="col-md-4"
                                                        label="Room Number" />
                                                    <text-input v-model="form.ry_prefered_name" class="col-md-4"
                                                        label="Prefered Name" />
                                                    <text-input v-model="form.ry_insurance_no" class="col-md-4"
                                                        label="National Insurance Number" />
                                                    <text-input type="date" v-model="form.ry_dob" class="col-md-4"
                                                        label="Date of Birth" />
                                                    <select-input v-model="form.ry_legalstatus" class="col-md-4"
                                                        label="Legal Status">
                                                        <option value="0">Select...</option>
                                                        <option value="1">Section 17</option>
                                                    </select-input>
                                                    <text-input v-model="form.ry_relegion_id" class="col-md-4"
                                                        label="Religion" />
                                                </div>
                                            </fieldset>

                                            <fieldset id="verticle-wizard-p-3" style="display:none" role="tabpanel"
                                                aria-labelledby="verticle-wizard-h-0" class="body " aria-hidden="true">
                                                <div class="row">
                                                    <textarea-input v-model="form.meta.resident_personaldescription"
                                                        class="col-md-4" rows="3"
                                                        label="Personal Description"></textarea-input>
                                                    <textarea-input v-model="form.meta.resident_contactdetails"
                                                        class="col-md-4" rows="3" label="Contact Details"></textarea-input>
                                                    <textarea-input v-model="form.meta.resident_prevaddress"
                                                        class="col-md-4" rows="3" label="Previous Address"></textarea-input>
                                                    <div class="col-md-12 text-center">
                                                        <hr />
                                                        <h6><b>Local Authority</b></h6>
                                                    </div>
                                                    <select-input v-model="form.meta.resident_placingauthority"
                                                        class="col-md-12" label="Placing Authority"> 
                                                        <option value="">Select...</option>
                                                        <option v-for="eth in placingauthoritydata" :value="eth.placingauthority_id">
                                                            {{ eth.placingauthority_name }}
                                                        </option>   
                                                    </select-input>
                                                    <textarea-input v-model="form.meta.resident_billingdetails"
                                                        class="col-md-4" label="Billing Details"></textarea-input>
                                                    <textarea-input v-model="form.meta.resident_socialworker"
                                                        class="col-md-4" label="Social Worker"></textarea-input>
                                                    <textarea-input v-model="form.meta.resident_reviewingofficer"
                                                        class="col-md-4"
                                                        label="Independent Reviewing Officer"></textarea-input>
                                                    <div class="col-md-12 text-center">
                                                        <hr />
                                                        <h6><b>Education Details</b></h6>
                                                    </div>
                                                    <text-input v-model="form.meta.resident_universalpupilnumber"
                                                        class="col-md-4" label="Universal Pupil Number" />
                                                    <select-input v-model="form.meta.resident_schoolrunby_caregroup"
                                                        class="col-md-5" label="Attends school run by care group?">
                                                        <option value="">Select...</option>
                                                        <option value="0">No</option>
                                                        <option value="1">Yes</option>
                                                    </select-input>
                                                    <select-input v-model="form.meta.senstatement" class="col-md-3"
                                                        label="SEN Statement">
                                                        <option value="">Select...</option>
                                                        <option value="0">No</option>
                                                        <option value="1">Yes</option>
                                                    </select-input>

                                                    <textarea-input v-model="form.meta.resident_schoolattented"
                                                        class="col-md-12" rows="4"
                                                        label="School Attended or Educational Provision"></textarea-input>

                                                </div>
                                            </fieldset>
                                            <fieldset id="verticle-wizard-p-4" style="display:none" role="tabpanel"
                                                aria-labelledby="verticle-wizard-h-0" class="body " aria-hidden="true">
                                                <div class="row">
                                                    <text-input v-model="form.meta.resident_nhsnumber" class="col-md-6"
                                                        label="NHS Number" />

                                                    <select-input v-model="form.meta.resident_disabled" class="col-md-6"
                                                        label="Registered Disabled">
                                                        <option value="">Select...</option>
                                                        <option value="0">No</option>
                                                        <option value="1">Yes</option>
                                                    </select-input>
                                                    <textarea-input v-model="form.meta.resident_doctor_admission"
                                                        class="col-md-4" rows="3"
                                                        label="Doctor on Admission"></textarea-input>
                                                    <textarea-input v-model="form.meta.resident_doctor" class="col-md-4"
                                                        rows="3" label="Doctor"></textarea-input>
                                                    <textarea-input v-model="form.meta.resident_dentist" class="col-md-4"
                                                        rows="3" label="Dentist"></textarea-input>
                                                    <textarea-input v-model="form.meta.resident_optician" class="col-md-4"
                                                        rows="3" label="Optician"></textarea-input>
                                                    <textarea-input v-model="form.meta.resident_hospital" class="col-md-4"
                                                        rows="3" label="Hospital"></textarea-input>
                                                    <textarea-input v-model="form.meta.resident_medicalneeds"
                                                        class="col-md-4" rows="3" label="Medical Needs"></textarea-input>
                                                    <textarea-input v-model="form.meta.resident_alergies" class="col-md-4"
                                                        rows="3" label="Known Allergies"></textarea-input>
                                                    <textarea-input v-model="form.meta.resident_healthdetails"
                                                        class="col-md-8" rows="3"
                                                        label="Other Important Health Details"></textarea-input>


                                                    <select-input v-model="form.meta.resident_distinationtype"
                                                        class="col-md-12" label="Discharge Destination Type">
                                                        <option value="0">Select...</option>
                                                        <option value="1">Authority One</option>
                                                        <option value="2">Authority Two</option>
                                                    </select-input>
                                                    <textarea-input v-model="form.meta.resident_distinationtype_details"
                                                        class="col-md-8" label="Destination Type if Other"></textarea-input>
                                                    <text-input v-model="form.meta.home_postcode" class="col-md-4"
                                                        label="Postcode" />

                                                    <textarea-input v-model="form.meta.resident_discharge_address"
                                                        class="col-md-12" rows="4"
                                                        label="Discharge Destination Address"></textarea-input>

                                                </div>
                                            </fieldset>
                                        </div>
                                        <div class="actions clearfix">
                                            <ul role="menu" aria-label="Pagination">
                                                <li aria-disabled="true" class="wizard-prev-btn disabled">
                                                    <a href="javascript:void(0);" role="menuitem">Previous</a>
                                                </li>
                                                <li aria-hidden="false" aria-disabled="false" class="wizard-next-btn"><a
                                                        href="javascript:void(0);" role="menuitem">Next</a></li>
                                                <li aria-hidden="true" style="display: none;" class="wizard-finish"><a
                                                        href="javascript:void(0);" role="menuitem"
                                                        @click="addResident();">Finish</a></li>
                                            </ul>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
    <div class="modal fade" id="add_people_associated">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Add New</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        @click="hidemodal('#add_people_associated');">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <text-input class="col-md-12" v-model="person_associated_name" label="Name" />
                        <text-input class="col-md-4" v-model="person_associated_gender" label="Gender" />
                        <text-input class="col-md-4" v-model="person_associated_relation" label="Relation" />
                        <select-input v-model="person_associated_allowed_contact" class="col-md-4" label="Allowed Contact">
                            <option value="">Select </option>
                            <option value="0">No</option>
                            <option value="1">Yes</option>
                        </select-input>
                        <text-input class="col-md-12" v-model="person_associated_nocontact_reason"
                            label="Reason for No Contact" />
                        <text-input class="col-md-12" v-model="person_associated_religion" label="Religion" />
                        <text-input class="col-md-12" v-model="person_associated_address" label="Address" />
                        <text-input class="col-md-12" v-model="person_associated_comment" label="Comment" />

                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default waves-effect " data-dismiss="modal"
                        @click="hidemodal('#add_people_associated');">Close</button>
                    <button type="button" class="btn btn-primary btn-round waves-effect waves-light "
                        @click="addPeopleAssociated();">Save
                        Information</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import SelectInput from '../../../shared/SelectBox.vue';
import TextInput from '../../../shared/TextInput'
import TextareaInput from '../../../shared/TextareaInput'
import ImageInput from '../../../shared/ImageInput.vue'
import { ref, reactive, onMounted, onBeforeMount, provide } from 'vue';
import { useAuthStore } from '@/store/auth.js'
import { functions } from '../../../../common/appFunctions.js';
import factory from '@/common/factory.js'
import { useRouter } from 'vue-router';
const router = useRouter();
const authStore = useAuthStore();
var resident_in = ref('');
var admission_date = ref('');
var leaving_date = ref('');
var person_associated_name = ref('');
var person_associated_gender = ref('');
var person_associated_relation = ref('');
var person_associated_allowed_contact = ref('');
var person_associated_nocontact_reason = ref('');
var person_associated_address = ref('');
var person_associated_religion = ref('');
var person_associated_comment = ref('');

// ****** Dropdowns data ********************
var companiesdata = ref([]);
var usersdata = ref([]);
var regionsdata = ref([]);
var genderdata = ref([]);
var residenttypedata = ref([]);
var placingauthoritydata = ref([]);
var ethinicitydata = ref([]);
// ****** Dropdowns data ********************

const form = reactive({
    ry_picture: 'https://demo.dashboardpack.com/adminty-html/files/assets/images/gallery-grid/1.png',
    ry_name: '',
    ry_company_id: '',
    ry_incharge_id: 0,
    ry_start_date: '',
    ry_end_date: '',
    ry_administrator: '',
    ry_region_id: '',
    ry_correspondinguser_id: '',
    ry_residenttype_id: '',
    ry_keyworker_id: '',
    ry_practicemanager_id: '',
    ry_therapist_id: '',
    ry_responsible_id: '',
    ry_allowalluser: '',
    ry_isemergency: '',
    ry_gender_id: '',
    ry_ethnicity: '',
    ry_room_no: '',
    ry_prefered_name: '',
    ry_insurance_no: '',
    ry_dob: '',
    ry_legalstatus: '',
    ry_relegion_id: '',
    meta: {
        resident_personaldescription: '',
        resident_contactdetails: '',
        resident_prevaddress: '',
        resident_placingauthority: '',
        resident_billingdetails: '',
        resident_socialworker: '',
        resident_reviewingofficer: '',
        resident_universalpupilnumber: '',
        resident_schoolrunby_caregroup: '',
        senstatement: '',
        resident_schoolattented: '',
        resident_nhsnumber: '',
        resident_disabled: '',
        resident_doctor_admission: '',
        resident_doctor: '',
        resident_dentist: '',
        resident_optician: '',
        resident_hospital: '',
        resident_medicalneeds: '',
        resident_alergies: '',
        resident_healthdetails: '',
        resident_distinationtype: '',
        resident_distinationtype_details: '',
        home_postcode: '',
        resident_discharge_address: '',
    }
});

function getToken() {
    const token = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authStore.token,
        },
    };
    return token;
}
provide('getToken', getToken());
async function addResident() {
    functions.initSoftLoader();
    const Resident = new factory(getToken(), 'residents', form);
    await Resident.postForm().then((response) => {
        if (response.data.status == "success") {
            functions.kSwal('Done', 'Resident Added Successfully.');
            router.push({ path: 'residents' })
        } else {
            console.log(response.data.message);
        }
    });
};
onMounted(async () => {

    const Resident = new factory(getToken(), 'getallapis', { index: ['companies', 'users', 'regions','gender','residenttype','placingauthority','ethinicity'] });
    await Resident.postForm().then((response) => {
        if (response.data.status == "success") {
            companiesdata.value = response.data.data.companies;
            usersdata.value = response.data.data.users;
            regionsdata.value = response.data.data.regions;
            genderdata.value = response.data.data.gender;
            residenttypedata.value = response.data.data.residenttype;
            placingauthoritydata.value = response.data.data.placingauthority;
            ethinicitydata.value = response.data.data.ethinicity;
        }
    });
    functions.callOnReady();
});
function showmodal(id) {
    $(id).modal('show');
}
function hidemodal(id) {
    $(id).modal('hide');
}


</script>
<style scoped>
#wizard2 fieldset {
    display: block;
    position: relative;
}

.wizard>.current {
    display: block;
}

.wizard>.content>.body {
    position: relative;

}
</style>