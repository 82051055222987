<template>
    <div class="main-body">
        <div class="page-wrapper">

            <!-- Page body start -->
            <div class="page-body">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-header">
                                <h5>
                                    <span>Companies</span>
                                    <button type="button"
                                        class="btn btn-primary addnewbtn btn-round waves-effect f-right "
                                        @click="addNew()">Add
                                        New</button>
                                </h5>
                                <div class="card-header-right">
                                    <div class="page-header-breadcrumb">
                                        <ul class="breadcrumb-title">
                                            <li class="breadcrumb-item" style="float: left;">
                                                <a href="index.html">
                                                    <i class="icofont icofont-home"></i>
                                                </a>
                                            </li>
                                            <li class="breadcrumb-item" style="float: left;"><router-link
                                                    :to="{ name: 'VACompanies' }">Companies</router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <div class="card-body">
                                <table-view v-if="displaytbl" :data="responsefromapi"
                                    @viewbuttonclicked="editbtnclicked" @activeunactive="activeunactive" @deletebuttonclicked="deletebuttonclicked" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script setup>

import TableView from '../../../shared/TableView.vue';
import { functions } from '../../../../common/appFunctions.js';
import { ref, reactive, onMounted } from 'vue';
import { useAuthStore } from '@/store/auth.js'
import factory from '@/common/factory.js'
import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const authStore = useAuthStore();

const form = reactive({
    vendor_id: authStore.user.vendor_id,
    name: '',
    company_type: 0,
    company_owner: 0,
    email: '',
    phone_primary: '',
    phone_secondary: '',
    post_code: '',
    city: '',
    country: '',
    address_primary: '',
    address_secondary: '',
    time_zone: '',
    longitude: '',
    latitude: '',
});
var responsefromapi = ref();
var displaytbl = ref(false);
function getToken() {
    const token = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authStore.token,
        },
    };
    return token;
}
function deletebuttonclicked(id) {
  var ask = functions.askSwal();
  ask.then(async (result) => {
    if (result.isConfirmed) {
      const companies = new factory(getToken(), "companies/" + id);
      await companies.deleteHttp().then((response) => {
        if (response.data.status == "success") {
          location.reload();
        } else {
          console.log(response.data.message);
        }
      });
    }
  });
}   
async function activeunactive(id,status){
    var upactiveunactive = {
        c_id:id,
        c_isactive:!status
    }
    var updatestatus = new factory(getToken(), 'companies', upactiveunactive);
    await updatestatus.putForm().then((response) => {
        swal.fire('Done!', 'Status Changed Successfully.','success');
    });
}
function editbtnclicked(id) {
    router.push({
        name: 'VAEditCompany',
        query: {
            id,
        },
    })
}
function addNew(id) {
    router.push({
        name: 'VAAddCompany'
    })
}
onMounted(async () => {
    functions.initLoader();
    var token = getToken();
    const Company = new factory(getToken(), 'companies/vendor/' + authStore.user.u_vendor_id);
    const dt = await Company.getHttp().then(response => {
        functions.destroyLoader();
        var tabledata = {
            "conditions": {
                'update': true,
                'trash': true,
                'active': true,
                'primarykey': 'c_id',
                'hiddendata': ['c_id'],
                'active_index': 'c_isactive',
            },
            "columns": [
                'Name',
                'Company Owner',
                'Company Type',
                'Active',
                '',
            ],
            "data_order": ['c_name', 'u_name', 'ct_name'],
            "data": response.data.data
        };
        displaytbl.value = true
        responsefromapi.value = tabledata;
        console.log(response.data.data);
    });
    functions.callOnReady();
})
function showmodal(id) {
    $(id).modal('show');
}
function hidemodal(id) {
    $(id).modal('hide');
}
</script> 
