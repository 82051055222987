<template>
    <div class="main-body">
        <div class="page-wrapper">
            <!-- Page-header start -->
            <div class="page-header">
                <div class="row align-items-end">
                    <div class="col-lg-10">
                        <div class="page-header-title">
                            <div class="d-inline">
                                <h4>Users</h4>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="page-header-breadcrumb">
                            <ul class="breadcrumb-title">
                                <li class="breadcrumb-item" style="float: left;">
                                    <a href="index.html">
                                        <i class="icofont icofont-home"></i>
                                    </a>
                                </li>
                                <li class="breadcrumb-item" style="float: left;"><router-link
                                        :to="{ name: 'SAUsers' }">Users</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
            <!-- Page-header end -->
            <!-- Page body start -->
            <div class="page-body">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-block">
                                <button type="button" class="btn btn-primary btn-round waves-effect f-right"
                                    @click="showmodal('#addnew_plan')">Add New</button>
                            </div>
                            <div class="card-body">
                                <tableView :data="tabledata" @viewbuttonclicked="editbtnclicked" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Page body end -->
        </div>
    </div>
    <div class="modal fade" id="addnew_plan">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Add New</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        @click="hidemodal('#addnew_plan');">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-4">

                            <!-- <image-input class="col-md-12" v-model="profilepic" :source="profilepic"
                                kStyle="height:20vh;" label="User Image"/> -->
                                <file-input   class="pb-8 pr-6 w-full lg:w-1/2" type="file" accept="image/*" label="Photo" />
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <text-input class="col-md-12" label="Full Name" />
                                <text-input class="col-md-6" label="Email" />
                                <text-input class="col-md-6" label="Phone" />
                                <text-input class="col-md-6" label="New Password" />
                                <text-input class="col-md-6" label="Re-Enter Password" />
                            </div>
                        </div>
                        <text-input class="col-md-12" label="Short Description" />
                        <textarea-input class="col-md-12" rows="5" label="Long Description" />
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default waves-effect " data-dismiss="modal"
                        @click="hidemodal('#addnew_plan');">Close</button>
                    <button type="button" class="btn btn-primary btn-round waves-effect waves-light ">Save</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="update_plan">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Update Information</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        @click="hidemodal('#update_plan');">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-4">
                            <file-input v-model="form.profilepic"  class="pb-8 pr-6 w-full lg:w-1/2" type="file" accept="image/*" label="Photo" />
                            <!-- <image-input class="col-md-12" v-model="form.profilepic" :source="form.profilepic" label="User Image"
                                kStyle="height:20vh;" /> -->
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <text-input class="col-md-12" v-model="form.fullname" label="Full Name" />
                                <text-input class="col-md-6" v-model="form.email" label="Email" />
                                <text-input class="col-md-6" v-model="form.phone" label="Phone" />
                            </div>
                        </div>
                        <text-input class="col-md-12" label="Short Description" v-model="form.shortdescription" />
                        <textarea-input class="col-md-12" rows="5" label="Long Description" v-model="form.longdescription" />
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default waves-effect " data-dismiss="modal"
                        @click="hidemodal('#update_plan');">Close</button>
                    <button type="button" class="btn btn-primary btn-round waves-effect waves-light ">Save</button>
                </div>
            </div>
        </div>
    </div>

</template>

<script setup>
import tableView from '../../../shared/table.vue';
import TextInput from '../../../shared/TextInput.vue';
import TextareaInput from '../../../shared/TextareaInput.vue';
//import FileInput from '../../../shared/FileInput.vue';
import ImageInput from '../../../shared/ImageInput.vue';
import { reactive } from 'vue';

const form = reactive({
            user_id: '',
            profilepic: 'https://demo.dashboardpack.com/adminty-html/files/assets/images/social/profile.jpg',
            fullname: '',
            email: '',
            phone: '',
            newpassowrd: '',
            reenterpassword: '',
            shortdescription: '',
            longdescription: '',
            tabledata: {},
            singleuser: {},
            responsefromapi: []

    });
function editbtnclicked(pkey) {
            //alert("hello");
            //
            form.singleuser = responsefromapi.data.find((item) => (item.id == pkey)); // i took data from exisitng data i have on page load, you may recall api for single record here
            console.log(form.singleuser);

            form.profilepic = form.singleuser.profilepic;

            form.fullname = form.singleuser.fullname;
            form.email = form.singleuser.email;
            form.phone = form.singleuser.phone;
            form.shortdescription = form.singleuser.shortdescription;
            form.longdescription = form.singleuser.longdescription;
            form.user_id = pkey;
            $('#update_plan').modal('show');
        }
        function showmodal(id) {
            $(id).modal('show');
        }
        function hidemodal(obj) {
            $(obj).modal('hide');

        }
const responsefromapi = {
            "status": "success",
            "message": "this is message",
            "data": [
                { 'id': 1, 'profilepic': 'https://demo.dashboardpack.com/adminty-html/files/assets/images/social/profile.jpg', 'fullname': 'Super Admin', 'email': 'abc@gmail.com', 'phone': '(123) 1231233', 'shortdescription': 'short desc', 'longdescription': 'long desc', 'status': true },
                { 'id': 2, 'profilepic': 'https://demo.dashboardpack.com/adminty-html/files/assets/images/social/profile.jpg', 'fullname': 'Admin Two', 'email': 'abc123@gmail.com', 'phone': '(123) 1231233', 'shortdescription': '', 'longdescription': '', 'status': true },
                { 'id': 3, 'profilepic': 'https://demo.dashboardpack.com/adminty-html/files/assets/images/social/profile.jpg', 'fullname': 'Admin three', 'email': 'example@gmail.com', 'phone': '(123) 1231233', 'shortdescription': '', 'longdescription': '', 'status': true },
            ]
        };

        const tabledata = {
            "conditions": {
                'update': true,
                'trash': true,
                'active': true,
                'active_index': 'status',
                'primarykey': 'id',
                'hiddendata': ['id', 'shortdescription', 'longdescription', 'profilepic'],
            },
            "columns": [
                'Name',
                'Email',
                'Mobile',
                'Active',
                '',
            ],
            "data": responsefromapi.data
        }
</script>
