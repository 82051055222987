<template>
  <div class="main-body">
    <div class="page-wrapper">
      <!-- Page body start -->
      <div class="page-body">
        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-header">
                <h5 style="width:50%;"> 
                  <select  @change="getReports()"
                        v-model="specificreportlist"
                        class="form-control"
                        label="Report" style="width: 233px;float: left;"
                        >
                        <option value="">Select...</option>
                        <option v-for="mod in authStore.reports" :value="mod.form_id">
                            {{ mod.form_title }}
                        </option>
                    </select>
                  <router-link 
                    :to="{
                      name: 'VAAddReport',
                      query: { id: specificreportlist },
                    }"
                    class="btn btn-primary addnewbtn btn-round waves-effect "
                  >
                    Create {{ authStore.reports.find((item) => item.form_id == specificreportlist).form_title }}
                  </router-link>
                </h5>
                <div class="card-header-right">
                  <div class="page-header-breadcrumb">
                    <ul class="breadcrumb-title">
                      <li class="breadcrumb-item" style="float: left">
                        <a href="index.html">
                          <i class="icofont icofont-home"></i>
                        </a>
                      </li>
                      <li class="breadcrumb-item" style="float: left">
                        <router-link :to="{ name: 'VAForms' }"
                          >Forms</router-link
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <table-view
                  v-if="displaytbl"
                  :data="responsefromapi"
                  @viewbuttonclicked="editbtnclicked"
                  @deletebuttonclicked="deletebuttonclicked"
                  @extrabuttonclicked="extrabuttonclicked"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="add_form">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Add New</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="hidemodal('#add_form')"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <text-input
              class="col-md-12"
              v-model="form.form_title"
              label="Form Title"
            />
            <select-input
              v-model="form.form_module_id"
              class="col-md-6"
              label="Module"
            >
              <option value="">Select...</option>
              <option v-for="mod in modulesdata" :value="mod.m_id">
                {{ mod.m_name }}
              </option>
            </select-input>
            <select-input
              v-model="form.form_company_id"
              class="col-md-6"
              label="Company"
            >
              <option value="">Select...</option>
              <option v-for="comp in companiesdata" :value="comp.c_id">
                {{ comp.c_name }}
              </option>
            </select-input>

            <textarea-input
              rows="5"
              class="col-md-12"
              v-model="form.form_description"
              label="Short Description"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default waves-effect"
            data-dismiss="modal"
            @click="hidemodal('#add_form')"
          >
            Close
          </button>
          <!-- <button type="button" class="btn btn-primary btn-round waves-effect waves-light "
                            @click="addForm();">Save
                            Information</button> -->
          <button
            type="button"
            class="btn funload btn-primary btn-round waves-effect waves-light"
            @click="addForm()"
          >
            Save Information
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import SelectInput from "../../../shared/SelectBox.vue";
import TextInput from "../../../shared/TextInput.vue";
import TextareaInput from "../../../shared/TextareaInput.vue";
import TableView from "../../../shared/TableView.vue";
import { functions } from "../../../../common/appFunctions.js";
import { ref, reactive, onMounted } from "vue";
import { useAuthStore } from "@/store/auth.js";
import factory from "@/common/factory.js";
import { useRouter, useRoute } from "vue-router";
const authStore = useAuthStore();
const router = useRouter();
const route = useRoute();
var specificreportlist = ref(((authStore.selectedreport != 0)?authStore.selectedreport:((authStore.reports[0])?authStore.reports[0].form_id:0)));
const form = reactive({
  form_title: "",
  form_module_id: "",
  form_company_id: "",
  form_description: "",
});
function extrabuttonclicked(condition, key) {
  if (condition == "print_btn") {
    router.push({
      name: "VAPrintReport",
      query: {
        id:key,
      },
    });
  }
}

var reportinfo = ref(
  authStore.reports.find((item) => item.form_id == route.query.id)
);

var companiesdata = ref([]);
var modulesdata = ref([]);

var responsefromapi = ref();
var displaytbl = ref(false);
function getToken() {
  const token = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authStore.token,
    },
  };
  return token;
}
async function addForm() {
  const Form = new factory(getToken(), "forms", form);
  await Form.postForm().then((response) => {
    if (response.data.status == "success") {
      functions.destroyBtnLoader();
      location.reload();
    } else {
      console.log(response.data.message);
    }
  });
}
async function deletebuttonclicked(id) {
  const Form = new factory(getToken(), "companies/" + id, form);
  await Form.deleteHttp().then((response) => {
    if (response.data.status == "success") {
      location.reload();
    } else {
      console.log(response.data.message);
    }
  });
}
function editbtnclicked(id) {
  router.push({
    name: "VAUpdateReport",
    query: {
      id,
    },
  });
}
function addNew() {
  showmodal("#add_form");
}
async function getReports(){
  functions.initSoftLoader();
  authStore.selectedreport = specificreportlist.value;
const Form = new factory(getToken(), "formsbyreport/" + specificreportlist.value);
  await Form.getHttp().then((response) => {
    swal.close();
    functions.destroyLoader();
    if (response.status == 200 || response.status == 400) {
      var data = response.status == 400 ? [] : response.data.data;
      var tabledata = {
        conditions: {
          update: true,
          trash: true,
          active: true,
          primarykey: "form_id",
          hiddendata: ["form_id"],
          active_index: "form_isactive",
          extra_btns: [
            {
              cssclass: "btn-dark",
              icon: "fa fa-print",
              returncondition: "print_btn",
            }, 
          ],
        },
        columns: ["Name", "Company",  "Active", ""],
        data_order: ["form_title", "company_name"],
        data: data,
      };
      displaytbl.value = true;
      responsefromapi.value = tabledata;
    }
  });
}

onMounted(async () => {
  const drodowndata = new factory(getToken(), "getallapis", {
    index: ["companies", "modules"],
  });
  await drodowndata.postForm().then((response) => {
    if (response.data.status == "success") {
      companiesdata.value = response.data.data.companies;
      modulesdata.value = response.data.data.modules;
    }
  });

  functions.initLoader();
  getReports(); 
  
  functions.callOnReady();
});
function showmodal(id) {
  $(id).modal("show");
}
function hidemodal(id) {
  $(id).modal("hide");
}
$(".pcoded-navbar").show();
$(".pcoded-content").removeAttr("style");
</script>
