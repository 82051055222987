<template>
    <div class="pcoded-inner-content">

        <div class="main-body">
            <div class="page-wrapper">
                <div class="page-header">
                    <div class="row align-items-end">
                        <div class="col-lg-8">
                            <div class="page-header-title">
                                <div class="d-inline">
                                    <h4>AccountTypes</h4>
                                    <span>Add</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="page-header-breadcrumb">
                                <ul class="breadcrumb-title">
                                    <li class="breadcrumb-item" style="float: left;">
                                        <a href="../index.html"> <i class="feather icon-home"></i> </a>
                                    </li>
                                   
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="page-body">
                    <div class="row">
                        <div class="col-sm-12">

                            <div class="card">
                                
                                <form >
                                    <div class="card-body">
                        <div class="row">
                            <text-input v-model="form.name" class="col-md-12" label="Company name" />
                            <select-input v-model="form.company_type" class="col-md-6" label="Company Type">
                                <option value="0">Select Type</option>
                                <option value="1">Private</option>
                                <option value="2">Hybrid</option>
                                <option value="3">Limited</option>
                            </select-input>
                            <select-input v-model="form.company_owner" class="col-md-6" label="Company Owner">
                                <option value="0">Select Owner
                                </option>
                                <option value="1">User 1</option>
                                <option value="2">User 2</option>
                                <option value="3">User 3</option>
                            </select-input>
                            <text-input v-model="form.email" class="col-md-12" label="Email" />
                            <text-input v-model="form.phone_primary" class="col-md-6" label="Primary Phone" />
                            <text-input v-model="form.phone_secondary" class="col-md-6" label="Secondary Phone" />
                            <text-input v-model="form.address_primary" class="col-md-6" label="Address Primary" />
                            <text-input v-model="form.address_secondary" class="col-md-6" label="Address Secondary" />
                            <text-input v-model="form.city" class="col-md-6" label="City" />
                            <text-input v-model="form.post_code" class="col-md-6" label="Post Code" />
                            <text-input v-model="form.country" class="col-md-6" label="Country" />
                            <text-input v-model="form.time_zone" class="col-md-6" label="Time Zone" />
                            <text-input v-model="form.longitude" class="col-md-6" label="longitude" />
                            <text-input v-model="form.latitude" class="col-md-6" label="latitude" />
                        </div>
                    </div>
                        <div class="card-footer">
                            <button type="button" class="btn btn-default waves-effect ">Cancel</button>
                            <button type="button" class="btn btn-primary btn-round waves-effect waves-light "
                                @click="addCompany()">Save</button>
                        </div>
                    </form>
                            
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>

</template>
<script setup>
import { functions } from '../../../../common/appFunctions.js';
import SelectInput from '../../../shared/SelectInput.vue';
import TextInput from '../../../shared/TextInput'
import { reactive } from 'vue';
import { transferData } from '@/store/transferdata.js'
import { useAuthStore } from '@/store/auth.js'
import factory from '@/common/factory.js'
import { useRouter, useRoute  } from 'vue-router';
const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();

const form = reactive({
    vendor_id: authStore.user.vendor_id,
    name: '',
    company_type: '',
    company_owner: 0,//dataStore.data.company_owner,
    isactive: '',
    email: '',
    phone_primary: '',
    phone_secondary: '',
    post_code:'',
    city: '',
    country: '',
    address_primary: '',
    address_secondary: '',
    time_zone: '',
    longitude: '',
    latitude: ''
});

function getToken() {
    const token = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authStore.token,
        },
    };
    return token;
}

async function addCompany() {
    const Company = new factory(getToken(), 'companies', form);
    await Company.postForm().then((response) => {
        console.log(response);
            if (response.data.status == "success") {
                router.push({ path: 'companies' })
            } else {
                console.log(response.data.message);
            }});   
};
</script>