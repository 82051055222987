<template>
  <nav
    class="pcoded-navbar"
    navbar-theme="themelight1"
    active-item-theme="theme1"
    sub-item-theme="theme2"
    active-item-style="style0"
    pcoded-navbar-position="fixed"
  >
    <div class="pcoded-inner-navbar main-menu" style="height: 90vh">
      <ul class="pcoded-item pcoded-left-item">
        <li class="">
          <router-link :to="{ name: 'VADashboard' }">
            <span class="pcoded-micon"
              ><i class="feather icon-aperture rotate-refresh"></i
            ></span>
            <span class="pcoded-mtext">Dashboard</span>
          </router-link>
        </li>
        <li class="">
          <router-link :to="{ name: 'VAReports' }">
            <span class="pcoded-micon"
              ><i class="feather icon-clipboard"></i
            ></span>
            <span class="pcoded-mtext">Reports</span>
          </router-link>
        </li>
        <!-- <li class="pcoded-hasmenu" dropdown-icon="style1" subitem-icon="style1">
          <a href="javascript:void(0)">
            <span class="pcoded-micon"
              ><i class="feather icon-clipboard"></i
            ></span>
            <span class="pcoded-mtext">Reports</span>
          </a>
          <ul class="pcoded-submenu">  
            <li class="" v-if="Object.keys(authStore.reports).length == 0">
              <router-link :to="{ name: 'VAForms' }">
                <span class="pcoded-micon"
                  ><i class="feather icon-edit-1"></i
                ></span>
                <span class="pcoded-mtext">Add Report Template</span>
              </router-link>
            </li>
            <li class="" v-for="report in authStore.reports">
              <router-link
                :to="{
                  name: 'VAReports',
                  query:{ 
                    id:report.form_id,
                  },
                }"
              >
                <span class="pcoded-mtext">{{ report.form_title }}</span>
              </router-link>
            </li>
          </ul>
        </li> -->
        <li class="">
          <router-link :to="{ name: 'VAForms' }">
            <span class="pcoded-micon"
              ><i class="feather icon-edit-1"></i
            ></span>
            <span class="pcoded-mtext">Forms</span>
          </router-link>
        </li>

        <li class="pcoded-hasmenu" dropdown-icon="style1" subitem-icon="style1">
          <a href="javascript:void(0)">
            <span class="pcoded-micon"
              ><i class="icofont icofont-calendar"></i
            ></span>
            <span class="pcoded-mtext">Attendance & Rota</span>
          </a>
          <ul class="pcoded-submenu">
            <li class="">
              <router-link :to="{ name: 'VAAttendance' }">
                <span class="pcoded-mtext">Attendance</span>
              </router-link>
            </li>
            <li class="">
              <router-link :to="{ name: 'VARota' }">
                <span class="pcoded-mtext">ROTA</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li class="pcoded-hasmenu" dropdown-icon="style1" subitem-icon="style1">
          <a href="javascript:void(0)">
            <span class="pcoded-micon"
              ><i class="icofont icofont-chart-line-alt"></i
            ></span>
            <span class="pcoded-mtext">Budget & Forecasting</span>
          </a>
          <ul class="pcoded-submenu">
            <li class="">
              <router-link :to="{ name: 'VAForcasting' }">
                <span class="pcoded-mtext">Labour Forecasting</span>
              </router-link>
            </li>
            <li class="">
              <router-link :to="{ name: 'VABugetCap' }">
                <span class="pcoded-mtext">Budget Caps</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li class="">
          <router-link :to="{ name: 'VAResidents' }">
            <span class="pcoded-micon"><i class="feather icon-users"></i></span>
            <span class="pcoded-mtext">Residents</span>
          </router-link>
        </li>
        <li class="">
          <router-link :to="{ name: 'VAEmployee' }">
            <span class="pcoded-micon"
              ><i class="icofont icofont-business-man"></i
            ></span>
            <span class="pcoded-mtext">Employee</span>
          </router-link>
        </li>
        <li class="">
          <router-link :to="{ name: 'VAVehicles' }">
            <span class="pcoded-micon"
              ><i class="zmdi zmdi-directions-car"></i
            ></span>
            <span class="pcoded-mtext">Vehicles</span>
          </router-link>
        </li>
        <li class="">
          <router-link :to="{ name: 'VAHomes' }">
            <span class="pcoded-micon"><i class="feather icon-home"></i></span>
            <span class="pcoded-mtext">Homes</span>
          </router-link>
        </li>
        <li class="">
          <router-link :to="{ name: 'VARegions' }">
            <span class="pcoded-micon"><i class="feather icon-map"></i></span>
            <span class="pcoded-mtext">Regions</span>
          </router-link>
        </li>
        <li class="">
          <router-link :to="{ name: 'VARoles' }">
            <span class="pcoded-micon"><i class="icofont icofont-queen-crown"></i></span>
            <span class="pcoded-mtext">Roles</span>
          </router-link>
        </li>

        <li class="">
          <router-link :to="{ name: 'VACompanies' }">
            <span class="pcoded-micon"
              ><i class="icofont icofont-company"></i
            ></span>
            <span class="pcoded-mtext">Companies</span>
          </router-link>
        </li>
        <li class="">
          <router-link :to="{ name: 'VAUsers' }">
            <span class="pcoded-micon"><i class="zmdi zmdi-accounts"></i></span>
            <span class="pcoded-mtext">Users</span>
          </router-link>
        </li>
        <li class="">
          <router-link :to="{ name: 'VAPermissions' }">
            <span class="pcoded-micon"><i class="icofont icofont-ui-password"></i></span>
            <span class="pcoded-mtext">Access Permission</span>
          </router-link>
        </li>
        <li class="">
          <router-link :to="{ name: 'VASupportTickets' }">
            <span class="pcoded-micon"
              ><i class="zmdi zmdi-ticket-star"></i
            ></span>
            <span class="pcoded-mtext">Support Tickets</span>
          </router-link>
        </li>
        <li class="">
          <router-link :to="{ name: 'VATasks' }">
            <span class="pcoded-micon"
              ><i class="zmdi zmdi-playlist-plus"></i
            ></span>
            <span class="pcoded-mtext">Tasks</span>
          </router-link>
        </li>
        <li class="">
          <router-link :to="{ name: 'VACompanyManuals' }">
            <span class="pcoded-micon"
              ><i class="zmdi zmdi-format-list-bulleted"></i
            ></span>
            <span class="pcoded-mtext">Company Manuals</span>
          </router-link>
        </li>
        <li class="">
          <router-link :to="{ name: 'VASettings' }">
            <span class="pcoded-micon"><i class="fa fa-gears"></i></span>
            <span class="pcoded-mtext">Settings</span>
          </router-link>
        </li>
        <li class="pcoded-hasmenu" dropdown-icon="style1" subitem-icon="style1">
          <a href="javascript:void(0)">
            <span class="pcoded-micon"
              ><i class="feather icon-airplay"></i
            ></span>
            <span class="pcoded-mtext">Website Builder</span>
          </a>
          <ul class="pcoded-submenu">
            <li class="">
              <router-link :to="{ name: 'VAPages' }">
                <span class="pcoded-mtext">Pages</span>
              </router-link>
            </li>
            <li class="">
              <router-link :to="{ name: 'VAMenus' }">
                <span class="pcoded-mtext">Menus</span>
              </router-link>
            </li>
            <li class="">
              <router-link :to="{ name: 'VAHeaderPage' }">
                <span class="pcoded-mtext">Header Page</span>
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</template>
<!-- <script>
export default {
    name: "Sidebar",
    data() {
        return {
            name: null,
        }
    },
}
</script> -->
<script setup>
import { useAuthStore } from "@/store/auth.js";
const authStore = useAuthStore();
</script>
