<template>
    <div class="pcoded-inner-content">

        <div class="main-body">
            <div class="page-wrapper">

                <div class="page-body">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-header">
                                    <h5>
                                        <span>Add New Employee</span>
                                    </h5>
                                    <div class="card-header-right">
                                        <div class="page-header-breadcrumb">
                                            <ul class="breadcrumb-title">
                                                <li class="breadcrumb-item" style="float: left;">
                                                    <a href="#"> <i class="feather icon-home"></i> </a>
                                                </li>
                                                <li class="breadcrumb-item" style="float: left;"><router-link
                                                        :to="{ name: 'VAEmployee' }">Employee</router-link>
                                                </li>
                                                <li class="breadcrumb-item" style="float: left;">
                                                    <a href="#"> Add New Employee </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                                <div class="card-body">
                                    <form class="wizard-form wizard clearfix vertical" action="#">
                                        <div class="steps clearfix">
                                            <ul class="tablist" role="tablist">
                                                <li role="tab" class="first current" aria-disabled="true"><a
                                                        id="verticle-wizard-t-1" href="javascript:void(0);"
                                                        data-target="#verticle-wizard-p-1"
                                                        aria-controls="verticle-wizard-p-1"><span class="number"></span>
                                                        Basic Information </a></li>
                                                <li role="tab" class="disabled" aria-disabled="false" aria-selected="true">
                                                    <a id="verticle-wizard-t-0" href="javascript:void(0);"
                                                        data-target="#verticle-wizard-p-0"
                                                        aria-controls="verticle-wizard-p-0"><span
                                                            class="current-info audible">current step: </span><span
                                                            class="number"></span> Detailed Information </a>
                                                </li>
                                                <li role="tab" class="disabled" aria-disabled="true"><a
                                                        id="verticle-wizard-t-2" href="javascript:void(0);"
                                                        data-target="#verticle-wizard-p-2"
                                                        aria-controls="verticle-wizard-p-2"><span class="number"></span>
                                                        Previous experience in children's services </a></li>

                                            </ul>
                                        </div>
                                        <div class="content clearfix">

                                            <fieldset id="verticle-wizard-p-1" role="tabpanel"
                                                aria-labelledby="verticle-wizard-h-0" class="body current"
                                                aria-hidden="true">
                                                <div class="row">
                                                    <image-input v-model="form.employee_picture" class="col-md-4"
                                                        :source="form.employee_picture" kStyle="height:20vh;" />

                                                    <div class="col-md-8">
                                                        <div class="row">
                                                            <text-input v-model="form.employee_name" class="col-md-6"
                                                                label="Name" />
                                                            <select-input v-model="form.employee_company_id"
                                                                class="col-md-6 my-select selectpicker" label="Company">
                                                                <option value="">Select...</option>
                                                                <option v-for="comp in companiesdata" :value="comp.c_id">{{
                                                                    comp.c_name }}</option>
                                                            </select-input>
                                                            <text-input type="date" v-model="form.employee_start_date"
                                                                class="col-md-6" label="Start Date" />
                                                            <text-input type="date" v-model="form.employee_end_date"
                                                                class="col-md-6" label="End Date" />
                                                            <select-input v-model="form.employee_administrator"
                                                                class="col-md-6" label="Administrator">
                                                                <option value="">Select...</option>
                                                                <option v-for="user in usersdata" :value="user.u_id">
                                                                    {{ user.u_name }}
                                                                </option>
                                                            </select-input>
                                                            <select-input v-model="form.employee_region" class="col-md-6"
                                                                label="Region (Optional)">
                                                                <option value="">Select...</option>
                                                                <option v-for="reg in regionsdata" :value="reg.r_id">
                                                                    {{ reg.r_name }}
                                                                </option>
                                                            </select-input>

                                                        </div>
                                                    </div>

                                                    <div class="col-md-12">
                                                        <hr />
                                                    </div>
                                                    <select-input v-model="form.employee_correspondinguser" class="col-md-4"
                                                        label="Corresponding User">
                                                        <option value="">None</option>
                                                        <option v-for="user in usersdata" :value="user.u_id">
                                                            {{ user.u_name }}
                                                        </option>
                                                    </select-input>
                                                </div>
                                            </fieldset>
                                            <fieldset id="verticle-wizard-p-0" style="display:none" role="tabpanel"
                                                aria-labelledby="verticle-wizard-h-0" class="body " aria-hidden="true">
                                                <div class="row">
                                                    <text-input v-model="form.employee_nationality" class="col-md-4"
                                                        label="Nationality" />
                                                    <select-input v-model="form.employee_ethnicity" class="col-md-4"
                                                        label="Ethnicity">
                                                        <option value="">Select...</option>
                                                        <option v-for="con in ethinicitydata" :value="con.ethinicity_id">
                                                            {{ con.ethinicity_name }}
                                                        </option>
                                                    </select-input>
                                                    <select-input v-model="form.employee_gender" class="col-md-4"
                                                        label="Gender">
                                                        <option value="">Select...</option>
                                                        <option v-for="gender, k in genderdata" :value="k">
                                                            {{ gender }}
                                                        </option>
                                                    </select-input>

                                                    <select-input v-model="form.employee_resideshome" class="col-md-4"
                                                        label="Resides at Care Home?">
                                                        <option value="0">No</option>
                                                        <option value="1">Yes</option>
                                                    </select-input>
                                                    <text-input v-model="form.employee_nextofkin" class="col-md-4"
                                                        label="Next Of Kin" />
                                                    <text-input v-model="form.employee_ninnumber" class="col-md-4"
                                                        label="National Insurance Number" />
                                                    <select-input v-model="form.employee_employmenttype" class="col-md-4"
                                                        label="Employment Type">
                                                        <option value="">Select...</option>
                                                        <option v-for="con in employmenttypedata" :value="con.id">
                                                            {{ con.priority }}
                                                        </option>
                                                    </select-input>
                                                    <select-input v-model="form.employee_contracttype" class="col-md-4"
                                                        label="Contract Type">
                                                        <option value="">Select...</option>
                                                        <option v-for="con in contracttypedata" :value="con.id">
                                                            {{ con.priority }}
                                                        </option>
                                                    </select-input>
                                                    <text-input v-model="form.employee_jobtitle" class="col-md-4"
                                                        label="Job Title" />
                                                    <text-input type="date" v-model="form.employee_joiningdate"
                                                        class="col-md-4" label="Joining Date" />
                                                    <text-input type="date" v-model="form.employee_leavingdate"
                                                        class="col-md-4" label="Leaving Date" />
                                                    <text-input v-model="form.employee_contractinghours" class="col-md-4"
                                                        label="Contracting Hours" />

                                                    <select-input v-model="form.employee_incarerole" class="col-md-4"
                                                        label="In Care Role?">
                                                        <option value="0">No</option>
                                                        <option value="1">Yes</option>
                                                    </select-input>
                                                    <text-input v-model="form.employee_currentgrading" class="col-md-4"
                                                        label="Current Grading" />


                                                </div>
                                            </fieldset>
                                            <fieldset id="verticle-wizard-p-2" style="display:none" role="tabpanel"
                                                aria-labelledby="verticle-wizard-h-0" class="body " aria-hidden="true">
                                                <div class="row">
                                                    <text-input v-model="form.employee_noofyear" class="col-md-6"
                                                        label="Number of Years" />
                                                    <text-input v-model="form.employee_noofmonth" class="col-md-6"
                                                        label="Number of Months" />
                                                    <select-input v-model="form.employee_probation" class="col-md-6"
                                                        label="On Probation?">
                                                        <option value="">Select</option>
                                                        <option value="0">No</option>
                                                        <option value="1">Yes</option>
                                                    </select-input>
                                                    <text-input v-model="form.employee_medicalinfo" class="col-md-6"
                                                        label="Medical Information" />
                                                    <textarea-input v-model="form.employee_miscdetails" class="col-md-12"
                                                        rows="3" label="Miscellaneous Details"></textarea-input>
                                                </div>
                                            </fieldset>



                                        </div>
                                        <div class="actions clearfix">
                                            <ul role="menu" aria-label="Pagination">
                                                <li aria-disabled="true" class="wizard-prev-btn disabled">
                                                    <a href="javascript:void(0);" role="menuitem">Previous</a>
                                                </li>
                                                <li aria-hidden="false" aria-disabled="false" class="wizard-next-btn"><a
                                                        href="javascript:void(0);" role="menuitem">Next</a></li>
                                                <li aria-hidden="true" style="display: none;" class="wizard-finish"><a
                                                        href="javascript:void(0);" @click="addEmployee();"
                                                        role="menuitem">Finish</a></li>
                                            </ul>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>
<script setup>
import SelectInput from '../../../shared/SelectBox.vue';
import TextInput from '../../../shared/TextInput'
import TextareaInput from '../../../shared/TextareaInput'
import ImageInput from '../../../shared/ImageInput.vue'
import { ref, reactive, onMounted, onBeforeMount, provide } from 'vue';
import { useAuthStore } from '@/store/auth.js'
import { functions } from '../../../../common/appFunctions.js';
import factory from '@/common/factory.js'
import { useRouter } from 'vue-router';
const router = useRouter();
const authStore = useAuthStore();
var resident_in = ref('');
var admission_date = ref('');
var leaving_date = ref('');

var qulaification_name = ref('');
var qulaification_type = ref('');
var qulaification_course = ref('');
var qulaification_issuebody = ref('');
var qulaification_date = ref('');
var qulaification_expirydate = ref('');
var qulaification_refresherdate = ref('');
var qulaification_uploadedevidence = ref('');

// ****** Dropdowns data ********************
var companiesdata = ref([]);
var usersdata = ref([]);
var regionsdata = ref([]);
var genderdata = ref([]);
var employmenttypedata = ref([]);
var contracttypedata = ref([]);
var ethinicitydata = ref([]);
// ****** Dropdowns data ********************

const form = reactive({
    employee_picture: 'https://demo.dashboardpack.com/adminty-html/files/assets/images/gallery-grid/1.png',
    employee_name: '',
    employee_company_id: '',
    employee_start_date: '',
    employee_end_date: '',
    employee_administrator: '',
    employee_region: '',
    employee_correspondinguser: '',
    employee_nationality: '',
    employee_ethnicity: '',
    employee_gender: '',
    employee_resideshome: '',
    employee_nextofkin: '',
    employee_ninnumber: '',
    employee_employmenttype: '',
    employee_contracttype: '',
    employee_jobtitle: '',
    employee_joiningdate: '',
    employee_leavingdate: '',
    employee_contractinghours: '',
    employee_incarerole: '',
    employee_currentgrading: '',
    employee_noofyear: '',
    employee_noofmonth: '',
    employee_probation: '',
    employee_medicalinfo: '',
    employee_miscdetails: '',
    employee_association: [],
    employee_qualifications: [],
});
function getToken() {
    const token = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authStore.token,
        },
    };
    return token;
}
provide('getToken', getToken());

async function addEmployee() {
    const Resident = new factory(getToken(), 'employees', form);
    await Resident.postForm().then((response) => {
        if (response.data.status == "success") {
            router.push({ path: 'employees' })
        } else {
            console.log(response.data.message);
        }
    });
};
function addEmployeeAssociation() {
    if (resident_in.value.trim() == '') {
        alert("Kindly Enter Resident!");
        return false;
    }
    form.employee_association.push({
        employee_in: resident_in.value,
        employee_admission_date: admission_date.value,
        employee_leaving_date: leaving_date.value,
        status: '',
    });
    resident_in.value = '';
    admission_date.value = '';
    leaving_date.value = '';
}

function addEmployeeAssociated() {
    if (qulaification_name.value.trim() == '') {
        alert("Kindly Enter Qulification Name!");
        return false;
    }
    form.employee_qualifications.push({
        name: qulaification_name.value,
        courseattended: qulaification_course.value,
        type: qulaification_type.value,
        relation: qulaification_issuebody.value,
        date: qulaification_date.value,
        expirydate: qulaification_expirydate.value,
        refresherdate: qulaification_refresherdate.value,
        evidence: qulaification_uploadedevidence.value,
    });
    qulaification_name.value = '';
    qulaification_course.value = '';
    qulaification_issuebody.value = '';
    qulaification_date.value = '';
    qulaification_expirydate.value = '';
    qulaification_refresherdate.value = '';
    qulaification_uploadedevidence.value = '';
}
onMounted(async () => {

    const Employee = new factory(getToken(), 'getallapis', { index: ['companies', 'users', 'regions', 'gender','employmenttype','contracttype','ethinicity'] });
    await Employee.postForm().then((response) => {
        if (response.data.status == "success") {
            companiesdata.value = response.data.data.companies;
            usersdata.value = response.data.data.users;
            regionsdata.value = response.data.data.regions;
            genderdata.value = response.data.data.gender;
            employmenttypedata.value = response.data.data.employmenttype;
            contracttypedata.value = response.data.data.contracttype;
            ethinicitydata.value = response.data.data.ethinicity;
        }
    });
    functions.callOnReady();
});
function showmodal(id) {
    $(id).modal('show');
}
function hidemodal(id) {
    $(id).modal('hide');
}

 
</script>
<style scoped>
#wizard2 fieldset {
    display: block;
    position: relative;
}

.wizard>.current {
    display: block;
}

.wizard>.content>.body {
    position: relative;

}
</style>