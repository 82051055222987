<template>
    <div class="main-body">
        <div class="page-wrapper">

            <!-- Page body start -->
            <div class="page-body">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-header">
                                <h5>
                                    <span>All Access Permissions</span>
                                    <button type="button" class="btn btn-primary addnewbtn btn-round waves-effect f-right "
                                        @click="addNew()">Add
                                        New</button>
                                </h5>
                                <div class="card-header-right">
                                    <div class="page-header-breadcrumb">
                                        <ul class="breadcrumb-title">
                                            <li class="breadcrumb-item" style="float: left;">
                                                <a href="index.html">
                                                    <i class="icofont icofont-home"></i>
                                                </a>
                                            </li>

                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <div class="card-body">
                                <table-view v-if="displaytbl" :data="responsefromapi" @viewbuttonclicked="editbtnclicked"
                                    @deletebuttonclicked="deletebuttonclicked" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="add_form">
        <div class="modal-dialog " role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Add New</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        @click="hidemodal('#add_form');">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <text-input class="col-md-12" v-model="form.permission_role" label="Permission Title" />
                        <select-input v-model="form.permission_role_company_id" class="col-md-12" label="Company">
                            <option value="">Select...</option>
                            <option v-for="comp in companiesdata" :value="comp.c_id">{{
                                comp.c_name }}</option>
                        </select-input>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default waves-effect " data-dismiss="modal"
                        @click="hidemodal('#add_form');">Close</button>
                    <!-- <button type="button" class="btn btn-primary btn-round waves-effect waves-light "
                            @click="addForm();">Save
                            Information</button> -->
                    <button type="button" class="btn funload btn-primary btn-round waves-effect waves-light "
                        @click="addPermission();">Save
                        Information</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
import SelectInput from '../../../shared/SelectBox.vue';
import TextInput from '../../../shared/TextInput.vue';
import TextareaInput from '../../../shared/TextareaInput.vue';
import TableView from '../../../shared/TableView.vue';
import { functions } from '../../../../common/appFunctions.js';
import { ref, reactive, onMounted } from 'vue';
import { useAuthStore } from '@/store/auth.js'
import factory from '@/common/factory.js'
import { useRouter } from 'vue-router';

const authStore = useAuthStore();
const router = useRouter();
const form = reactive({
    permission_role: '',
    permission_role_company_id: '',
    permission_description: '',
});

var companiesdata = ref([]);
var modulesdata = ref([]);

var responsefromapi = ref();
var displaytbl = ref(false);
function getToken() {
    const token = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authStore.token,
        },
    };
    return token;
}
async function addPermission() {
    const Permission = new factory(getToken(), 'proles', form);
    await Permission.postForm().then((response) => {
        if (response.data.status == "success") {
            functions.destroyBtnLoader()
            location.reload();
        } else {
            console.log(response.data.message);
        }
    });
}

async function getPermission() {
    const drodowndata = new factory(getToken(), 'getallapis', { index: ['companies', 'modules'] });
    await drodowndata.postForm().then((response) => {
        if (response.data.status == "success") {
            companiesdata.value = response.data.data.companies;
            modulesdata.value = response.data.data.modules;
        }
    });
}


async function deletebuttonclicked(id) {
    const Form = new factory(getToken(), 'rolepermission/' + id, form);
    await Form.deleteHttp().then((response) => {
        if (response.data.status == "success") {
            location.reload();
        } else {
            console.log(response.data.message);
        }
    });

};
function editbtnclicked(id) {
    router.push({
        name: 'VAUpdatePermission',
        query: {
            id,
        },
    })
}
function addNew() {
    showmodal('#add_form');
}
onMounted(async () => {
    getPermission();

    functions.initLoader();
    const Form = new factory(getToken(), 'proles');
    await Form.getHttp().then(response => {
        functions.destroyLoader();
        if (response.status == 200 || response.status == 400) {
            var data = (response.status == 400) ? [] : response.data.data;
            var tabledata = {
                "conditions": {
                    'update': true,
                    'trash': true,
                    'active': true,
                    'primarykey': 'permission_role_id',
                    'hiddendata': ['permission_role_id'],
                    'active_index': 'permission_role_isactive',
                },
                "columns": [
                    'Role',
                    'Active',
                    'Action',
                ],
                "data_order": ['permission_role'],
                "data": data,
            };
            displaytbl.value = true
            responsefromapi.value = tabledata;
        }
    });
    functions.callOnReady();
})

function showmodal(id) {
    $(id).modal('show');
}
function hidemodal(id) {
    $(id).modal('hide');
}
$('.pcoded-navbar').show();
$('.pcoded-content').removeAttr('style');
</script> 
