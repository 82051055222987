<template>
  <div class="main-body">
    <div class="page-wrapper">
      <!-- Page body start -->
      <div class="page-body">
        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-header">
                <h5>
                  <span>Residents</span>
                  <button
                    type="button"
                    class="btn btn-primary addnewbtn btn-round waves-effect f-right"
                    @click="addNew()"
                  >
                    Add New
                  </button>
                </h5>
                <div class="card-header-right">
                  <div class="page-header-breadcrumb">
                    <ul class="breadcrumb-title">
                      <li class="breadcrumb-item" style="float: left">
                        <a href="index.html">
                          <i class="icofont icofont-home"></i>
                        </a>
                      </li>
                      <li class="breadcrumb-item" style="float: left">
                        <router-link :to="{ name: 'VAResidents' }"
                          >Residents</router-link
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <table-view
                  v-if="displaytbl"
                  :data="responsefromapi"
                  @viewbuttonclicked="editbtnclicked"
                  @deletebuttonclicked="deletebuttonclicked"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import TableView from "../../../shared/TableView.vue";
import { functions } from "../../../../common/appFunctions.js";
import { ref, reactive, onMounted } from "vue";
import { useAuthStore } from "@/store/auth.js";
import factory from "@/common/factory.js";
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const authStore = useAuthStore();

var responsefromapi = ref();
var displaytbl = ref(false);
function getToken() {
  const token = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authStore.token,
    },
  };
  return token;
}

function deletebuttonclicked(id) {
  var ask = functions.askSwal();
  ask.then(async (result) => {
    if (result.isConfirmed) {
      const Residents = new factory(getToken(), "residents/" + id);
      await Residents.deleteHttp().then((response) => {
        if (response.data.status == "success") {
          location.reload();
        } else {
          console.log(response.data.message);
        }
      });
    }
  });
}
function editbtnclicked(id) {
  router.push({
    name: "VAEditResident",
    query: {
      id,
    },
  });
}
function addNew() {
  router.push({
    name: "VAAddResident",
  });
}
onMounted(async () => {
  functions.initLoader();

  const Residents = new factory(getToken(), "residents");
  const dt = await Residents.getHttp().then((response) => {
    functions.destroyLoader();
    var tabledata = {
      conditions: {
        update: true,
        trash: true,
        active: false,
        primarykey: "ry_id",
        hiddendata: ["ry_id"],
      },
      columns: [
        "Name",
        "Company",
        "Location",
        "Administrator",
        "Start Date",
        "End Date",
        "",
      ],
      data_order: [
        "ry_name",
        "company",
        "",
        "administrator",
        "ry_start_date",
        "ry_end_date",
      ],
      data: response.data.data,
    };
    displaytbl.value = true;
    responsefromapi.value = tabledata;
  });
  functions.callOnReady();
});
</script>
