<template>
    <div class="main-body">
        <div class="page-wrapper">
            <div class="page-header">
                <div class="row align-items-end">
                    <div class="col-lg-8">
                        <div class="page-header-title">
                            <div class="d-inline">
                                <h4>Vendor Number {{ vendorid }} </h4>
                                <span>this is profile of vendor </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="page-header-breadcrumb">
                            <ul class="breadcrumb-title">
                                <li class="breadcrumb-item" style="float: left;">
                                    <a href="index.html"> <i class="feather icon-home"></i> </a>
                                </li>
                                <li class="breadcrumb-item" style="float: left;"> <router-link
                                        :to="{ name: 'SAVendors' }">Vendors</router-link>
                                </li>
                                <li class="breadcrumb-item" style="float: left;"><a href="#!">Single Vendor</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-body">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="cover-profile">
                            <div class="profile-bg-img" style="position: relative;">
                                <img class="profile-bg-img img-fluid" :src="vendorinfo.profilecoverimg" alt="bg-img">
                                <div class="card-block user-info">
                                    <div class="col-md-12">
                                        <div class="media-left">
                                            <a href="#" class="profile-image">
                                                <img class="user-img img-radius" :src="vendorinfo.profileimg"
                                                    alt="user-img">
                                            </a>
                                        </div>
                                        <div class="media-body row">
                                            <div class="col-lg-12">
                                                <div class="user-title">
                                                    <h2>{{ vendorinfo.fullname }}</h2>
                                                    <span class="text-white">{{ vendorinfo.shortdescription }}</span>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="pull-right cover-btn">
                                                    <!-- <button type="button" class="btn btn-primary btn-round m-r-10 m-b-5"><i
                                                            class="icofont icofont-plus"></i>
                                                        Follow</button> -->
                                                    <button type="button" class="btn btn-primary btn-round"><i
                                                            class="icofont icofont-ui-messaging"></i>
                                                        Message</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="tab-header card">
                            <ul class="nav nav-tabs md-tabs tab-timeline" role="tablist" id="mytab">
                                <li class="nav-item">
                                    <a class="nav-link active" data-bs-toggle="tab" href="#personal" role="tab">Personal
                                        Info</a>
                                    <div class="slide"></div>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="tab" href="#busniess_accounts" role="tab">
                                        Busniess Accounts</a>
                                    <div class="slide"></div>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="tab" href="#subscriptplans" role="tab">Active
                                        Plans</a>
                                    <div class="slide"></div>
                                </li>

                            </ul>
                        </div>
                        <div class="tab-content">
                            <div class="tab-pane active" id="personal" role="tabpanel">
                                <div class="card">
                                    <div class="card-header">
                                        <h5 class="card-header-text">Basic Information</h5>
                                        <button id="edit-btn" type="button" @click="showbasicinfoedit()"
                                            class="btn btn-sm btn-primary waves-effect waves-light f-right">
                                            <i class="icofont icofont-edit"></i>
                                        </button>
                                    </div>
                                    <div class="card-block">
                                        <div class="view-info">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="general-info">
                                                        <div class="row">
                                                            <div class="col-lg-12 col-xl-6">
                                                                <div class="table-responsive">
                                                                    <table class="table m-0">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th scope="row">
                                                                                    Full Name
                                                                                </th>
                                                                                <td>{{ vendorinfo.fullname }}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">
                                                                                    Created Date
                                                                                </th>
                                                                                <td>{{ vendorinfo.created_date }}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">
                                                                                    Email</th>
                                                                                <td> {{ vendorinfo.email
}}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">
                                                                                    Mobile
                                                                                    Number</th>
                                                                                <td>{{ vendorinfo.mob }}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">
                                                                                    Address
                                                                                </th>
                                                                                <td>{{ vendorinfo.address }}</td>
                                                                            </tr>

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12 col-xl-6">
                                                                <div class="table-responsive">
                                                                    <table class="table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th scope="row"> No of Business </th>
                                                                                <td>{{ Object.keys(vendorinfo.accounts).length }}
                                                                                </td>
                                                                            </tr>
                                                                            <tr
                                                                                v-for="(account, index) in vendorinfo.accounts">
                                                                                <th scope="row">
                                                                                    {{ (index + 1) + ": " +
        account.businessname
}}
                                                                                </th>
                                                                                <td><a href="#!">{{
        account.businessdomain
}}</a>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="edit-info" style="display:none;">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="general-info">
                                                        <div class="row">
                                                            <div class="col-lg-6">
                                                                <table class="table">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <div class="input-group">
                                                                                    <span class="input-group-addon"><i
                                                                                            class="icofont icofont-user"></i></span>
                                                                                    <input type="text"
                                                                                        class="form-control"
                                                                                        placeholder="Full Name"
                                                                                        :value="vendorinfo.fullname">
                                                                                </div>
                                                                            </td>
                                                                        </tr>


                                                                        <tr>
                                                                            <td>
                                                                                <div class="input-group">
                                                                                    <span class="input-group-addon"><i
                                                                                            class="icofont icofont-location-pin"></i></span>
                                                                                    <input type="text"
                                                                                        class="form-control"
                                                                                        placeholder="Address"
                                                                                        :value="vendorinfo.address">
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <table class="table">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <div class="input-group">
                                                                                    <span class="input-group-addon"><i
                                                                                            class="icofont icofont-email"></i></span>
                                                                                    <input type="text"
                                                                                        class="form-control"
                                                                                        placeholder="Email"
                                                                                        :value="vendorinfo.email">
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <div class="input-group">
                                                                                    <span class="input-group-addon"><i
                                                                                            class="icofont icofont-mobile-phone"></i></span>
                                                                                    <input type="text"
                                                                                        class="form-control"
                                                                                        placeholder="Mobile Number"
                                                                                        :value="vendorinfo.mob">
                                                                                </div>
                                                                            </td>
                                                                        </tr>

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div class="text-center">
                                                            <a href="#!"
                                                                class="btn btn-primary btn-round waves-effect waves-light m-r-20">Save</a>
                                                            <a href="#!" id="edit-cancel"
                                                                class="btn btn-default waves-effect">Cancel</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="card">
                                            <div class="card-header">
                                                <h5 class="card-header-text">Description</h5>
                                                <button id="edit-info-btn" type="button"
                                                    class="btn btn-sm btn-primary waves-effect waves-light f-right">
                                                    <i class="icofont icofont-edit"></i>
                                                </button>
                                            </div>
                                            <div class="card-block user-desc">
                                                <div class="view-desc">
                                                    <p>{{ vendorinfo.description }}</p>
                                                </div>
                                                <div class="edit-desc" style="display:none">
                                                    <div class="col-md-12">
                                                        <textarea id="description" rows="6"
                                                            class="form-control">{{ vendorinfo.description }} </textarea>
                                                    </div>
                                                    <div class="text-center">
                                                        <a href="#!"
                                                            class="btn btn-primary btn-round waves-effect waves-light m-r-20 m-t-20">Save</a>
                                                        <a href="#!" id="edit-cancel-btn"
                                                            class="btn btn-default waves-effect m-t-20">Cancel</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" id="busniess_accounts" role="tabpanel">
                                <div class="row">
                                    <div class="col-xl-3">
                                        <div class="card">
                                            <div class="card-block">
                                                <ul class="list-group list-contacts">
                                                    <li class="list-group-item active"><a href="#">All Businesses</a>
                                                    </li>
                                                    <li class="list-group-item"><a href="#">Active Businesses</a></li>
                                                    <li class="list-group-item"><a href="#">Unactive Businesses</a></li>
                                                    <li class="list-group-item"><a href="#">Expired Plans</a></li>
                                                </ul>
                                            </div>

                                        </div>
                                        <div class="card">
                                            <div class="card-header">
                                                <h4 class="card-title">All Businesses Logos</h4>
                                            </div>
                                            <div class="card-block">
                                                <div class="connection-list">
                                                    <a href="#" v-for="(account, index) in vendorinfo.accounts">
                                                        <img class="img-fluid img-radius" :src="account.businesslogo"
                                                            alt="f-1" data-bs-toggle="tooltip" data-placement="top"
                                                            :data-original-title="account.businessname">
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-9">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h5 class="card-header-text">Businesses
                                                        </h5>
                                                    </div>
                                                    <div class="card-block contact-details">
                                                        <div class="data_table_main table-responsive dt-responsive">
                                                            <tableView :data="tabledata"
                                                                @viewbuttonclicked="editbtnclicked" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" id="subscriptplans" role="tabpanel">
                                <div class="card">
                                    <div class="card-header">
                                        <h5 class="card-header-text">Active Plans</h5>
                                    </div>
                                    <div class="card-block">
                                        <div class="row">
                                            <div class="col-md-6" v-for="(account, index) in vendorinfo.accounts">
                                                <div class="card b-l-success business-info services m-b-20">
                                                    <div class="card-header">
                                                        <div class="service-header">
                                                            <a href="#">
                                                                <h5 class="card-header-text">
                                                                    {{ account.businessname }} - {{
        account.activeplan.plantitle
}}</h5>
                                                            </a>
                                                        </div>
                                                        <span
                                                            class="dropdown-toggle addon-btn text-muted f-right service-btn"
                                                            data-bs-toggle="dropdown" aria-haspopup="true"
                                                            aria-expanded="true" role="tooltip">
                                                        </span>
                                                        <div
                                                            class="dropdown-menu dropdown-menu-right b-none services-list">
                                                            <a class="dropdown-item" href="#!"><i
                                                                    class="icofont icofont-edit"></i>
                                                                Edit</a>
                                                            <a class="dropdown-item" href="#!"><i
                                                                    class="icofont icofont-ui-delete"></i>
                                                                Delete</a>
                                                            <a class="dropdown-item" href="#!"><i
                                                                    class="icofont icofont-eye-alt"></i>
                                                                View</a>
                                                        </div>
                                                    </div>
                                                    <div class="card-block">
                                                        <div class="row">
                                                            <div class="col-sm-12">
                                                                <p class="task-detail">{{
        account.activeplan.planshortdescription
                                                                    }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import tableView from '../../../shared/table.vue';

export default {
    data() {
        return {
            vendorid: 0,
            responsefromapi: [],
            tabledata: {},
            vendorinfo: []
        };
    },
    components: {
        tableView
    },
    methods: {
        showbasicinfoedit() {

        },
        editbtnclicked(pkey) {

        }
    },
    mounted() {
        $('#edit-btn').on('click', function () {
            var b = $(this).find("i");
            var edit_class = b.attr('class');
            if (edit_class == 'icofont icofont-edit') {
                b.removeClass('icofont-edit');
                b.addClass('icofont-close');
                $('.view-info').hide();
                $('.edit-info').show();
            } else {
                b.removeClass('icofont-close');
                b.addClass('icofont-edit');
                $('.view-info').show();
                $('.edit-info').hide();
            }
        });
        $('#edit-cancel').on('click', function () {

            var c = $('#edit-btn').find("i");
            c.removeClass('icofont-close');
            c.addClass('icofont-edit');
            $('.view-info').show();
            $('.edit-info').hide();

        });
        $('#edit-cancel-btn').on('click', function () {

            var c = $('#edit-info-btn').find("i");
            c.removeClass('icofont-close');
            c.addClass('icofont-edit');
            $('.view-desc').show();
            $('.edit-desc').hide();

        });
        $('#edit-info-btn').on('click', function () {
            var b = $(this).find("i");
            var edit_class = b.attr('class');
            if (edit_class == 'icofont icofont-edit') {
                b.removeClass('icofont-edit');
                b.addClass('icofont-close');
                $('.view-desc').hide();
                $('.edit-desc').show();
            } else {
                b.removeClass('icofont-close');
                b.addClass('icofont-edit');
                $('.view-desc').show();
                $('.edit-desc').hide();
            }
        });
    },
    created() {
        this.vendorid = this.$route.params.id;
        this.responsefromapi = {
            "status": "success",
            "message": "this is message",
            "data": {
                "fullname": "Josephine Villa",
                "email": "Demo@example.com",
                "created_date": "October 25th, 1990",
                "mob": "(0123) - 4567891",
                "address": "New York, USA",
                "shortdescription": "Web designer",
                "description": "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.",
                "profileimg": "https://demo.dashboardpack.com/adminty-html/files/assets/images/user-profile/user-img.jpg",
                "profilecoverimg": "https://demo.dashboardpack.com/adminty-html/files/assets/images/user-profile/bg-img1.jpg",
                "accounts": [
                    {
                        'businessid': 1,
                        'businesslogo': 'https://demo.dashboardpack.com/adminty-html/files/assets/images/widget/icon-3.png',
                        'activeplan': {
                            "planid": 1,
                            "plantitle": '2 Year Subscription',
                            "planshortdescription": 'But I must explain to you how all this mistaken idea of denouncing pleasure',
                            "planexpirydate": 'October 25th, 2025',
                        },
                        'businessname': 'JV Carehome London',
                        'businessemail': 'jvlondon@jv.com',
                        'businessphone': '(0123) - 4567891',
                        'businessdomain': 'jvlondon.co.uk',
                        'status': true,
                    },
                    {
                        'businessid': 2,
                        'businesslogo': 'https://demo.dashboardpack.com/adminty-html/files/assets/images/widget/icon-4.png',
                        'activeplan': {
                            "planid": 1,
                            "plantitle": '2 Year Subscription',
                            "planshortdescription": 'But I must explain to you how all this mistaken idea of denouncing pleasure',
                            "planexpirydate": 'October 25th, 2025',
                        },
                        'businessname': 'JV Carehome Solihull',
                        'businessemail': 'jvsolihull@jv.com',
                        'businessphone': '(0123) - 4567891',
                        'businessdomain': 'jvsolihull.co.uk',
                        'status': true,
                    },
                ],
            }
        };

        this.vendorinfo = this.responsefromapi.data;
        // console.log(this.responsefromapi.data.accounts);
        this.tabledata = {
            "conditions": {
                'update': true,
                'trash': false,
                'active': true,
                'primarykey': 'businessid',
                'hiddendata': ['businessid', 'businesslogo', 'activeplan'],
            },
            "columns": [
                'Title',
                'Domain',
                'Phone',
                'Email',
                'Active',
                '',
            ],
            "data": this.responsefromapi.data.accounts
        }
        // console.log(this.tabledata);
    }

};



</script> 
