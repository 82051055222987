<template>
  <div class="pcoded-inner-content">
    <div class="main-body">
      <div class="page-wrapper">
        <div class="page-body">
          <div class="card">
            <div class="card-header">
              <h5>
                <span>Single Home Information</span>
              </h5>
              <div class="card-header-right">
                <div class="page-header-breadcrumb">
                  <ul class="breadcrumb-title">
                    <li class="breadcrumb-item" style="float: left">
                      <a href="index.html">
                        <i class="feather icon-home"></i>
                      </a>
                    </li>
                    <li class="breadcrumb-item" style="float: left">
                      <router-link :to="{ name: 'VAResidents' }"
                        >Residents</router-link
                      >
                    </li>
                    <li class="breadcrumb-item" style="float: left">
                      <a href="#!">Single Resident Info</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- Page-body start -->

            <div class="row card-body">
              <div class="col-lg-12">
                <ul
                  class="nav nav-tabs md-tabs tab-timeline tabs-left"
                  role="tablist"
                  id="mytab"
                >
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      data-bs-toggle="tab"
                      href="#personal"
                      role="tab"
                    >
                      Basic Information
                    </a>
                    <div class="slide"></div>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      data-bs-toggle="tab"
                      href="#contactdetails"
                      role="tab"
                      >Contact & Ofsted Details</a
                    >
                    <div class="slide"></div>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      data-bs-toggle="tab"
                      href="#otherdetails"
                      role="tab"
                      >Other Details</a
                    >
                    <div class="slide"></div>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      data-bs-toggle="tab"
                      href="#employees"
                      role="tab"
                      >Employees</a
                    >
                    <div class="slide"></div>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      data-bs-toggle="tab"
                      href="#clockinout"
                      role="tab"
                      >Clock In/Out Settings</a
                    >
                    <div class="slide"></div>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      data-bs-toggle="tab"
                      href="#otherinfo"
                      role="tab"
                      >Meta Information</a
                    >
                    <div class="slide"></div>
                  </li>
                </ul>
                <!-- tab content start -->
                <div
                  class="tab-content tabs-left-content card-block"
                  style="width: 100%"
                >
                  <!-- tab panel personal start -->
                  <div class="tab-pane active" id="personal" role="tabpanel">
                    <!-- personal card start -->

                    <form>
                      <div class="row">
                        <image-input
                          v-model="form.home_picture"
                          class="col-md-4"
                          :source="form.home_picture"
                          kStyle="height:20vh;"
                        />

                        <div class="col-md-8">
                          <div class="row">
                            <text-input
                              v-model="form.home_name"
                              class="col-md-6"
                              label="Name"
                            />
                            <select-input
                              v-model="form.home_company_id"
                              class="col-md-6 my-select selectpicker"
                              label="Company"
                            >
                              <option value="">Select...</option>
                              <option
                                v-for="comp in companiesdata"
                                :value="comp.c_id"
                              >
                                {{ comp.c_name }}
                              </option>
                            </select-input>
                            <text-input
                              v-model="form.home_start_date"
                              class="col-md-6"
                              type="date"
                              label="Start Date"
                            />
                            <text-input
                              v-model="form.home_end_date"
                              class="col-md-6"
                              type="date"
                              label="End Date"
                            />
                            <select-input
                              v-model="form.home_administrator"
                              class="col-md-6"
                              label="Administrator"
                            >
                              <option value="">Select...</option>
                              <option
                                v-for="user in usersdata"
                                :value="user.u_id"
                              >
                                {{ user.u_name }}
                              </option>
                            </select-input>
                            <select-input
                              v-model="form.home_region"
                              class="col-md-6"
                              label="Region (Optional)"
                            >
                              <option value="">Select...</option>
                              <option
                                v-for="reg in regionsdata"
                                :value="reg.r_id"
                              >
                                {{ reg.r_name }}
                              </option>
                            </select-input>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <hr />
                        </div>
                        <select-input
                          v-model="form.home_incharge"
                          class="col-md-3"
                          label="Person in Charge"
                        >
                          <option value="">Select...</option>
                          <option v-for="user in usersdata" :value="user.u_id">
                            {{ user.u_name }}
                          </option>
                        </select-input>
                        <select-input
                          v-model="form.home_responsible"
                          class="col-md-3"
                          label="Responsible Individual"
                        >
                          <option value="">Select...</option>
                          <option v-for="user in usersdata" :value="user.u_id">
                            {{ user.u_name }}
                          </option>
                        </select-input>
                        <text-input
                          v-model="form.home_min_age"
                          class="col-md-3"
                          label="Minimum Age Group"
                        />
                        <text-input
                          v-model="form.home_max_age"
                          class="col-md-3"
                          label="Maximum Age Group"
                        />
                        <text-input
                          v-model="form.home_no_beds"
                          class="col-md-3"
                          label="No. of Beds"
                        />
                        <text-input
                          v-model="form.home_pat_date"
                          class="col-md-3"
                          type="date"
                          label="PAT Date"
                        />
                        <text-input
                          v-model="form.home_electrical"
                          class="col-md-3"
                          label="Electrical Certificate"
                        />
                        <text-input
                          v-model="form.home_gas"
                          class="col-md-3"
                          label="Gas Certificate"
                        />
                        <text-input
                          v-model="form.home_firedrill_day"
                          class="col-md-3"
                          label="Fire Drill - Day"
                        />
                        <text-input
                          v-model="form.home_firedrill_night"
                          class="col-md-3"
                          label="Fire Drill - Night"
                        />
                      </div>
                      <div class="col-md-12 text-center">
                        <button
                          type="button"
                          class="btn btn-primary btn-round waves-effect waves-light funload"
                          @click="updateHome()"
                        >
                          Update Changes
                        </button>
                      </div>
                    </form>
                  </div>
                  <!-- tab pane personal end -->
                  <!-- tab pane info start -->
                  <div class="tab-pane" id="contactdetails" role="tabpanel">
                    <!-- info card start -->

                    <div class="row">
                      <textarea-input
                        v-model="form.home_address"
                        class="col-md-12"
                        rows="5"
                        label="Address"
                      ></textarea-input>
                      <text-input
                        v-model="form.home_postcode"
                        class="col-md-3"
                        label="Postcode"
                      />
                      <text-input
                        v-model="form.home_telephone"
                        class="col-md-3"
                        label="Telephone Number"
                      />
                      <text-input
                        v-model="form.home_faxnumber"
                        class="col-md-3"
                        label="Fax Number"
                      />
                      <text-input
                        v-model="form.home_email"
                        class="col-md-3"
                        label="Email Address"
                      />
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <hr />
                      </div>
                    </div>
                    <div class="row">
                      <text-input
                        v-model="form.home_ofsted_urn"
                        class="col-md-3"
                        label="Ofsted URN"
                      />
                      <text-input
                        v-model="form.home_ofsted_rating"
                        class="col-md-3"
                        label="Full Ofsted Rating"
                      />
                      <text-input
                        v-model="form.home_ofsted_rating_date"
                        class="col-md-3"
                        type="date"
                        label="Rating Date"
                      />
                      <text-input
                        v-model="form.home_ofsted_requirements"
                        class="col-md-3"
                        label="Number of Requirements"
                      />
                      <text-input
                        v-model="form.home_ofsted_list_regulation_requirements"
                        class="col-md-5"
                        label="List Regulation Numbers for Requirements"
                      />
                      <text-input
                        v-model="form.home_ofsted_recommendations"
                        class="col-md-2"
                        label="No. of Recommendations"
                      />
                      <text-input
                        v-model="form.home_ofsted_no_recommendations"
                        class="col-md-5"
                        label="List Regulation Numbers for Recommendations"
                      />
                      <text-input
                        v-model="form.home_ofsted_interim_rating"
                        class="col-md-2"
                        label="Interim Ofsted Rating"
                      />
                      <text-input
                        v-model="form.home_ofsted_interim_rating_date"
                        type="date"
                        class="col-md-2"
                        label="Interim Rating Date"
                      />
                      <text-input
                        v-model="form.home_ofsted_interim_requirment"
                        class="col-md-3"
                        label="No. of Requirements (Interim)"
                      />
                      <text-input
                        v-model="form.home_ofsted_interim_no_requirment"
                        class="col-md-5"
                        label="List Regulation Numbers for Requirements (Interim)"
                      />
                      <text-input
                        v-model="form.home_ofsted_interim_recommendation"
                        class="col-md-3"
                        label="Number of Recommendations"
                      />
                      <text-input
                        v-model="form.home_ofsted_interim_regulation"
                        class="col-md-6"
                        label="List Regulation Numbers for Requirements (Interim)"
                      />

                      <div class="col-md-12 text-center">
                        <button
                          type="button"
                          class="btn btn-primary btn-round waves-effect waves-light funload"
                          @click="updateHome()"
                        >
                          Update Changes
                        </button>
                      </div>
                    </div>

                    <!-- info card end -->
                  </div>
                  <!-- tab pane info end -->
                  <!-- tab pane info start -->
                  <div class="tab-pane" id="otherdetails" role="tabpanel">
                    <!-- info card start -->

                    <div class="row">
                      <textarea-input
                        v-model="form.home_longdescription"
                        class="col-md-12"
                        rows="5"
                        label="Description"
                      ></textarea-input>
                      <select-input
                        v-model="form.home_secure_accomodation"
                        class="col-md-6"
                        label="Home is secure accomodation"
                      >
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                      </select-input>
                      <select-input
                        v-model="form.home_shortstay"
                        class="col-md-6"
                        label="Home accomodates short-term stays"
                      >
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                      </select-input>
                    </div>
                    <div class="row">
                      <div class="col-md-12 text-center">
                        <button
                          type="button"
                          class="btn btn-primary btn-round waves-effect waves-light funload"
                          @click="updateHome()"
                        >
                          Update Changes
                        </button>
                      </div>
                    </div>

                    <!-- info card end -->
                  </div>
                  <div class="tab-pane" id="employees" role="tabpanel">
                    <!-- info card start -->

                    <div class="row">
                        <div class="col-md-3" v-for="emp in employeesdata">
                        <div class="form-group">
                          <div class="checkbox-fade fade-in-primary m-0"> 
                            <label>
                              <input type="checkbox" class="empsselected" :value="emp.employee_id" :checked="(homesemployeesdata.some((item) => item.employee_id == emp.employee_id))"    />
                              <span class="cr">
                                <i
                                  class="cr-icon icofont icofont-ui-check txt-primary"
                                ></i>
                              </span>
                              <span> {{ emp.employee_name }}</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 text-center">
                        <button
                          type="button"
                          class="btn btn-primary btn-round waves-effect waves-light funload mt-2"
                          @click="updateEmployee()"
                        >
                          Update Changes
                        </button>
                      </div>
                    </div>

                    <!-- info card end -->
                  </div>
                  <div class="tab-pane" id="clockinout" role="tabpanel">
                    <!-- info card start -->

                    <div class="row">
                      <div class="col-md-12">
                        <h3>Restrict mobile clocking in / out</h3>
                      </div>
                      <div class="col-md-12">
                        <div class="checkbox-fade fade-in-primary m-0">
                          <!-- home_id -->
                          <label>
                            <input type="checkbox" v-model="restrict_specific_range"   />
                            <span class="cr">
                              <i
                                class="cr-icon icofont icofont-ui-check txt-primary"
                              ></i>
                            </span>
                            <span>
                              Restrict clock in to the location address, with
                              specified range (e.g. 50 meters) <br /><small
                                >We will use the GPS on the user's mobile device
                                to determine their distance from the work
                                location, and only allow them to clock in once
                                they're within the specified range.</small
                              ></span
                            >
                          </label>
                        </div>
                      </div>
                      <select-input class="col-md-6" label=""  v-model="restricted_range" v-show="restrict_specific_range">
                        <option value="">Select...</option>
                        <option  value="50">
                          within 50 meters of location
                        </option>
                        <option  value="150">
                          within 150 meters of location
                        </option>
                        <option  value="250">
                          within 250 meters of location
                        </option>
                        <option  value="500">
                          within 500 meters of location
                        </option>
                      </select-input>
                      <div class="col-md-12"><hr></div>
                      <div class="col-md-12">
                        <div class="checkbox-fade fade-in-primary m-0">
                          <!-- home_id -->
                          <label>
                            <input type="checkbox" class="homesselected"  v-model="restrict_ip" />
                            <span class="cr">
                              <i
                                class="cr-icon icofont icofont-ui-check txt-primary"
                              ></i>
                            </span>
                            <span>
                                Restrict clock in to a specific IP address (e.g. a wifi network at your workplace) <br /><small
                                >We will use the IP address of the user's device to determine if they're connected to an approved network. It is important you only use this setting if you have a static IP address. If you are unsure please check with your internet service provider.</small
                              ></span
                            >
                          </label>
                        </div>
                      </div>
                      <text-input v-model="restricted_ip" v-show="restrict_ip"
                        class="col-md-12"
                        placeholder="e.g 111.111.111.111, 222.222.222.222"
                      ></text-input>

                      <div class="col-md-12">
                        <small><b>Note: Selecting both restrictions will mean that employees will need to be within the specified range and on your wifi network.</b></small>
                      </div>
                      <div class="col-md-12"><hr></div>
                    </div>
                    
                    <div class="row">
                      <div class="col-md-12 text-center">
                        <button
                          type="button"
                          class="btn btn-primary btn-round waves-effect waves-light funload mt-2"
                          @click="updateHome()"
                        >
                          Update Changes
                        </button>
                      </div>
                    </div>

                    <!-- info card end -->
                  </div>
                  <!-- tab pane info end -->
                  <div class="tab-pane" id="otherinfo" role="tabpanel">
                    <!-- info card start -->
                    <div class="row">
                      <div class="col-xl-3">
                        <!-- user contact card left side start -->

                        <ul class="list-group list-contacts">
                          <li class="list-group-item active">
                            <a href="#">Steps</a>
                          </li>
                          <li class="list-group-item">
                            <a href="#">Active Businesses</a>
                          </li>
                          <li class="list-group-item">
                            <a href="#">Unactive Businesses</a>
                          </li>
                          <li class="list-group-item">
                            <a href="#">Expired Plans</a>
                          </li>
                        </ul>

                        <!-- user contact card left side end -->
                      </div>
                      <div class="col-xl-9">
                        <div class="row">
                          <div class="col-sm-12">
                            <!-- contact data table card start -->
                            <div class="card">
                              <div class="card-header">
                                <h5 class="card-header-text">Businesses</h5>
                              </div>
                              <div class="card-block contact-details"></div>
                            </div>
                            <!-- contact data table card end -->
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- info card end -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Page-body end -->
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { functions } from "../../../../common/appFunctions.js";
import SelectInput from "../../../shared/SelectBox.vue";
import TextInput from "../../../shared/TextInput";
import ImageInput from "../../../shared/ImageInput.vue";
import TextareaInput from "../../../shared/TextareaInput.vue";
import { onMounted, ref, reactive } from "vue";
import { useAuthStore } from "@/store/auth.js";
import factory from "@/common/factory.js";
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();

// ****** Dropdowns data ********************
var companiesdata = ref([]);
var usersdata = ref([]);
var regionsdata = ref([]);
var employeesdata = ref([]);
var homesemployeesdata = ref([]);
// ****** Dropdowns data ********************

var restrict_specific_range = ref(false);
var restricted_range = ref('');
var restrict_ip = ref(false);
var restricted_ip = ref('');

const form = ref({
  home_picture:
    "https://demo.dashboardpack.com/adminty-html/files/assets/images/gallery-grid/1.png",
  home_name: "",
  home_company_id: "",
  home_start_date: "",
  home_end_date: "",
  home_administrator: "",
  home_region: "",
  home_incharge: "",
  home_responsible: "",
  home_min_age: "",
  home_max_age: "",
  home_no_beds: "",
  home_pat_date: "",
  home_electrical: "",
  home_gas: "",
  home_firedrill_day: "",
  home_firedrill_night: "",
  home_address: "",
  home_postcode: "",
  home_telephone: "",
  home_faxnumber: "",
  home_email: "",
  home_ofsted_urn: "",
  home_ofsted_rating: "",
  home_ofsted_rating_date: "",
  home_ofsted_requirements: "",
  home_ofsted_list_regulation_requirements: "",
  home_ofsted_recommendations: "",
  home_ofsted_no_recommendations: "",
  home_ofsted_interim_rating: "",
  home_ofsted_interim_rating_date: "",
  home_ofsted_interim_requirment: "",
  home_ofsted_interim_no_requirment: "",
  home_ofsted_interim_recommendation: "",
  home_ofsted_interim_regulation: "",
  home_longdescription: "",
  home_secure_accomodation: "",
  home_shortstay: "",
});

function getToken() {
  const token = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authStore.token,
    },
  };
  return token;
}
async function updateHome() {
  const Company = new factory(getToken(), "homes", form.value);
  await Company.putForm().then((response) => {
    functions.destroyBtnLoader();
    if (response.data.status == "success") {
      // router.push({ path: 'companies' })
      functions.kSwal("Done", "Changes Saved Successfully.");
    } else {
      console.log(response.data.message);
    }
  });
}
async function updateEmployee() {
  var empsselected = [];
    $('.empsselected').each(function(){
        if($(this).prop('checked')){
          empsselected.push($(this).attr('value'));
        }
    });
  var updateEmployee = {
      "location_id": form.value.home_id,
      "employees":empsselected,
  }
  const Company = new factory(getToken(), "locationemployees", updateEmployee);
  await Company.postForm().then((response) => {
    functions.destroyBtnLoader();
    if (response.data.status == "success") {
      // router.push({ path: 'companies' })
      functions.kSwal("Done", "Changes Saved Successfully.");
    } else {
      console.log(response.data.message);
    }
  });
}
onMounted(async () => {
  var Homes = new factory(getToken(), "getallapis", {
    index: ["companies", "users", "regions","employees"],
  });
  await Homes.postForm().then((response) => {
    if (response.data.status == "success") {
      companiesdata.value = response.data.data.companies;
      usersdata.value = response.data.data.users;
      regionsdata.value = response.data.data.regions;
      employeesdata.value = response.data.data.employees;
    }
  });

  Homes = new factory(getToken(), "homes/" + route.query.id);
  await Homes.getHttp().then((response) => {
    if (response.data.status == "success") {
        swal.close();
      form.value = response.data.data;
      console.log(response.data.data);
    } else {
      return response.data.message;
    }
  });

  var HomesEMployedata={
    location_id:route.query.id
  }
  try {
    var HomesEMploye = new factory(getToken(), "getemployees", HomesEMployedata);
    await HomesEMploye.postForm().then((response) => {
        if (response.data.status == "success") {
            swal.close();
            homesemployeesdata.value = response.data.data;
        }  
    });
  } catch (error) {
    swal.close();
  }
  
  functions.callOnReady();
});
</script>
<style scoped>
.tabs-left {
  min-width: 200px !important;
}
</style>
