import { createApp } from "vue";
import moment from 'moment'
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "axios";
import piniaPluginPersistedState from "pinia-plugin-persistedstate"
import { createPinia } from "pinia";

const pinia = createPinia();
pinia.use(piniaPluginPersistedState);
const app = createApp(App);
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$moment = moment;
app.use(pinia);
app.use(store);
app.use(router);

app.mount("#app");
