import VADashboard from "../views/vendorAccount/Pages/VADashboard/index.vue";
import VACompanies from "../views/vendorAccount/Pages/VACompanies/index.vue";
import VAFormbuilder from "../views/vendorAccount/Pages/VAFormbuilder/index.vue";
import VAPreviewForm from "../views/vendorAccount/Pages/VAFormbuilder/preview.vue";
import VAUpdateForm from "../views/vendorAccount/Pages/VAFormbuilder/update.vue";
import VAPermissions from "../views/vendorAccount/Pages/VAPermissions/index.vue";
import VAUpdatePermission from "../views/vendorAccount/Pages/VAPermissions/update.vue";
import VAReports from "../views/vendorAccount/Pages/VAReport/index.vue";
import VAAddReport from "../views/vendorAccount/Pages/VAReport/add.vue";
import VAUpdateReport from "../views/vendorAccount/Pages/VAReport/update.vue";
import VAPrintReport from "../views/vendorAccount/Pages/VAReport/print.vue";
import Page2 from "../views/vendorAccount/Pages/page2/index.vue";
import VAEditCompany from "../views/vendorAccount/Pages/VACompanies/update.vue";
import VAAddCompany from "../views/vendorAccount/Pages/VACompanies/add.vue";
import VAAccountTypes from "../views/vendorAccount/Pages/VAAccountTypes/index.vue";
import VAEditAccountType from "../views/vendorAccount/Pages/VAAccountTypes/update.vue";
import VAAddAccountType from "../views/vendorAccount/Pages/VAAccountTypes/add.vue";
import VARegions from "../views/vendorAccount/Pages/VARegions/index.vue";
import VARoles from "../views/vendorAccount/Pages/VARoles/index.vue";
import VAHomes from "../views/vendorAccount/Pages/VAHomes/index.vue";
import VAAddHome from "../views/vendorAccount/Pages/VAHomes/add.vue";
import VAEditHome from "../views/vendorAccount/Pages/VAHomes/update.vue";
import VAResidents from "../views/vendorAccount/Pages/VAResidents/index.vue";
import VAAddResident from "../views/vendorAccount/Pages/VAResidents/add.vue";
import VAEditResident from "../views/vendorAccount/Pages/VAResidents/update.vue";
import VAEmployee from "../views/vendorAccount/Pages/VAEmployee/index.vue";
import VAAddEmployee from "../views/vendorAccount/Pages/VAEmployee/add.vue";
import VAEditEmployee from "../views/vendorAccount/Pages/VAEmployee/update.vue";
import VAVehicle from "../views/vendorAccount/Pages/VAVehicles/index.vue";
import VAAddVehicle from "../views/vendorAccount/Pages/VAVehicles/add.vue";
import VAEditVehicle from "../views/vendorAccount/Pages/VAVehicles/update.vue";
import VAUsers from "../views/vendorAccount/Pages/VAUsers/index.vue";
import VAAddUser from "../views/vendorAccount/Pages/VAUsers/add.vue";
import VAEditUser from "../views/vendorAccount/Pages/VAUsers/update.vue";
import VASupportTickets from "../views/vendorAccount/Pages/VASupportTickets/index.vue";
import VAAddSupportTicket from "../views/vendorAccount/Pages/VASupportTickets/add.vue";
import VAUpdateSupportTicket from "../views/vendorAccount/Pages/VASupportTickets/update.vue";
import VATasks from "../views/vendorAccount/Pages/VATasks/index.vue";
import VAAddTask from "../views/vendorAccount/Pages/VATasks/add.vue";
import VAUpdateTask from "../views/vendorAccount/Pages/VATasks/update.vue";
import VACompanyManuals from "../views/vendorAccount/Pages/VACompanyManuals/index.vue";
import VAAddCompanyManual from "../views/vendorAccount/Pages/VACompanyManuals/add.vue";
import VAUpdateCompanyManual from "../views/vendorAccount/Pages/VACompanyManuals/update.vue";
import VAPages from "../views/vendorAccount/Pages/VAWebbuilder/pages.vue";
import VAUpdatePages from "../views/vendorAccount/Pages/VAWebbuilder/updatepage.vue";
import VAMenus from "../views/vendorAccount/Pages/VAWebbuilder/menus.vue";
import VAHeaderPage from "../views/vendorAccount/Pages/VAWebbuilder/headerpage.vue";
import VAAttendance from "../views/vendorAccount/Pages/VARota/attendance.vue";
import VARota from "../views/vendorAccount/Pages/VARota/rota.vue";
import VAForcasting from "../views/vendorAccount/Pages/VARota/forcasting.vue";
import VABugetCap from "../views/vendorAccount/Pages/VARota/budgetcap.vue";
import VASettings from "../views/vendorAccount/Pages/VASettings/settings.vue";

export default [
  {
    name: "VADashboard",
    path: "dashboard",
    component: VADashboard,
    meta: {
      title: "Vendor Account Dashboard",
    },
  },
  {
    name: "VACompanies",
    path: "companies",
    component: VACompanies,
    meta: {
      title: "Companies",
    },
  },
  {
    name: "VAAddCompany",
    path: "companies/add",
    component: VAAddCompany,
    meta: {
      title: "Add Company",
    },
  },
  {
    name: "VAEditCompany",
    path: "update",
    component: VAEditCompany,
    meta: {
      title: "Edit Company",
    },
    props: (route) => ({ query: route.query.id }),
  },
  {
    name: "VAAccountTypes",
    path: "accounttypes",
    component: VAAccountTypes,
    meta: {
      title: "AccountTypes",
    },
  },
  {
    name: "VAAddAccountType",
    path: "add",
    component: VAAddAccountType,
    meta: {
      title: "Add AccountType",
    },
  },
  {
    name: "VAEditAccountType",
    path: "update",
    component: VAEditAccountType,
    meta: {
      title: "Edit AccountType",
    },
    props: (route) => ({ query: route.query.id }),
  },
  {
    name: "VAPage2",
    path: "page2",
    component: Page2,
    meta: {
      title: "Vendor Account Page 2",
    },
  },
  {
    name: "VAForms",
    path: "forms",
    component: VAFormbuilder,
    meta: {
      title: "All Forms",
    },
  },
  {
    name: "VAPreviewForms",
    path: "preveiwforms",
    component: VAPreviewForm,
    meta: {
      title: "Preview Forms",
    },
    props: (route) => ({ query: route.query.id }),
  },
  {
    name: "VAPermissions",
    path: "permission",
    component: VAPermissions,
    meta: {
      title: "Role Permission",
    }, 
  },
  {
    name: "VAUpdatePermission",
    path: "updatepermission",
    component: VAUpdatePermission,
    meta: {
      title: "Update Permission",
    },
    props: (route) => ({ query: route.query.id }),
  },
  {
    name: "VAReports",
    path: "reports",
    component: VAReports,
    meta: {
      title: "All Reports Created",
    },
    props: (route) => ({ query: route.query.id }),
  },
  {
    name: "VAAddReport",
    path: "addnewreports",
    component: VAAddReport,
    meta: {
      title: "Add New",
    },
    props: (route) => ({ query: route.query.id }),
  },
  {
    name: "VAUpdateReport",
    path: "updatereports",
    component: VAUpdateReport,
    meta: {
      title: "Update Report",
    },
    props: (route) => ({ query: route.query.id }),
  },
  {
    name: "VAPrintReport",
    path: "printreports",
    component: VAPrintReport,
    meta: {
      title: "Print Report",
    },
    props: (route) => ({ query: route.query.id }),
  },

  {
    name: "VARegions",
    path: "regions",
    component: VARegions,
    meta: {
      title: "Regions",
    },
  },
  {
    name: "VARoles",
    path: "roles",
    component: VARoles,
    meta: {
      title: "Roles",
    },
  },
  {
    name: "VAHomes",
    path: "homes",
    component: VAHomes,
    meta: {
      title: "Homes",
    },
  },
  {
    name: "VAAddHome",
    path: "addhome",
    component: VAAddHome,
    meta: {
      title: "Add Home",
    },
  },
  {
    name: "VAEditHome",
    path: "updatehome",
    component: VAEditHome,
    meta: {
      title: "Edit Home",
    },
    props: (route) => ({ query: route.query.id }),
  },
  {
    name: "VAResidents",
    path: "residents",
    component: VAResidents,
    meta: {
      title: "Residents",
    },
  },
  {
    name: "VAAddResident",
    path: "addresident",
    component: VAAddResident,
    meta: {
      title: "Add Resident",
    },
  },
  {
    name: "VAEditResident",
    path: "updateresident",
    component: VAEditResident,
    meta: {
      title: "Edit Resident",
    },
    props: (route) => ({ query: route.query.id }),
  },
  {
    name: "VAEmployee",
    path: "employees",
    component: VAEmployee,
    meta: {
      title: "Employee",
    },
  },
  {
    name: "VAAddEmployee",
    path: "addemployee",
    component: VAAddEmployee,
    meta: {
      title: "Add Employee",
    },
  },
  {
    name: "VAEditEmployee",
    path: "updateemployee",
    component: VAEditEmployee,
    meta: {
      title: "Edit Employee",
    },
    props: (route) => ({ query: route.query.id }),
  },
  {
    name: "VAVehicles",
    path: "vehicles",
    component: VAVehicle,
    meta: {
      title: "Vehicles",
    },
  },
  {
    name: "VAAddVehicle",
    path: "addvehicle",
    component: VAAddVehicle,
    meta: {
      title: "Add Vehicle",
    },
  },
  {
    name: "VAEditVehicle",
    path: "updatevehicle",
    component: VAEditVehicle,
    meta: {
      title: "Edit Vehicle",
    },
    props: (route) => ({ query: route.query.id }),
  },
  {
    name: "VAUsers",
    path: "users",
    component: VAUsers,
    meta: {
      title: "Users",
    },
  },
  {
    name: "VASupportTickets",
    path: "support_tickets",
    component: VASupportTickets,
    meta: {
      title: "Support Tickets",
    },
  },
  {
    name: "VAAddSupportTicket",
    path: "support_tickets/add",
    component: VAAddSupportTicket,
    meta: {
      title: "Add New Support Ticket",
    },
  },
  {
    name: "VAUpdateSupportTicket",
    path: "support_tickets/update",
    component: VAUpdateSupportTicket,
    meta: {
      title: "Update Support Ticket",
    },
  },
  {
    name: "VATasks",
    path: "tasks",
    component: VATasks,
    meta: {
      title: "Tasks",
    },
  },
  {
    name: "VAAddTask",
    path: "tasks/add",
    component: VAAddTask,
    meta: {
      title: "Add Tasks",
    },
  },
  {
    name: "VAUpdateTask",
    path: "tasks/update",
    component: VAUpdateTask,
    meta: {
      title: "Update Tasks",
    },
  },
  {
    name: "VACompanyManuals",
    path: "company_manuals",
    component: VACompanyManuals,
    meta: {
      title: "Company Manuals",
    },
  },
  {
    name: "VAAddCompanyManual",
    path: "company_manuals/add",
    component: VAAddCompanyManual,
    meta: {
      title: "Add Company Manual",
    },
  },
  {
    name: "VAUpdateCompanyManual",
    path: "company_manuals/update",
    component: VAUpdateCompanyManual,
    meta: {
      title: "Update Company Manual",
    },
  },
  {
    name: "VAAddUser",
    path: "adduser",
    component: VAAddUser,
    meta: {
      title: "Add User",
    },
  },
  {
    name: "VAEditUser",
    path: "updateuser",
    component: VAEditUser,
    meta: {
      title: "Edit User",
    },
    props: (route) => ({ query: route.query.id }),
  },
  {
    name: "VAMenus",
    path: "menus",
    component: VAMenus,
    meta: {
      title: "All Menus",
    },
  },
  {
    name: "VAHeaderPage",
    path: "headerpage",
    component: VAHeaderPage,
    meta: {
      title: "Header Page Settings",
    },
  },
  {
    name: "VASettings",
    path: "settings",
    component: VASettings,
    meta: {
      title: "Account Settings",
    },
  },
  {
    name: "VAPages",
    path: "pages",
    component: VAPages,
    meta: {
      title: "All Pages",
    },
  },
  {
    name: "VAUpdateForm",
    path: "updateform",
    component: VAUpdateForm,
    meta: {
      title: "Update Form Builder",
    },
    props: (route) => ({ query: route.query.id }),
  },
  {
    name: "VAUpdatePages",
    path: "updatepage",
    component: VAUpdatePages,
    meta: {
      title: "All Pages",
    },
    props: (route) => ({ query: route.query.id }),
  },
  {
    name: "VAAttendance",
    path: "attendance",
    component: VAAttendance,
    meta: {
      title: "Employee Attendance",
    }
  },
  {
    name: "VARota",
    path: "rota",
    component: VARota,
    meta: {
      title: "Rota Calendar",
    }
  },
  {
    name: "VAForcasting",
    path: "forcasting",
    component: VAForcasting,
    meta: {
      title: "Employee Attendance",
    }
  },
  {
    name: "VABugetCap",
    path: "budgetcap",
    component: VABugetCap,
    meta: {
      title: "Rota Calendar",
    }
  },
];
