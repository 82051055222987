<template>

    <div :class="$attrs.class + ' ' + data.child_cssclass + ((data.child_display) ? '' : ' cond-hide ')" class="f-left"
    :data-key="$attrs.childindex"
    :data-sectionkey="$attrs.index">
        <template v-if="$attrs.isbuilder">
            <i class="ti-move movebtn" @click="addSortChild();"></i>
            <i class="ti-close removebtn" @click="removeCompunent($attrs.index, $attrs.childindex,$attrs.child_uniqueid);"></i>
            <i class="ti-settings settingbtn" @click="configureCompunent($attrs.childindex);"></i>
        </template>
        <div class="left-content wow fadeInLeft" data-wow-delay="0s">
            <h2 class="wow fadeInLeft" data-wow-delay="0.1s">{{ data.meta.heading }}</h2>
            <p class="wow fadeInLeft" data-wow-delay="0.2s">
                {{ data.meta.shortdescription }}
            </p>
            <a :href="data.meta.buttonlink" class="btn btn-primary btn-round btn-action btn-fill wow fadeInLeft"
                v-if="data.meta.buttontext != ''">{{
                    data.meta.buttontext
                }}</a>
        </div>
    </div>
    <div class="f-right" :style="{backgroundImage: `url(${data.meta.src})`}" style="background-position: 50% 50%;
    background-size: cover;
">
    </div>

</template>
<script setup>
let props = defineProps({
    data: Array,
});
var definedEmits = defineEmits(['addSortChild', 'removeCompunent', 'configureCompunent'])

function addSortChild() {
    definedEmits('addSortChild');
}

function removeCompunent(index, childindex,child_uniqueid) {
    definedEmits('removeCompunent', index, childindex,child_uniqueid);
}
function configureCompunent(childindex) {
    definedEmits('configureCompunent', childindex);
}
</script>