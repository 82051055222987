<template>
    <div :class="$attrs.class + ' ' + data.child_cssclass + ((data.child_display) ? '' : ' cond-hide ')" class="counter-up"
    :data-key="$attrs.childindex"
    :data-sectionkey="$attrs.index">
        <template v-if="$attrs.isbuilder">
            <i class="ti-move movebtn" @click="addSortChild();"></i>
            <i class="ti-close removebtn"  @click="removeCompunent($attrs.index, $attrs.childindex,$attrs.child_uniqueid);"></i>
            <i class="ti-settings settingbtn" @click="configureCompunent($attrs.childindex);"></i>
        </template>
        <div class="counter-icon">
            <i class="" :class="data.meta.icon"></i>
        </div>
        <h3>{{ data.meta.heading }}</h3>
        <div class="counter-text">
            <h4>{{ data.meta.shortdescription }}</h4>
        </div>
    </div>
</template>
<script setup>
let props = defineProps({
    data: Array,
});
var definedEmits = defineEmits(['addSortChild', 'removeCompunent', 'configureCompunent'])

function addSortChild() {
    definedEmits('addSortChild');
}

function removeCompunent(index, childindex,child_uniqueid) {
    definedEmits('removeCompunent', index, childindex,child_uniqueid);
}
function configureCompunent(childindex) {
    definedEmits('configureCompunent', childindex);
}
</script>