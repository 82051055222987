<template>
    <div class="pcoded-inner-content">

        <div class="main-body">
            <div class="page-wrapper">


                <!-- Page-body start -->
                <div class="page-body">
                    <div class="card">
                        <div class="card-header">
                            <h5>
                                <span>Single Resident Information</span>
                            </h5>
                            <div class="card-header-right">
                                <div class="page-header-breadcrumb">
                                    <ul class="breadcrumb-title">
                                        <li class="breadcrumb-item" style="float: left;">
                                            <a href="index.html"> <i class="feather icon-home"></i> </a>
                                        </li>
                                        <li class="breadcrumb-item" style="float: left;"><router-link
                                                :to="{ name: 'VAResidents' }">Residents</router-link>
                                        </li>
                                        <li class="breadcrumb-item" style="float: left;"><a href="#!">Single Resident
                                                Info</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div class="row card-body">
                            <div class="col-lg-12">

                                <ul class="nav nav-tabs md-tabs tabs-left" role="tablist" id="mytab">
                                    <li class="nav-item">
                                        <a class="nav-link active" data-bs-toggle="tab" href="#personal" role="tab">
                                            Basic Information </a>
                                        <div class="slide"></div>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-bs-toggle="tab" href="#residentassosicated" role="tab">
                                            Resident
                                            Associations </a>
                                        <div class="slide"></div>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-bs-toggle="tab" href="#peopleassociated" role="tab">
                                            People
                                            Associated With The Resident </a>
                                        <div class="slide"></div>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-bs-toggle="tab" href="#contactdetails" role="tab">
                                            Contact
                                            Details </a>
                                        <div class="slide"></div>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-bs-toggle="tab" href="#healthinfo" role="tab"> Health
                                            Details </a>
                                        <div class="slide"></div>
                                    </li>

                                </ul>
                                <div class="tab-content tabs-left-content card-block" style="width: 100%">
                                    <!-- tab panel personal start -->
                                    <div class="tab-pane active" id="personal" role="tabpanel">
                                        <!-- personal card start -->
                                        <form>
                                            <div class="row">
                                                <image-input v-model="form.ry_picture" class="col-md-4"
                                                    :source="form.ry_picture" kStyle="height:20vh;" />

                                                <div class="col-md-8">
                                                    <div class="row">
                                                        <text-input v-model="form.ry_name" class="col-md-6" label="Name" />
                                                        <select-input v-model="form.ry_company_id"
                                                            class="col-md-6 my-select selectpicker" label="Company">
                                                            <option value="">Select...</option>
                                                            <option v-for="comp in companiesdata" :value="comp.c_id">{{
                                                                comp.c_name }}</option>
                                                        </select-input>
                                                        <text-input v-model="form.ry_start_date" type="date"
                                                            class="col-md-6" label="Start Date" />
                                                        <text-input v-model="form.ry_end_date" type="date" class="col-md-6"
                                                            label="End Date" />
                                                        <select-input v-model="form.ry_administrator" class="col-md-6"
                                                            label="Administrator">
                                                            <option value="">Select...</option>
                                                            <option v-for="user in usersdata" :value="user.u_id">
                                                                {{ user.u_name }}
                                                            </option>
                                                        </select-input>
                                                        <select-input v-model="form.ry_region_id" class="col-md-6"
                                                            label="Region (Optional)">
                                                            <option value="">Select...</option>
                                                            <option v-for="reg in regionsdata" :value="reg.r_id">
                                                                {{ reg.r_name }}
                                                            </option>
                                                        </select-input>

                                                    </div>
                                                </div>

                                                <div class="col-md-12">
                                                    <hr />
                                                    <div class="row">

                                                        <select-input v-model="form.ry_correspondinguser_id"
                                                            class="col-md-4" label="Corresponding User">
                                                            <option value="0">None</option>
                                                            <option v-for="user in usersdata" :value="user.u_id">
                                                                {{ user.u_name }}
                                                            </option>
                                                        </select-input>
                                                        <select-input v-model="form.ry_residenttype_id" class="col-md-4"
                                                            label="Resident Type">
                                                            <option value="">None</option>
                                                            <option v-for="user in residenttypedata" :value="user.residenttype_id">
                                                                {{ user.residenttype_type }}
                                                            </option> 
                                                        </select-input>
                                                        <select-input v-model="form.ry_keyworker_id" class="col-md-4"
                                                            label="Key Worker">
                                                            <option value="">Select...</option>
                                                            <option v-for="user in usersdata" :value="user.u_id">
                                                                {{ user.u_name }}
                                                            </option>
                                                        </select-input>

                                                        <select-input v-model="form.ry_practicemanager_id" class="col-md-4"
                                                            label="Practice Manager">
                                                            <option value="">Select...</option>
                                                            <option v-for="user in usersdata" :value="user.u_id">
                                                                {{ user.u_name }}
                                                            </option>
                                                        </select-input>
                                                        <select-input v-model="form.ry_therapist_id" class="col-md-4"
                                                            label="Therapist">
                                                            <option value="">Select...</option>
                                                            <option v-for="user in usersdata" :value="user.u_id">
                                                                {{ user.u_name }}
                                                            </option>
                                                        </select-input>
                                                        <select-input v-model="form.ry_responsible_id" class="col-md-4"
                                                            label="Person with Responsibility">
                                                            <option value="">Select...</option>
                                                            <option v-for="user in usersdata" :value="user.u_id">
                                                                {{ user.u_name }}
                                                            </option>
                                                        </select-input>
                                                        <select-input v-model="form.ry_allowalluser" class="col-md-12"
                                                            label="Allow all users with access to this resident's home to be given access to this resident?">
                                                            <option value="0">No</option>
                                                            <option value="1">Yes</option>
                                                        </select-input>
                                                        <select-input v-model="form.ry_isemergency" class="col-md-4"
                                                            label="Is this an emergency placement?">
                                                            <option value="">N/A</option>
                                                            <option value="0">No</option>
                                                            <option value="1">Yes</option>
                                                        </select-input>
                                                        <select-input v-model="form.ry_gender_id" class="col-md-4"
                                                            label="Gender">
                                                            <option value="">Select...</option>
                                                            <option v-for="gender, k in genderdata" :value="k">
                                                                {{ gender }}
                                                            </option>
                                                        </select-input>
                                                        <select-input v-model="form.ry_ethnicity" class="col-md-4"
                                                            label="Ethnicity">
                                                            <option value="">Select...</option>
                                                            <option v-for="eth in ethinicitydata" :value="eth.ethinicity_id">
                                                                {{ eth.ethinicity_name }}
                                                            </option>  
                                                        </select-input>
                                                        <text-input v-model="form.ry_room_no" class="col-md-4"
                                                            label="Room Number" />
                                                        <text-input v-model="form.ry_prefered_name" class="col-md-4"
                                                            label="Prefered Name" />
                                                        <text-input v-model="form.ry_insurance_no" class="col-md-4"
                                                            label="National Insurance Number" />
                                                        <text-input type="date" v-model="form.ry_dob" class="col-md-4"
                                                            label="Date of Birth" />
                                                        <select-input v-model="form.ry_legalstatus" class="col-md-4"
                                                            label="Legal Status">
                                                            <option value="0">Select</option>
                                                            <option value="1">Section 17</option>
                                                        </select-input>
                                                        <text-input v-model="form.ry_relegion_id" class="col-md-4"
                                                            label="Religion" />
                                                    </div>
                                                </div>
                                                <div class="col-md-12 text-center">
                                                    <button type="button"
                                                        class="btn btn-primary btn-round waves-effect waves-light funload"
                                                        @click="updateResident()">Update Changes</button>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                    <!-- tab pane personal end -->
                                    <!-- tab pane info start -->
                                    <div class="tab-pane" id="residentassosicated" role="tabpanel">

                                        <div class="row">
                                            <select-input v-model="resident_in" class="col-md-4"
                                                label="Resident in Home or School">
                                                <option value="0">Select</option>
                                                <option value="1">Home</option>
                                            </select-input>
                                            <text-input v-model="admission_date" class="col-md-3" label="Admission Date" />
                                            <text-input v-model="leaving_date" class="col-md-3" label="Leaving Date" />
                                            <div class="col-md-2">
                                                <label style="width: 100%">&nbsp;</label>
                                                <button type="button" class="btn btn-warning btn-block"
                                                    @click="addResidentAssociation()"><i class="ti-plus"></i></button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>Resident In</th>
                                                        <th>Admission Date</th>
                                                        <th>Leaving Date</th>
                                                        <th>Status</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <template v-if="form.resident_association">
                                                        <tr v-for="residentassociation in form.resident_association">
                                                            <td>{{ residentassociation.resident_in }}</td>
                                                            <td>{{
                                                                residentassociation.resident_admission_date
                                                            }}</td>
                                                            <td>{{
                                                                residentassociation.resident_leaving_date
                                                            }}</td>
                                                            <td>{{ residentassociation.status }}</td>
                                                            <td>

                                                            </td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                            </table>
                                        </div>


                                        <!-- info card end -->
                                    </div>
                                    <!-- tab pane info end -->
                                    <!-- tab pane info start -->
                                    <div class="tab-pane" id="peopleassociated" role="tabpanel">

                                        <div class="row">
                                            <div class="col-md-12">
                                                <button type="button" class="btn btn-warning fr btn-block"
                                                    @click="showmodal('#add_people_associated')"><i class="ti-plus"></i>
                                                    Add
                                                    New</button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Gender</th>
                                                        <th>Relation</th>
                                                        <th>Contact Allowed?</th>
                                                        <th>Reason</th>
                                                        <th>Religion</th>
                                                        <th>Address</th>
                                                        <th>Comment</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <template v-if="form.resident_people_association">
                                                        <tr v-for="people in form.resident_people_association">
                                                            <td>{{ people.name }}</td>
                                                            <td>{{ people.gender }}</td>
                                                            <td>{{ people.relation }}</td>
                                                            <td>{{ people.allowed_contact }}</td>
                                                            <td>{{ people.reason_no_contact }}</td>
                                                            <td>{{ people.religion }}</td>
                                                            <td>{{ people.address }}</td>
                                                            <td>{{ people.comment }}</td>
                                                            <td>

                                                            </td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                            </table>
                                        </div>


                                        <!-- info card end -->
                                    </div>
                                    <!-- tab pane info end -->
                                    <div class="tab-pane" id="contactdetails" role="tabpanel">
                                        <!-- info card start -->
                                        <div class="row">
                                            <textarea-input v-model="form.meta.resident_personaldescription"
                                                class="col-md-4" rows="3" label="Personal Description"></textarea-input>
                                            <textarea-input v-model="form.meta.resident_contactdetails" class="col-md-4"
                                                rows="3" label="Contact Details"></textarea-input>
                                            <textarea-input v-model="form.meta.resident_prevaddress" class="col-md-4"
                                                rows="3" label="Previous Address"></textarea-input>
                                            <div class="col-md-12 text-center">
                                                <hr />
                                                <h6><b>Local Authority</b></h6>
                                            </div>
                                            <select-input v-model="form.meta.resident_placingauthority" class="col-md-12"
                                                label="Placing Authority">
                                                <option value="">Select...</option>
                                                <option v-for="eth in placingauthoritydata" :value="eth.placingauthority_id">
                                                    {{ eth.placingauthority_name }}
                                                </option>  
                                            </select-input>
                                            <textarea-input v-model="form.meta.resident_billingdetails" class="col-md-4"
                                                label="Billing Details"></textarea-input>
                                            <textarea-input v-model="form.meta.resident_socialworker" class="col-md-4"
                                                label="Social Worker"></textarea-input>
                                            <textarea-input v-model="form.meta.resident_reviewingofficer" class="col-md-4"
                                                label="Independent Reviewing Officer"></textarea-input>
                                            <div class="col-md-12 text-center">
                                                <hr />
                                                <h6><b>Education Details</b></h6>
                                            </div>
                                            <text-input v-model="form.meta.resident_universalpupilnumber" class="col-md-4"
                                                label="Universal Pupil Number" />
                                            <select-input v-model="form.meta.resident_schoolrunby_caregroup"
                                                class="col-md-5" label="Attends school run by care group?">
                                                <option value="">Select</option>
                                                <option value="0">No</option>
                                                <option value="1">Yes</option>
                                            </select-input>
                                            <select-input v-model="form.meta.senstatement" class="col-md-3"
                                                label="SEN Statement">
                                                <option value="">Select</option>
                                                <option value="0">No</option>
                                                <option value="1">Yes</option>
                                            </select-input>

                                            <textarea-input v-model="form.meta.resident_schoolattented" class="col-md-12"
                                                rows="4" label="School Attended or Educational Provision"></textarea-input>

                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 text-center">
                                                <button type="button"
                                                    class="btn btn-primary btn-round waves-effect waves-light funload"
                                                    @click="updateResident()">Update Changes</button>
                                            </div>
                                        </div>
                                        <!-- info card end -->
                                    </div>
                                    <div class="tab-pane" id="healthinfo" role="tabpanel">
                                        <div class="row">
                                            <text-input v-model="form.meta.resident_nhsnumber" class="col-md-6"
                                                label="NHS Number" />

                                            <select-input v-model="form.meta.resident_disabled" class="col-md-6"
                                                label="Registered Disabled">
                                                <option value="">Select</option>
                                                <option value="0">No</option>
                                                <option value="1">Yes</option>
                                            </select-input>
                                            <textarea-input v-model="form.meta.resident_doctor_admission" class="col-md-4"
                                                rows="3" label="Doctor on Admission"></textarea-input>
                                            <textarea-input v-model="form.meta.resident_doctor" class="col-md-4" rows="3"
                                                label="Doctor"></textarea-input>
                                            <textarea-input v-model="form.meta.resident_dentist" class="col-md-4" rows="3"
                                                label="Dentist"></textarea-input>
                                            <textarea-input v-model="form.meta.resident_optician" class="col-md-4" rows="3"
                                                label="Optician"></textarea-input>
                                            <textarea-input v-model="form.meta.resident_hospital" class="col-md-4" rows="3"
                                                label="Hospital"></textarea-input>
                                            <textarea-input v-model="form.meta.resident_medicalneeds" class="col-md-4"
                                                rows="3" label="Medical Needs"></textarea-input>
                                            <textarea-input v-model="form.meta.resident_alergies" class="col-md-4" rows="3"
                                                label="Known Allergies"></textarea-input>
                                            <textarea-input v-model="form.meta.resident_healthdetails" class="col-md-8"
                                                rows="3" label="Other Important Health Details"></textarea-input>


                                            <select-input v-model="form.meta.resident_distinationtype" class="col-md-12"
                                                label="Discharge Destination Type">
                                                <option value="0">Select</option>
                                                <option value="1">Authority One</option>
                                                <option value="2">Authority Two</option>
                                            </select-input>
                                            <textarea-input v-model="form.meta.resident_distinationtype_details"
                                                class="col-md-4" label="Destination Type if Other"></textarea-input>
                                            <text-input v-model="form.meta.home_postcode" class="col-md-8"
                                                label="Postcode" />

                                            <textarea-input v-model="form.meta.resident_discharge_address" class="col-md-12"
                                                rows="4" label="Discharge Destination Address"></textarea-input>

                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 text-center">
                                                <button type="button"
                                                    class="btn btn-primary btn-round waves-effect waves-light funload"
                                                    @click="updateResident()">Update Changes</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- tab content end -->

                            </div>
                        </div>
                    </div>
                </div>
                <!-- Page-body end -->
            </div>
        </div>
    </div>
</template>
<script setup>
import { functions } from '../../../../common/appFunctions.js';
import SelectInput from '../../../shared/SelectBox.vue';
import TextInput from '../../../shared/TextInput'
import ImageInput from '../../../shared/ImageInput.vue'
import TextareaInput from '../../../shared/TextareaInput.vue'
import { onMounted, ref, reactive, provide } from 'vue';
import { useAuthStore } from '@/store/auth.js'
import factory from '@/common/factory.js'
import { useRouter, useRoute } from 'vue-router';
const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();

// ****** Dropdowns data ********************
var companiesdata = ref([]);
var usersdata = ref([]);
var regionsdata = ref([]);
var genderdata = ref([]);
var residenttypedata = ref([]);
var placingauthoritydata = ref([]);
var ethinicitydata = ref([]);
// ****** Dropdowns data ********************

var form = ref({
    ry_picture: 'https://demo.dashboardpack.com/adminty-html/files/assets/images/gallery-grid/1.png',
    ry_name: '',
    ry_company_id: '',
    ry_start_date: '',
    ry_end_date: '',
    ry_administrator: '',
    ry_region_id: '',
    ry_correspondinguser_id: '',
    ry_residenttype_id: '',
    ry_keyworker_id: '',
    ry_practicemanager_id: '',
    ry_therapist_id: '',
    ry_responsible_id: '',
    ry_allowalluser: '',
    ry_isemergency: '',
    ry_gender_id: '',
    ry_ethnicity: '',
    ry_room_no: '',
    ry_prefered_name: '',
    ry_insurance_no: '',
    ry_dob: '',
    ry_legalstatus: '',
    ry_relegion_id: '',
    meta: {
        resident_personaldescription: '',
        resident_contactdetails: '',
        resident_prevaddress: '',
        resident_placingauthority: '',
        resident_billingdetails: '',
        resident_socialworker: '',
        resident_reviewingofficer: '',
        resident_universalpupilnumber: '',
        resident_schoolrunby_caregroup: '',
        senstatement: '',
        resident_schoolattented: '',
        resident_nhsnumber: '',
        resident_disabled: '',
        resident_doctor_admission: '',
        resident_doctor: '',
        resident_dentist: '',
        resident_optician: '',
        resident_hospital: '',
        resident_medicalneeds: '',
        resident_alergies: '',
        resident_healthdetails: '',
        resident_distinationtype: '',
        resident_distinationtype_details: '',
        home_postcode: '',
        resident_discharge_address: '',
    },
    resident_association: [],
    resident_people_association: [],
});
function addResidentAssociation() {
    if (resident_in.value.trim() == '') {
        alert("Kindly Enter Resident!");
        return false;
    }
    form.resident_association.push({
        resident_in: resident_in.value,
        resident_admission_date: admission_date.value,
        resident_leaving_date: leaving_date.value,
        status: '',
    });
    resident_in.value = '';
    admission_date.value = '';
    leaving_date.value = '';
}

function addPeopleAssociated() {
    if (person_associated_name.value.trim() == '') {
        alert("Kindly Enter Person Name!");
        return false;
    }
    form.resident_people_association.push({
        name: person_associated_name,
        gender: person_associated_gender,
        relation: person_associated_relation,
        allowed_contact: person_associated_allowed_contact,
        reason_no_contact: person_associated_nocontact_reason,
        religion: person_associated_religion,
        address: person_associated_address,
        comment: person_associated_comment,
    });
}
provide('getToken', getToken());
function getToken() {
    const token = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authStore.token,
        },
    };
    return token;
}
async function updateResident() {
    const Resident = new factory(getToken(), 'residents', form.value);
    await Resident.putForm().then((response) => {
        functions.destroyBtnLoader();
        if (response.data.status == "success") {
            // router.push({ path: 'residents' })
            functions.kSwal('Done', 'Changes Saved Successfully.');
        } else {
            console.log(response.data.message);
        }
    });
};
onMounted(async () => {
    var Resident = new factory(getToken(), 'getallapis', { index: ['companies', 'users', 'regions','gender','residenttype','placingauthority','ethinicity'] });
    await Resident.postForm().then((response) => {
        if (response.data.status == "success") {
            companiesdata.value = response.data.data.companies;
            usersdata.value = response.data.data.users;
            regionsdata.value = response.data.data.regions;
            genderdata.value = response.data.data.gender;
            residenttypedata.value = response.data.data.residenttype;
            placingauthoritydata.value = response.data.data.placingauthority;
            ethinicitydata.value = response.data.data.ethinicity;
        }
    });

    Resident = new factory(getToken(), 'resident/' + route.query.id);
    await Resident.getHttp().then(response => {
        if (response.data.status == "success") {
            form.value = response.data.data;
            form.value.meta = (typeof form.value.meta != 'object') ? JSON.parse(form.value.meta) : form.value.meta;
            console.log(response.data.data);
        } else {
            return response.data.message;
        }
    });
    functions.callOnReady();
})
</script>
<style scoped>
.tabs-left {
    min-width: 200px !important;
}
</style>