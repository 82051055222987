import { reactive } from 'vue'

export const appConsts = reactive({
  appName: 'Care Doodles.',
  appTag: 'A Caring Company',
  // baseURL: 'https://multishioapi.t6ve.com/api/v1/',
  baseURL: 'https://caredoodles.t6ve.com/api/v1/',
});


export default {
  name: "Constants",
  data() {
      return {
          appConsts,
          langConsts,
          Vendor: 1
      }
  },
}
