<template>
  <div class="pcoded-inner-content">
    <div class="main-body">
      <div class="page-wrapper">
        <!-- Page-body start -->
        <div class="page-body">
          <div class="card">
            <div class="card-header">
              <h5>
                <span>Single Employee Information</span>
              </h5>
              <div class="card-header-right">
                <div class="page-header-breadcrumb">
                  <ul class="breadcrumb-title">
                    <li class="breadcrumb-item" style="float: left">
                      <a href="index.html">
                        <i class="feather icon-home"></i>
                      </a>
                    </li>
                    <li class="breadcrumb-item" style="float: left">
                      <router-link :to="{ name: 'VAEmployee' }">Employee</router-link>
                    </li>
                    <li class="breadcrumb-item" style="float: left">
                      <a href="#!">Single Employee Info</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row card-body">
              <div class="col-lg-12">
                <ul class="nav nav-tabs md-tabs tabs-left" role="tablist" id="mytab">
                  <li class="nav-item">
                    <a class="nav-link active" data-bs-toggle="tab" href="#personal" role="tab">
                      Basic Information
                    </a>
                    <div class="slide"></div>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#residentassosicated" role="tab">
                      Detailed Information
                    </a>
                    <div class="slide"></div>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#homesassigned" role="tab">
                      Homes Assigned</a>
                    <div class="slide"></div>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#rolesassigned" role="tab">
                      Roles Assigned</a>
                    <div class="slide"></div>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#wagesalary" role="tab">
                      Wage & Salary</a>
                    <div class="slide"></div>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#peopleassociated" role="tab">
                      Previous Experience</a>
                    <div class="slide"></div>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#contactdetails" role="tab">
                      Employee Associations
                    </a>
                    <div class="slide"></div>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#healthinfo" role="tab">
                      Qualification Records
                    </a>
                    <div class="slide"></div>
                  </li>
                </ul>
                <div class="tab-content tabs-left-content card-block" style="width: 100%">
                  <!-- tab panel personal start -->
                  <div class="tab-pane active" id="personal" role="tabpanel">
                    <!-- personal card start -->
                    <form>
                      <div class="row">
                        <image-input v-model="form.employee_picture" class="col-md-4" :source="form.employee_picture"
                          kStyle="height:20vh;" />

                        <div class="col-md-8">
                          <div class="row">
                            <text-input v-model="form.employee_name" class="col-md-6" label="Name" />
                            <select-input v-model="form.employee_company_id" class="col-md-6 my-select selectpicker"
                              label="Company">
                              <option value="">Select...</option>
                              <option v-for="comp in companiesdata" :value="comp.c_id">
                                {{ comp.c_name }}
                              </option>
                            </select-input>
                            <text-input v-model="form.employee_start_date" type="date" class="col-md-6"
                              label="Start Date" />
                            <text-input v-model="form.employee_end_date" type="date" class="col-md-6" label="End Date" />
                            <select-input v-model="form.employee_administrator" class="col-md-6" label="Administrator">
                              <option value="">Select...</option>
                              <option v-for="user in usersdata" :value="user.u_id">
                                {{ user.u_name }}
                              </option>
                            </select-input>
                            <select-input v-model="form.employee_region" class="col-md-6" label="Region (Optional)">
                              <option value="">Select...</option>
                              <option v-for="reg in regionsdata" :value="reg.r_id">
                                {{ reg.r_name }}
                              </option>
                            </select-input>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <hr />
                        </div>
                        <select-input v-model="form.employee_correspondinguser" class="col-md-4"
                          label="Corresponding User">
                          <option value="">Select...</option>
                          <option v-for="user in usersdata" :value="user.u_id">
                            {{ user.u_name }}
                          </option>
                        </select-input>
                      </div>
                      <div class="col-md-12 text-center">
                        <button type="button" class="btn btn-primary btn-round waves-effect waves-light funload"
                          @click="updateEmployee()">
                          Update Changes
                        </button>
                      </div>
                    </form>
                  </div>
                  <!-- tab pane personal end -->
                  <!-- tab pane info start -->
                  <div class="tab-pane" id="residentassosicated" role="tabpanel">
                    <div class="row">
                      <text-input v-model="form.employee_nationality" class="col-md-4" label="Nationality" />
                      <select-input v-model="form.employee_ethnicity" class="col-md-4" label="Ethnicity">
                        <option value="">Select...</option>
                        <option v-for="con in ethinicitydata" :value="con.ethinicity_id">
                          {{ con.ethinicity_name }}
                        </option>
                      </select-input>
                      <select-input v-model="form.employee_gender" class="col-md-4" label="Gender">
                        <option value="">Select...</option>
                        <option v-for="(gender, k) in genderdata" :value="k">
                          {{ gender }}
                        </option>
                      </select-input>
                      <select-input v-model="form.employee_resideshome" class="col-md-4" label="Resides at Care Home?">
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                      </select-input>
                      <text-input v-model="form.employee_nextofkin" class="col-md-4" label="Next Of Kin" />
                      <text-input v-model="form.employee_ninnumber" class="col-md-4" label="National Insurance Number" />
                      <select-input v-model="form.employee_employmenttype" class="col-md-4" label="Employment Type">
                        <option value="">Select...</option>
                        <option v-for="con in employmenttypedata" :value="con.id">
                          {{ con.priority }}
                        </option>
                      </select-input>
                      <select-input v-model="form.employee_contracttype" class="col-md-4" label="Contract Type">
                        <option value="">Select...</option>
                        <option v-for="con in contracttypedata" :value="con.id">
                          {{ con.priority }}
                        </option>
                      </select-input>
                      <text-input v-model="form.employee_jobtitle" class="col-md-4" label="Job Title" />
                      <text-input type="date" v-model="form.employee_joiningdate" class="col-md-4" label="Joining Date" />
                      <text-input type="date" v-model="form.employee_leavingdate" class="col-md-4" label="Leaving Date" />
                      <text-input v-model="form.employee_contractinghours" class="col-md-4" label="Contracting Hours" />

                      <select-input v-model="form.employee_incarerole" class="col-md-4" label="In Care Role?">
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                      </select-input>
                      <text-input v-model="form.employee_currentgrading" class="col-md-4" label="Current Grading" />
                    </div>
                    <div class="row">
                      <div class="col-md-12 text-center">
                        <button type="button" class="btn btn-primary btn-round waves-effect waves-light funload"
                          @click="updateEmployee()">
                          Update Changes
                        </button>
                      </div>
                    </div>

                    <!-- info card end -->
                  </div>
                  <!-- tab pane info end -->
                  <!-- tab pane info start -->
                  <!-- tab pane personal end -->
                  <!-- tab pane info start -->
                  <div class="tab-pane" id="homesassigned" role="tabpanel">
                    <div class="row">
                      <!-- homesdata -->
                      <div class="col-md-3" v-for="home in homesdata">
                        <div class="form-group">
                          <div class="checkbox-fade fade-in-primary m-0">
                            <!-- home_id -->
                            <label>
                              <input type="checkbox" class="homesselected" :value="home.home_id"
                                :checked="(form.locations.some((item) => item.location_id == home.home_id))" />
                              <span class="cr">
                                <i class="cr-icon icofont icofont-ui-check txt-primary"></i>
                              </span>
                              <span> {{ home.home_name }}</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 text-center">
                        <button type="button" class="btn btn-primary btn-round waves-effect waves-light funload"
                          @click="updateEmployeeHomes()">
                          Update Changes
                        </button>
                      </div>
                    </div>

                    <!-- info card end -->
                  </div>
                  <div class="tab-pane" id="rolesassigned" role="tabpanel">
                    <div class="row">
                      <div class="col-md-3" v-for="rol in rolesdata">
                        <div class="form-group">
                          <div class="checkbox-fade fade-in-primary m-0 ">
                            <!-- home_id -->
                            <label>
                              <input type="checkbox" :value="rol.role_id" class="rolesselected"
                                :checked="(form.roles.some((item) => item.role_id == rol.role_id))" />
                              <span class="cr">
                                <i class="cr-icon icofont icofont-ui-check txt-primary"></i>
                              </span>
                              <span> {{ rol.role }}</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 text-center">
                        <button type="button" class="btn btn-primary btn-round waves-effect waves-light funload"
                          @click="updateEmployeeRoles()">
                          Update Changes
                        </button>
                      </div>
                    </div>

                    <!-- info card end -->
                  </div>
                  <div class="tab-pane" id="wagesalary" role="tabpanel">
                    <div class="row">Wages & Salary</div>
                    <div class="row">
                      <div class="col-md-12 text-center">
                        <button type="button" class="btn btn-primary btn-round waves-effect waves-light funload"
                          @click="updateEmployee()">
                          Update Changes
                        </button>
                      </div>
                    </div>

                    <!-- info card end -->
                  </div>
                  <!-- tab pane info end -->
                  <!-- tab pane info start -->
                  <div class="tab-pane" id="peopleassociated" role="tabpanel">
                    <div class="row">
                      <text-input v-model="form.employee_noofyear" class="col-md-6" label="Number of Years" />
                      <text-input v-model="form.employee_noofmonth" class="col-md-6" label="Number of Months" />
                      <select-input v-model="form.employee_probation" class="col-md-6" label="On Probation?">
                        <option value="">Select</option>
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                      </select-input>
                      <text-input v-model="form.employee_medicalinfo" class="col-md-6" label="Medical Information" />
                      <textarea-input v-model="form.employee_miscdetails" class="col-md-12" rows="3"
                        label="Miscellaneous Details"></textarea-input>
                    </div>
                    <div class="row">
                      <div class="col-md-12 text-center">
                        <button type="button" class="btn btn-primary btn-round waves-effect waves-light funload"
                          @click="updateEmployee()">
                          Update Changes
                        </button>
                      </div>
                    </div>
                    <!-- info card end -->
                  </div>
                  <!-- tab pane info end -->
                  <div class="tab-pane" id="contactdetails" role="tabpanel">
                    <!-- info card start -->
                    <div class="row">
                      <select-input v-model="resident_in" class="col-md-4" label="Employee in Home or School">
                        <option value="0">Select</option>
                        <option value="1">Home</option>
                      </select-input>
                      <text-input v-model="admission_date" class="col-md-3" type="date" label="Admission Date" />
                      <text-input v-model="leaving_date" class="col-md-3" type="date" label="Leaving Date" />
                      <div class="col-md-2">
                        <label style="width: 100%">&nbsp;</label>
                        <button type="button" class="btn btn-warning btn-block" @click="addEmployeeAssociation()">
                          <i class="ti-plus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="row">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Employee In</th>
                            <th>Admission Date</th>
                            <th>Leaving Date</th>
                            <th>Status</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-if="form.employee_association">
                            <tr v-for="employee_association in form.employee_association">
                              <td>{{ employee_association.employee_in }}</td>
                              <td>
                                {{
                                  employee_association.employee_admission_date
                                }}
                              </td>
                              <td>
                                {{ employee_association.employee_leaving_date }}
                              </td>
                              <td>{{ employee_association.status }}</td>
                              <td></td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                    <div class="row">
                      <div class="col-md-12 text-center">
                        <button type="button" class="btn btn-primary btn-round waves-effect waves-light funload"
                          @click="updateEmployee()">
                          Update Changes
                        </button>
                      </div>
                    </div>
                    <!-- info card end -->
                  </div>
                  <div class="tab-pane" id="healthinfo" role="tabpanel">
                    <div class="row">
                      <div class="col-md-10"></div>
                      <div class="col-md-2">
                        <button type="button" class="btn btn-warning btn-round fr btn-block"
                          @click="showmodal('#add_qualification')">
                          <i class="ti-plus"></i> Add New
                        </button>
                      </div>
                    </div>
                    <div class="row">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Qualification Name</th>
                            <th>Qualification Type</th>
                            <th>Qualification Date</th>
                            <th>Training Course Attended</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-if="form.employee_qualifications">
                            <tr v-for="qualifications in form.employee_qualifications">
                              <td>{{ qualifications.name }}</td>
                              <td>{{ qualifications.type }}</td>
                              <td>{{ qualifications.date }}</td>
                              <td>{{ qualifications.courseattended }}</td>
                              <td></td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                    <div class="row">
                      <div class="col-md-12 text-center">
                        <button type="button" class="btn btn-primary btn-round waves-effect waves-light funload"
                          @click="updateEmployee()">
                          Update Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- tab content end -->
              </div>
            </div>
          </div>
        </div>
        <!-- Page-body end -->
      </div>
    </div>
  </div>
  <div class="modal fade" id="add_qualification">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Add New</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            @click="hidemodal('#add_qualification')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <text-input class="col-md-6" v-model="qulaification_name" label="Qualification Name" />
            <text-input class="col-md-6" v-model="qulaification_course" label="Training Course Attended" />
            <select-input v-model="qulaification_type" class="col-md-4" label="Qualification Type">
              <option value="">Select</option>
            </select-input>
            <select-input v-model="qulaification_issuebody" class="col-md-4" label="Issuing Body">
              <option value="">Select</option>
            </select-input>
            <text-input type="date" class="col-md-4" v-model="qulaification_date" label="Qualification Date" />
            <text-input type="date" class="col-md-4" v-model="qulaification_expirydate" label="Expiry Date" />
            <text-input type="date" class="col-md-4" v-model="qulaification_refresherdate" label="Refresher Date" />
            <text-input type="text" class="col-md-12" v-model="qulaification_uploadedevidence"
              label="Upload Evidence Files (You can upload multiple files)" />
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default waves-effect" data-dismiss="modal"
            @click="hidemodal('#add_qualification')">
            Close
          </button>
          <button type="button" class="btn btn-primary btn-round waves-effect waves-light"
            @click="addEmployeeAssociated()">
            Save Information
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { functions } from "../../../../common/appFunctions.js";
import SelectInput from "../../../shared/SelectBox.vue";
import TextInput from "../../../shared/TextInput";
import ImageInput from "../../../shared/ImageInput.vue";
import TextareaInput from "../../../shared/TextareaInput.vue";
import { onMounted, ref, reactive, provide } from "vue";
import { useAuthStore } from "@/store/auth.js";
import factory from "@/common/factory.js";
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();

var resident_in = ref("");
var admission_date = ref("");
var leaving_date = ref("");

var qulaification_name = ref("");
var qulaification_type = ref("");
var qulaification_course = ref("");
var qulaification_issuebody = ref("");
var qulaification_date = ref("");
var qulaification_expirydate = ref("");
var qulaification_refresherdate = ref("");
var qulaification_uploadedevidence = ref("");

// ****** Dropdowns data ********************
var companiesdata = ref([]);
var usersdata = ref([]);
var regionsdata = ref([]);
var genderdata = ref([]);
var homesdata = ref([]);
var rolesdata = ref([]);
var employmenttypedata = ref([]);
var contracttypedata = ref([]);
var ethinicitydata = ref([]);
// ****** Dropdowns data ********************

const form = ref({
  employee_picture:
    "https://demo.dashboardpack.com/adminty-html/files/assets/images/gallery-grid/1.png",
  employee_name: "",
  employee_company_id: "",
  employee_start_date: "",
  employee_end_date: "",
  employee_administrator: "",
  employee_region: "",
  employee_correspondinguser: "",
  employee_nationality: "",
  employee_ethnicity: "",
  employee_gender: "",
  employee_resideshome: "",
  employee_nextofkin: "",
  employee_ninnumber: "",
  employee_employmenttype: "",
  employee_contracttype: "",
  employee_jobtitle: "",
  employee_joiningdate: "",
  employee_leavingdate: "",
  employee_contractinghours: "",
  employee_incarerole: "",
  employee_currentgrading: "",
  employee_noofyear: "",
  employee_noofmonth: "",
  employee_probation: "",
  employee_medicalinfo: "",
  employee_miscdetails: "",
  employee_association: [],
  employee_qualifications: [],
});

function addEmployeeAssociation() {
  if (resident_in.value.trim() == "") {
    alert("Kindly Enter Resident!");
    return false;
  }
  form.value.employee_association.push({
    employee_in: resident_in.value,
    employee_admission_date: admission_date.value,
    employee_leaving_date: leaving_date.value,
    status: "",
  });
  resident_in.value = "";
  admission_date.value = "";
  leaving_date.value = "";
}

function addEmployeeAssociated() {
  if (qulaification_name.value.trim() == "") {
    alert("Kindly Enter Qulification Name!");
    return false;
  }
  form.value.employee_qualifications.push({
    name: qulaification_name.value,
    courseattended: qulaification_course.value,
    type: qulaification_type.value,
    relation: qulaification_issuebody.value,
    date: qulaification_date.value,
    expirydate: qulaification_expirydate.value,
    refresherdate: qulaification_refresherdate.value,
    evidence: qulaification_uploadedevidence.value,
  });
  qulaification_name.value = "";
  qulaification_course.value = "";
  qulaification_issuebody.value = "";
  qulaification_date.value = "";
  qulaification_expirydate.value = "";
  qulaification_refresherdate.value = "";
  qulaification_uploadedevidence.value = "";
  hidemodal("#add_qualification");
}
provide("getToken", getToken());
function getToken() {
  const token = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authStore.token,
    },
  };
  return token;
}
async function updateEmployee() {
  const Company = new factory(getToken(), "employees", form.value);
  await Company.putForm().then((response) => {
    functions.destroyBtnLoader();
    if (response.data.status == "success") {
      // router.push({ path: 'companies' })
      functions.kSwal("Done", "Changes Saved Successfully.");
    } else {
      console.log(response.data.message);
    }
  });
}
async function updateEmployeeRoles() {
  var rolesselected = [];
  $('.rolesselected').each(function () {
    if ($(this).prop('checked')) {
      rolesselected.push($(this).attr('value'));
    }
  });
  var emprolesform = {
    "employee_id": form.value.employee_id,
    "roles": rolesselected
  }
  const Company = new factory(getToken(), "employeesroles", emprolesform);
  await Company.postForm().then((response) => {
    functions.destroyBtnLoader();
    if (response.data.status == "success") {
      // router.push({ path: 'companies' })
      functions.kSwal("Done", "Changes Saved Successfully.");
    } else {
      console.log(response.data.message);
    }
  });
}
async function updateEmployeeHomes() {
  var homesselected = [];
  $('.homesselected').each(function () {
    if ($(this).prop('checked')) {
      homesselected.push($(this).attr('value'));
    }
  });
  var emphomesform = {
    "employee_id": form.value.employee_id,
    "locations": homesselected
  }
  const Company = new factory(getToken(), "employeeslocations", emphomesform);
  await Company.postForm().then((response) => {
    functions.destroyBtnLoader();
    if (response.data.status == "success") {
      // router.push({ path: 'companies' })
      functions.kSwal("Done", "Changes Saved Successfully.");
    } else {
      console.log(response.data.message);
    }
  });
}
onMounted(async () => {
  var Employee = new factory(getToken(), "getallapis", {
    index: [
      "companies",
      "users",
      "regions",
      "gender",
      "homes",
      "employmenttype",
      "contracttype",
      "ethinicity",
      "roles",
    ],
  });
  await Employee.postForm().then((response) => {
    if (response.data.status == "success") {
      companiesdata.value = response.data.data.companies;
      usersdata.value = response.data.data.users;
      regionsdata.value = response.data.data.regions;
      genderdata.value = response.data.data.gender;
      homesdata.value = response.data.data.homes;
      rolesdata.value = response.data.data.roles;
      employmenttypedata.value = response.data.data.employmenttype;
      contracttypedata.value = response.data.data.contracttype;
      ethinicitydata.value = response.data.data.ethinicity;
    }
  });

  var Employee = new factory(getToken(), "employees/" + route.query.id);
  await Employee.getHttp().then((response) => {
    if (response.data.status == "success") {
      form.value = response.data.data;
      form.value.employee_association =
        typeof form.value.employee_association != "object"
          ? JSON.parse(form.value.employee_association)
          : form.value.employee_association;
      form.value.employee_qualifications =
        typeof form.value.employee_qualifications != "object"
          ? JSON.parse(form.value.employee_qualifications)
          : form.value.employee_qualifications;

    } else {
      return response.data.message;
    }
  });
  functions.callOnReady();
});
function showmodal(id) {
  $(id).modal("show");
}
function hidemodal(id) {
  $(id).modal("hide");
}
</script>
<style scoped>
.tabs-left {
  min-width: 200px !important;
}
</style>
