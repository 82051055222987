<template>
    <div class="page-wrapper">
        <!-- Page-header start -->
        <div class="page-header m-t-50">
            <div class="row align-items-end">
                <div class="col-lg-8">
                    <div class="page-header-title">
                        <div class="d-inline">
                            <h4>Vendor Layout Page 2</h4>
                            <span>lorem ipsum dolor sit amet, consectetur adipisicing
                                elit</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="page-header-breadcrumb">
                        <ul class="breadcrumb-title">
                            <li class="breadcrumb-item" style="float: left;">
                                <a href="index.html">
                                    <i class="icofont icofont-home"></i>
                                </a>
                            </li>
                            <li class="breadcrumb-item" style="float: left;"><a href="#!">Page Layouts</a>
                            </li>
                            <li class="breadcrumb-item" style="float: left;"><a href="#!">Horizontal</a>
                            </li>
                            <li class="breadcrumb-item" style="float: left;"><a href="#!">fixed Layout</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Page-header end -->
        <!-- Page body start -->
        <div>working on the main body pages</div>
        <!-- Page body end -->
    </div>
</template>

<script>


export default {
    data() {
        return {

        };
    },

};
</script>
