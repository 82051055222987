<template>
    <div class="main-body">
        <div class="page-wrapper">
             
            <!-- Page body start -->
            <div class="page-body">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-header">
                                <h5>
                                    <span>Labour Forecasting</span>
                                </h5>
                                <span class="text-muted">Labour forecasting lets you accurately plan your rotas by generating labour
                                    requirements based on predicted revenue. </span>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <select-input v-model="location_id" class="col-md-3" label="Location">
                                        <option value="1">Location 1</option>
                                    </select-input>


                                    <text-input v-model="year" class="col-md-3" label="Year" />
                                    <div class="col-md-3 mt-1">
                                        <div class="form-group">
                                            <label style="width:100%">&nbsp;</label>
                                            <button type="button"
                                                class="btn btn-block btn-primary btn-round waves-effect waves-light "
                                                @click="reloadData();">Apply Changes</button> 
                                        </div>
                                    </div>
                                    <div class="col-md-3 mt-1">
                                        <div class="form-group">
                                            <label style="width:100%">&nbsp;</label> 
                                            <button type="button"
                                                class="btn btn-block btn-dark waves-effect waves-light "
                                                @click="consoleData();">Console Object</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-body p-0 ">
                                <div class="col-md-12 table-responsive p-0">
                                    <table class="table ">
                                        <thead>
                                            <tr>
                                                <th class="text-center" style="width:5%">WK #</th>
                                                <th class="text-center" style="width:15%">Weekly est. Revenue</th>
                                                <th class="text-center" style="width:15%">Weekly labour % target <br />
                                                    (calculated)</th>
                                                <th style="width:85%" class="p-0">
                                                    <table
                                                        style="width:100%;background: #2e333e;color:white;height:50px;"
                                                        class="table table-bordered mb-0">
                                                        <tbody>
                                                            <th class="text-center ta-middle">M</th>
                                                            <th class="text-center ta-middle">T</th>
                                                            <th class="text-center ta-middle">W</th>
                                                            <th class="text-center ta-middle">T</th>
                                                            <th class="text-center ta-middle">F</th>
                                                            <th class="text-center ta-middle">S</th>
                                                            <th class="text-center ta-middle">S</th>
                                                        </tbody>
                                                    </table>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-if="datesarray.length > 0">
                                                <tr v-for="weeks, index in datesarray" :class="$moment(weeks[0]).add(6, 'Days').format('YYYY_MM_DD')+' '+$moment(weeks[0]).add(5, 'Days').format('YYYY_MM_DD')+' '+$moment(weeks[0]).add(4, 'Days').format('YYYY_MM_DD')+' '+$moment(weeks[0]).add(3, 'Days').format('YYYY_MM_DD')+' '+$moment(weeks[0]).add(2, 'Days').format('YYYY_MM_DD')+' '+$moment(weeks[0]).add(1, 'Days').format('YYYY_MM_DD')+' '+$moment(weeks[0]).format('YYYY_MM_DD')" :data-index="index">
                                                    <td class="ta-middle">{{ index+ 1 }}</td>
                                                    <td class="ta-middle text-center">
                                                        <span class="pb-1">{{
                                                            weeks[0].format('DD MMM') + ' - ' +
                                                                $moment(weeks[0]).add(6, 'Days').format('DD MMM YYYY')
                                                        }}</span>

                                                        <div class="input-group mt-1 pr-0 mb-0  input-group-sm">
                                                            <div class="input-group-prepend">
                                                                <span
                                                                    class="input-group-text input-group-text-sm ">£</span>
                                                            </div>
                                                            <input type="text" v-model="weeks[1]"
                                                                @change="totalChange(index,weeks[0]);"
                                                                class="form-control form-bg-inverse totalpound form-control-sm form-control-right">
                                                        </div>
                                                    </td>
                                                    <td class="ta-middle text-center">
                                                        <span v-show="weeks[3][0] == '' && weeks[3][0] == 0">-</span>
                                                        <div class="input-group  pr-0 mb-0 pt-0 input-group-sm"
                                                            v-show="weeks[3][0] != '' && weeks[3][0] > 0">
                                                            <div class="input-group-prepend">
                                                                <span
                                                                    style="font-size:0.5rem;background: black;color: white;"
                                                                    class="input-group-text input-group-text-sm ">%</span>
                                                            </div>
                                                            <input type="text" readonly v-model="weeks[3][0]" :style="{
                                                                fontSize: '0.5rem',
                                                            }" class="form-control  total_inpercent form-control-sm ">
                                                        </div>
                                                        <div class="input-group  pr-0 mb-0 pt-0 input-group-sm"
                                                            v-show="weeks[3][1] != '' && weeks[3][1] > 0">
                                                            <div class="input-group-prepend">
                                                                <span
                                                                    style="font-size:0.5rem;background: black;color: white;"
                                                                    class="input-group-text input-group-text-sm ">£</span>
                                                            </div>
                                                            <input type="text" readonly v-model="weeks[3][1]" :style="{
                                                                fontSize: '0.5rem',
                                                            }" class="form-control total_percentamount  form-control-sm ">
                                                        </div>

                                                    </td>
                                                    <td style="width:85%" class="p-0">
                                                        <table style="width:100%;background: #2e333e;"
                                                            class="table table-bordered mb-0">
                                                            <tbody>

                                                                <td class="p-0" v-for="weekinfo, dayindex in weeks[2]">
                                                                    <div class="input-group  pr-0 mb-0  input-group-sm">
                                                                        <div class="input-group-prepend">
                                                                            <span
                                                                                class="input-group-text input-group-text-sm ">£</span>
                                                                        </div>
                                                                        <input type="text" v-model="weekinfo[0]"
                                                                            @change="singCalculate(index, dayindex,weeks[0]);"
                                                                            class="form-control  form-bg-inverse form-control-sm form-control-right" :class="dayindex+'_total_reveniew'">
                                                                    </div>
                                                                    <div
                                                                        class="input-group  pr-0 mb-0 pt-0 input-group-sm">
                                                                        <div class="input-group-prepend">
                                                                            <span
                                                                                class="input-group-text input-group-text-sm ">%</span>
                                                                        </div>
                                                                        <input type="text" v-model="weekinfo[1]"
                                                                            @change="singCalculate(index, dayindex,weeks[0]);"
                                                                            class="form-control  form-bg-inverse form-control-sm form-control-right" :class="dayindex+'_total_inpercent'">
                                                                    </div>
                                                                    <div class="input-group  pr-0 mb-0 pt-0 input-group-sm"
                                                                        v-show="weekinfo[2] != '' && weekinfo[2] > 0">
                                                                        <div class="input-group-prepend">
                                                                            <span
                                                                                style="font-size:0.5rem;background: black;color: white;"
                                                                                class="input-group-text input-group-text-sm ">£</span>
                                                                        </div>
                                                                        <input type="text" readonly
                                                                            v-model="weekinfo[2]" :style="{
                                                                                background: 'transparent',
                                                                                color: 'white',
                                                                                border: '1px dashed white',
                                                                                fontSize: '0.5rem',
                                                                                // border: 'none',
                                                                            }" class="form-control   form-control-sm " :class="dayindex+'_total_percentamount'">
                                                                    </div>
                                                                </td>

                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script setup>
import SelectInput from '../../../shared/SelectBox.vue';
import HeaderPreview from '../../../website/headerDesigns/index.vue';
import TextInput from '../../../shared/TextInput.vue';
import ColorInput from '../../../shared/ColorInput.vue';
import ImageInput from '../../../shared/ImageInput.vue'
import { functions } from '../../../../common/appFunctions.js';
import { ref, reactive, onMounted } from 'vue';
import { useAuthStore } from '@/store/auth.js'
import factory from '@/common/factory.js'
import { useRouter } from 'vue-router';
import moment from 'moment';

const authStore = useAuthStore();
const isPreview = ref(false);
const calenderdisplay = ref(false);
var location_id = ref(1);


var today = new Date();
var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
// var lastDay = new Date(firstDay.getFullYear(), 12, 0);
var datesarray = ref([]);
var employeeid = ref(1);
// var month = ref(today.getMonth().toString());
var year = ref(firstDay.getFullYear());
var nextThirtyDays = getAllWeekDatesArray(year.value);
nextThirtyDays.forEach((v, k) => {
    // datesarray 0,1,2
    // ******************************************************************************
    // datesarray[0] is equal to date
    // ******************************************************************************
    // datesarray[1] is equal to totalamount
    // ******************************************************************************
    // datesarray[2] is equal to object
    // datesarray[2] object key have days value have 3 indexed array 0,1,2
    // #####################################################################
    // datesarray[2].monday[0] - Amount (pound)
    // datesarray[2].monday[1] - Percetage Value (%)
    // datesarray[2].monday[2] - Percet Amount in Pound 
    // ******************************************************************************
    // datesarray[3][0] - Weekly labour % target (total percentage)
    // datesarray[3][1] - Weekly labour % target (total Pound)
    datesarray.value.push([
        v, '', {
            monday: ['', '', ''],
            tuesday: ['', '', ''],
            wednesday: ['', '', ''],
            thursday: ['', '', ''],
            friday: ['', '', ''],
            saturday: ['', '', ''],
            sunday: ['', '', ''],
        }, ['', '']
    ]);
});

function getAllWeekDatesArray(year) {
    const start = moment(`${year}-01-01`).startOf('week');
    const end = moment(`${year}-12-31`).endOf('week');
    let currentDate = start;
    const dates = [];

    while (currentDate <= end) {
        dates.push(moment(currentDate));
        currentDate = currentDate.clone().add(1, 'week');
    }

    return dates;
}

function reloadData() {

    var dateselected = new Date(year.value, '01', '01');
    var firstDay = new Date(dateselected.getFullYear(), dateselected.getMonth(), 1);
     
    datesarray.value = [];  
    year.value = firstDay.getFullYear();
    var nextThirtyDays = getAllWeekDatesArray(year.value);
    nextThirtyDays.forEach((v, k) => {
        // datesarray 0,1,2
        // ******************************************************************************
        // datesarray[0] is equal to date
        // ******************************************************************************
        // datesarray[1] is equal to totalamount
        // ******************************************************************************
        // datesarray[2] is equal to object
        // datesarray[2] object key have days value have 3 indexed array 0,1,2
        // #####################################################################
        // datesarray[2].monday[0] - Amount (pound)
        // datesarray[2].monday[1] - Percetage Value (%)
        // datesarray[2].monday[2] - Percet Amount in Pound 
        // ******************************************************************************
        // datesarray[3][0] - Weekly labour % target (total percentage)
        // datesarray[3][1] - Weekly labour % target (total Pound)
        datesarray.value.push([
            v, '', {
                monday: ['', '', ''],
                tuesday: ['', '', ''],
                wednesday: ['', '', ''],
                thursday: ['', '', ''],
                friday: ['', '', ''],
                saturday: ['', '', ''],
                sunday: ['', '', ''],
            }, ['', '']
        ]);
    });
}
async function singCalculate(index, dayindex,weekdate) {
    var weekdate = moment(weekdate).format('YYYY-MM-DD');

    var forecastdata = {
        "forcasting_company_id":1,
        // "total_reveniew":0,
        // "total_inpercent":0,
        // "total_percentamount":0,
        "monday_total_reveniew":datesarray.value[index][2]['monday'][0],
        "monday_total_inpercent":datesarray.value[index][2]['monday'][1],
        "monday_total_percentamount":datesarray.value[index][2]['monday'][2],
        "tuesday_total_reveniew":datesarray.value[index][2]['tuesday'][0],
        "tuesday_total_inpercent":datesarray.value[index][2]['tuesday'][1],
        "tuesday_total_percentamount":datesarray.value[index][2]['tuesday'][2],
        "wednesday_total_reveniew":datesarray.value[index][2]['wednesday'][0],
        "wednesday_total_inpercent":datesarray.value[index][2]['wednesday'][1],
        "wednesday_total_percentamount":datesarray.value[index][2]['wednesday'][2],
        "thursday_total_reveniew":datesarray.value[index][2]['thursday'][0],
        "thursday_total_inpercent":datesarray.value[index][2]['thursday'][1],
        "thursday_total_percentamount":datesarray.value[index][2]['thursday'][2],
        "friday_total_reveniew":datesarray.value[index][2]['friday'][0],
        "friday_total_inpercent":datesarray.value[index][2]['friday'][1],
        "friday_total_percentamount":datesarray.value[index][2]['friday'][2],
        "saturday_total_reveniew":datesarray.value[index][2]['saturday'][0],
        "saturday_total_inpercent":datesarray.value[index][2]['saturday'][1],
        "saturday_total_percentamount":datesarray.value[index][2]['saturday'][2],
        "sunday_total_reveniew":datesarray.value[index][2]['sunday'][0],
        "sunday_total_inpercent":datesarray.value[index][2]['sunday'][1],
        "sunday_total_percentamount":datesarray.value[index][2]['sunday'][2],
        "forcast_date":weekdate,
        "forcast_location_id":location_id.value
    }
     
    var value = datesarray.value[index][2][dayindex][0];
    var percentage = datesarray.value[index][2][dayindex][1];
    var extrapounds = '';
    if (value > 0) {
        extrapounds = (value / 100) * percentage;
        extrapounds = parseFloat(extrapounds).toFixed(2);
    }
    datesarray.value[index][2][dayindex][2] = extrapounds;
    forecastdata[dayindex+'_total_percentamount'] = extrapounds;

    var totalPound = 0;
    var rowTotalPound = 0;
    Object.keys(datesarray.value[index][2]).forEach(function (day, value) {
        if (datesarray.value[index][2][day][2] != undefined && datesarray.value[index][2][day][2] != '' && datesarray.value[index][2][day][2] != null) {
            totalPound += parseFloat(datesarray.value[index][2][day][2]);
        }
        if (datesarray.value[index][2][day][0] != undefined && datesarray.value[index][2][day][0] != '' && datesarray.value[index][2][day][0] != null) {
            rowTotalPound += parseFloat(datesarray.value[index][2][day][0]);
        }
    });
    datesarray.value[index][1] = rowTotalPound;
    forecastdata.total_reveniew = rowTotalPound;
    if (totalPound == 0) {
        totalPound = '';
        datesarray.value[index][3][0] = '';
        datesarray.value[index][3][1] = '';
        forecastdata.total_inpercent = 0;
        forecastdata.total_percentamount = 0;
    } else {

        var totalPercentage = (totalPound / datesarray.value[index][1]) * 100;

        datesarray.value[index][3][0] = parseFloat(totalPercentage).toFixed(2);
        datesarray.value[index][3][1] = parseFloat(totalPound).toFixed(2);
        forecastdata.total_inpercent = parseFloat(totalPercentage).toFixed(2);;
        forecastdata.total_percentamount = parseFloat(totalPound).toFixed(2);;
    }

    const Forcast = new factory(getToken(), 'forecastings',forecastdata);
    await Forcast.postForm().then(response => {
        if (response.data.status == "success") {
            swal.close();
             
        }
    });

    
}
 
async function totalChange(index,weekdate) {
    var weekdate = moment(weekdate).format('YYYY-MM-DD');
    var totalamount = datesarray.value[index][1];
    var singleamt = totalamount / 7;
    singleamt = parseFloat(singleamt).toFixed(2);
    var forecastdata = {
        "forcasting_company_id":1,
        "total_reveniew":totalamount,
        "total_inpercent":0,
        "total_percentamount":0,
        "monday_total_reveniew":singleamt,
        "monday_total_inpercent":0,
        "monday_total_percentamount":0,
        "tuesday_total_reveniew":singleamt,
        "tuesday_total_inpercent":0,
        "tuesday_total_percentamount":0,
        "wednesday_total_reveniew":singleamt,
        "wednesday_total_inpercent":0,
        "wednesday_total_percentamount":0,
        "thursday_total_reveniew":singleamt,
        "thursday_total_inpercent":0,
        "thursday_total_percentamount":0,
        "friday_total_reveniew":singleamt,
        "friday_total_inpercent":0,
        "friday_total_percentamount":0,
        "saturday_total_reveniew":singleamt,
        "saturday_total_inpercent":0,
        "saturday_total_percentamount":0,
        "sunday_total_reveniew":singleamt,
        "sunday_total_inpercent":0,
        "sunday_total_percentamount":0,
        "forcast_date":weekdate,
        "forcast_location_id":location_id.value
    }
    const Forcast = new factory(getToken(), 'forecastings',forecastdata);
    await Forcast.postForm().then(response => {
        if (response.data.status == "success") {
            swal.close();
            Object.keys(datesarray.value[index][2]).forEach(function (day, value) {
                datesarray.value[index][2][day][0] = parseFloat(singleamt).toFixed(2);
            });
        }
    });
   
}

async function getForcastingInfo(){
    var filterdata = {
        forcast_date:year.value+'-01-01'
    }
    const Company = new factory(getToken(), 'forecastingsbydate',filterdata);
    await Company.postForm().then(response => { 
        
        response.data.data.forEach(function(v,k){
             
            var index = $('.'+moment(v.forcast_date).format('YYYY_MM_DD')).data('index');
            datesarray.value[index][1] = v.total_reveniew;

            datesarray.value[index][3][0] = v.total_inpercent;
            datesarray.value[index][3][1] = v.total_percentamount;
            
            
            datesarray.value[index][2].monday[0] = v.monday_total_reveniew;
            datesarray.value[index][2].monday[1] = v.monday_total_inpercent;
            datesarray.value[index][2].monday[2] = v.monday_total_percentamount;
            

            datesarray.value[index][2].tuesday[0] = v.tuesday_total_reveniew;
            datesarray.value[index][2].tuesday[1] = v.tuesday_total_inpercent;
            datesarray.value[index][2].tuesday[2] = v.tuesday_total_percentamount;
             

            datesarray.value[index][2].wednesday[0] = v.wednesday_total_reveniew;
            datesarray.value[index][2].wednesday[1] = v.wednesday_total_inpercent;
            datesarray.value[index][2].wednesday[2] = v.wednesday_total_percentamount;
             

            datesarray.value[index][2].thursday[0] = v.thursday_total_reveniew;
            datesarray.value[index][2].thursday[1] = v.thursday_total_inpercent;
            datesarray.value[index][2].thursday[2] = v.thursday_total_percentamount;
             

            datesarray.value[index][2].friday[0] = v.friday_total_reveniew;
            datesarray.value[index][2].friday[1] = v.friday_total_inpercent;
            datesarray.value[index][2].friday[2] = v.friday_total_percentamount;
             

            datesarray.value[index][2].saturday[0] = v.saturday_total_reveniew;
            datesarray.value[index][2].saturday[1] = v.saturday_total_inpercent;
            datesarray.value[index][2].saturday[2] = v.saturday_total_percentamount;
            

            datesarray.value[index][2].sunday[0] = v.sunday_total_reveniew;
            datesarray.value[index][2].sunday[1] = v.sunday_total_inpercent;
            datesarray.value[index][2].sunday[2] = v.sunday_total_percentamount;
              
        });
    });
}
function getToken() {
    const token = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authStore.token,
        },
    };
    return token;
}
function consoleData(){
    console.log(datesarray.value[0]);
}
onMounted(async () => {

    functions.initLoader();
    getForcastingInfo();
    functions.callOnReady();
    calenderdisplay.value = true;
}) 
</script>
<style scoped>
table {
    font-size: 12px;
}
</style>
