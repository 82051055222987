<template>
    <div class="main-body">
        <div class="page-wrapper">

            <!-- Page body start -->
            <div class="page-body">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-header">
                                <h5>
                                    <span>Menus</span>
                                    <button type="button"
                                        class="btn btn-primary addnewbtn btn-round waves-effect f-right "
                                        @click="addNew()">Add
                                        New</button>
                                </h5>
                                <div class="card-header-right">
                                    <div class="page-header-breadcrumb">
                                        <ul class="breadcrumb-title">
                                            <li class="breadcrumb-item" style="float: left;">
                                                <a href="index.html">
                                                    <i class="icofont icofont-home"></i>
                                                </a>
                                            </li>
                                            <li class="breadcrumb-item" style="float: left;"><router-link
                                                    :to="{ name: 'VAMenus' }">Menus</router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <div class="card-body">
                                <table-view v-if="displaytbl" :data="responsefromapi" @activeunactive="activeunactive"
                                    @viewbuttonclicked="editbtnclicked" @deletebuttonclicked="deletebuttonclicked" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="add_menus">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Add New</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        @click="hidemodal('#add_menus');">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <text-input class="col-md-6" v-model="form.mn_name" label="Name" />
                        <select-input v-model="form.mn_mg_id" class="col-md-6" label="Menu Group">
                            <option value="0">Select</option>
                            <option v-for="item in mn_group" :key="item.mg_id" :value="item.mg_id">{{ item.mg_name }}
                            </option>
                        </select-input>
                        <text-input class="col-md-12" v-model="form.mn_s_description" label="Short Description" />
                        <select-input v-model="form.mn_link_to" class="col-md-6" label="Link to">
                            <option value="0">Select Group</option>
                            <option value="1">Page 1</option>
                        </select-input>
                        <text-input class="col-md-6" v-model="form.mn_custom_link" label="Custom Link" />
                        <text-input class="col-md-6" v-model="form.mn_order" label="Order" />
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default waves-effect " data-dismiss="modal"
                        @click="hidemodal('#add_menus');">Close</button>
                    <button type="button" class="btn btn-primary funload btn-round waves-effect waves-light "
                        @click="addMenu();">Save
                        Information</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="update_menus">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Update Menu</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        @click="hidemodal('#update_menus');">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <text-input class="col-md-6" v-model="updateform.mn_name" label="Name" />
                        <select-input v-model="updateform.mn_mg_id" class="col-md-6" label="Menu Group">
                            <option value="0">Select</option>
                            <option v-for="item in mn_group" :key="item.mg_id" :value="item.mg_id">{{ item.mg_name }}
                            </option>
                        </select-input>
                        <text-input class="col-md-12" v-model="updateform.mn_s_description" label="Short Description" />
                        <select-input v-model="updateform.mn_link_to" class="col-md-6" label="Link to">
                            <option value="0">Custom Page</option>
                            <option value="1">Page 1</option>
                        </select-input>
                        <text-input class="col-md-6" v-model="updateform.mn_custom_link" label="Custom Link" />
                        <text-input class="col-md-6" v-model="updateform.mn_order" label="Order" />
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default waves-effect " data-dismiss="modal"
                        @click="hidemodal('#update_menus');">Close</button>
                    <button type="button" class="btn btn-primary funload btn-round waves-effect waves-light "
                        @click="updateMenu();">Save Changes</button>
                </div>
            </div>
        </div>
    </div>

</template>

<script setup>
import SelectInput from '../../../shared/SelectBox.vue';
import TextInput from '../../../shared/TextInput.vue';
import TableView from '../../../shared/TableView.vue';
import { functions } from '../../../../common/appFunctions.js';
import { ref, reactive, onMounted } from 'vue';
import { useAuthStore } from '@/store/auth.js'
import factory from '@/common/factory.js'
import { useRouter } from 'vue-router';

const authStore = useAuthStore();

var form = ref({
    mn_id: 0,
    mn_vendor_id: authStore.user.u_vendor_id,
    mn_name: '',
    mn_mg_id: '',
    mn_s_description: '',
    mn_link_to: '',
    mn_custom_link: '',
    mn_order: '',
    mn_meta: {}
});
var updateform = ref({
    mn_id: 0,
    mn_vendor_id: authStore.user.u_vendor_id,
    mn_name: '',
    mn_mg_id: '',
    mn_s_description: '',
    mn_link_to: '',
    mn_custom_link: '',
    mn_order: '',
    mn_meta: {}
});
var responsefromapi = ref();
var mn_group = ref({});
var displaytbl = ref(false);
function getToken() {
    const token = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authStore.token,
        },
    };
    return token;
}
async function addMenu() {
    const Menu = new factory(getToken(), 'menus', form.value);
    await Menu.postForm().then((response) => {
        if (response.data.status == "success") {
            location.reload();
        } else {
            console.log(response.data.message);
        }
    });
}
async function updateMenu() {
    const Menu = new factory(getToken(), 'menus', updateform.value);
    await Menu.postForm().then((response) => {
        if (response.data.status == "success") {
            // location.reload();
            swal.close();
            hidemodal('#update_menus');
        } else {
            console.log(response.data.message);
        }
    });
}
async function deletebuttonclicked(id) {
    const Menu = new factory(getToken(), 'menus/' + id);
    await Menu.deleteHttp().then((response) => {
        if (response.data.status == "success") {
            location.reload();
        } else {
            console.log(response.data.message);
        }
    });

};
async function activeunactive(id,status){
    var upactiveunactive = { 
        mn_id:id, 
        mn_isactive:!status
    }
    var updatestatus = new factory(getToken(), 'activemenus', upactiveunactive);
    await updatestatus.putForm().then((response) => {
        swal.fire('Done!', 'Status Changed Successfully.','success');
    });
}
function editbtnclicked(id) {
    var edit_information = responsefromapi.value.data.find((x) => (x.mn_id == id));
    updateform.value = edit_information;
    showmodal('#update_menus');
}
function addNew() {
    showmodal('#add_menus');
}
onMounted(async () => {
    // var token = getToken();
    functions.initLoader();
    const MenuGroup = new factory(getToken(), 'menugroups/vendor/' + authStore.user.u_vendor_id);
    await MenuGroup.getHttp().then(response => {
        console.log('Menu Group api');
        mn_group.value = response.data.data;
    })

    const Menu = new factory(getToken(), 'menus/vendor/' + authStore.user.u_vendor_id);
    await Menu.getHttp().then(response => {
         functions.destroyLoader();

        var tabledata = {
            "conditions": {
                'update': true,
                'trash': true,
                'active': true,
                'primarykey': 'mn_id',
                'hiddendata': ['mn_id', 'mn_vendor_id', 'mn_mg_id', 'mn_custom_link', 'mn_meta', 'mn_isactive', 'mn_istrashed', 'created_at', 'updated_at', 'mg_id', 'mg_vendor_id', 'mg_isactive', 'mg_istrashed'],
                'active_index': 'mn_isactive',
            },
            "columns": [
                'Name',
                'Group',
                'Link',
                'Order',
                'Active',
                '',
            ],
            "data_order": ['mn_name', 'mg_name', 'mn_link_to', 'mn_order'],
            "data": response.data.data
        };
        displaytbl.value = true
        responsefromapi.value = tabledata;

    });

    functions.callOnReady();
})
function showmodal(id) {
    $(id).modal('show');
}
function hidemodal(id) {
    $(id).modal('hide');
}
</script> 
