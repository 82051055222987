import SASingleVendor from "../views/superAdmin/Pages/SAVendors/singleVendor.vue";
import SASubscriptions from "../views/superAdmin/Pages/SASubscriptions/index.vue";
import SADashboard from "../views/superAdmin/Pages/SADashboard/index.vue";
import SAVendors from "../views/superAdmin/Pages/SAVendors/index.vue";
import SAUsers from "../views/superAdmin/Pages/SAUsers/index.vue";

export default [
  {
    name: "SADashboard",
    path: "dashboard",
    component: SADashboard,
    meta: {
      title: "Dashboad",
    },
  },
  {
    path: "vendors",
    children: [
      {
        name: "SAVendors",
        path: "/",
        component: SAVendors,
        meta: {
          title: "Vendors",
        },
      },
      {
        name: "SASingleVendor",
        path: "single_vendor",
        component: SASingleVendor,
        meta: {
          title: "Single Vendor",
        },
      },
    ],
  },
  {
    name: "SASubscriptions",
    path: "subscription",
    component: SASubscriptions,
    meta: {
      title: "Subscription",
    },
  },
  {
    name: "SAUsers",
    path: "users",
    component: SAUsers,
    meta: {
      title: "Users",
    },
  },
];
