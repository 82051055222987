<template>
  <div :class="$attrs.class + ' parentimg'">
    <label v-if="label" style="width:100%" class="form-label text-center">{{ label }}</label>
    <div class="social-profile text-center">
      <img class="img-fluid " :style="$attrs.kStyle" :datasrc="source" :src="url ?? source" alt="">
      <div class="profile-hvr m-t-15">
        <i @click="browse" class="icofont icofont-ui-edit p-r-10"></i>
        <i @click="remove" class="icofont icofont-ui-delete"></i>
      </div>
    </div>
    <input ref="hidinput" type="hidden"  class="hidden" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" style="display:none;" />
    <input ref="file" type="file" :accept="accept" class="hidden" @change="change($event)" style="display:none;" />
  </div>
</template>

<script>
import { inject } from 'vue'
import { appConsts } from '../../common/constants.js'

export default {
  inject: ['getToken'],
  data() {
    return {
      url: null,
      token: null
    }
  },
  props: {
    modelValue: String,
    source: String,
    label: String,
    accept: String,
    errors: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  watch: {
    modelValue(value) {
      if (!value) {
        this.$refs.file.value = ''
      }
    },
  },
  methods: {

    // filesize(size) {
    //   var i = Math.floor(Math.log(size) / Math.log(1024))
    //   return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
    // },
    browse() {
      this.$refs.file.click()
    },
    change(b) {
      var response = uploadFile(b.target, this.token);
      response.then((response) => {
        console.log(response);
        if (response.data.status == 'success') {

          this.url = response.data.data.file_url;
          console.log(this.$refs.hidinput.value);
          console.log(this.$refs.hidinput);
          this.$refs.hidinput.value = response.data.data.file_url;
          console.log(this.$refs.hidinput.value);
          
          this.$emit('update:modelValue', response.data.data.file_url);
        } else {
          alert('Error! Kindly try again!');
        }
      })
      async function uploadFile(fileobj, token) {

        const formData = new FormData();
        formData.append('file', fileobj.files[0]);

        try {
          var headd = {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': token.headers.Authorization
            }
          };
          return await axios.post(appConsts.baseURL + 'files', formData, headd);


        } catch (e) {
          console.log(e);
        }
      }


    },
    remove() {
      this.url = null;
      this.$emit('update:modelValue', null);
    },
  },
  created() {
    this.token = this.getToken;
    console.log(this.getToken);
    // this.url = source
  },

}
</script>
