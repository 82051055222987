import { createWebHistory, createRouter } from "vue-router";
import { useAuthStore } from "@/store/auth.js";
import AuthRoutes from "./Auth.js";
import AdminRoutes from "./Admin.js";
import VendorRoutes from "./Vendor.js";
import WebsiteRoutes from "./Website.js";
// import store from "../store/index";
import PageNotFound from "../views/pageNotFound.vue";

let routes = [
  {
    name: "PageNotFound",
    path: "/:pathMatch(.*)*",
    component: PageNotFound,
    meta: { title: "Page Not Found" },
  },
  {
    path: "/",
    redirect: "/authentication/login",
    meta: { title: "Page Not Found" },
  },
  {
    path: "/authentication/",
    meta: {
      requiresAuth: false,
      authPage: true,
    },
    children: [...AuthRoutes],
  },
  {
    path: "/super_admin/",
    meta: {
      requiresAuth: true,
      adminAuth: true,
      vendorAuth: false,
    },
    children: [...AdminRoutes],
  },
  {
    path: "/vendor_account/",
    meta: {
      requiresAuth: true,
      adminAuth: false,
      vendorAuth: true,
    },
    children: [...VendorRoutes],
  },
  {
    path: "/website/:id",
    meta: {
      requiresAuth: false,
      adminAuth: false,
      vendorAuth: false,
    },
    children: [...WebsiteRoutes],
  },

  ,
];

const router = createRouter({
  history: createWebHistory(""),
  routes: routes,
  linkExactActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  const auth = useAuthStore();
  // console.log("User Account Type: " + auth.user.account_type_id);
  if (to.meta.requiresAuth) {
    if (!auth.token) {
      next({ name: "Login" });
    } else if (to.meta.adminAuth) {
      if (auth.user.u_account_type_id === "1") {
        next();
      } else {
        next({ name: "PageNotFound" });
      }
    } else if (to.meta.vendorAuth) {
      if (auth.user.u_account_type_id === "2") {
        next();
      } else {
        next({ name: "PageNotFound" });
      }
    }
    // } else if (to.meta.requiresAuth == false && to.meta.authPage) {
    //   if (store.state.role === "Super Admin") {
    //     next({ name: "SADashboard" });
    //   } else {
    //     next({ name: "VADashboard" });
    //   }
  } else {
    next();
  }
});

export default router;
