<template>
  <div class="pcoded-inner-content">
    <div class="main-body">
      <div class="page-wrapper">
        <div class="page-body">
          <div class="card">
            <div class="card-header">
              <h5>
                <span>Access Permission Information</span>
              </h5>
              <div class="card-header-right">
                <div class="page-header-breadcrumb">
                  <ul class="breadcrumb-title">
                    <li class="breadcrumb-item" style="float: left">
                      <a href="index.html">
                        <i class="feather icon-home"></i>
                      </a>
                    </li>
                    <li class="breadcrumb-item" style="float: left">
                      <router-link :to="{ name: 'VAPermissions' }"
                        >Access Permissions</router-link>
                    </li>
                    <li class="breadcrumb-item" style="float: left">
                      <a href="#!">Single Access Permission</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- Page-body start -->

            <div class="row card-body">
              <div class="col-lg-12">
                <div class="row">
                  <text-input
                    v-model="form.home_name"
                    class="col-md-12"
                    label="Permission Title"
                  />

                  <div class="col-md-12">
                    <hr />
                  </div>
                  <div class="col-md-12">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Access</th>
                          <th class="text-center">Veiw</th>
                          <th class="text-center">Add</th>
                          <th class="text-center">Update</th>
                          <th class="text-center">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Reports</td>
                          <td class="text-center">
                            <div class="checkbox-fade fade-in-primary m-0">
                              <!-- home_id -->
                              <label>
                                <input
                                  type="checkbox"
                                  class="homesselected" checked
                                />
                                <span class="cr">
                                  <i
                                    class="cr-icon icofont icofont-ui-check txt-primary"
                                  ></i>
                                </span>
                                <span></span>
                              </label>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="checkbox-fade fade-in-primary m-0">
                              <!-- home_id -->
                              <label>
                                <input
                                  type="checkbox"
                                  class="homesselected" checked
                                />
                                <span class="cr">
                                  <i
                                    class="cr-icon icofont icofont-ui-check txt-primary"
                                  ></i>
                                </span>
                                <span></span>
                              </label>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="checkbox-fade fade-in-primary m-0">
                              <!-- home_id -->
                              <label>
                                <input
                                  type="checkbox"
                                  class="homesselected" checked
                                />
                                <span class="cr">
                                  <i
                                    class="cr-icon icofont icofont-ui-check txt-primary"
                                  ></i>
                                </span>
                                <span></span>
                              </label>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="checkbox-fade fade-in-primary m-0">
                              <!-- home_id -->
                              <label>
                                <input
                                  type="checkbox"
                                  class="homesselected" checked
                                />
                                <span class="cr">
                                  <i
                                    class="cr-icon icofont icofont-ui-check txt-primary"
                                  ></i>
                                </span>
                                <span></span>
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Forms</td>
                          <td class="text-center">
                            <div class="checkbox-fade fade-in-primary m-0">
                              <!-- home_id -->
                              <label>
                                <input
                                  type="checkbox"
                                  class="homesselected" checked
                                />
                                <span class="cr">
                                  <i
                                    class="cr-icon icofont icofont-ui-check txt-primary"
                                  ></i>
                                </span>
                                <span></span>
                              </label>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="checkbox-fade fade-in-primary m-0">
                              <!-- home_id -->
                              <label>
                                <input
                                  type="checkbox"
                                  class="homesselected" checked
                                />
                                <span class="cr">
                                  <i
                                    class="cr-icon icofont icofont-ui-check txt-primary"
                                  ></i>
                                </span>
                                <span></span>
                              </label>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="checkbox-fade fade-in-primary m-0">
                              <!-- home_id -->
                              <label>
                                <input
                                  type="checkbox"
                                  class="homesselected" checked
                                />
                                <span class="cr">
                                  <i
                                    class="cr-icon icofont icofont-ui-check txt-primary"
                                  ></i>
                                </span>
                                <span></span>
                              </label>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="checkbox-fade fade-in-primary m-0">
                              <!-- home_id -->
                              <label>
                                <input
                                  type="checkbox"
                                  class="homesselected" checked
                                />
                                <span class="cr">
                                  <i
                                    class="cr-icon icofont icofont-ui-check txt-primary"
                                  ></i>
                                </span>
                                <span></span>
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Employee</td>
                          <td class="text-center">
                            <div class="checkbox-fade fade-in-primary m-0">
                              <!-- home_id -->
                              <label>
                                <input
                                  type="checkbox"
                                  class="homesselected" checked
                                />
                                <span class="cr">
                                  <i
                                    class="cr-icon icofont icofont-ui-check txt-primary"
                                  ></i>
                                </span>
                                <span></span>
                              </label>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="checkbox-fade fade-in-primary m-0">
                              <!-- home_id -->
                              <label>
                                <input
                                  type="checkbox"
                                  class="homesselected" checked
                                />
                                <span class="cr">
                                  <i
                                    class="cr-icon icofont icofont-ui-check txt-primary"
                                  ></i>
                                </span>
                                <span></span>
                              </label>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="checkbox-fade fade-in-primary m-0">
                              <!-- home_id -->
                              <label>
                                <input
                                  type="checkbox"
                                  class="homesselected" checked
                                />
                                <span class="cr">
                                  <i
                                    class="cr-icon icofont icofont-ui-check txt-primary"
                                  ></i>
                                </span>
                                <span></span>
                              </label>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="checkbox-fade fade-in-primary m-0">
                              <!-- home_id -->
                              <label>
                                <input
                                  type="checkbox"
                                  class="homesselected" checked
                                />
                                <span class="cr">
                                  <i
                                    class="cr-icon icofont icofont-ui-check txt-primary"
                                  ></i>
                                </span>
                                <span></span>
                              </label>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-md-12 text-center">
                  <button
                    type="button"
                    class="btn btn-primary btn-round waves-effect waves-light funload"
                    @click="updateHome()"
                  >
                    Update Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { functions } from "../../../../common/appFunctions.js";
import SelectInput from "../../../shared/SelectBox.vue";
import TextInput from "../../../shared/TextInput";
import ImageInput from "../../../shared/ImageInput.vue";
import TextareaInput from "../../../shared/TextareaInput.vue";
import { onMounted, ref, reactive } from "vue";
import { useAuthStore } from "@/store/auth.js";
import factory from "@/common/factory.js";
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();

// ****** Dropdowns data ********************
var companiesdata = ref([]);
var usersdata = ref([]);
var regionsdata = ref([]);
var employeesdata = ref([]);
var homesemployeesdata = ref([]);
// ****** Dropdowns data ********************

var restrict_specific_range = ref(false);
var restricted_range = ref("");
var restrict_ip = ref(false);
var restricted_ip = ref("");

const form = ref({
  home_picture:
    "https://demo.dashboardpack.com/adminty-html/files/assets/images/gallery-grid/1.png",
  home_name: "",
  home_company_id: "",
  home_start_date: "",
  home_end_date: "",
  home_administrator: "",
  home_region: "",
  home_incharge: "",
  home_responsible: "",
  home_min_age: "",
  home_max_age: "",
  home_no_beds: "",
  home_pat_date: "",
  home_electrical: "",
  home_gas: "",
  home_firedrill_day: "",
  home_firedrill_night: "",
  home_address: "",
  home_postcode: "",
  home_telephone: "",
  home_faxnumber: "",
  home_email: "",
  home_ofsted_urn: "",
  home_ofsted_rating: "",
  home_ofsted_rating_date: "",
  home_ofsted_requirements: "",
  home_ofsted_list_regulation_requirements: "",
  home_ofsted_recommendations: "",
  home_ofsted_no_recommendations: "",
  home_ofsted_interim_rating: "",
  home_ofsted_interim_rating_date: "",
  home_ofsted_interim_requirment: "",
  home_ofsted_interim_no_requirment: "",
  home_ofsted_interim_recommendation: "",
  home_ofsted_interim_regulation: "",
  home_longdescription: "",
  home_secure_accomodation: "",
  home_shortstay: "",
});

function getToken() {
  const token = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authStore.token,
    },
  };
  return token;
}
async function updateHome() {
  const Company = new factory(getToken(), "homes", form.value);
  await Company.putForm().then((response) => {
    functions.destroyBtnLoader();
    if (response.data.status == "success") {
      // router.push({ path: 'companies' })
      functions.kSwal("Done", "Changes Saved Successfully.");
    } else {
      console.log(response.data.message);
    }
  });
}
async function updateEmployee() {
  var empsselected = [];
  $(".empsselected").each(function () {
    if ($(this).prop("checked")) {
      empsselected.push($(this).attr("value"));
    }
  });
  var updateEmployee = {
    location_id: form.value.home_id,
    employees: empsselected,
  };
  const Company = new factory(getToken(), "locationemployees", updateEmployee);
  await Company.postForm().then((response) => {
    functions.destroyBtnLoader();
    if (response.data.status == "success") {
      // router.push({ path: 'companies' })
      functions.kSwal("Done", "Changes Saved Successfully.");
    } else {
      console.log(response.data.message);
    }
  });
}
onMounted(async () => {
  var Homes = new factory(getToken(), "getallapis", {
    index: ["companies", "users", "regions", "employees"],
  });
  await Homes.postForm().then((response) => {
    if (response.data.status == "success") {
      companiesdata.value = response.data.data.companies;
      usersdata.value = response.data.data.users;
      regionsdata.value = response.data.data.regions;
      employeesdata.value = response.data.data.employees;
    }
  });

  Homes = new factory(getToken(), "homes/" + route.query.id);
  await Homes.getHttp().then((response) => {
    if (response.data.status == "success") {
      swal.close();
      form.value = response.data.data;
      console.log(response.data.data);
    } else {
      return response.data.message;
    }
  });

  var HomesEMployedata = {
    location_id: route.query.id,
  };
  try {
    var HomesEMploye = new factory(
      getToken(),
      "getemployees",
      HomesEMployedata
    );
    await HomesEMploye.postForm().then((response) => {
      if (response.data.status == "success") {
        swal.close();
        homesemployeesdata.value = response.data.data;
      }
    });
  } catch (error) {
    swal.close();
  }

  functions.callOnReady();
});
</script>
<style scoped>
.tabs-left {
  min-width: 200px !important;
}
</style>
