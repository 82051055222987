<template>
    <div class="main-body">
        <div class="page-wrapper">
            <div class="page-body">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-header">
                                <h5>
                                    <span>Vehicles</span>
                                    <button type="button" class="btn btn-primary addnewbtn btn-round waves-effect f-right "
                                        @click="addNew()">Add New
                                    </button>
                                </h5>
                                <div class="card-header-right">
                                    <div class="page-header-breadcrumb">
                                        <ul class="breadcrumb-title">
                                            <li class="breadcrumb-item" style="float: left;">
                                                <a href="#">
                                                    <i class="icofont icofont-home"></i>
                                                </a>
                                            </li>
                                            <li class="breadcrumb-item" style="float: left;"><router-link
                                                    :to="{ name: 'VAVehicles' }">Vehicles</router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <table-view v-if="displaytbl" :data="responsefromapi" @activeunactive="activeunactive"
                                    @viewbuttonclicked="editbtnclicked" @deletebuttonclicked="deletebuttonclicked" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import TableView from '../../../shared/TableView.vue';
import { functions } from '../../../../common/appFunctions.js';
import { ref, reactive, onMounted } from 'vue';
import { useAuthStore } from '@/store/auth.js'
import factory from '@/common/factory.js'
import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const authStore = useAuthStore();

var responsefromapi = ref();
var displaytbl = ref(false);
function getToken() {
    const token = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authStore.token,
        },
    };
    return token;
}
function deletebuttonclicked(id) {
    var ask = functions.askSwal();
    ask.then(async (result) => {
        if (result.isConfirmed) {
            const vehicles = new factory(getToken(), "vehicles/" + id);
            await vehicles.deleteHttp().then((response) => {
                if (response.data.status == "success") {
                    location.reload();
                } else {
                    console.log(response.data.message);
                }
            });
        }
    });
}
async function activeunactive(id, status) {
    var upactiveunactive = {
        vehicle_id: id,
        vehicle_isactive: !status
    }
    var updatestatus = new factory(getToken(), 'vehicles', upactiveunactive);
    await updatestatus.putForm().then((response) => {
        swal.fire('Done!', 'Status Changed Successfully.', 'success');
    });
}
function editbtnclicked(id) {
    router.push({
        name: 'VAEditVehicle',
        query: {
            id,
        },
    })
}
function addNew() {
    router.push({
        name: 'VAAddVehicle'
    })
}
onMounted(async () => {

    functions.initLoader();
    const Vehicles = new factory(getToken(), 'vehicles');
    const dt = await Vehicles.getHttp().then(response => {
        functions.destroyLoader();
        var tabledata = {
            "conditions": {
                'update': true,
                'trash': true,
                'active': true,
                'primarykey': 'vehicle_id',
                'hiddendata': ['vehicle_id'],
                'active_index': 'vehicle_isactive',
            },
            "columns": [
                'Name',
                'Company',
                'Location',
                'Administrator',
                'Start Date',
                'End Date',
                'Active',
                'Action',
            ],
            "data_order": ['vehicle_name', 'company', 'administrator', 'administrator', 'vehicle_start_date', 'vehicle_end_date'],
            "data": response.data.data
        };
        displaytbl.value = true
        responsefromapi.value = tabledata;
    });
    functions.callOnReady();
});
</script> 
