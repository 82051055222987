<template>
    <!-- <div class="theme-loader">
        <div class="ball-scale">
            <div class='contain'>
                <div class="ring">
                    <div class="frame"></div>
                </div>
                <div class="ring">
                    <div class="frame"></div>
                </div>
                <div class="ring">
                    <div class="frame"></div>
                </div>
                <div class="ring">
                    <div class="frame"></div>
                </div>
                <div class="ring">
                    <div class="frame"></div>
                </div>
                <div class="ring">
                    <div class="frame"></div>
                </div>
                <div class="ring">
                    <div class="frame"></div>
                </div>
                <div class="ring">
                    <div class="frame"></div>
                </div>
                <div class="ring">
                    <div class="frame"></div>
                </div>
                <div class="ring">
                    <div class="frame"></div>
                </div>
            </div>
        </div>
    </div> -->
    <div id="pcoded" class="pcoded">
        <div class="pcoded-overlay-box"></div>
        <div class="pcoded-container navbar-wrapper"> 
            <div class="pcoded-main-container">
                <div class="pcoded-wrapper"> 
                    <div class="pcoded-content">
                        <h1>Website</h1>
                        <div class="pcoded-inner-content">
                            <!-- <router-view :key="$route.fullPath"></router-view> -->
                            <div id="styleSelector">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import Sidebar from './sidebar.vue';
// import Navbar from './navbar.vue';
export default {
    // name: "SAMaster",
    // data() {
    //     return {
    //         name: null,
    //     }
    // },
    components: {
        // Sidebar, Navbar
    },
}
</script>
