<template>
    <div class="text-center" :class="$attrs.class + ' ' + data.child_cssclass + ((data.child_display) ? '' : ' cond-hide ')"
    :data-key="$attrs.childindex"
    :data-sectionkey="$attrs.index">

        <template v-if="$attrs.isbuilder">
            <i class="ti-move movebtn" @click="addSortChild();"></i>
            <i class="ti-close removebtn" @click="removeCompunent($attrs.index, $attrs.childindex,$attrs.child_uniqueid);"></i>
            <i class="ti-settings settingbtn" @click="configureCompunent($attrs.childindex);"></i>
        </template>
        <div class="testimonials owl-carousel owl-theme" :class="(data.meta.initclass) ? data.meta.initclass : 'testimonials'">

            <div class="testimonial-single" v-for="testimonial in data.meta.data">
                <img class="img-circle" :src="testimonial.image" alt="" />
                <div class="testimonial-text wow fadeInUp" data-wow-delay="0.2s">
                    <p>{{ testimonial.paragraph }}</p>
                    <h3>{{ testimonial.heading1 }}</h3>
                    <h3>{{ testimonial.heading2 }}</h3>

                    <template v-for="index in 5">
                        <i v-if="testimonial.noofstars > index" class="ion ion-star"></i>
                        <i v-if="testimonial.noofstars <= index" class="ion ion-star-o"></i>
                    </template>
                    <!-- <i class="ion ion-star"></i>
                    <i class="ion ion-star"></i>
                    <i class="ion ion-star"></i>
                    <i class="ion ion-star"></i> -->
                </div>
            </div>

        </div>
    </div>
</template>
<script setup>
let props = defineProps({
    data: Array,
});
var definedEmits = defineEmits(['addSortChild', 'removeCompunent', 'configureCompunent'])

function addSortChild() {
    definedEmits('addSortChild');
}

function removeCompunent(index, childindex,child_uniqueid) {
    definedEmits('removeCompunent', index, childindex,child_uniqueid);
}
function configureCompunent(childindex) {
    definedEmits('configureCompunent', childindex);
}
</script>