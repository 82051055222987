<template>
    <div class="pcoded-inner-content">

        <div class="main-body">
            <div class="page-wrapper">
                <!-- Page-body start -->
                <div class="page-body">
                    <div class="card">
                        <div class="card-header">
                            <h5>
                                <span>Single User Information  </span>
                            </h5>
                            <div class="card-header-right">
                                <div class="page-header-breadcrumb">
                                    <ul class="breadcrumb-title">
                                        <li class="breadcrumb-item" style="float: left;">
                                            <a href="index.html"> <i class="feather icon-home"></i> </a>
                                        </li>
                                        <li class="breadcrumb-item" style="float: left;"><router-link
                                                :to="{ name: 'VAUsers' }">Users</router-link>
                                        </li>
                                        <li class="breadcrumb-item" style="float: left;"><a href="#!">Single User
                                                Info</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div class="row card-body">
                            <div class="col-lg-12">

                                <ul class="nav nav-tabs md-tabs tabs-left" role="tablist" id="mytab">
                                    <li class="nav-item">
                                        <a class="nav-link active" data-bs-toggle="tab" href="#personal" role="tab">
                                            Personal Information </a>
                                        <div class="slide"></div>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-bs-toggle="tab" href="#residentassosicated" role="tab">
                                            Access Administration </a>
                                        <div class="slide"></div>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-bs-toggle="tab" href="#peopleassociated" role="tab">
                                            Addresses</a>
                                        <div class="slide"></div>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-bs-toggle="tab" href="#contactdetails" role="tab">
                                            Phone
                                            Number </a>
                                        <div class="slide"></div>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-bs-toggle="tab" href="#scoialmediadetails" role="tab">
                                            Social
                                            Media Details </a>
                                        <div class="slide"></div>
                                    </li>
                                </ul>
                                <div class="tab-content tabs-left-content card-block" style="width: 100%">
                                    <!-- tab panel personal start -->
                                    <div class="tab-pane active" id="personal" role="tabpanel">
                                        <!-- personal card start -->
                                        <form>
                                            <div class="row">
                                                <image-input v-model="form.u_photo" class="col-md-4"
                                                    :source="form.u_photo" kStyle="height:20vh;" />

                                                <div class="col-md-8">
                                                    <div class="row">
                                                        <text-input v-model="form.u_name" class="col-md-4"
                                                            label="First Name" />
                                                        <text-input v-model="form.u_middlename" class="col-md-4"
                                                            label="Middle Name" />
                                                        <text-input v-model="form.u_lastname" class="col-md-4"
                                                            label="Last Name" />
                                                        <text-input v-model="form.user_email" class="col-md-6"
                                                            label="Email" />
                                                        <select-input v-model="form.u_company_id"
                                                            class="col-md-6 my-select selectpicker" label="Company">
                                                            <option value="">Select...</option>
                                                                <option v-for="comp in companiesdata" :value="comp.c_id">{{
                                                                    comp.c_name }}</option>
                                                        </select-input>
                                                        <text-input type="date" v-model="form.u_birthdate" class="col-md-6"
                                                            label="Date of Birth" />
                                                        <select-input v-model="form.user_active"
                                                            class="col-md-6 my-select selectpicker" label="Active">
                                                            <option value="0">No</option>
                                                            <option value="1">Yes</option>
                                                        </select-input>
                                                    </div>
                                                </div>

                                                <div class="col-md-12">
                                                    <hr />
                                                </div>
                                                <select-input v-model="form.user_type"
                                                    class="col-md-4 my-select selectpicker" label="User Type">
                                                    <option value="">Select...</option>
                                                    <option v-for="comp in usertypedata" :value="comp.at_id">{{
                                                                    comp.at_name }}</option>
                                                </select-input>
                                                <select-input v-model="form.user_role"
                                                    class="col-md-4 my-select selectpicker" label="User Role">
                                                    <option value="">Select...</option>
                                                    <option v-for="comp in rolesdata" :value="comp.role_id">{{
                                                                    comp.role }}</option>
                                                </select-input> 
                                                    <select-input v-model="form.u_gender" class="col-md-4"
                                                        label="Gender">
                                                        <option value="">Select...</option>
                                                        <option v-for="gender, k in genderdata" :value="k">
                                                            {{ gender }}
                                                        </option>
                                                    </select-input>
                                            </div>
                                            <div class="col-md-12 text-center">
                                                <button type="button"
                                                    class="btn btn-primary btn-round waves-effect waves-light funload"
                                                    @click="updateCompany()">Update Changes</button>
                                            </div>
                                        </form>

                                    </div>
                                    <!-- tab pane personal end -->
                                    <!-- tab pane info start -->
                                    <div class="tab-pane" id="residentassosicated" role="tabpanel">

                                        <div class="row">
                                            <select-input v-model="form.user_hidefuturetask"
                                                class="col-md-4 my-select selectpicker" label="Hide Future tasks">
                                                <option value="0">No</option>
                                                <option value="1">Yes</option>
                                            </select-input>
                                            <select-input v-model="form.user_accessristrict"
                                                class="col-md-4 my-select selectpicker"
                                                label="Access restricted to Company Network">
                                                <option value="0">No</option>
                                                <option value="1">Yes</option>
                                            </select-input>
                                            <select-input v-model="form.user_ristrictlogin"
                                                class="col-md-4 my-select selectpicker"
                                                label="Restrict Login to Region">
                                                <option value="0">No</option>
                                                <option value="1">Yes</option>
                                            </select-input>
                                            <text-input v-model="form.user_disableloginat" class="col-md-4"
                                                label="Disable Login At" />
                                            <select-input v-model="form.user_instantexpiry"
                                                class="col-md-4 my-select selectpicker" label="Password Instant Expire">
                                                <option value="0">No</option>
                                                <option value="1">Yes</option>
                                            </select-input>
                                            <text-input type="date" v-model="form.user_passwordexpiry" class="col-md-4"
                                                label="Password Expires At" />
                                            <text-input v-model="form.user_password" class="col-md-4"
                                                label="Password" />
                                            <text-input v-model="form.user_confirmpassword" class="col-md-4"
                                                label="Confirm Password" />
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 text-center">
                                                <button type="button"
                                                    class="btn btn-primary btn-round waves-effect waves-light funload"
                                                    @click="updateCompany()">Update Changes</button>
                                            </div>
                                        </div>

                                        <!-- info card end -->
                                    </div>
                                    <!-- tab pane info end -->
                                    <!-- tab pane info start -->
                                    <div class="tab-pane" id="peopleassociated" role="tabpanel">

                                        <div class="row">
                                            <div class="col-md-12">
                                                <button type="button" class="btn btn-warning fr btn-block"
                                                    @click="showmodal('#add_address')"><i class="ti-plus"></i>
                                                    Add New</button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>Address</th>
                                                        <th>Country</th>
                                                        <th>City</th>
                                                        <th>County</th>
                                                        <th>Postcode</th>
                                                        <th>Address 1</th>
                                                        <th>Address 2</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <template v-if="form.user_addresses">
                                                        <tr v-for="address in form.user_addresses">
                                                            <td>{{ address.address_name }}</td>
                                                            <td>{{ address.address_country }}</td>
                                                            <td>{{ address.address_city }}</td>
                                                            <td>{{ address.address_state }}</td>
                                                            <td>{{ address.address_postcode }}</td>
                                                            <td>{{ address.address_address_1 }}</td>
                                                            <td>{{ address.address_address_2 }}</td>
                                                            <td></td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                            </table>
                                        </div>
                                        <!-- info card end -->
                                    </div>
                                    <!-- tab pane info end -->
                                    <div class="tab-pane" id="contactdetails" role="tabpanel">
                                        <!-- info card start -->
                                        <div class="row">
                                            <div class="col-md-12">
                                                <button type="button" class="btn btn-warning fr btn-block"
                                                    @click="showmodal('#add_phone')"><i class="ti-plus"></i>
                                                    Add New</button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>Phone Type</th>
                                                        <th>Country</th>
                                                        <th>Number</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <template v-if="form.user_phonenumbers">
                                                        <tr v-for="phone in form.user_phonenumbers">
                                                            <td>{{ phone.type }}</td>
                                                            <td>{{ phone.country }}</td>
                                                            <td>{{ phone.phonenumber }}</td>
                                                            <td></td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                            </table>
                                        </div>

                                        <!-- info card end -->
                                    </div>
                                    <div class="tab-pane" id="scoialmediadetails" role="tabpanel">
                                        <!-- info card start -->
                                        <div class="row">
                                            <div class="col-md-12">
                                                <button type="button" class="btn btn-warning fr btn-block"
                                                    @click="showmodal('#add_socialmedia')"><i class="ti-plus"></i>
                                                    Add New</button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>Media Type</th>
                                                        <th>Name</th>
                                                        <th>Link</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <template v-if="form.user_sociallinks">
                                                        <tr v-for="sociallinks in form.user_sociallinks">
                                                            <td>{{ sociallinks.type }}</td>
                                                            <td>{{ sociallinks.name }}</td>
                                                            <td>{{ sociallinks.link }}</td>
                                                            <td></td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                            </table>
                                        </div>

                                        <!-- info card end -->
                                    </div>

                                </div>
                                <!-- tab content end -->

                            </div>
                        </div>
                    </div>
                </div>
                <!-- Page-body end -->
            </div>
        </div>
    </div>
    <div class="modal fade" id="add_address">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Add Address</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        @click="hidemodal('#add_address');">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <text-input class="col-md-8" v-model="address_name" label="Name" />
                        <select-input v-model="address_country" class="col-md-4" label="Country">
                            <option value="">Select </option>
                        </select-input>
                        <text-input class="col-md-4" v-model="address_city" label="City" />
                        <text-input class="col-md-4" v-model="address_state" label="State" />
                        <text-input class="col-md-4" v-model="address_postcode" label="Post Code" />
                        <text-input class="col-md-6" v-model="address_address_1" label="Address 1" />
                        <text-input class="col-md-6" v-model="address_address_2" label="Address 2" />
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default waves-effect " data-dismiss="modal"
                        @click="hidemodal('#add_address');">Close</button>
                    <button type="button" class="btn btn-primary btn-round waves-effect waves-light "
                        @click="addUserAddress();">Save
                        Information</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="add_phone">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Add Phone Number</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        @click="hidemodal('#add_phone');">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <select-input v-model="phonenumber_type" class="col-md-6" label="Number Type">
                            <option value="1">Home </option>
                            <option value="2">Work </option>
                        </select-input>
                        <select-input v-model="phonenumber_country" class="col-md-6" label="Country">
                            <option value="">Select </option>
                        </select-input>
                        <text-input class="col-md-12" v-model="phonenumber" label="Number" />
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default waves-effect " data-dismiss="modal"
                        @click="hidemodal('#add_phone');">Close</button>
                    <button type="button" class="btn btn-primary btn-round waves-effect waves-light "
                        @click="addUserPhone();">Save
                        Information</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="add_socialmedia">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Add Social Media Info</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        @click="hidemodal('#add_socialmedia');">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <select-input v-model="socialmedia_type" class="col-md-12" label="Social Media Type">
                            <option value="1">FB </option>
                            <option value="2">Insta </option>
                        </select-input>
                        <text-input class="col-md-12" v-model="socialmedia_name" label="Name" />
                        <text-input class="col-md-12" v-model="socialmedia_link" label="Link" />
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default waves-effect " data-dismiss="modal"
                        @click="hidemodal('#add_socialmedia');">Close</button>
                    <button type="button" class="btn btn-primary btn-round waves-effect waves-light "
                        @click="addUserSocial();">Save
                        Information</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { functions } from '../../../../common/appFunctions.js';
import SelectInput from '../../../shared/SelectBox.vue';
import TextInput from '../../../shared/TextInput'
import ImageInput from '../../../shared/ImageInput.vue'
import TextareaInput from '../../../shared/TextareaInput.vue'
import { onMounted, ref, reactive } from 'vue';
import { useAuthStore } from '@/store/auth.js'
import factory from '@/common/factory.js'
import { useRouter, useRoute } from 'vue-router';
const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();


// ****** Dropdowns data ********************
var companiesdata = ref([]);
var genderdata = ref([]);
var usertypedata = ref([]);
var rolesdata = ref([]);
// ****** Dropdowns data ********************

var address_name = ref('');
var address_country = ref('');
var address_city = ref('');
var address_state = ref('');
var address_postcode = ref('');
var address_address_1 = ref('');
var address_address_2 = ref('');
var phonenumber_type = ref('');
var phonenumber_country = ref('');
var phonenumber = ref('');
var socialmedia_type = ref('');
var socialmedia_link = ref('');
var socialmedia_name = ref('');

const form = ref({
    user_picture: 'https://demo.dashboardpack.com/adminty-html/files/assets/images/gallery-grid/1.png',
    user_firstname: '',
    user_lastname: '',
    user_email: '',
    user_company_id: '',
    user_dob: '',
    user_active: '',
    user_type: '',
    user_role: '',
    user_gender: '',
    user_hidefuturetask: '',
    user_accessristrict: '',
    user_ristrictlogin: '',
    user_disableloginat: '',
    user_passwordexpiry: '',
    user_password: '',
    user_confirmpassword: '',
    user_addresses: [],
    user_phonenumbers: [],
    user_sociallinks: [],
});
function addUserAddress() {
    if (address_name.value.trim() == '') {
        alert("Kindly Enter Vehicle IN!");
        return false;
    }
    form.user_addresses.push({
        address_name: address_name.value,
        address_country: address_country.value,
        address_city: address_city.value,
        address_state: address_state.value,
        address_postcode: address_postcode.value,
        address_address_1: address_address_1.value,
        address_address_2: address_address_2.value,
    });
    address_name.value = '';
    address_country.value = '';
    address_city.value = '';
    address_state.value = '';
    address_postcode.value = '';
    address_address_1.value = '';
    address_address_2.value = '';
    hidemodal('#add_address');
}
function addUserPhone() {
    if (phonenumber.value.trim() == '') {
        alert("Kindly Enter Vehicle IN!");
        return false;
    }
    form.user_phonenumbers.push({
        type: phonenumber_type.value,
        country: phonenumber_country.value,
        phonenumber: phonenumber.value,

    });
    phonenumber_type.value = '';
    phonenumber_country.value = '';
    phonenumber.value = '';
    hidemodal('#add_phone');
}
function addUserSocial() {
    if (socialmedia_name.value.trim() == '') {
        alert("Kindly Enter Vehicle IN!");
        return false;
    }
    form.user_sociallinks.push({
        type: socialmedia_type.value,
        link: socialmedia_link.value,
        name: socialmedia_name.value,

    });
    socialmedia_type.value = '';
    socialmedia_link.value = '';
    socialmedia_name.value = '';
    hidemodal('#add_socialmedia');
}
function getToken() {
    const token = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authStore.token,
        },
    };
    return token;
}
onMounted(async () => {
    functions.initSoftLoader();
    var Users = new factory(getToken(), 'getallapis', { index: ['companies', 'gender','AccountTypes','roles'] });
    await Users.postForm().then((response) => {
        if (response.data.status == "success") {
            companiesdata.value = response.data.data.companies;
            genderdata.value = response.data.data.gender;
            usertypedata.value = response.data.data.accountTypes;
            rolesdata.value = response.data.data.roles;
        }
    });

    const Company = new factory(getToken(), 'users/' + route.query.id);
    await Company.getHttp().then(response => {
        if (response.data.status == "success") {
            form.value = response.data.data; 
        } else {
            return response.data.message;
        }
    });
    functions.callOnReady();
});
function showmodal(id) {
    $(id).modal('show');
}
function hidemodal(id) {
    $(id).modal('hide');
}
</script>
<style scoped>
.tabs-left {
    min-width: 200px !important;
}
</style>