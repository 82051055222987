<template>
  <div :class="$attrs.class">
    <div class="form-group">
      <label v-if="label" class="form-label" :for="id">{{ label }}</label>
      <select :id="id" :value="modelValue" v-bind="{ ...$attrs, class: null }" class="form-control"
        :class="{ error: error }" @input="$emit('update:modelValue', $event.target.value)">
        <slot />
      </select>
    </div>
  </div>
</template>
 
<script>
import { v4 as uuid } from 'uuid'

export default {
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default() {
        return `text-input-${uuid()}`
      },
    },
    type: {
      type: String,
      default: 'text',
    },
    error: String,
    label: String,
    modelValue: String,
  },
  emits: ['update:modelValue'],
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    select() {
      this.$refs.input.select()
    },
    setSelectionRange(start, end) {
      this.$refs.input.setSelectionRange(start, end)
    },
  },
}
</script>
