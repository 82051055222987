<template v-if="form.form_components != null">
  <div class="row mb-3" v-if="$attrs.preview != 1">
    <div class="col-md-12">
      <h3 class="text-center"><b>{{ form.form_title }}</b></h3>    
    </div>
  </div>
  <div
    v-for="(component, index, object) in form.form_components"
    :key="component"
    :class="component.form_component_cssclass"
    class="p-3"
    style="min-height: 10vh"
    v-show="component.form_component_display"
  >
    <div class="row">
      <template v-if="component.children != null">
        <div
          v-for="(childval, childindex, childobject) in component.children"
          class="p-2 pt-0"
          :class="childval.form_child_cssclass"
          :style="{
            display: childval.form_child_display == 1 ? 'block' : 'none',
          }"
        >
          <div class="form-group" style="position: relative">
            <template
              v-if="
                childval.form_child_type != 'checkbox' &&
                childval.form_child_type != 'heading'
              "
            >
              <label
                >{{ childval.form_child_label }}
                <i
                  style="float: right; display: none"
                  class="fa fa-repeat btn btn-dark resign"
                  v-if="childval.form_child_type == 'signaturepad'"
                  @click="saveSign($event.target)"
                ></i>
              </label>
            </template>
            <template v-if="childval.form_child_type == 'checkbox'">
              <label style="width: 100%">&nbsp;</label>
            </template>
            <template
              v-if="
                childval.form_child_type == 'datetime-local' ||
                childval.form_child_type == 'time' ||
                childval.form_child_type == 'text' ||
                childval.form_child_type == 'number' ||
                childval.form_child_type == 'date'
              "
            >
              <input
                :type="childval.form_child_type"
                class="form-control"
                :required="childval.form_child_mandatory"
                v-model="childval.form_child_value"
                :placeholder="childval.form_child_placeholder"
                :value="childval.form_child_value"
              />
            </template>
            <template v-else-if="childval.form_child_type == 'textarea'">
              <textarea
                class="form-control"
                v-model="childval.form_child_value"
                :required="childval.form_child_mandatory"
                :placeholder="childval.form_child_placeholder"
                :rows="childval.rows"
                >{{ childval.form_child_value }}</textarea
              >
            </template>
            <template v-else-if="childval.form_child_type == 'fileuploader'">
              <input
                class="form-control"
                :required="childval.form_child_mandatory"
                type="file"
              />
            </template>
            <template v-else-if="childval.form_child_type == 'signaturepad'">
              <!-- <div class="form-control" :style="{
                                height: ((childval.form_child_height) ? childval.form_child_height + 'px' : ''),
                                width: ((childval.form_child_width) ? childval.form_child_width + 'px' : ''),
                            }" style="border:1px solid grey;"></div> -->

              <img
                :src="childval.form_child_value"
                v-show="
                  childval.form_child_value == '' ||
                  childval.form_child_value == null
                    ? false
                    : true
                "
                class="signdisp"
              />
              <i
                class="fa fa-eraser eraserbtn btn"
                style="position: absolute; z-index: 11"
                v-if="childval.form_child_type == 'signaturepad'"
                @click="ActivateErasor($event.target)"
              ></i>
              <i
                style="float: right; position: absolute; right: 0; z-index: 11"
                class="fa fa-check btn btn-primary savesign"
                v-if="childval.form_child_type == 'signaturepad'"
                @click="saveSign($event.target)"
              ></i>
              <Sketchpad
                v-model="childval.form_child_value"
                :displayGrid="false"
                nooptions="true"
                :kstyle="{
                  height: childval.form_child_height
                    ? childval.form_child_height + 'px'
                    : '',
                  width: childval.form_child_width
                    ? childval.form_child_width + 'px'
                    : '',
                }"
              />
            </template>
            <template v-else-if="childval.form_child_type == 'imageeditor'">
              <div
                class="form-control"
                :style="{
                  height: childval.form_child_height
                    ? childval.form_child_height + 'px'
                    : '',
                  width: childval.form_child_width
                    ? childval.form_child_width + 'px'
                    : '',
                }"
                style="border: 1px solid grey"
              ></div>
            </template>
            <template v-else-if="childval.form_child_type == 'inlineimage'">
              <img
                class="img-responsive"
                :style="{
                  height: childval.form_child_height
                    ? childval.form_child_height + 'px'
                    : '',
                  width: childval.form_child_width
                    ? childval.form_child_width + 'px'
                    : '',
                }"
                :src="childval.form_child_link"
              />
            </template>

            <template v-else-if="childval.form_child_type == 'heading'">
              <span :class="childval.form_child_headtype">{{
                childval.form_child_value
              }}</span>
            </template>
            <template v-else-if="childval.form_child_type == 'paragraph'">
              <p>{{ childval.form_child_value }}</p>
            </template>

            <template v-else-if="childval.form_child_type == 'divider'">
              <hr
                :size="childval.form_child_height + 'px'"
                :style="{ opacity: '8' }"
              />
            </template>
            <template v-else-if="childval.form_child_type == 'checkbox'">
              <div class="checkbox-fade fade-in-primary">
                <label>
                  <input
                    type="checkbox"
                    :checked="childval.form_child_checked"
                    :required="childval.form_child_mandatory"
                    :value="childval.form_child_value"
                    v-model="childval.form_child_checked"
                    @change="
                      displayOrNot(
                        childval.form_child_uniqueid,
                        childval.form_child_checked
                          ? childval.form_child_value
                          : ''
                      )
                    "
                  />
                  <span class="cr">
                    <i class="cr-icon icofont icofont-ui-check txt-primary"></i>
                  </span>
                  <span>{{ childval.form_child_label }}</span>
                </label>
              </div>
            </template>
            <template v-else-if="childval.form_child_type == 'dropdown'">
              <select
                class="form-control"
                :required="childval.form_child_mandatory"
                v-model="childval.form_child_value"
                @change="
                  displayOrNot(
                    childval.form_child_uniqueid,
                    childval.form_child_value
                  )
                "
              >
                <template
                  v-for="(
                    options, option_index, option_object
                  ) in childval.form_child_options"
                >
                  <option
                    :value="options.optionvalue"
                    :selected="options.selected"
                  >
                    {{ options.optiontext }}
                  </option>
                </template>
              </select>
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
  <!-- <Signature v-model="signval" /> -->

  <div class="row mb-3" v-if="$attrs.preview != 1">
    <select-input
      v-model="form.report_assign_to"
      class="col-md-4"
      label="Assign To"
    >
      <option value="">Select...</option>
      <option v-for="user in usersdata" :value="user.u_id">
        {{ user.u_name }}
      </option>
    </select-input>
    <div class="col-md-4 text-center">
        <label>&nbsp;</label>
      <button
        type="button"
        @click="saveReport()"
        class="btn btn-primary btn-round waves-effect waves-light funload"
      >
        Save Changes
      </button>
    </div>
    <select-input
      v-model="form.report_reported_to"
      class="col-md-4"
      label="Reported To"
    >
      <option value="">Select...</option>
      <option v-for="user in usersdata" :value="user.u_id">
        {{ user.u_name }}
      </option>
    </select-input>
  </div>
</template>
<script setup>
import { defineEmits, onMounted, ref, reactive } from "vue";
import factory from '@/common/factory.js'
import Sketchpad from "../shared/sketchpad.vue";
import SelectInput from "./SelectBox.vue";
import { useAuthStore } from '@/store/auth.js'
const authStore = useAuthStore();
let props = defineProps({
  form: Array,
});
var usersdata = ref([]);
function getToken() {
    const token = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authStore.token,
        },
    };
    return token;
}
onMounted(async () => {
  var FormExtraData = new factory(getToken(), "getallapis", {
    index: ["users"],
  });
  await FormExtraData.postForm().then((response) => {
    swal.close();
    if (response.data.status == "success") {
      usersdata.value = response.data.data.users;
    }
  });

  $(".pcoded-navbar").show();
  $(".pcoded-content").removeAttr("style");
});

var definEmit = defineEmits(["reportSaveBtnTriggered"]);
function ActivateErasor(targetref) {
  if ($(targetref).hasClass("text-danger")) {
    $(targetref).closest(".form-group").find(".tool-pen").click();
    $(targetref).removeClass("text-danger");
  } else {
    $(targetref).closest(".form-group").find(".tool-eraser").click();
    $(targetref).addClass("text-danger");
  }
}
function saveSign(targetref) {
  $(targetref).hide();
  if ($(targetref).hasClass("savesign")) {
    $(targetref).closest(".form-group").find(".signdisp").show();
    $(targetref).closest(".form-group").find(".sketchpad").hide();
    $(targetref).closest(".form-group").find(".resign").show();
    $(targetref).closest(".form-group").find(".eraserbtn").hide();
    $(targetref).closest(".form-group").find(".tool-screenshot").click();
  } else {
    $(targetref).closest(".form-group").find(".signdisp").hide();
    $(targetref).closest(".form-group").find(".savesign").show();
    $(targetref).closest(".form-group").find(".eraserbtn").show();
    $(targetref).closest(".form-group").find(".sketchpad").show();
  }
}
var signval = ref();
const form = ref(props.form);
function displayOrNot(uniquename, currenvalue) {
  debugger;
  if (uniquename == undefined || uniquename == null || uniquename == "") {
  } else {
    for (var i = 0; i < form.value.form_components.length; i++) {
      // sections
      if (
        form.value.form_components[i].form_component_conditionalevents.length >
        0
      ) {
        form.value.form_components[i].form_component_conditionalevents.filter(
          filterEvents
        );
        function filterEvents(v, k) {
          if (v.uniquename == uniquename) {
            if (currenvalue == v.compunentvalue && v.condition == "==") {
              if (v.do == "show") {
                form.value.form_components[i].form_component_display = true;
              } else if (v.do == "hide") {
                form.value.form_components[i].form_component_display = false;
              }
              // console.log('Equal');
            } else if (currenvalue != v.compunentvalue && v.condition == "!=") {
              // console.log('Not Equal');
              if (v.do == "show") {
                form.value.form_components[i].form_component_display = true;
              } else if (v.do == "hide") {
                form.value.form_components[i].form_component_display = false;
              }
            }
          }
        }
      }

      // compunents
      if (form.value.form_components[i].children.length > 0) {
        form.value.form_components[i].children.filter((x, childkey) =>
          x.form_child_conditionalevents.filter((cond) =>
            filterCompunentEvents(cond, childkey)
          )
        );
        function filterCompunentEvents(v, k) {
          if (v.uniquename == uniquename) {
            if (currenvalue == v.compunentvalue && v.condition == "==") {
              if (v.do == "show") {
                form.value.form_components[i].children[
                  k
                ].form_child_display = true;
              } else if (v.do == "hide") {
                form.value.form_components[i].children[
                  k
                ].form_child_display = false;
              }
            } else if (currenvalue != v.compunentvalue && v.condition == "!=") {
              if (v.do == "show") {
                form.value.form_components[i].children[
                  k
                ].form_child_display = true;
              } else if (v.do == "hide") {
                form.value.form_components[i].children[
                  k
                ].form_child_display = false;
              }
            }
          }
        }
      }
    }
  }
}
// function displayOrNot(uniquename, currenvalue) {
//     if (uniquename == undefined || uniquename == null || uniquename == '') {
//     } else {
//         for (var i = 0; i < form.form_components.length; i++) {

//             // sections
//             if (form.form_components[i].conditionalevents.length > 0) {
//                 form.form_components[i].conditionalevents.filter(filterEvents);
//                 function filterEvents(v, k) {
//                     if (v.uniquename == uniquename) {
//                         if (currenvalue == v.compunentvalue && v.condition == '==') {
//                             if (v.do == 'show') {
//                                 form.form_components[i].display = true;
//                             } else if (v.do == 'hide') {
//                                 form.form_components[i].display = false;
//                             }
//                             // console.log('Equal');
//                         } else if (currenvalue != v.compunentvalue && v.condition == '!=') {
//                             // console.log('Not Equal');
//                             if (v.do == 'show') {
//                                 form.form_components[i].display = true;
//                             } else if (v.do == 'hide') {
//                                 form.form_components[i].display = false;
//                             }
//                         }
//                     }
//                 }
//             }

//             // compunents
//             if (form.form_components[i].children.length > 0) {
//                 form.form_components[i].children.filter((x, childkey) => x.conditionalevents.filter((cond) => filterCompunentEvents(cond, childkey)))
//                 function filterCompunentEvents(v, k) {
//                     if (v.uniquename == uniquename) {
//                         if (currenvalue == v.compunentvalue && v.condition == '==') {
//                             if (v.do == 'show') {
//                                 form.form_components[i].children[k].display = true;
//                             } else if (v.do == 'hide') {
//                                 form.form_components[i].children[k].display = false;
//                             }
//                         } else if (currenvalue != v.compunentvalue && v.condition == '!=') {
//                             if (v.do == 'show') {
//                                 form.form_components[i].children[k].display = true;
//                             } else if (v.do == 'hide') {
//                                 form.form_components[i].children[k].display = false;
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }

function saveReport() {
  definEmit("reportSaveBtnTriggered");
}
</script>
