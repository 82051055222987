<template>
  <div :class="$attrs.class">
    <div class="form-group">
      <label v-if="label" class="form-label" :for="id">{{ label }}</label>
      <textarea :id="id" :rows="$attrs.rows" ref="input" v-bind="{ ...$attrs, class: null }" class="form-control"
        :class="{ error: error }" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" />
      <div v-if="error" class="form-error">{{ error }}</div>
      <div class="col-form-label text-danger" v-if="$attrs.textinfo != undefined && $attrs.textinfo != null && $attrs.textinfo != ''">{{$attrs.textinfo}}</div>
    </div>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'

export default {
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default() {
        return `textarea-input-${uuid()}`
      },
    },
    error: String,
    label: String,
    modelValue: String,
  },
  emits: ['update:modelValue'],
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    select() {
      this.$refs.input.select()
    },
  },
}
</script>
