<template>
    <div class="main-body">
        <div class="page-wrapper">
            <!-- Page-header start -->
            <div class="page-header">
                <div class="row align-items-end">
                    <div class="col-lg-10">
                        <div class="page-header-title">
                            <div class="d-inline">
                                <h4>AccountTypes</h4>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="page-header-breadcrumb">
                            <ul class="breadcrumb-title">
                                <li class="breadcrumb-item" style="float: left;">
                                    <a href="index.html">
                                        <i class="icofont icofont-home"></i>
                                    </a>
                                </li>
                                <li class="breadcrumb-item" style="float: left;"><router-link
                                        :to="{ name: 'VAAccountTypes' }">AccountTypes</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
            <!-- Page-header end -->
            <!-- Page body start -->
            <div class="page-body">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-block">
                                <button type="button" class="btn  waves-effect f-right" @click="addNew()">Add
                                    New</button>
                            </div>
                            <div class="card-body">
                                <tableView v-if="displaytbl" :data="responsefromapi" @viewbuttonclicked="editbtnclicked"
                                    @deletebuttonclicked="deletebuttonclicked" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script setup>
import tableView from '../../../shared/table.vue';
import { functions } from '../../../../common/appFunctions.js';
import { ref, reactive, onMounted } from 'vue';
import { useAuthStore } from '@/store/auth.js'
import factory from '@/common/factory.js'
import { transferData } from '@/store/transferdata.js'
import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const authStore = useAuthStore();
const dataStore = transferData();
const Vue = window.vue;
const route = useRoute();
const form = reactive({
    id: 0,
    company_id: 0,
    company_name: '',
    name: '',
    isactive: 0,
});
var responsefromapi = ref();
var displaytbl = ref(false);
function getToken() {
    const token = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authStore.token,
        },
    };
    return token;
}

async function deletebuttonclicked(id) {
    const Company = new factory(getToken(), 'companies/' + id, form);
    await Company.deleteHttp().then((response) => {
        console.log(response);
        if (response.data.status == "success") {
            location.reload();
        } else {
            console.log(response.data.message);
        }
    });

};
function editbtnclicked(id) {
    router.push({
        name: 'VAEditAccountType',
        query: {
            id,
        },
    })
}
function addNew(id) {
    router.push({
        name: 'VAAddAccountType'
    })
}
onMounted(async () => {
    var token = getToken();
    const Company = new factory(getToken(), 'companies');
    const dt = await Company.getHttp().then(response => {
        var tabledata = {
            "conditions": {
                'update': true,
                'trash': true,
                'active': true,
                'primarykey': 'id',
                'hiddendata': ['id', 'created_at', 'istrashed', 'updated_at', 'company_id', 'isactive'],
                'active_index': 'isactive',
            },
            "columns": [
                'Company',
                'Name',
                'Active',
                '',
            ],
            "data": response.data.data
        };
        displaytbl.value = true
        responsefromapi.value = tabledata;
        console.log(response.data.data);
    });
    functions.callOnReady();
})
function showmodal(id) {
    $(id).modal('show');
}
function hidemodal(id) {
    $(id).modal('hide');
}
</script> 
