<template>
    <div class="d-flex align-items-center justify-content-center vh-100">
        <div class="text-center">
            <h1 class="display-1 fw-bold">404</h1>
            <p class="fs-3"> <span class="text-danger">Opps!</span> Page not found.</p>
            <p class="lead">
                The page you’re looking for doesn’t exist.
            </p>
            <div v-if="auth.user.account_type_id === '1'">
                <router-link :to="{ name: 'SADashboard' }">Go Home</router-link>
            </div>
            <div v-else="auth.user.account_type_id === '2'">
                <router-link :to="{ name: 'VADashboard' }">Go Home</router-link>
            </div>
            <div v-else>
                <router-link :to="{ name: 'Login' }">Go Back</router-link>
            </div>
        </div>
    </div>
</template>
<script>

import { useAuthStore } from '@/store/auth.js'



export default {
    setup() {
        const auth = useAuthStore()
        return { auth }
    },
}
</script>
