<template>
    <div class="main-body">
        <div class="page-wrapper">
            <!-- Page body start -->
            <div class="page-body">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-header">
                                <h5>
                                    <span>Account Settings</span>

                                </h5>
                                <div class="card-header-right">
                                    <div class="page-header-breadcrumb">
                                        <ul class="breadcrumb-title">
                                            <li class="breadcrumb-item" style="float: left;">
                                                <a href="index.html">
                                                    <i class="icofont icofont-home"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-3" style="border-right: 1px dashed grey;">
                                        <div class="row">
                                            <image-input v-model="form.accountsetting_logo" class="col-md-12"
                                                :source="form.accountsetting_logo" kStyle="height:20vh;" label="Account Logo" />
                                                <div class="col-md-12">
                                                    <hr />
                                                </div> 
                                            <image-input v-model="form.accountsetting_loginlogo" class="col-md-12"
                                                :source="form.accountsetting_loginlogo" kStyle="height:20vh;" label="Login Logo" />
                                                <div class="col-md-12">
                                                    <hr />
                                                </div> 
                                            <image-input v-model="form.accountsetting_favlogo" class="col-md-12"
                                                :source="form.accountsetting_favlogo" kStyle="height:20vh;"
                                                label="Fav Logo" />
                                                <div class="col-md-12">
                                                    <hr />
                                                </div> 
                                            <image-input v-model="form.accountsetting_bglogo" class="col-md-12"
                                                :source="form.accountsetting_bglogo" kStyle="height:20vh;"
                                                label="Login Page Background" />
                                        </div>
                                    </div> 
                                    <div class="col-md-9">
                                        <div class="row">
                                            <text-input class="col-md-3"
                                                v-model="form.accountsetting_meta.accountlogo_height"
                                                label="Logo Height (px)" type="number" />
                                            <color-input class="col-md-3"
                                                v-model="form.accountsetting_meta.primary" label="Primary" />
                                            <color-input class="col-md-3"
                                                v-model="form.accountsetting_meta.primaryColor"
                                                label="Primary Text Color" />
                                            <color-input class="col-md-3"
                                                v-model="form.accountsetting_meta.primaryBorder"
                                                label="Primary Border Color" />
                                             
                                            <color-input class="col-md-3"
                                                v-model="form.accountsetting_meta.primaryHover" label="Primary Hover" />
                                            <color-input class="col-md-3"
                                                v-model="form.accountsetting_meta.primaryHoverColor"
                                                label="Primary Hover Color" />
                                            <color-input class="col-md-3"
                                                v-model="form.accountsetting_meta.primaryHoverBorder"
                                                label="Primary Hover Border" />
                                            <color-input class="col-md-3"
                                                v-model="form.accountsetting_meta.primaryLight"
                                                label="Primary Light" />
                                            <color-input class="col-md-3"
                                                v-model="form.accountsetting_meta.secondaryColor"
                                                label="Secondary Color" />
                                            <color-input class="col-md-3"
                                                v-model="form.accountsetting_meta.secondaryBorder"
                                                label="Secondary Border" />
                                            <color-input class="col-md-3"
                                                v-model="form.accountsetting_meta.sidebarTopBg"
                                                label="Sidebar Top Background" />
                                            <color-input class="col-md-3"
                                                v-model="form.accountsetting_meta.sidebartopToggleBtn"
                                                label="Sidebar Toggle Button" />
                                            <color-input class="col-md-3"
                                                v-model="form.accountsetting_meta.textColor"
                                                label="Text Color" />
                                        </div>
                                    </div>
                                    <div class="col-md-12 text-center">
                                        <button type="button" class="btn btn-primary btn-round waves-effect waves-light "
                                            @click="addHeaderPage()">Save Changes</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import SelectInput from '../../../shared/SelectBox.vue';
import HeaderPreview from '../../../website/headerDesigns/index.vue';
import TextInput from '../../../shared/TextInput.vue';
import ColorInput from '../../../shared/ColorInput.vue';
import ImageInput from '../../../shared/ImageInput.vue';
import { functions } from '../../../../common/appFunctions.js';
import { ref, reactive, onMounted, provide } from 'vue';
import { useAuthStore } from '@/store/auth.js'
import factory from '@/common/factory.js'
import { useRouter } from 'vue-router';
var mn_group = ref({});
const authStore = useAuthStore();
const isPreview = ref(false);
 

var form = ref({
    accountsetting_id: 0,
    accountsetting_vendor_id: authStore.user.u_vendor_id,
    accountsetting_logo: 'https://caredoodles.t6ve.com/public/uploads/images/1676478976.png',
    accountsetting_loginlogo: 'https://caredoodles.t6ve.com/public/uploads/images/1676478976.png',
    accountsetting_favlogo: 'https://caredoodles.t6ve.com/public/uploads/images/1678096214.png',
    accountsetting_bglogo: 'https://caredoodles.t6ve.com/public/uploads/images/1678096245.jpg',
    accountsetting_meta: {
        primary:'#5271ff',
        primaryColor:'#5271ff',
        primaryBorder:'#5271ff',
        primaryHover:'#5de1e6',
        primaryHoverColor:'#black',
        primaryHoverBorder:'#5de1e6',
        primaryLight:'#ebf7fc',
        secondaryColor:'#5de1e6',
        secondaryBorder:'#5de1e6',
        sidebarTopBg:'white',
        sidebartopToggleBtn:'#5271ff',
        textColor:'#404e67',
    },
});


function getToken() {
    const token = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authStore.token,
        },
    };
    return token;
}

async function addHeaderPage() {

    const PageHeader = new factory(getToken(), 'accountsetting', form.value);
    await PageHeader.postForm().then((response) => {
        if (response.data.status == "success") {
            swal.close();
            functions.kSwal('Done','Changes Saved Successfully!');
        } else {
            console.log(response.data.message);
        }
    });
};
provide('getToken', getToken());
onMounted(async () => {
    functions.initLoader();
    const user = new factory(getToken(), 'accountsetting');
    const dt = await user.getHttp().then(response => {
        functions.destroyLoader();
        form.value = response.data.data[0];
        form.value.accountsetting_meta = JSON.parse(form.value.accountsetting_meta);
         
    });
    

    functions.callOnReady();
}) 
</script>  