<template>
    <div class="main-body">
        <div class="page-wrapper">
            <!-- Page-header start -->
            <div class="page-header">
                <div class="row align-items-end">
                    <div class="col-lg-10">
                        <div class="page-header-title">
                            <div class="d-inline">
                                <h4>Vendors</h4>
                                <span>All Vendors</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="page-header-breadcrumb">
                            <ul class="breadcrumb-title">
                                <li class="breadcrumb-item" style="float: left;">
                                    <a href="index.html">
                                        <i class="icofont icofont-home"></i>
                                    </a>
                                </li>
                                <li class="breadcrumb-item" style="float: left;"><router-link
                                        :to="{ name: 'SAVendors' }">Vendors</router-link>
                                </li>
                                <li class="breadcrumb-item" style="float: left;"><router-link
                                        :to="{ name: 'SAVendors' }">All</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
            <div class="page-body">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-block">
                                
                            </div>
                            <div class="card-body">
                                <tableView :data="tabledata" @viewbuttonclicked="editbtnclicked" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import tableView from '../../../shared/table.vue';

export default {
    data() {
        return {
            tabledata: {
                "conditions": {
                    'update': true,
                    'trash': true,
                    'active': true,
                    'active_index': 'status',
                    'primarykey': 'id',
                    'hiddendata': ['id'],
                },
                "columns": [
                    'Name',
                    'Email',
                    'Phone',
                    'Active',
                    '',
                ],
                "data": [

                    { 'id': 1, 'name': 'Vendor 1', 'email': 'vendor1@gmail.com', 'phone': '+1 0000 1233123123', 'status': true },
                    { 'id': 2, 'name': 'Vendor 2', 'email': 'vendor2@gmail.com', 'phone': '+1 0000 1233123123', 'status': true },
                    { 'id': 3, 'name': 'Vendor 3', 'email': 'vendor3@gmail.com', 'phone': '+1 0000 1233123123', 'status': true }

                ]
            }
        };
    },
    methods: {
        editbtnclicked(pkey) {
            this.$router.push({ path: '/super_admin/vendors/single_vendor/', params: { id: pkey } });
            // router.push('/users/eduardo')
            // this.$route.push({ name: 'home' }) 
            //this.$router.push('/super_admin/vendors/single_vendor/' + pkey)
            // this.$router.push({
            //     name: 'NotFound',
            //     // preserve current path and remove the first char to avoid the target URL starting with `//`
            //     params: { pathMatch: this.$route.path.substring(1).split('/') },
            //     // preserve existing query and hash if any
            //     query: this.$route.query,
            //     hash: this.$route.hash,
            // })
        }
    },
    components: {
        tableView
    },
    mounted: function () {
        // router.push('/users/eduardo')
        // $(document).on('click','.editbtn', function(){
        //     this.$router.push('/users/eduardo')
        // })
    }
};
</script>
