<template>Super Admin Dashboard</template>
<!-- <script>
import { useAuthStore } from '@/stores/auth.js';

export default {
    setup() {
        const store = useAuthStore()

        return {
            // you can return the whole store instance to use it in the template
            store,
        }
    },
}



</script> -->