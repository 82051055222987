import Login from "../views/authentication/login.vue";
import Signup from "../views/authentication/signup.vue";

export default [
  {
    name: "Login",
    path: "login",
    component: Login,
    meta: { title: "Login", requiresAuth: false, authPage: true },
  },
  {
    name: "Signup",
    path: "signup",
    component: Signup,
    meta: { title: "Vendor Signup", requiresAuth: false, authPage: true },
  },
];
