<template>
    <div class="main-body">
        <div class="page-wrapper">
             
            <!-- Page body start -->
            <div class="page-body">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-header">
                                <h5>
                                    <span>Budget Cap</span>
                                </h5>
                                <span class="text-muted">Budget Caps are used on the rota to ensure you are not under or over staffed on a
                                    given day or week.</span>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <select-input v-model="locationid" class="col-md-3" label="Location">
                                        <option value="1">Location 1</option>
                                    </select-input>


                                    <text-input v-model="year" class="col-md-3" label="Year" />
                                    <div class="col-md-3 mt-1">
                                        <div class="form-group">
                                            <label style="width:100%">&nbsp;</label>
                                            <button type="button"
                                                class="btn btn-block btn-primary waves-effect waves-light "
                                                @click="reloadData();">Apply Changes</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-body p-0 ">
                                <div class="col-md-12 table-responsive p-0">
                                    <table class="table ">
                                        <thead>
                                            <tr>
                                                <th class="text-center" style="width:5%">WK #</th> 
                                                <th class="text-center" style="width:15%">Target Hours <br />
                                                    Target Cost</th>
                                                <th style="width:85%" class="p-0">
                                                    <table
                                                        style="width:100%;background: black;color:white;height:50px;"
                                                        class="table table-bordered mb-0">
                                                        <tbody>
                                                            <th class="text-center ta-middle">M</th>
                                                            <th class="text-center ta-middle">T</th>
                                                            <th class="text-center ta-middle">W</th>
                                                            <th class="text-center ta-middle">T</th>
                                                            <th class="text-center ta-middle">F</th>
                                                            <th class="text-center ta-middle">S</th>
                                                            <th class="text-center ta-middle">S</th>
                                                        </tbody>
                                                    </table>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-if="datesarray.length > 0">
                                                <tr v-for="weeks, index in datesarray">
                                                    <td class="ta-middle">{{ index+ 1 }}</td>
                                                    <td class="ta-middle text-center">
                                                        <span class="pb-1">{{
                                                            weeks[0].format('DD MMM') + ' - ' +
                                                                $moment(weeks[0]).add(6, 'Days').format('DD MMM YYYY')
                                                        }}</span>

                                                        <div class="input-group mt-1 pr-0 mb-0  input-group-sm">
                                                            <div class="input-group-prepend">
                                                                <span
                                                                    class="input-group-text input-group-text-sm ">h</span>
                                                            </div>
                                                            <input type="text" v-model="weeks[1]"
                                                                @change="totalHoursChange(index,weeks[0].format('YYYY-MM-DD'));"
                                                                class="form-control form-bg-inverse form-control-sm form-control-right">
                                                        </div>
                                                        <div class="input-group mt-1 pr-0 mb-0  input-group-sm">
                                                            <div class="input-group-prepend">
                                                                <span
                                                                    class="input-group-text input-group-text-sm ">£</span>
                                                            </div>
                                                            <input type="text" v-model="weeks[2]"
                                                                @change="totalChange(index,weeks[0].format('YYYY-MM-DD'));"
                                                                class="form-control form-bg-inverse form-control-sm form-control-right">
                                                        </div>
                                                    </td>
                                                    <td style="width:85%" class="p-0">
                                                        <table style="width:100%;background: black;"
                                                            class="table table-bordered mb-0">
                                                            <tbody>

                                                                <td class="p-0" v-for="weekinfo, dayindex in weeks[3]">
                                                                    <div class="input-group  pr-0 mb-0  input-group-sm">
                                                                        <div class="input-group-prepend">
                                                                            <span
                                                                                class="input-group-text input-group-text-sm ">h</span>
                                                                        </div>
                                                                        <input type="text" v-model="weekinfo[0]"
                                                                            @change="singCalculate(index, dayindex,weeks[0].format('YYYY-MM-DD'));"
                                                                            class="form-control form-bg-inverse form-control-sm form-control-right">
                                                                    </div>
                                                                    <div
                                                                        class="input-group  pr-0 mb-0 pt-0 input-group-sm">
                                                                        <div class="input-group-prepend">
                                                                            <span
                                                                                class="input-group-text input-group-text-sm ">£</span>
                                                                        </div>
                                                                        <input type="text" v-model="weekinfo[1]"
                                                                            @change="singCalculate(index, dayindex,weeks[0].format('YYYY-MM-DD'));"
                                                                            class="form-control form-bg-inverse form-control-sm form-control-right">
                                                                    </div>
                                                                </td>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script setup>
import SelectInput from '../../../shared/SelectBox.vue';
import HeaderPreview from '../../../website/headerDesigns/index.vue';
import TextInput from '../../../shared/TextInput.vue';
import ColorInput from '../../../shared/ColorInput.vue';
import ImageInput from '../../../shared/ImageInput.vue'
import { functions } from '../../../../common/appFunctions.js';
import { ref, reactive, onMounted } from 'vue';
import { useAuthStore } from '@/store/auth.js'
import factory from '@/common/factory.js'
import { useRouter } from 'vue-router';
import moment from 'moment';

const authStore = useAuthStore();
const isPreview = ref(false);
const calenderdisplay = ref(false);
var locationid = ref(1);


var today = new Date();
var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
// var lastDay = new Date(firstDay.getFullYear(), 12, 0);
var datesarray = ref([]);
var employeeid = ref(1);
// var month = ref(today.getMonth().toString());
var year = ref(firstDay.getFullYear());
var nextThirtyDays = getAllWeekDatesArray(year.value);
nextThirtyDays.forEach((v, k) => {
    // datesarray 0,1,2
    // ******************************************************************************
    // datesarray[0] is equal to date
    // ******************************************************************************
    // datesarray[1] is equal to totalamount
    // ******************************************************************************
    // datesarray[2] is equal to object
    // datesarray[2] object key have days value have 3 indexed array 0,1,2
    // #####################################################################
    // datesarray[2].monday[0] - Amount (pound)
    // datesarray[2].monday[1] - Percetage Value (%)
    // datesarray[2].monday[2] - Percet Amount in Pound 
    // ******************************************************************************
    // datesarray[3][0] - Weekly labour % target (total percentage)
    // datesarray[3][1] - Weekly labour % target (total Pound)
    datesarray.value.push([
        v, '','', {
            monday: ['', ''],
            tuesday: ['', ''],
            wednesday: ['', ''],
            thursday: ['', ''],
            friday: ['', ''],
            saturday: ['', ''],
            sunday: ['', ''],
        }
    ]);
});

function getAllWeekDatesArray(year) {
    const start = moment(`${year}-01-01`).startOf('week');
    const end = moment(`${year}-12-31`).endOf('week');
    let currentDate = start;
    const dates = [];

    while (currentDate <= end) {
        dates.push(moment(currentDate));
        currentDate = currentDate.clone().add(1, 'week');
    }

    return dates;
}

function reloadData() {

    var dateselected = new Date(year.value, '01', '01');
    var firstDay = new Date(dateselected.getFullYear(), dateselected.getMonth(), 1);
     
    datesarray.value = [];  
    year.value = firstDay.getFullYear();
    var nextThirtyDays = getAllWeekDatesArray(year.value);
    nextThirtyDays.forEach((v, k) => {
        // datesarray 0,1,2
        // ******************************************************************************
        // datesarray[0] is equal to date
        // ******************************************************************************
        // datesarray[1] is equal to totalamount
        // ******************************************************************************
        // datesarray[2] is equal to object
        // datesarray[2] object key have days value have 3 indexed array 0,1,2
        // #####################################################################
        // datesarray[2].monday[0] - Amount (pound)
        // datesarray[2].monday[1] - Percetage Value (%)
        // datesarray[2].monday[2] - Percet Amount in Pound 
        // ******************************************************************************
        // datesarray[3][0] - Weekly labour % target (total percentage)
        // datesarray[3][1] - Weekly labour % target (total Pound)
        datesarray.value.push([
            v, '','', {
                monday: ['', ''],
                tuesday: ['', ''],
                wednesday: ['', ''],
                thursday: ['', ''],
                friday: ['', ''],
                saturday: ['', ''],
                sunday: ['', ''],
            }
        ]);
    });
}

async function singCalculate(index, dayindex,dated) {
 
    var budgetcapdata = {
        budgetcab_location_id:locationid.value,
        total_cost:0,
        total_hours:0,
        monday_total_cost:0,
        monday_total_hours:0,
        tuesday_total_cost:0,
        tuesday_total_hours:0,
        wednesday_total_cost:0,
        wednesday_total_hours:0,
        thursday_total_cost:0,
        thursday_total_hours:0,
        friday_total_cost:0,
        friday_total_hours:0,
        saturday_total_cost:0,
        saturday_total_hours:0,
        sunday_total_cost:0,
        sunday_total_hours:0,
        budgetcab_date: dated
    }
    
    var totalhours = 0;
    var rowTotalPound = 0;
    Object.keys(datesarray.value[index][3]).forEach(function (day, value) {
        if (datesarray.value[index][3][day][0] != undefined && datesarray.value[index][3][day][0] != '' && datesarray.value[index][3][day][0] != null) {
            totalhours += parseFloat(datesarray.value[index][3][day][0]);
            budgetcapdata[day+'_total_hours'] = parseFloat(datesarray.value[index][3][day][0]);
        }
        if (datesarray.value[index][3][day][1] != undefined && datesarray.value[index][3][day][1] != '' && datesarray.value[index][3][day][1] != null) {
            rowTotalPound += parseFloat(datesarray.value[index][3][day][1]);
            budgetcapdata[day+'_total_cost'] = parseFloat(datesarray.value[index][3][day][0]);
        }
    });
    datesarray.value[index][1] = parseFloat(totalhours).toFixed(2);
    datesarray.value[index][2] = parseFloat(rowTotalPound).toFixed(2);
    budgetcapdata.total_cost = parseFloat(totalhours).toFixed(2);
    budgetcapdata.total_hours = parseFloat(rowTotalPound).toFixed(2);

    const Forcast = new factory(getToken(), 'budgetcabs',budgetcapdata);
        await Forcast.postForm().then(response => {
            if (response.data.status == "success") {
                swal.close(); 
            }
    });
}
function getToken() {
    const token = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authStore.token,
        },
    };
    return token;
}
async function totalChange(index,dated) {
    var totalamount = datesarray.value[index][2];
    var singleamt = totalamount / 7;

    var budgetcapdata = {
        budgetcab_location_id:locationid.value,
        // "budgetcab_company_id":22,
        // budgetcab_residency_id:parseFloat(singleamt).toFixed(2),
        "total_cost":parseFloat(singleamt).toFixed(2),
        //total_hours:parseFloat(totalamount).toFixed(2),
        "monday_total_cost":parseFloat(totalamount).toFixed(2),
        //monday_total_hours: parseFloat(singleamt).toFixed(2),
        "tuesday_total_cost": parseFloat(singleamt).toFixed(2),
        //tuesday_total_hours: parseFloat(singleamt).toFixed(2),
        "wednesday_total_cost": parseFloat(singleamt).toFixed(2),
        //wednesday_total_hours: parseFloat(singleamt).toFixed(2),
        "thursday_total_cost": parseFloat(singleamt).toFixed(2),
        //thursday_total_hours: parseFloat(singleamt).toFixed(2),
        "friday_total_cost": parseFloat(singleamt).toFixed(2),
        //friday_total_hours: parseFloat(singleamt).toFixed(2),
        "saturday_total_cost": parseFloat(singleamt).toFixed(2),
        //saturday_total_hours:parseFloat(singleamt).toFixed(2),
        "sunday_total_cost":parseFloat(singleamt).toFixed(2), 
        //sunday_total_hours:parseFloat(singleamt).toFixed(2),
        budgetcab_date: dated
    }
    const Forcast = new factory(getToken(), 'budgetcabs',budgetcapdata);
        await Forcast.postForm().then(response => {
            if (response.data.status == "success") {
                swal.close();
                Object.keys(datesarray.value[index][3]).forEach(function (day, value) {
                    datesarray.value[index][3][day][1] = parseFloat(singleamt).toFixed(2);
                });
            }
    });
}
async function totalHoursChange(index,dated) { 
    var totalamount = datesarray.value[index][1];
    var singleamt = totalamount / 7;

    var budgetcapdata = {
        budgetcab_location_id:locationid.value,
        // "budgetcab_company_id":22,
        // budgetcab_residency_id:parseFloat(singleamt).toFixed(2),
        // "total_cost":222,
        total_hours:parseFloat(totalamount).toFixed(2),
        // "monday_total_cost":222,
        monday_total_hours: parseFloat(singleamt).toFixed(2),
        // "tuesday_total_cost":222,
        tuesday_total_hours: parseFloat(singleamt).toFixed(2),
        // "wednesday_total_cost":22,
        wednesday_total_hours: parseFloat(singleamt).toFixed(2),
        // "thursday_total_cost":22,
        thursday_total_hours: parseFloat(singleamt).toFixed(2),
        // "friday_total_cost":22,
        friday_total_hours: parseFloat(singleamt).toFixed(2),
        // "saturday_total_cost":22,
        saturday_total_hours:parseFloat(singleamt).toFixed(2),
        // "sunday_total_cost":22,
        sunday_total_hours:parseFloat(singleamt).toFixed(2),
        budgetcab_date: dated
    }

    const Forcast = new factory(getToken(), 'budgetcabs',budgetcapdata);
    await Forcast.postForm().then(response => {
        if (response.data.status == "success") {
            swal.close();
            Object.keys(datesarray.value[index][3]).forEach(function (day, value) {
                datesarray.value[index][3][day][0] = parseFloat(singleamt).toFixed(2);
            });
             
        }
    });
}


onMounted(async () => {
    functions.callOnReady();
    calenderdisplay.value = true;
}) 
</script>
<style scoped>
table {
    font-size: 12px;
}
</style>
