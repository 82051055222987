<template>
    <div :class="$attrs.class + ' ' + data.child_cssclass + ((data.child_display) ? '' : ' cond-hide ')"
    :data-key="$attrs.childindex"
    :data-sectionkey="$attrs.index">
        <template v-if="$attrs.isbuilder">
            <i class="ti-move movebtn" @click="addSortChild();"></i>
            <i class="ti-close removebtn" @click="removeCompunent($attrs.index, $attrs.childindex,$attrs.child_uniqueid);"></i>
            <i class="ti-settings settingbtn" @click="configureCompunent($attrs.childindex);"></i>
        </template>
        <div class="clients owl-carousel owl-theme" :class="(data.meta.initclass) ? data.meta.initclass : 'clients'">
            <template v-if="data.meta.data != null && data.meta.data != undefined">
                <div class="single" v-for="imageurl in data.meta.data">
                    <img :src="imageurl.image" alt="" />
                </div>
            </template>
        </div>
    </div>
</template>
<script setup>
let props = defineProps({
    data: Array,
});
var definedEmits = defineEmits(['addSortChild', 'removeCompunent', 'configureCompunent'])

function addSortChild() {
    definedEmits('addSortChild');
}

function removeCompunent(index, childindex,child_uniqueid) {
    definedEmits('removeCompunent', index, childindex,child_uniqueid);
}
function configureCompunent(childindex) {
    definedEmits('configureCompunent', childindex);
}
</script>