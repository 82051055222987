<template>
    <div class="main-body">
        <div class="page-wrapper">
             
            <!-- Page body start -->
            <div class="page-body">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-header">
                                <h5>
                                    <span>Pages</span>
                                    <button type="button"
                                        class="btn btn-primary addnewbtn btn-round waves-effect f-right "
                                        @click="addNew()">Add
                                        New</button>
                                </h5>
                                <div class="card-header-right">
                                    <div class="page-header-breadcrumb">
                                        <ul class="breadcrumb-title">
                                            <li class="breadcrumb-item" style="float: left;">
                                                <a href="index.html">
                                                    <i class="icofont icofont-home"></i>
                                                </a>
                                            </li>
                                            <li class="breadcrumb-item" style="float: left;"><router-link
                                                    :to="{ name: 'VAPages' }">Pages</router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <div class="card-body">
                                <table-view v-if="displaytbl" :data="responsefromapi" @activeunactive="activeunactive"
                                    @viewbuttonclicked="editbtnclicked" @deletebuttonclicked="deletebuttonclicked" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="add_page">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Add New</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        @click="hidemodal('#add_page');">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <text-input class="col-md-6" v-model="form.page_name" label="Name" @change="renderAlias('add');"
                            @keyup="renderAlias('add');" @keydown="renderAlias('add');" />
                        <text-input class="col-md-6" v-model="form.page_alias" label="Alias (unique)" readonly />
                        <text-input class="col-md-12" v-model="form.page_shortdescription" label="Short Description" />
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default waves-effect " data-dismiss="modal"
                        @click="hidemodal('#add_page');">Close</button>
                    <button type="button" class="btn btn-primary btn-round waves-effect waves-light "
                        @click="addPage();">Save
                        Information</button>
                </div>
            </div>
        </div>
    </div>


</template>

<script setup>
import TextInput from '../../../shared/TextInput.vue';
import TableView from '../../../shared/TableView.vue';
import { functions } from '../../../../common/appFunctions.js';
import { ref, reactive, onMounted } from 'vue';
import { useAuthStore } from '@/store/auth.js'
import factory from '@/common/factory.js'
import { useRouter } from 'vue-router';

const authStore = useAuthStore();
const router = useRouter();
const form = reactive({
    page_vendor_id: authStore.user.u_vendor_id,
    page_name: '',
    page_alias: '',
    page_shortdescription: '',
});
var responsefromapi = ref();
var displaytbl = ref(false);
function getToken() {
    const token = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authStore.token,
        },
    };
    return token;
}
function renderAlias(condition = '') {
    if (condition == 'add') {
        form.page_alias = form.page_name.replace(/ /g, '-');
    } else {
        updateform.page_alias = updateform.page_name.replace(/ /g, '-');
    }
}
async function addPage() {
    const Page = new factory(getToken(), 'pages', form);
    await Page.postForm().then((response) => {
        if (response.data.status == "success") {
            location.reload();
        } else {
            console.log(response.data.message);
        }
    });
}
async function updatePage() {
    const Page = new factory(getToken(), 'companies/' + id, updateform);
    await Page.putForm().then((response) => {
        if (response.data.status == "success") {
            location.reload();
        } else {
            console.log(response.data.message);
        }
    });
}
function deletebuttonclicked(id) {
  var ask = functions.askSwal();
  ask.then(async (result) => {
    if (result.isConfirmed) {
      const users = new factory(getToken(), "pages/" + id);
      await users.deleteHttp().then((response) => {
        if (response.data.status == "success") {
          location.reload();
        } else {
          console.log(response.data.message);
        }
      });
    }
  });
} 
async function activeunactive(id,status){
    var upactiveunactive = { 
        page_id:id,
        page_isactive:!status
    }
    var updatestatus = new factory(getToken(), 'activepages', upactiveunactive);
    await updatestatus.putForm().then((response) => {
        swal.fire('Done!', 'Status Changed Successfully.','success');
    });
}
function editbtnclicked(id) {
    router.push({
        name: 'VAUpdatePages',
        query: {
            id,
        },
    })
}
function addNew() {
    showmodal('#add_page');
}
onMounted(async () => {
    // var token = getToken();
    functions.initLoader();
    const Menu = new factory(getToken(), 'pages/vendor/' + authStore.user.u_vendor_id);
    await Menu.getHttp().then(response => {
         functions.destroyLoader();
        var tabledata = {
            "conditions": {
                'update': true,
                'trash': true,
                'active': true,
                'primarykey': 'page_id',
                'hiddendata': ['page_id'],
                'active_index': 'page_isactive',
            },
            "columns": [
                'Name',
                'Alias',
                'Link',
                'Active',
                '',
            ],
            "data_order": ['page_name', 'page_alias', 'page_link'],
            "data": response.data.data
        };
        displaytbl.value = true
        responsefromapi.value = tabledata;

    });
    functions.callOnReady();
})
function showmodal(id) {
    $(id).modal('show');
}
function hidemodal(id) {
    $(id).modal('hide');
}
$('.pcoded-navbar').show();
$('.pcoded-content').removeAttr('style');
</script> 
