<template>

    <div :class="$attrs.class + ' ' + data.child_cssclass + ((data.child_display) ? '' : ' cond-hide ')" class=" text-center wow services"
        data-wow-delay="0.2s"
        :data-key="$attrs.childindex"
    :data-sectionkey="$attrs.index">
        <template v-if="$attrs.isbuilder">
            <i class="ti-move movebtn" @click="addSortChild();"></i>
            <i class="ti-close removebtn  "   @click="removeCompunent($attrs.index, $attrs.childindex,$attrs.child_uniqueid)"></i>
            <i class="ti-settings settingbtn" @click="configureCompunent($attrs.childindex);"></i>
        </template>
        <div class="services-icon">
            <img :src="data.meta.src" :height="(data.meta.imgheight)?data.meta.imgheight:'60'" :width="(data.meta.imgwidth)?data.meta.imgwidth:'60'" alt="" />
        </div>
        <div class="services-description">
            <h1>{{ data.meta.heading }}</h1>
            <p>{{ data.meta.paragraph }}</p>
            <a :href="data.meta.buttonlink" class="btn btn-primary btn-round btn-action btn-fill mt-3" v-if="data.meta.buttontext != ''">{{
                data.meta.buttontext
            }}</a>
        </div>

    </div>
</template>
<script setup>
let props = defineProps({
    data: Array,
});
var definedEmits = defineEmits(['addSortChild', 'removeCompunent', 'configureCompunent'])

function addSortChild() {
    definedEmits('addSortChild');
}

function removeCompunent(index, childindex,child_uniqueid) {
    definedEmits('removeCompunent', index, childindex,child_uniqueid);
}
function configureCompunent(childindex) {
    definedEmits('configureCompunent', childindex);
}
</script>