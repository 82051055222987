<template>
  <!-- Notification Toasts -->
  <!-- <Toasts /> -->
  <!-- Show Authentication APP -->
  <div v-if="auth.token != null"> 
    <SuperAdminMaster v-if="auth.user.u_account_type_id === '1'" />
    <VendorAccountMaster v-else />
    <!-- <WebsiteMaster v-else /> -->
  </div>
  <!-- <div v-if="this.$store.state.token">
      <SuperAdminMaster v-if="this.$store.state.role == 'Super Admin'" />
      <VendorAccountMaster v-else="this.$store.state.role == 'Vendor'" />
  </div> -->
  <AuthMaster v-else></AuthMaster>
</template>
<script>

// import Loading from './views/components/Loading.vue';
// import Toasts from './views/components/Toasts.vue';
import SuperAdminMaster from './views/superAdmin/layout/master.vue';
import AuthMaster from './views/authentication/master.vue';
import VendorAccountMaster from './views/vendorAccount/layout/master.vue';
import WebsiteMaster from './views/website/layout/master.vue';
import { useAuthStore } from '@/store/auth.js'



export default {
  setup() {
    const auth = useAuthStore()
    return { auth }
  },
  components: {
    // Loading,
    // Toasts
    SuperAdminMaster,
    AuthMaster,
    VendorAccountMaster,
    WebsiteMaster,
  },
}
</script>
