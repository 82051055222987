<template>
    <section class="login-block">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <form class="md-float-material form-material">
                        <div class="text-center">
                            <img alt="logo.png">
                        </div>
                        <div class="auth-box card">
                            <div class="card-block">
                                <div class="row m-b-20">
                                    <div class="col-md-12">
                                        <h3 class="text-center">Sign In</h3>
                                    </div>
                                </div>
                                <div class="form-group form-primary">
                                    <input v-model="form.email" type="text" name="email" class="form-control"
                                        required="" placeholder="Your Email Address">
                                    <span class="form-bar"></span>
                                </div>
                                <div class="form-group form-primary">
                                    <input v-model="form.password" type="password" name="password" class="form-control"
                                        required="" placeholder="Password">
                                    <span class="form-bar"></span>
                                </div>
                                <div class="row m-t-25 text-left">
                                    <div class="col-12">
                                        <div class="checkbox-fade fade-in-primary d-">
                                            <label>
                                                <input type="checkbox" value="">
                                                <span class="cr"><i
                                                        class="cr-icon icofont icofont-ui-check txt-primary"></i></span>
                                                <span class="text-inverse">Remember me</span>
                                            </label>
                                        </div>
                                        <div class="forgot-phone text-right f-right">
                                            <a href="auth-reset-password.html" class="text-right f-w-600"> Forgot
                                                Password?</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="row m-t-30">
                                    <div class="col-md-12">
                                        <button type="button"
                                            class="btn btn-primary btn-round btn-md btn-block waves-effect waves-light text-center m-b-20"
                                            @click="handleSubmit()">Sign
                                            in</button>
                                    </div>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-md-10">
                                        <p class="text-inverse text-left m-b-0">Thank you.</p>
                                        <p class="text-inverse text-left"><a href="../index.html"><b
                                                    class="f-w-600">Back
                                                    to website</b></a></p>
                                    </div>
                                    <div class="col-md-2">
                                        <img alt="small-logo.png">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>

            </div>

        </div>

    </section>
</template>
<script setup>
import { appConsts } from '../../common/constants.js'
import { useAuthStore } from '@/store/auth.js'
import { reactive } from 'vue';
import { useRouter } from 'vue-router';

const authStore = useAuthStore();

const form = reactive({
    email: "admin@t6ve.com",
    password: '123456789',
    password_require: true,
})
const router = useRouter()

// import { appConsts } from '../../common/constants.js'
// export default {
//     name: "App",
//     data() {
//         return {
//             email: "test1235@test.com",
//             password: "123456789",
//             pass_msg: "",
//             password_require: false,
//             error: null
//         }
//     },
//     methods: {
//         show() {
//             // console.log(this.$store.state.access_token);
//             localStorage.clear();
//         },
//         save_user(user) {
//             this.$store.dispatch('addUser', user)
//         },
//         save_role(role) {
//             this.$store.dispatch('addRole', role)
//         },
//         save_token(token) {
//             this.$store.dispatch('addToken', token)
//         },

//         delete_token() {
//             // this.$store.dispatch('deleteToken')
//         },
async function handleSubmit() {
    if (form.password.length > 4) {
        form.password_require = false;
        await axios.post(appConsts.baseURL + 'login', {
            email: form.email,
            password: form.password
        })
            .then(response => {
                if (response.data.status == 'success') {
                    console.log(response.data.data.token);
                    authStore.token = response.data.data.token;
                    authStore.user = response.data.data.user;
                    // router.push('/super_admin/dashboard');
                    //TODO: is k agay ka logic ap dekh lein
                    //this.save_role('Vendor');
                    // this.save_user(response.data.data);
                    if (response.data.data.user.u_account_type_id == '1') {
                        // console.log(response.data.data.user.account_type_id);
                        router.push('/super_admin/dashboard');
                        window.location.href = "/super_admin/dashboard";
                    } else if (response.data.data.user.u_account_type_id == '2') {
                        router.push('/vendor_account/dashboard');
                        window.location.href = '/vendor_account/dashboard';
                    }
                } else {
                    this.error = response.data
                }
            })
            .catch(function (error) {
                console.error(error);
            });
    } else {
        this.password_require = true;
    }
}
   // },
//     created() {
//         // if (this.$store.state.access_token) {
//         //     this.$router.push('/super_admin/dashboard');
//         // }
//     },
//     beforeRouteEnter(to, from, next) {
//         // if(this.$store.state.access_token){
//         //     this.$router.push('/dashboard');
//         // }
//         next();
//         // localStorage.clear();
//     }
 //}
</script>