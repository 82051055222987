import VAWebpage from "../views/website/index.vue";
 

export default [
  {
    name: "VAWebpage",
    path: "",
    component: VAWebpage,
    meta: {
      title: "Website",
    },
  },
   
];
