<template>
    <div class="wrapper mywebapp animsition" data-animsition-in-class="fade-in" data-animsition-in-duration="1000"
        data-animsition-out-class="fade-out" data-animsition-out-duration="1000">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light navbar-default navbar-fixed-top" role="navigation">
                <div class="container">
                    <a class="navbar-brand page-scroll" href="#main"><img src="assets/logos/logo.png"
                            alt="adminity Logo" /></a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav me-auto">
                        </ul>
                        <ul class="navbar-nav my-2 my-lg-0">
                            <li class="nav-item">
                                <a class="nav-link page-scroll" href="#main">Home</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link page-scroll" href="#services">Important</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link page-scroll" href="#features">Benefits</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link page-scroll" href="#reviews">Testimonials</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link page-scroll" href="#pricing">Pricing</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#">Contact</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
        <div class="main" id="main">
            <!-- Main Section-->
            <template v-if="form.page_components != null">
                <div v-for="(component, index, object) in form.page_components" :key="component"
                    :class="component.component_cssclass + ' ' + ''" class="        " style="min-height: 10vh;">
                    <div class="row ">
                         
                        <template v-if="component.children != null">
                            <template v-for="(childval, childindex, childobject) in component.children">
                                <template v-if="childval.child_type == 'inlineimage'">
                                    <div
                                        :class="childval.child_cssclass + ((childval.child_display) ? '' : ' cond-hide ')">
                                        <img class="img-responsive" :style="{
                                            height: ((childval.meta.height) ? childval.meta.height + 'px' : ''),
                                            width: ((childval.meta.width) ? childval.meta.width + 'px' : '100%'),
                                        }" :src="childval.meta.link">
                                    </div>
                                </template>

                                <template v-else-if="childval.child_type == 'heading'">
                                    <div class=" "
                                        :class="childval.child_cssclass + ((childval.child_display) ? '' : ' cond-hide ')">
                                        <span :class="childval.meta.headtype">{{
                                            childval.meta.value
                                        }}</span>
                                    </div>
                                </template>
                                <template v-else-if="childval.child_type == 'paragraph'">
                                    <div
                                        :class="childval.child_cssclass + ((childval.child_display) ? '' : ' cond-hide ')">
                                        <p>{{ childval.meta.value }}</p>
                                    </div>
                                </template>

                                <template v-else-if="childval.child_type == 'divider'">
                                    <div
                                        :class="childval.child_cssclass + ((childval.child_display) ? '' : ' cond-hide ')">
                                        <hr :size="childval.meta.height + 'px'" :style="{ opacity: '8' }">
                                    </div>
                                </template>
                                <template v-else-if="childval.child_type == 'imagebox'">
                                    <template v-if="childval.meta.theme == '3'">
                                        <image-box3 :data="childval" @addSortChild="addSortChild"
                                            @removeCompunent="removeCompunent" @configureCompunent="configureCompunent"
                                            :index="index" :childindex="childindex"></image-box3>
                                    </template>
                                    <template v-else-if="childval.meta.theme == '2'">
                                        <image-box2 :data="childval" @addSortChild="addSortChild"
                                            @removeCompunent="removeCompunent" @configureCompunent="configureCompunent"
                                            :index="index" :childindex="childindex"></image-box2>
                                    </template>
                                    <template v-else>
                                        <image-box1 :data="childval" @addSortChild="addSortChild"
                                            @removeCompunent="removeCompunent" @configureCompunent="configureCompunent"
                                            :index="index" :childindex="childindex"></image-box1>
                                    </template>
                                </template>
                                <template v-else-if="childval.child_type == 'iconbox'">
                                    <!-- <template> -->
                                    <icon-box1 :data="childval" @addSortChild="addSortChild"
                                        @removeCompunent="removeCompunent" @configureCompunent="configureCompunent"
                                        :index="index" :childindex="childindex"></icon-box1>
                                    <!-- </template> -->
                                </template>
                                <template v-else-if="childval.child_type == 'banner'">
                                    <!-- <template> -->
                                    <banner1 :data="childval" @addSortChild="addSortChild"
                                        @removeCompunent="removeCompunent" @configureCompunent="configureCompunent"
                                        :index="index" :childindex="childindex">
                                    </banner1>
                                    <!-- </template> -->
                                </template>
                                <template v-else-if="childval.child_type == 'testimonials'">
                                    <!-- <template> -->
                                    <testimonials1 :data="childval" @addSortChild="addSortChild"
                                        @removeCompunent="removeCompunent" @configureCompunent="configureCompunent"
                                        :index="index" :childindex="childindex">
                                    </testimonials1>
                                    <!-- </template> -->
                                </template>
                                <template v-else-if="childval.child_type == 'imagesslider'">
                                    <!-- <template> -->
                                    <images-slider1 :data="childval" @addSortChild="addSortChild"
                                        @removeCompunent="removeCompunent" @configureCompunent="configureCompunent"
                                        :index="index" :childindex="childindex"></images-slider1>
                                    <!-- </template> -->
                                </template>

                            </template>
                        </template>
                    </div>
                </div>
            </template>
            <!-- Footer Section
            <div class="footer">
                <div class="container">
                    <div class="col-md-12 text-center">
                        <img src="assets/logos/logo.png" alt="Adminty Logo" />
                        <ul class="footer-menu">
                            <li><a href="http://demo.com">Site</a></li>
                            <li><a href="#">Support</a></li>
                            <li><a href="#">Terms</a></li>
                            <li><a href="#">Privacy</a></li>
                        </ul>
                        <div class="footer-text">
                            <p>
                                Copyright © 2017 Adminty. All Rights Reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </div> -->

            <!-- Scroll To Top -->
            <!-- <a id="back-top" class="back-to-top page-scroll" href="#main">
                <i class="ion-ios-arrow-thin-up"></i>
            </a> -->
            <!-- Scroll To Top Ends-->
        </div>
        <!-- Main Section -->
    </div>

</template> 
<script setup>
import { functions } from '../../common/appFunctions.js';
import { appConsts } from '../../common/constants.js';

import ImageBox1 from './websiteCompunents/imagebox/imagebox-1.vue';
import ImageBox2 from './websiteCompunents/imagebox/imagebox-2.vue';
import ImageBox3 from './websiteCompunents/imagebox/imagebox-3.vue';
import IconBox1 from './websiteCompunents/iconbox/iconbox-1.vue';
import Banner1 from './websiteCompunents/banner/banner-1.vue';
import Testimonials1 from './websiteCompunents/testimonials/testimonials-1.vue';
import ImagesSlider1 from './websiteCompunents/imagesslider/imagesslider-1.vue';

import { useAuthStore } from '@/store/auth.js'
import factory from '@/common/factory.js'

import { ref, reactive, onMounted } from 'vue';

const authStore = useAuthStore();

function getToken() {
    const token = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authStore.token,
        },
    };
    return token;
}
const form = reactive({
    page_name: '',
    page_alias: '',
    page_shortdescription: '',
    page_components: [],
});
onMounted(async () => {

    var getpageinfo = {
        page_vendor_id: 1,
        page_alias: 'home'
    }
    // console.log('here');
    const FormApi = new factory(getToken(), 'pages/alias', getpageinfo);
    await FormApi.postForm().then((response) => {
        // console.log('=======================');
        if (response.data.status == "success") {
            form.page_components = response.data.data.page_components;
            console.log(response.data);
        }
    });

    functions.callOnReady();
    $(document).ready(function () {

        $(".testimonials").owlCarousel({
            slideSpeed: 200,
            items: 1,
            singleItem: true,
            autoPlay: true,
            pagination: false
        });

        /* ------ Clients Section Owl Carousel ----- */

        $(".clients").owlCarousel({
            slideSpeed: 200,
            items: 5,
            singleItem: false,
            autoPlay: true,
            pagination: false
        });

        var $lateral_menu_trigger = $('.nav-trigger'),
            $content_wrapper = $('.main');

        //open-close lateral menu clicking on the menu icon
        $lateral_menu_trigger.on('click', function (event) {
            event.preventDefault();

            $lateral_menu_trigger.toggleClass('is-active');
            $content_wrapper.toggleClass('is-active').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function () {
                // firefox transitions break when parent overflow is changed, so we need to wait for the end of the trasition to give the body an overflow hidden
                $('body').toggleClass('overflow-hidden');
            });
            $('#nav-menu').toggleClass('is-active');

            //check if transitions are not supported - i.e. in IE9
            if ($('html').hasClass('no-csstransitions')) {
                $('body').toggleClass('overflow-hidden');
            }
        });

        //close lateral menu clicking outside the menu itself
        $content_wrapper.on('mouseover', function (event) {
            if (!$(event.target).is('.nav-trigger, .nav-trigger span')) {
                $lateral_menu_trigger.removeClass('is-active');
                $content_wrapper.removeClass('is-active').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function () {
                    $('body').removeClass('overflow-hidden');
                });
                $('#nav-menu').removeClass('is-active');
                //check if transitions are not supported
                if ($('html').hasClass('no-csstransitions')) {
                    $('body').removeClass('overflow-hidden');
                }

            }
        });
    })
});
</script>