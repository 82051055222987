<template>
    <div class="main-body">
        <div class="page-wrapper">
            
            <!-- Page body start -->
            <div class="page-body">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-header">
                                <h5>
                                    <span>Header Page Settings</span>
                                     
                                </h5>
                                <div class="card-header-right">
                                    <div class="page-header-breadcrumb">
                                        <ul class="breadcrumb-title">
                                            <li class="breadcrumb-item" style="float: left;">
                                                <a href="index.html">
                                                    <i class="icofont icofont-home"></i>
                                                </a>
                                            </li>
                                            <li class="breadcrumb-item" style="float: left;"><router-link
                                                    :to="{ name: 'VAHeaderPage' }">Header Page</router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div> 
                            <div class="card-body" v-if="!isPreview">
                                <div class="row">
                                    <image-input v-model="form.pgh_logo" class="col-md-3" :source="form.pgh_logo"
                                        kStyle="height:20vh;" label="Logo" />

                                    <div class="col-md-9">
                                        <div class="row">
                                            <text-input class="col-md-3" v-model="form.pgh_meta.header_logoheight"
                                                label="Logo Height (px)" type="number" />
                                            <color-input class="col-md-3" v-model="form.pgh_meta.header_backgroundcolor"
                                                label="Background" />
                                            <color-input class="col-md-3" v-model="form.pgh_meta.header_menucolor"
                                                label="Menu Color" />
                                            <color-input class="col-md-3" v-model="form.pgh_meta.header_menuhovercolor"
                                                label="Menu Hover Color" />
                                            <select-input v-model="form.pgh_menu_group_id" class="col-md-8"
                                                label="Menu Group">
                                                <option value="0">Select Menu</option>
                                                <option v-for="item in mn_group" :key="item.mg_id" :value="item.mg_id">
                                                    {{ item.mg_name }}</option>
                                            </select-input>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label style="width:100%">Display Header?</label>
                                                    <input type="checkbox" class="js-switch"
                                                        :checked="form.pgh_display_header"
                                                        v-model="form.pgh_display_header" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 text-center">
                                                <button type="button"
                                                    class="btn btn-primary btn-round waves-effect funload waves-light "
                                                    @click="addHeaderPage()">Save Changes</button>
                                            </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script setup>
import SelectInput from '../../../shared/SelectBox.vue';
import HeaderPreview from '../../../website/headerDesigns/index.vue';
import TextInput from '../../../shared/TextInput.vue';
import ColorInput from '../../../shared/ColorInput.vue';
import ImageInput from '../../../shared/ImageInput.vue';
import { functions } from '../../../../common/appFunctions.js';
import { ref, reactive, onMounted, provide } from 'vue';
import { useAuthStore } from '@/store/auth.js'
import factory from '@/common/factory.js'
import { useRouter } from 'vue-router';
var mn_group = ref({});
const authStore = useAuthStore();
const isPreview = ref(false);

var form = ref({
    pg_id: 0,
    pgh_vendor_id: authStore.user.u_vendor_id,
    pgh_logo: 'https://demo.dashboardpack.com/adminty-html/files/extra-pages/landingpage/assets/logos/logo.png',
    pgh_menu_group_id: '',
    pgh_display_header: false,
    pgh_meta: {
        header_logoheight: "",
        header_backgroundcolor: "",
        header_menucolor: "",
        header_menuhovercolor: ""

    },
});


function previewHeader() {
    if (isPreview.value) {
        isPreview.value = false;
    } else {
        isPreview.value = true;
    }
}
function getToken() {
    const token = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authStore.token,
        },
    };
    return token;
}

async function addHeaderPage() {

    const PageHeader = new factory(getToken(), 'pageheaders', form.value);
    await PageHeader.putForm().then((response) => {
        if (response.data.status == "success") {
            alert("Header Added successfully!")
        } else {
            console.log(response.data.message);
        }
    });
};
provide('getToken', getToken());
onMounted(async () => {
    functions.initLoader();
    const user = new factory(getToken(), 'pageheaders/' + authStore.user.u_vendor_id);
    const dt = await user.getHttp().then(response => {
         functions.destroyLoader();
        form.value = response.data.data;
        if (form.value.pgh_meta.header_logoheight == null) {
            form.value.pgh_meta = {};
            form.value.pgh_meta.header_logoheight = '';
            form.value.pgh_meta.header_backgroundcolor = '';
            form.value.pgh_meta.header_menucolor = '';
            form.value.pgh_meta.header_menuhovercolor = '';
        }
    });
    const MenuGroup = new factory(getToken(), 'menugroups/vendor/' + authStore.user.u_vendor_id);
    await MenuGroup.getHttp().then(response => {
        mn_group.value = response.data.data;
    });

    functions.callOnReady();
}) 
</script>  