<template>
    <div class="main-body">
        <div class="page-wrapper">
            <div class="page-header">
                <div class="row align-items-end">
                    <div class="col-lg-10">
                        <div class="page-header-title">
                            <div class="d-inline">
                                <h4>Subscription Plans</h4>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="page-header-breadcrumb">
                            <ul class="breadcrumb-title">
                                <li class="breadcrumb-item" style="float: left;">
                                    <a href="index.html">
                                        <i class="icofont icofont-home"></i>
                                    </a>
                                </li>
                                <li class="breadcrumb-item" style="float: left;"><router-link
                                        :to="{ name: 'SASubscriptions' }">Subscription Plans</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
            <div class="page-body">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-block">
                                <button type="button" class="btn btn-primary btn-round waves-effect f-right"
                                    @click="showmodal('#addnew_plan')">Add New</button>
                            </div>
                            <div class="card-body">
                                <tableView :data="tabledata" @viewbuttonclicked="editbtnclicked" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="addnew_plan">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Add New</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        @click="hidemodal('#addnew_plan');">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <text-input class="col-md-6" label="Subscription Title" />
                        <text-input class="col-md-6" label="Validity (in days)" />
                        <text-input class="col-md-12" label="Short Description" />
                        <textarea-input class="col-md-12" rows="5" label="Long Description" />
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default waves-effect " data-dismiss="modal"
                        @click="hidemodal('#addnew_plan');">Close</button>
                    <button type="button" class="btn btn-primary btn-round waves-effect waves-light ">Save</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="update_plan">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Update Plan</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        @click="hidemodal('#update_plan');">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <input type="hidden" v-model="planid" />
                        <text-input class="col-md-6" label="Subscription Title" v-model="form.plantitle" />
                        <text-input class="col-md-6" label="Validity (in days)" v-model="form.planvalidity" />
                        <text-input class="col-md-12" label="Short Description" v-model="form.planshortdescription" />
                        <textarea-input class="col-md-12" rows="5" label="Long Description"
                            v-model="form.planlongdescription" />
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default waves-effect " data-dismiss="modal"
                        @click="hidemodal('#update_plan');">Close</button>
                    <button type="button" class="btn btn-primary btn-round waves-effect waves-light ">Save</button>
                </div>
            </div>
        </div>
    </div>

</template>

<script setup>
import tableView from '../../../shared/table.vue';
import TextInput from '../../../shared/TextInput.vue';
import TextareaInput from '../../../shared/TextareaInput.vue';
import { reactive } from 'vue';
const form = reactive({
            plantitle: '',
            planvalidity: 0,
            planshortdescription: '',
            planlongdescription: '',
            planid: 0,
            tabledata: {},
            singleplan: {},
            responsefromapi: []
        });
  function  editbtnclicked(pkey) {
            $('#update_plan').modal('show');
            form.singleplan = responsefromapi.data.find((item) => (item.id == pkey)); // i took data from exisitng data i have on page load, you may recall api for single record here

            form.plantitle = form.singleplan.plantitle;
            form.planvalidity = form.singleplan.planvalidity;
            form.planshortdescription = form.singleplan.planshortdescription;
            form.planlongdescription = form.singleplan.planlongdescription;
            form.planid = pkey;
        }
        function   showmodal(id) {
            $(id).modal('show');
        }
        function  hidemodal(obj) {
            $(obj).modal('hide');

        }

    const responsefromapi = {
            "status": "success",
            "message": "this is message",
            "data": [
                { 'id': 1, 'plantitle': '2 Year Subscription ', 'planvalidity': '3 Days', 'planshortdescription': 'But I must explain to you how all this mistaken idea of denouncing pleasure', 'planlongdescription': 'But I must explain to you how all this mistaken idea of denouncing pleasure' },
                { 'id': 2, 'plantitle': '3 Year Subscription ', 'planvalidity': '5 Days', 'planshortdescription': 'But I must explain to you how all this mistaken idea of denouncing pleasure', 'planlongdescription': 'But I must explain to you how all this mistaken idea of denouncing pleasure' },
                { 'id': 3, 'plantitle': '4 Year Subscription ', 'planvalidity': '7 Days', 'planshortdescription': 'But I must explain to you how all this mistaken idea of denouncing pleasure', 'planlongdescription': 'But I must explain to you how all this mistaken idea of denouncing pleasure' },
            ]
        };

       const tabledata = {
            "conditions": {
                'update': true,
                'trash': true,
                'active': false,
                'primarykey': 'id',
                'hiddendata': ['id', 'planlongdescription'],
            },
            "columns": [
                'Title',
                'Validity',
                'Short Description',
                '',
            ],
            "data": responsefromapi.data
        };

</script>
