<template>
    <div class="pcoded-inner-content">

        <div class="main-body">
            <div class="page-wrapper">


                <!-- Page-body start -->
                <div class="page-body">
                    <div class="card">
                        <div class="card-header">
                            <h5>
                                <span>Single Vehicle Information</span>
                            </h5>
                            <div class="card-header-right">
                                <div class="page-header-breadcrumb">
                                    <ul class="breadcrumb-title">
                                        <li class="breadcrumb-item" style="float: left;">
                                            <a href="index.html"> <i class="feather icon-home"></i> </a>
                                        </li>
                                        <li class="breadcrumb-item" style="float: left;"><router-link
                                                :to="{ name: 'VAVehicles' }">Vehicles</router-link>
                                        </li>
                                        <li class="breadcrumb-item" style="float: left;"><a href="#!">Single Vehicle
                                                Info</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div class="row card-body">
                            <div class="col-lg-12">

                                <ul class="nav nav-tabs md-tabs tabs-left" role="tablist" id="mytab">
                                    <li class="nav-item">
                                        <a class="nav-link active" data-bs-toggle="tab" href="#personal" role="tab">
                                            Basic Information </a>
                                        <div class="slide"></div>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-bs-toggle="tab" href="#residentassosicated" role="tab">
                                            Detailed Information </a>
                                        <div class="slide"></div>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-bs-toggle="tab" href="#peopleassociated" role="tab">
                                            Purchase Details</a>
                                        <div class="slide"></div>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-bs-toggle="tab" href="#contactdetails" role="tab">
                                            Vehicle
                                            Associations </a>
                                        <div class="slide"></div>
                                    </li>
                                </ul>
                                <div class="tab-content tabs-left-content card-block" style="width: 100%">
                                    <!-- tab panel personal start -->
                                    <div class="tab-pane active" id="personal" role="tabpanel">
                                        <!-- personal card start -->
                                        <form>
                                            <div class="row">
                                                <image-input v-model="form.vehicle_picture" class="col-md-4"
                                                    :source="form.vehicle_picture" kStyle="height:20vh;" />
                                                <div class="col-md-8">
                                                    <div class="row">
                                                        <text-input v-model="form.vehicle_name" class="col-md-6"
                                                            label="Name" />
                                                        <select-input v-model="form.vehicle_company_id"
                                                            class="col-md-6 my-select selectpicker" label="Company">
                                                            <option value="">Select...</option>
                                                            <option v-for="comp in companiesdata" :value="comp.c_id">{{
                                                                comp.c_name }}</option>
                                                        </select-input>
                                                        <text-input v-model="form.vehicle_start_date" class="col-md-6"
                                                            type="date" label="Start Date" />
                                                        <text-input v-model="form.vehicle_end_date" class="col-md-6"
                                                            type="date" label="End Date" />
                                                        <select-input v-model="form.vehicle_administrator" class="col-md-6"
                                                            label="Administrator">
                                                            <option value="">Select...</option>
                                                            <option v-for="user in usersdata" :value="user.u_id">
                                                                {{ user.u_name }}
                                                            </option>
                                                        </select-input>
                                                        <select-input v-model="form.vehicle_region" class="col-md-6"
                                                            label="Region (Optional)">
                                                            <option value="">Select...</option>
                                                            <option v-for="reg in regionsdata" :value="reg.r_id">
                                                                {{ reg.r_name }}
                                                            </option>
                                                        </select-input>

                                                    </div>
                                                </div>

                                                <div class="col-md-12">
                                                    <hr />
                                                </div>
                                                <select-input v-model="form.vehicle_correspondinguser" class="col-md-4"
                                                    label="Corresponding User">
                                                    <option value="">None</option>
                                                    <option v-for="user in usersdata" :value="user.u_id">
                                                        {{ user.u_name }}
                                                    </option>
                                                </select-input>
                                            </div>
                                            <div class="col-md-12 text-center">
                                                <button type="button"
                                                    class="btn btn-primary btn-round waves-effect waves-light funload "
                                                    @click="updateVehicle()">Update Changes</button>
                                            </div>
                                        </form>

                                    </div>
                                    <!-- tab pane personal end -->
                                    <!-- tab pane info start -->
                                    <div class="tab-pane" id="residentassosicated" role="tabpanel">

                                        <div class="row">
                                            <text-input v-model="form.vehicle_make" class="col-md-4" label="Make" />
                                            <text-input v-model="form.vehicle_model" class="col-md-4" label="Model" />
                                            <text-input v-model="form.vehicle_color" class="col-md-4" label="Colour" />
                                            <text-input v-model="form.vehicle_innumber" class="col-md-4"
                                                label="Vehicle Identification Number" />
                                            <text-input v-model="form.vehicle_registrationno" class="col-md-4"
                                                label="Registration Number" />
                                            <text-input v-model="form.vehicle_registrationdate" class="col-md-4"
                                                label="Registration Date" />
                                            <text-input v-model="form.vehicle_motdate" class="col-md-4" label="MOT Date"
                                                type="date" />
                                            <text-input v-model="form.vehicle_insurancedate" class="col-md-4"
                                                label="Insurance Date" />
                                            <text-input v-model="form.vehicle_taxdate" class="col-md-4" label="Tax Date"
                                                type="date" />
                                            <text-input v-model="form.vehicle_fueltype" class="col-md-4"
                                                label="Fuel Type" />
                                            <select-input v-model="form.vehicle_ownership" class="col-md-4"
                                                label="Ownership">
                                                <option value="0">Select</option>
                                            </select-input>
                                            <text-input v-model="form.vehicle_purchaseprice" class="col-md-4"
                                                label="Purchase Price" />
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 text-center">
                                                <button type="button"
                                                    class="btn btn-primary btn-round waves-effect waves-light funload"
                                                    @click="updateVehicle()">Update Changes</button>
                                            </div>
                                        </div>

                                        <!-- info card end -->
                                    </div>
                                    <!-- tab pane info end -->
                                    <!-- tab pane info start -->
                                    <div class="tab-pane" id="peopleassociated" role="tabpanel">

                                        <div class="row">
                                            <text-input type="date" v-model="form.vehicle_purchasedate" class="col-md-4"
                                                label="Purchase Date (If applicable)" />
                                            <text-input type="date" v-model="form.vehicle_leasestartdate" class="col-md-4"
                                                label="Lease Start Date (If applicable)" />
                                            <text-input type="date" v-model="form.vehicle_leaseenddate" class="col-md-4"
                                                label="Lease End Date (If applicable)" />
                                            <text-input v-model="form.vehicle_balloon" class="col-md-12" label="Balloon" />
                                            <textarea-input v-model="form.vehicle_usefulcontacts" class="col-md-12" rows="3"
                                                label="Useful Contact Details"></textarea-input>
                                            <textarea-input v-model="form.vehicle_miscdetails" class="col-md-12" rows="3"
                                                label="Miscellaneous Details"></textarea-input>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 text-center">
                                                <button type="button"
                                                    class="btn btn-primary btn-round waves-effect waves-light funload"
                                                    @click="updateVehicle()">Update Changes</button>
                                            </div>
                                        </div>
                                        <!-- info card end -->
                                    </div>
                                    <!-- tab pane info end -->
                                    <div class="tab-pane" id="contactdetails" role="tabpanel">
                                        <!-- info card start -->
                                        <div class="row">
                                            <select-input v-model="resident_in" class="col-md-4"
                                                label="Vehicle in Home or School">
                                                <option value="0">Select</option>
                                                <option value="1">Home</option>
                                            </select-input>
                                            <text-input type="date" v-model="admission_date" class="col-md-3"
                                                label="Admission Date" />
                                            <text-input type="date" v-model="leaving_date" class="col-md-3"
                                                label="Leaving Date" />
                                            <div class="col-md-2">
                                                <label style="width: 100%">&nbsp;</label>
                                                <button type="button" class="btn btn-warning btn-block"
                                                    @click="addVehicleAssociation()"><i class="ti-plus"></i></button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>Vehicle In</th>
                                                        <th>Admission Date</th>
                                                        <th>Leaving Date</th>
                                                        <th>Status</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <template v-if="form.vehicle_association">
                                                        <tr v-for="residentassociation in form.vehicle_association">
                                                            <td>{{ residentassociation.vehicle_in }}</td>
                                                            <td>{{
                                                                residentassociation.vehicle_admission_date
                                                            }}</td>
                                                            <td>{{
                                                                residentassociation.vehicle_leaving_date
                                                            }}</td>
                                                            <td>{{ residentassociation.status }}</td>
                                                            <td>

                                                            </td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 text-center">
                                                <button type="button"
                                                    class="btn btn-primary btn-round waves-effect waves-light funload"
                                                    @click="updateVehicle()">Update Changes</button>
                                            </div>
                                        </div>
                                        <!-- info card end -->
                                    </div>

                                </div>
                                <!-- tab content end -->

                            </div>
                        </div>
                    </div>
                </div>
                <!-- Page-body end -->
            </div>
        </div>
    </div>
    <div class="modal fade" id="add_qualification">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Add New</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        @click="hidemodal('#add_qualification');">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <text-input class="col-md-6" v-model="qulaification_name" label="Qualification Name" />
                        <text-input class="col-md-6" v-model="qulaification_course" label="Training Course Attended" />
                        <select-input v-model="qulaification_type" class="col-md-4" label="Qualification Type">
                            <option value="">Select </option>
                        </select-input>
                        <select-input v-model="qulaification_issuebody" class="col-md-4" label="Issuing Body">
                            <option value="">Select </option>
                        </select-input>
                        <text-input type="date" class="col-md-4" v-model="qulaification_date" label="Qualification Date" />
                        <text-input type="date" class="col-md-4" v-model="qulaification_expirydate" label="Expiry Date" />
                        <text-input type="date" class="col-md-4" v-model="qulaification_refresherdate"
                            label="Refresher Date" />
                        <text-input type="text" class="col-md-12" v-model="qulaification_uploadedevidence"
                            label="Upload Evidence Files (You can upload multiple files)" />
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default waves-effect " data-dismiss="modal"
                        @click="hidemodal('#add_qualification');">Close</button>
                    <button type="button" class="btn btn-primary btn-round waves-effect waves-light "
                        @click="addEmployeeAssociated();">Save
                        Information</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { functions } from '../../../../common/appFunctions.js';
import SelectInput from '../../../shared/SelectBox.vue';
import TextInput from '../../../shared/TextInput'
import ImageInput from '../../../shared/ImageInput.vue'
import TextareaInput from '../../../shared/TextareaInput.vue'
import { onMounted, ref, reactive, provide } from 'vue';
import { useAuthStore } from '@/store/auth.js'
import factory from '@/common/factory.js'
import { useRouter, useRoute } from 'vue-router';
const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();

var resident_in = ref('');
var admission_date = ref('');
var leaving_date = ref('');

// ****** Dropdowns data ********************
var companiesdata = ref([]);
var usersdata = ref([]);
var regionsdata = ref([]);
// ****** Dropdowns data ********************

const form = ref({
    vehicle_id: 0,
    vehicle_picture: 'https://demo.dashboardpack.com/adminty-html/files/assets/images/gallery-grid/1.png',
    vehicle_name: '',
    vehicle_company_id: '',
    vehicle_start_date: '',
    vehicle_end_date: '',
    vehicle_administrator: '',
    vehicle_region: '',
    vehicle_correspondinguser: '',
    vehicle_make: '',
    vehicle_model: '',
    vehicle_color: '',
    vehicle_innumber: '',
    vehicle_registrationno: '',
    vehicle_registrationdate: '',
    vehicle_motdate: '',
    vehicle_insurancedate: '',
    vehicle_taxdate: '',
    vehicle_fueltype: '',
    vehicle_ownership: '',
    vehicle_purchaseprice: '',
    vehicle_purchasedate: '',
    vehicle_leasestartdate: '',
    vehicle_leaseenddate: '',
    vehicle_balloon: '',
    vehicle_usefulcontacts: '',
    vehicle_miscdetails: '',
    vehicle_association: [],
});

function addVehicleAssociation() {
    if (resident_in.value.trim() == '') {
        alert("Kindly Enter Vehicle IN!");
        return false;
    }
    form.value.vehicle_association.push({
        vehicle_in: resident_in.value,
        vehicle_admission_date: admission_date.value,
        vehicle_leaving_date: leaving_date.value,
        status: '',
    });
    resident_in.value = '';
    admission_date.value = '';
    leaving_date.value = '';
}
provide('getToken', getToken());
function getToken() {
    const token = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authStore.token,
        },
    };
    return token;
}
async function updateVehicle() {
    const Company = new factory(getToken(), 'vehicles', form.value);
    await Company.putForm().then((response) => {
        // console.log(response);
        functions.destroyBtnLoader();
        if (response.data.status == "success") {
            // router.push({ path: 'companies' }) 
            functions.kSwal('Done', 'Changes Saved Successfully.');
        } else {
            console.log(response.data.message);
        }
    });
};
onMounted(async () => {
    var Vehicles = new factory(getToken(), 'getallapis', { index: ['companies', 'users', 'regions'] });
    await Vehicles.postForm().then((response) => {
        if (response.data.status == "success") {
            companiesdata.value = response.data.data.companies;
            usersdata.value = response.data.data.users;
            regionsdata.value = response.data.data.regions;
        }
    });

    const Company = new factory(getToken(), 'vehicles/' + route.query.id);
    await Company.getHttp().then(response => {
        if (response.data.status == "success") {
            form.value = response.data.data;
            form.value.vehicle_association = (typeof form.value.vehicle_association != 'object') ? JSON.parse(form.value.vehicle_association) : form.value.vehicle_association;
            console.log(response.data.data);
        } else {
            return response.data.message;
        }
    });
    functions.callOnReady();
});
function showmodal(id) {
    $(id).modal('show');
}
function hidemodal(id) {
    $(id).modal('hide');
}
</script>
<style scoped>
.tabs-left {
    min-width: 200px !important;
}
</style>