<template>
    <div class="pcoded-inner-content">

        <div class="main-body">
            <div class="page-wrapper">
                 

                <!-- Page-body start -->
                <div class="page-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <!-- tab header start -->
                            <div class="tab-header card">
                                <ul class="nav nav-tabs md-tabs tab-timeline" role="tablist" c_id="mytab">
                                    <li class="nav-item">
                                        <a class="nav-link active" data-bs-toggle="tab" href="#personal"
                                            role="tab">Personal
                                            Info</a>
                                        <div class="slide"></div>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-bs-toggle="tab" href="#busniess_accounts" role="tab">Meta Information</a>
                                        <div class="slide"></div>
                                    </li> 

                                </ul>
                            </div>
                            <!-- tab header end -->
                            <!-- tab content start -->
                            <div class="tab-content">
                                <!-- tab panel personal start -->
                                <div class="tab-pane active" c_id="personal" role="tabpanel">
                                    <!-- personal card start -->
                                    <div class="card">
                                        <div class="card-block">
                                            <form>
                                            <div class="row">
                                                <text-input v-model="form.c_name" class="col-md-9"
                                                    label="Company name" />
                                                <select-input :selected="form.c_company_type" v-model="form.c_company_type"
                                                    class="col-md-3" label="Company Type">
                                                    <option :value="0">Select Type</option>
                                                    <option v-for="u in companytypes" :key="u.ct_id" :value="u.ct_id">{{ u.ct_name }}</option>
                                                </select-input>
                                                <select-input v-model="form.c_company_owner" class="col-md-3"
                                                    label="Company Owner">
                                                    <option value="0">Select Owner
                                                    </option>
                                                    <option v-for="u in users" :key="u.u_id" :value="u.u_id">{{ u.u_name }}</option>
                                                </select-input>
                                                <text-input v-model="form.e_email" class="col-md-3" label="Email" />
                                                <text-input v-model="form.e_phone_primary" class="col-md-3"
                                                    label="Primary Phone" />
                                                <text-input v-model="form.e_phone_secondary" class="col-md-3"
                                                    label="Secondary Phone" />
                                                <text-input v-model="form.e_address_primary" class="col-md-6"
                                                    label="Address Primary" />
                                                <text-input v-model="form.e_address_secondary" class="col-md-6"
                                                    label="Address Secondary" />
                                                <text-input v-model="form.e_city" class="col-md-3" label="City" />
                                                <text-input v-model="form.e_post_code" class="col-md-3"
                                                    label="Post Code" />
                                                <text-input v-model="form.e_country" class="col-md-3" label="Country" />
                                                <text-input v-model="form.e_time_zone" class="col-md-3"
                                                    label="Time Zone" />
                                                <text-input v-model="form.e_longitude" class="col-md-3"
                                                    label="longitude" />
                                                <text-input v-model="form.e_latitude" class="col-md-3" label="latitude" />
                                                <div class="col-md-12 text-center">
                                                    <button type="button" class="btn btn-primary btn-round waves-effect waves-light funload" @click="updateCompany()">Update Changes</button>
                                                </div>
                                            </div>
                                        </form>
                                        </div>
                                    </div>
                                </div>
                                <!-- tab pane personal end -->
                                <!-- tab pane info start -->
                                <div class="tab-pane" c_id="busniess_accounts" role="tabpanel">
                                    <!-- info card start -->
                                    <div class="row">
                                        <div class="col-xl-3">
                                            <!-- user contact card left side start -->
                                            <div class="card">
                                                <div class="card-block">
                                                    <ul class="list-group list-contacts">
                                                        <li class="list-group-item active"><a href="#">Steps</a></li>
                                                        <li class="list-group-item"><a href="#">Active Businesses</a>
                                                        </li>
                                                        <li class="list-group-item"><a href="#">Unactive Businesses</a>
                                                        </li>
                                                        <li class="list-group-item"><a href="#">Expired Plans</a></li>
                                                    </ul>
                                                </div>

                                            </div>
                                             
                                            <!-- user contact card left side end -->
                                        </div>
                                        <div class="col-xl-9">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <!-- contact data table card start -->
                                                    <div class="card">
                                                        <div class="card-header">
                                                            <h5 class="card-header-text">Businesses
                                                            </h5>
                                                        </div>
                                                        <div class="card-block contact-details">

                                                        </div>
                                                    </div>
                                                    <!-- contact data table card end -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- info card end -->
                                </div>
                                <!-- tab pane info end -->
                                
                            </div>
                            <!-- tab content end -->
                        </div>
                    </div>
                </div>
                <!-- Page-body end -->
            </div>
        </div>
    </div>

</template>
<script setup>
import { functions } from '../../../../common/appFunctions.js';
import SelectInput from '../../../shared/SelectBox.vue';
import TextInput from '../../../shared/TextInput'
import { onMounted, ref } from 'vue';
import { useAuthStore } from '@/store/auth.js'
import factory from '@/common/factory.js'
import { useRouter, useRoute } from 'vue-router';
const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();


var form = ref({
    c_id: 0,
    c_vendor_id: '',
    c_name: '',
    c_company_type: '',
    c_company_owner:'',
    c_isactive: '',
    e_email: '',
    e_phone_primary: '',
    e_phone_secondary: '',
    e_post_code: '',
    e_city: '',
    e_country: '',
    e_address_primary: '',
    e_address_secondary: '',
    e_time_zone: '',
    e_longitude: '',
    e_latitude: ''
});
var users = ref([]);
var companytypes = ref([]);
function getToken() {
    const token = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authStore.token,
        },
    };
    return token;
}
async function updateCompany() {
    const Company = new factory(getToken(), 'companies', form.value);
    await Company.putForm().then((response) => {
        functions.destroyBtnLoader();
        if (response.data.status == "success") {
            functions.kSwal('Done', 'Changes Saved Successfully.');
        } else {
            console.log(response.data.message);
        }
    });
};
onMounted(async () => { 
    functions.initSoftLoader();
    const Company = new factory(getToken(), 'companies/' + route.query.id);
    await Company.getHttp().then(response => {
        if (response.data.status == "success") {
            form.value = response.data.data;
            console.log(response.data.data);
        } else {
            return response.data.message;
        }
    });
    const user = new factory(getToken(), 'users/company/'+authStore.user.u_vendor_id);
    const dt = await user.getHttp().then(response => {
        users.value = response.data.data;
        console.log(users);
    });
    const companytypo = new factory(getToken(), 'companytypes/vendor/'+authStore.user.u_vendor_id);
    const dtt = await companytypo.getHttp().then(response => {
        companytypes.value = response.data.data;
        console.log(companytypes);
    });
    functions.callOnReady();
})
</script>