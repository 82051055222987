<template>
    <nav class="pcoded-navbar" navbar-theme="themelight1" active-item-theme="theme1" sub-item-theme="theme2"
        active-item-style="style0" pcoded-navbar-position="fixed">
        <!-- <nav class="pcoded-navbar" navbar-theme="themelight1" active-item-theme="theme1" sub-item-theme="theme2"
        active-item-style="style0" pcoded-navbar-position="fixed"> -->
        <div class="pcoded-inner-navbar main-menu">
            <ul class="pcoded-item pcoded-left-item">
                <li class="">
                    <router-link :to="{ name: 'SADashboard' }">
                        <span class="pcoded-micon"><i class="feather icon-aperture rotate-refresh"></i></span>
                        <span class="pcoded-mtext">Dashboard</span>
                    </router-link>
                </li>
                <li class="">
                    <router-link :to="{ name: 'SAVendors' }">
                        <span class="pcoded-micon"><i class="ion-flag"></i></span>
                        <span class="pcoded-mtext">All Vendors</span>
                    </router-link>
                </li>
                <li class="">
                    <router-link :to="{ name: 'SASubscriptions' }">
                        <span class="pcoded-micon"><i class="feather icon-menu"></i></span>
                        <span class="pcoded-mtext">Subscription Plans</span>
                    </router-link>
                </li>

                <li class="">
                    <router-link :to="{ name: 'SAUsers' }">
                        <span class="pcoded-micon"><i class="feather icon-users"></i></span>
                        <span class="pcoded-mtext">Users</span>
                    </router-link>
                </li>
                 
            </ul>
             
        </div>
    </nav>
</template>
<script>
export default {
    name: "Sidebar",
    data() {
        return {
            name: null,
        }
    },
}
</script>
